import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, withRouter } from "react-router-dom";
import Spinner from "sushiweb/Spinner/Spinner";
import Modal from "sushiweb/Modal";
import Button from "sushiweb/Button";
import {
  trackPopUp,
  sideMenu,
  showNotification,
  showOutletSwitchModal,
  showChatBot,
  toggleSOAModal,
} from "../../../../redux/dispatchers/userDispatcher";
import styles from "./AuthOptions.module.scss";
import { signOut } from "../../../../redux/dispatchers/authDispatcher";
import stateTypes from "../../../../utils/constants/stateTypes";
import {
  getThousandsFormatCurrency,
  handleActionType,
  clearLocalStorage
} from "../../../../utils/helpers";
import { CTA_COLOUR } from "../../../../utils/constants/others";
import Cross from "sushiweb/Icons/all/Cross";
import { HTMLDiv } from "../../HTMLDiv";

class AuthOptions extends React.Component {
  state = {
    isLogoutVisible: false,
  };

  toggleModalVisible(action) {
    this.setState((prevState) => ({
      isLogoutVisible: action,
    }));
  }

  handleClick = (e) => {
    const authOptionsNode = document.getElementById("authOptions");
    if (
      authOptionsNode &&
      authOptionsNode !== e.target &&
      !authOptionsNode.contains(e.target)
    ) {
      this.props.toggleMenuOpen();
    }
  };

  componentDidMount() {
    if (
      this.props.userDataStatus.state === stateTypes.STATE_DONE &&
      !this.props.authOptions && this.props.isAuthenticated
    ) {
      this.props.actions.sideMenu();
    }
    if (this.props.device === "desktop") {
      window.addEventListener("click", this.handleClick);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.userData &&
      this.props.userDataStatus.state === stateTypes.STATE_DONE &&
      !this.props.authOptions && this.props.isAuthenticated
    ) {
      this.props.actions.sideMenu();
    }
  }

  componentWillUnmount() {
    if (this.props.device === "desktop") {
      window.removeEventListener("click", this.handleClick);
    }
  }

  renderWalletBalance() {
    const { paymentOptionsForDropdownStatus, paymentOptions } = this.props;

    if (
      paymentOptionsForDropdownStatus.state === stateTypes.STATE_INIT ||
      paymentOptionsForDropdownStatus.state === stateTypes.STATE_BEGIN
    ) {
      return (
        <div className={`d-inline-block ${styles.ml12px}`}>
          <Spinner color={CTA_COLOUR} />
        </div>
      );
    } else if (
      paymentOptionsForDropdownStatus.state === stateTypes.STATE_DONE
    ) {
      return (
        <span className={styles.amountBg}>
          {getThousandsFormatCurrency(paymentOptions.wallet.totalAmount)}
        </span>
      );
    } else {
      return <span className={`text-red ${styles.ml12px}`}>Error</span>;
    }
  }

  renderAmount(amount) {
    return (
      <span className={styles.amountBg}>
        {getThousandsFormatCurrency(amount)}
      </span>
    );
  }

  renderZPayLaterBalance() {
    const { paymentOptionsForDropdownStatus, paymentOptions } = this.props;

    if (
      !(
        paymentOptions.payLater.optInStatus &&
        paymentOptions.payLater.isEligible
      )
    ) {
      return null;
    }

    if (
      paymentOptionsForDropdownStatus.state === stateTypes.STATE_INIT ||
      paymentOptionsForDropdownStatus.state === stateTypes.STATE_BEGIN
    ) {
      return (
        <div className={`d-inline-block ${styles.ml12px}`}>
          <Spinner color={CTA_COLOUR} />
        </div>
      );
    } else if (
      paymentOptionsForDropdownStatus.state === stateTypes.STATE_DONE
    ) {
      return (
        <span className={styles.amountBg}>
          {getThousandsFormatCurrency(
            paymentOptions.payLater.availableCreditLimit
          )}
        </span>
      );
    } else {
      return <span className={`text-red ${styles.ml12px}`}>Error</span>;
    }
  }

  renderCreditBalance() {
    const { paymentOptionsForDropdownStatus, paymentOptions } = this.props;

    if (
      paymentOptionsForDropdownStatus.state === stateTypes.STATE_INIT ||
      paymentOptionsForDropdownStatus.state === stateTypes.STATE_BEGIN
    ) {
      return (
        <div className={`d-inline-block ${styles.ml12px}`}>
          <Spinner color={CTA_COLOUR} />
        </div>
      );
    } else if (
      paymentOptionsForDropdownStatus.state === stateTypes.STATE_DONE
    ) {
      return (
        <span className={styles.amountBg}>
          {getThousandsFormatCurrency(paymentOptions.credit.totalAmount)}
        </span>
      );
    } else {
      return <span className={`text-red ${styles.ml12px}`}>Error</span>;
    }
  }

  trackPopUp = (url) => {
    this.props.actions.trackPopUp(
      this.props.paymentOptions.payLater.trackingParams
    );
    this.props.history.push(url);
  };

  toggleChatBot=()=>{
    this.props.actions.showChatBot(this.props.isSummaryPage  , this.props.renderChatBotEverywhere)
    this.props.setSourceSideMenuTrue()
    this.props.toggleMenuOpen()
  }

  toggleSOAModal=()=>{
    this.props.actions.toggleSOAModal(!this.props.showSOAModal)
  }

  renderOptions() {
    const {
      userDataStatus,
      userData,
      toggleDownloadModalHandler,
      toggleEmailModalHandler,
      authOptionStatus,
      authOptions,
    } = this.props;
    if (
      userDataStatus.state === stateTypes.STATE_INIT ||
      userDataStatus.state === stateTypes.STATE_BEGIN ||
      authOptionStatus.state === stateTypes.STATE_INIT ||
      authOptionStatus.state === stateTypes.STATE_BEGIN
    ) {
      return (
        <div className={styles.section}>
          <div
            className={`${styles.dropdownItem} d-flex justify-content-center`}
          >
            <Spinner color={CTA_COLOUR} />
          </div>
        </div>
      );
    } else if (
      userDataStatus.state === stateTypes.STATE_FAIL ||
      authOptionStatus.state === stateTypes.STATE_FAIL
    ) {
      return (
        <div className={styles.section}>
          <div
            className={`${styles.dropdownItem} d-flex justify-content-center`}
          >
            <span className="text-red">Error</span>
          </div>
          <div
            className={`${styles.dropdownItem} pointer`}
            onClick={() => {
              this.toggleModalVisible(true);
            }}
          >
            <img
              src="/assets/images/logout.svg"
              className={styles.imageIcon}
              style={{ height: "15px" }}
              alt=""
            />
            <span className={`${styles.text} red-text-cta ${styles.logout}`}>
              Logout
            </span>
          </div>
        </div>
      );
    } else if (authOptions) {
      const BannerDetails=authOptions?.Header?.BottomContainer
      return (
        <React.Fragment>
          <div className={styles.header} style={{
              height: authOptions?.Header?.BottomContainer ? '140px' : '120px',
            }}>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className={styles.headerContent}>
                <img
                  src={authOptions.Header.Image}
                  alt=""
                  className={styles.headerIcon}
                />
                <div className={styles.fixedWidth}>
                  {/* Outlet Name comes in SubTitle key but is the title */}
                  <div className="fs-24 w-600 text-gray-900 mb-1" style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}>
                    {authOptions.Header?.SubTitle}
                  </div>
                  {/* Account Name comes in Title */}
                  <div className="fs-16 w-500 text-gray-900" style={{ color: '#767C8F' , whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',}}>
                    {authOptions.Header?.Title}
                  </div>
                  
                </div>
              </div>
              {authOptions.Header?.CTA ? (
              <div
                className={styles.switchOutletCta}
                onClick={() =>
              {
              this.props.actions.trackPopUp({
                ActionType : "click",
                Element: "switch_outlet_option",
                ReferenceId : 0,
                ReferenceType: "",
                Source: "SideMenu"
              })
              this.props.actions.showOutletSwitchModal( authOptions.Header.CTA?.Action?.ActionType === "SWITCH_OUTLET" ?  true: false,{Source: "SideMenu"})
              this.props.toggleMenuOpen()
              }
                  // handleActionType(
                  //   authOptions.Header.CTA?.Action?.ActionType,
                  //   authOptions.Header.CTA?.Action?.ActionData,
                  //   this.props.history
                  // )
                }
              >
                <img
                  src={authOptions.Header.CTA?.IconImage}
                  alt=""
                  className={styles.ctaIcon}
                />
                <div
                  className="fs-18 w-600 pointer"
                  style={{
                    color: authOptions.Header.CTA?.TextColor,
                  }}
                >
                  {authOptions.Header.CTA?.Text}
                </div>
              </div>
            ) : null}
            </div>
          
          </div>
          {
          authOptions?.Header?.BottomContainer && 
          <div className={`pointer ${styles.bannerContainer}`} onClick={() => (window.location.href = "/in/hp-plus")}>
             <div className={styles.bannerDetailContainer} style={{
                   background: `linear-gradient(${BannerDetails.BgGradient?.colors[0]}, ${BannerDetails.BgGradient?.colors[1]})`}}>
              <div className={styles.horizontalCenter}>
                <img src={BannerDetails?.Image?.ImageURL} style={{marginRight:"8px", height:BannerDetails?.Image?.Height, width:BannerDetails?.Image?.Width}} alt=''/>
                  <HTMLDiv className={styles.textStyle} html={BannerDetails?.Heading?.Text}/>
               </div>
             <div className={styles.horizontalCenter}>
              <div className={styles.bannerSubHeading} 
                   style={{ background:`linear-gradient(${BannerDetails.SubHeadingContainer.BgGradient?.colors[0]}, ${BannerDetails.BgGradient?.colors[1]})`,
                          border:` ${BannerDetails.SubHeadingContainer?.BorderData?.Width}px solid ${BannerDetails.SubHeadingContainer?.BorderData?.Color}`}}>
               <HTMLDiv  className={styles.textStyleSubHeading} html={BannerDetails?.SubHeadingContainer?.Text?.Text}/>
               {
                BannerDetails?.SubHeadingContainer?.Text?.SuffixIcon && <img className={styles.suffixIcon} src={BannerDetails.SubHeadingContainer.Text.SuffixIcon.ImageURL} alt=''/> 
               }
              </div>
                {
                  BannerDetails?.RightIcon?.ImageURL &&  <img src={BannerDetails.RightIcon.ImageURL} className={styles.bannerRightIcon} alt=''/>
                }
                </div>
              </div>
          </div>
         }
          {(
            <React.Fragment>
              {authOptions.Sections.map((section) => {
                return (
                  section?.Data?.length > 0 && (
                    <div className={styles.section} key={section.Heading}>
                      <div className={styles.dropdownItemWrapper}>
                        {section.Heading ? (
                          <div
                          className="fs-16 w-800  mb-4 d-flex align-items-center"
                          style={{ marginLeft: "-13px" }}
                        >
                          <div
                            style={{
                              borderLeft: "4px solid red",
                              height: "24px",
                              padding: 0,
                              marginRight: "5px",
                              borderTopRightRadius: "4px",
                              borderBottomRightRadius: "4px",
                            }}
                          ></div>
                          <span className="ml-2">{section.Heading}</span>
                        </div>
                        ) : (
                          <></>
                        )}
                        {section?.Data?.length > 0 &&
                          section?.Data.map((ele, index, data) => {
                            return (
                              <React.Fragment key={index}>
                                <div
                                  onClick={() =>
                                    handleActionType(
                                      ele.Action.ActionType,
                                      ele.Action.ActionData,
                                      this.props.router,
                                      {
                                        logout: () =>
                                          this.toggleModalVisible(true),
                                        downloadStatement: () =>
                                          toggleDownloadModalHandler(),
                                        emailStatement: () =>
                                          toggleEmailModalHandler(),
                                        trackPopUp: () =>
                                          this.props.actions.trackPopUp(
                                            ele.Action.TrackingParams,
                                          ),
                                        showNotification: () =>
                                          this.props.actions.showNotification(
                                            true,
                                          ),
                                        closeModal: () => this.toggleChatBot(),
                                        toggleSOAModal: () =>
                                          this.toggleSOAModal(),
                                      },
                                    )
                                  }
                                  className={`${styles.dropdownItem} link-dark-black `}
                                >
                                  <div className="d-flex justify-content-between wd-100">
                                    <div>
                                      <img
                                        src={ele.Icon}
                                        className={styles.imageIcon}
                                        alt="icon"
                                      />
                                      <span
                                        className={`${styles.text} ${
                                          ele.Action.ActionType === 'LOGOUT'
                                            ? 'text-red-cta'
                                            : 'text-gray-900'
                                        }`}
                                      >
                                        {ele.Name}
                                      </span>
                                    </div>
                                    {ele.New && (
                                      <div
                                        className={
                                          `${styles.newIcon} ` + 'fs-11 w-400'
                                        }
                                      >
                                        NEW
                                      </div>
                                    )}
                                  </div>
                                  {ele?.Name !== 'Logout' && (
                                    <div className="d-flex align-items-center">
                                      {ele.ShowAmount
                                        ? this.renderAmount(ele.Amount)
                                        : null}
                                      <img
                                        src={ele.SideIcon}
                                        className={styles.sideIcon}
                                        alt="icon"
                                      />
                                    </div>
                                  )}
                                </div>
                                {index < data.length - 1 ? (
                                  <div className="line-light"></div>
                                ) : null}
                              </React.Fragment>
                            );
                          })}
                      </div>
                    </div>
                  )
                );
              })}
            </React.Fragment>
          )}
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <div
        className={`${
          this.props.device === "mobile"
            ? styles.navbarSidebarDropdown
            : styles.navbarDropdown
        }`}
        id="authOptions"
      >
        <Modal
          visible={this.state.isLogoutVisible}
          onClose={() => {
            this.props.toggleMenuOpen();
            this.toggleModalVisible(false);
          }}
          title=" "
          showCloseIcon={true}
          isCentered
          type="default"
          padding="2rem"
        >
          <React.Fragment>
            <div
              className={`d-flex mt-3 justify-content-center flex-column align-items-center ${styles.modalCont}`}
            >
              <img
                src= { process.env.PUBLIC_URL+"/assets/images/logoutGroup.svg"}
                alt="Logout Validation"
                className={`mw-100 mb-2`}
              />
              <div className="mt-5 text-align-center fs-18">
                Are you sure you want to logout ?
              </div>
              <div className="mt-4 mb-2 d-flex justify-content-center">
                <Button
                  btnColor="red"
                  appearance="outline"
                  onClick={() => {
                    this.props.toggleMenuOpen();
                    this.toggleModalVisible(false);
                  }}
                >
                  <span className="w-400">No</span>
                </Button>
                <Button
                  className="ml-4 solid w-400"
                  btnColor="red"
                  onClick={() => {
                    clearLocalStorage();
                    this.props.toggleMenuOpen();
                    this.props.actions.signOut();
                  }}
                >
                  Yes
                </Button>
              </div>
            </div>
          </React.Fragment>
        </Modal>
        {this.renderOptions()}
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      // data
      userData: state.user.data,
      authOptions: state.user.authOptions,
      paymentOptions: state.payment.paymentOptions,
      authOptionStatus: state.status.user_authOptions,
      isAuthenticated: state.auth.isAuthenticated,
      showChatBot : state.user.showChatBotConfig?.showChatBot,
      isSummaryPage: state.user.showChatBotConfig?.isSummaryPage,
      renderChatBotEverywhere : state.user.showChatBotConfig?.renderChatBotEverywhere ,
      // status
      userDataStatus: state.status.user_getData,
      paymentOptionsForDropdownStatus:
        state.status.payment_getOptionsForDropdown,
      showSOAModal: state.user.showSOAModal,
    }),
    (dispatch) => ({
      actions: bindActionCreators(
        {
          signOut,
          trackPopUp,
          sideMenu,
          showNotification,
          showOutletSwitchModal,
          showChatBot,
          toggleSOAModal,
        },
        dispatch
      ),
    })
  )(AuthOptions)
);
