import actionTypes from "../actionTypes";

const initialState = {
  sessionId: null,
  merchantId: null,
  maskedNumber: null,
  originalData: null,
  userType: null,
  stepOne: {
    ownerName: null,
    legalName: null,
    panNumber: null,
    panImage: null,
    email: null,
    phoneNumber: null
  },
  stepTwo: {
    outlets: [],
    outletCheckBox: {},
    outletTimeSlot: {},
    outletPincode: {},
    originalPincode: {},
    selectedOutlets: []
  },
  stepThree: {
    phoneNumber: null,
    otp: null,
    //
    isSet: false,
  },
  documents: {
    pan: ""
  },
  signedInFromZomato: false,
  globalSignedInFromZomato: false
};

function happyRegisterReducer(state = initialState, action) {
  const payload = action.payload;

  switch (action.type) {
    case actionTypes.FETCH_MERCHANT_ZOMATO_DONE:
      {
        const stepOneData = payload.data;

        return {
          ...state,
          originalData: stepOneData,
          sessionId: stepOneData.SessionId,
          merchantId: stepOneData.Merchant.MerchantId,
          maskedNumber: stepOneData.UserPhoneMasked,
          stepOne: {
            ...state.stepOne,
            isSet: true,
            //
            ownerName: stepOneData.Merchant.OwnerName,
            legalName: stepOneData.Merchant.LegalName,
            panNumber: stepOneData.Merchant.PAN,
            panImage: stepOneData.Merchant.PANImagePath,
            email: stepOneData.Merchant.Email,
            phoneNumber: stepOneData.Merchant.PhoneNumber,
          },
          stepTwo: {
            ...state.stepTwo,
            isSet: true,
            //
            outletCheckBox: stepOneData.Restaurants.reduce((acc, ele) => {
              acc[ele.ResId] = {
                isChecked: true,
                HPOutletId: ele.HPOutletId,
                ErrorMessage: ele.ErrorMessage
              };
              return acc
            }, {}),
            outletTimeSlot: stepOneData.Restaurants.reduce((acc, ele) => {
              acc[ele.ResId] = null;
              return acc
            }, {}),
            outletPincode: stepOneData.Restaurants.reduce((acc, ele) => {
              acc[ele.ResId] = ele.Pincode;
              return acc
            }, {}),
            originalPincode: stepOneData.Restaurants.reduce((acc, ele) => {
              acc[ele.ResId] = ele.Pincode;
              return acc
            }, {}),
            outletPincodeError: stepOneData.Restaurants.reduce((acc, ele) => {
              acc[ele.ResId] = ele.Pincode ? true : null;
              return acc
            }, {}),
            outlets: stepOneData.Restaurants
          },
        };
      }

    case actionTypes.HAPPY_REGISTER_STEP_ONE_DONE:
      {
        const stepOneData = payload.data;

        return {
          ...state,
          stepOne: {
            ...state.stepOne,
            isSet: true,
            //
            ownerName: stepOneData.ownerName,
            legalName: stepOneData.legalName,
            accountName: stepOneData.accountName,
            panNumber: stepOneData.panNumber,
            panImage: stepOneData.panImage,
            phoneNumber: stepOneData.phoneNumber,
            panDisplayImage: stepOneData.panDisplayImage,
            email: stepOneData.email,
            referralCode: stepOneData.referralCode
          },
        };
      }

    case actionTypes.HAPPY_REGISTER_STEP_THREE_DONE:
      {
        return {
          ...state,
          signedInFromZomato: true,
          globalSignedInFromZomato: true
        };
      }

    case actionTypes.REMOVE_ZOMATO_SIGNEDIN:
      {
        return {
          ...state,
          signedInFromZomato: false
        };
      }

    case actionTypes.VERIFY_ZOMATO_PHONE_DONE:
      {
        const userData = action.data;

        return {
          ...state,
          userType: userData.response.UserType
        };
      }

    case actionTypes.REGISTER_HAPPY_STEP_2_SAVE:
      {
        const stepTwoData = { ...payload.data };

        return {
          ...state,
          stepTwo: {
            ...state.stepTwo,
            outletCheckBox: stepTwoData.outletCheckBox,
            outletTimeSlot: stepTwoData.outletTimeSlot,
            outletPincode: stepTwoData.outletPincode,
            outletPincodeError: stepTwoData.outletPincodeError
          },
        };
      }

    case actionTypes.UPLOAD_ZONBOARD_PAN_IMAGE_DONE:
      return {
        ...state,
        documents: {
          ...state.documents,
          pan: payload.path,
        },
      };

    default:
      return state;
  }
}

export default happyRegisterReducer;
