import _get from "lodash-es/get";

import getAxios from "../../utils/axios";
import APIendpoints from "../../utils/constants/APIendpoints";
import actionTypes from "../actionTypes";

import { DYNAMIC_ENDPOINT } from "../../utils/constants/APIendpoints";

function getPaymentAccessCode(
    type = "order",
    showPaymentMethodsMessageHinting = false
) {
    return (dispatch) => {
        dispatch({ type: actionTypes.PAYMENT_GET_ACCESS_CODE_BEGIN });
        getAxios(true)
            .get(
                APIendpoints.getPaymentAccessCode(
                    type,
                    showPaymentMethodsMessageHinting
                )
            )
            .then(({ data }) => {
                dispatch({
                    type: actionTypes.PAYMENT_GET_ACCESS_CODE_DONE,
                    accessCode: data.response.AccessCode,
                    serviceType: data.response.ServiceType,
                    redirectURL: data.response.HostRedirectUrl,
                    additionalParams: data.response.AdditionalParams,
                    additionalParamsForWallet:
                        data.response.AdditionalParamsForWallet,
                });
            })
            .catch((err) => {
                dispatch({
                    type: actionTypes.PAYMENT_GET_ACCESS_CODE_FAIL,
                    error: _get(
                        err,
                        ["response", "data", "error", "message"],
                        "Something went wrong!"
                    ),
                });
            });
    };
}

function getPaymentHash(requestData) {
    return (dispatch) => {
        dispatch({ type: actionTypes.PAYMENT_GET_HASH_BEGIN });
        getAxios(true)
            .post(APIendpoints.getPaymentHash, requestData)
            .then(({ data }) => {
                dispatch({
                    type: actionTypes.PAYMENT_GET_HASH_DONE,
                    paymentHash: data.response.PaymentHash,
                });
            })
            .catch((err) => {
                dispatch({
                    type: actionTypes.PAYMENT_GET_HASH_FAIL,
                    error: _get(
                        err,
                        ["response", "data", "error", "message"],
                        "Something went wrong!"
                    ),
                });
            });
    };
}

function updatePaymentStatus(paymentGUID, params, callBack) {
    return (dispatch) => {
        dispatch({ type: actionTypes.PAYMENT_UPDATE_STATUS_BEGIN });
        getAxios(true)
            .post(APIendpoints.updatePaymentStatus(paymentGUID), params)
            .then(({ data }) => {
                const response = data.response;

                let { ResponseV2 = {} } = response;
                if (!ResponseV2) {
                    ResponseV2 = {};
                }
                dispatch({
                    type: actionTypes.PAYMENT_UPDATE_STATUS_DONE,
                    payload: {
                        responseV3: response.ResponseV3,
                        source: response.Source,
                        transactionStatus: response.TransactionStatus,
                        transactionMessage:
                            ResponseV2.PaymentTransactionMessage,
                        forPlacedOrders: ResponseV2.PayingForPlacedOrders,
                        orders: ResponseV2.Orders,
                        paymentNumber: ResponseV2.PaymentNumber,
                        text: ResponseV2.Text,
                        subText: ResponseV2.SubText,
                        message: ResponseV2.Message,
                        image: ResponseV2.Image,
                        mediaURL: ResponseV2.MediaUrl,
                        totalOutstandingAmount:
                            ResponseV2.TotalOutstandingAmount,
                        paymentTxnMessage: ResponseV2.PaymentTxnMessage,
                        onlineAmount: ResponseV2.OnlineAmount,
                        mainCTA: ResponseV2.MainCTA,
                        loyaltyReward: ResponseV2.LoyaltyReward,
                        info: ResponseV2.Info,
                        orderInfo: ResponseV2.OrderInfo,
                    },
                });

                if (typeof callBack === "function") {
                    callBack(response);
                }
            })
            .catch((err) => {
                dispatch({
                    type: actionTypes.PAYMENT_UPDATE_STATUS_FAIL,
                    error: _get(
                        err,
                        ["response", "data", "error", "message"],
                        "Something went wrong!"
                    ),
                });
            });
    };
}

function makePendingPayment(requestData) {
    return (dispatch) => {
        dispatch({ type: actionTypes.PAYMENT_MAKE_PENDING_BEGIN });
        getAxios(true)
            .post(APIendpoints.makePendingPayment, requestData)
            .then(({ data }) => {
                const response = data.response;

        dispatch({
          // check here
          type: actionTypes.PAYMENT_MAKE_PENDING_DONE,
          payload: {
            orders: response.Orders || [],
            paymentGUID: response.PaymentGuid,
            encryptedPaymentGuid: response.EncryptedPaymentGuid,
            paymentPlan: {
              online: response.OnlineAmount,
              wallet: response.WalletAmount,
              cod: response.CODAmount,
            },
            gatewayInfo: response.GatewayInfo,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.PAYMENT_MAKE_PENDING_FAIL,
          error: _get(
            err,
            ["response", "data", "error", "message"],
            "Something went wrong!"
          ),
        });
      });
  };
}

function getPaymentOptions(outletId, isForDropdown) {
    return (dispatch) => {
        dispatch({
            type: isForDropdown
                ? actionTypes.PAYMENT_GET_OPTIONS_FOR_DROPDOWN_BEGIN
                : actionTypes.PAYMENT_GET_OPTIONS_BEGIN,
        });
        getAxios(true)
            .get(DYNAMIC_ENDPOINT.getPaymentOptions(outletId))
            .then(({ data }) => {
                const refund = data.response.Refund;
                dispatch({
                    type: isForDropdown
                        ? actionTypes.PAYMENT_GET_OPTIONS_FOR_DROPDOWN_DONE
                        : actionTypes.PAYMENT_GET_OPTIONS_DONE,
                    payload: {
                        creditTotalAmount:
                            data.response.Credit?.TotalAmount || 0,
                        creditAccountId:
                            data.response.Credit?.CreditAccountId || 0,
                        isOnlinePaymentEnabled:
                            data.response.Credit?.OnlinePaymentEnabled,
                        onlinePaymentExeedingMessage:
                            data.response.Credit?.OnlinePaymentExceedingMessage,
                        isCreditDisabled: data.response.Credit?.IsDisabled,
                        creditMessage: data.response.Credit?.Message,
                        walletTotalAmount: data.response.Wallet?.TotalAmount,
                        walletEntries: data.response.Wallet?.WalletEntries,
                        refundDetail: (refund || {}).RefundDetail,
                        refundHead: (refund || {}).RefundHead,
                        refundToWallet: (refund || {}).RefundToWallet,
                        walletFilters: data.response.Wallet?.WalletFilters,
                        walletNearestExpiry:
                            data.response.Wallet?.WalletNearestExpiry,
                        walletTopUpRail: data.response.Wallet?.WalletTopUpRail,
                        walletTopupHeading:
                            data.response.Wallet?.WalletTopupHeading,
                        isWalletTopupActive:
                            data.response.Wallet?.IsWalletTopupActive,
                        maxTopupLimit: data.response.Wallet?.MaxTopupLimit,
                        minTopupLimit: data.response.Wallet?.MinTopupLimit,
                        payLaterAvailableCreditLimit:
                            data.response.PayLater?.AvailableCreditLimit,
                        payLaterIsEligible: data.response.PayLater?.IsEligible,
                        payLaterOptInStatus:
                            data.response.PayLater?.OptInStatus,
                        payLaterIsDeactivated:
                            data.response.PayLater?.IsDeactivated,
                        payLaterNew: data.response.PayLater?.New,
                        payLaterV2: data.response.PayLaterV2,
                        trackingParams: data.response.PayLater?.TrackingParams,
                        cod: data.response?.COD,
                    },
                });
            })
            .catch((err) => {
                dispatch({
                    type: isForDropdown
                        ? actionTypes.PAYMENT_GET_OPTIONS_FOR_DROPDOWN_FAIL
                        : actionTypes.PAYMENT_GET_OPTIONS_FAIL,
                    error: _get(
                        err,
                        ["response", "data", "error", "message"],
                        "Something went wrong!"
                    ),
                });
            });
    };
}

function getPaymentNudge(source) {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_PAYMENT_NUDGE });
        getAxios(true)
            .get(APIendpoints.getPaymentNudges(source))
            .then(({ data }) => {
                const { response: { Nudge } = {} } = data;
                dispatch({
                    type: actionTypes.GET_PAYMENT_NUDGES,
                    payload: { nudge: Nudge, activeNudge: !!Nudge },
                });
            });
    };
}

function clearPaymentDetail() {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_TRANSACTION_DETAILS });
    };
}

function getInvoiceDetails(orderNumbers, callBack, errCallBack) {
    return (dispatch) => {
        dispatch({
            type: actionTypes.GET_INVOICE_DETAILS_BEGIN,
        });
        getAxios(true)
            .get(
                APIendpoints.invoiceDetails +
                    "?order_numbers=" +
                    orderNumbers +
                    "&are_warehouse_details_required=true"
            )
            .then(({ data }) => {
                const results = data.response.response.results;
                dispatch({
                    type: actionTypes.GET_INVOICE_DETAILS_DONE,
                    payload: results,
                });
                if (typeof callBack === "function") {
                    callBack(results);
                }
            })
            .catch((err) => {
                dispatch({
                    type: actionTypes.GET_INVOICE_DETAILS_FAIL,
                    error: _get(
                        err,
                        ["response", "data", "error", "message"],
                        "Something went wrong!"
                    ),
                });
                if (typeof errCallBack === "function") {
                    errCallBack(err);
                }
            });
    };
}

function getPaymentInfo(outletId, dontReload) {
    return (dispatch) => {
        if (!dontReload) {
            dispatch({ type: actionTypes.GET_PAYMENT_INFO_BEGIN });
        }
        getAxios(true)
            .get(APIendpoints.getPaymentInfo(outletId))
            .then(({ data }) => {
                const { response } = data;
                if (!dontReload) {
                    dispatch({
                        type: actionTypes.GET_PAYMENT_INFO_DONE,
                        payload: response,
                    });
                } else {
                    dispatch({
                        type: actionTypes.GET_PAYMENT_INFO_NO_RELOAD,
                        payload: response,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: actionTypes.GET_PAYMENT_INFO_FAIL,
                    error: _get(
                        err,
                        ["response", "data", "error", "message"],
                        "Something went wrong!"
                    ),
                });
            });
    };
}

function updatePaymentInfo(params) {
    return (dispatch) => {
        dispatch({ type: actionTypes.UPDATE_PAYMENT_INFO_BEGIN });
        getAxios(true)
            .put(APIendpoints.getPaymentInfo(params.OutletId), params)
            .then(({ data }) => {
                const { response } = data;
                dispatch({
                    type: actionTypes.UPDATE_PAYMENT_INFO_DONE,
                    payload: response,
                });
            })
            .catch((err) => {
                dispatch({
                    type: actionTypes.UPDATE_PAYMENT_INFO_FAIL,
                    error: _get(
                        err,
                        ["response", "data", "error", "message"],
                        "Something went wrong!"
                    ),
                });
            });
    };
}

function clearPaymentInfo() {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_PAYMENT_INFO });
    };
}

function createInfoPopup(actionData) {
    return (dispatch) => {
        dispatch({
            type: actionTypes.PAYMENT_INFO_POPUP,
            payload: actionData,
        });
    };
}

function clearInfoPopup() {
    return (dispatch) => {
        dispatch({
            type: actionTypes.PAYMENT_INFO_POPUP,
            payload: {},
        });
    };
}

function clearConsumerRefundJourney() {
    return (dispatch) => {
        dispatch({
            type: actionTypes.GET_CONSUMER_REFUND_JOURNEY_INIT,
            payload: {},
        });
    };
}

function getConsumerRefundJourney(params) {
    return (dispatch) => {
        dispatch({ type: actionTypes.GET_CONSUMER_REFUND_JOURNEY_BEGIN });
        getAxios(true)
            .get(APIendpoints.consumerRefundJourney(params))
            .then(({ data }) => {
                const { response } = data;
                dispatch({
                    type: actionTypes.GET_CONSUMER_REFUND_JOURNEY_DONE,
                    payload: response,
                });
            })
            .catch((err) => {
                dispatch({
                    type: actionTypes.GET_CONSUMER_REFUND_JOURNEY_FAIL,
                    error: _get(
                        err,
                        ["response", "data", "error", "message"],
                        "Something went wrong!"
                    ),
                });
            });
    };
}

function getPaymentDetails(identifier, paymentMethodId, paymentMethodType, successCallback) {
    return (dispatch) => {
        getAxios(true)
            .get(APIendpoints.paymentDetails(identifier, paymentMethodId, paymentMethodType))
            .then(({ data }) => {

        if (data.response.Source === 'hp_plus') {

          dispatch({
            type: actionTypes.HP_PLUS_PAYMENT_INFO_DONE,
            payload: data.response
          })
        }
        else {
          dispatch({
            type: actionTypes.GET_ORDER_PAYMENT_INFO_DONE,
            payload: data.response,
          });

          console.log("getPaymentDetails res -> ", { res: data.response });

          let temp = { orders: data.response?.Orders || [], };
          if (data.response?.PaymentPlan) {
            // temp = { ...temp, paymentPlan: data.response.PaymentPlan };
            temp = {
              ...temp,
              paymentPlan: {
                credit: data.response?.PaymentPlan?.credit,
                wallet: data.response?.PaymentPlan?.wallet,
                online: data.response?.PaymentPlan?.online,
              }
            };
            // temp = { ...temp, credit: data.response?.PaymentPlan?.credit, wallet: data.response?.PaymentPlan?.wallet, online: data.response?.PaymentPlan?.online };
          }
          if (data.response?.GatewayInfo) {
            temp = { ...temp, gatewayInfo: data.response.GatewayInfo };
          }
          if (data.response?.PaymentGUID) {
            temp = { ...temp, paymentGUID: data.response.PaymentGUID };
          }
          if (data.response?.EncryptedPaymentGuid) {
            temp = { ...temp, encryptedPaymentGuid: data.response.EncryptedPaymentGuid }
          }
          if (data.response?.ShowPaymentMethodsMessageHinting) {
            temp = {
              ...temp,
              showPaymentMethodsMessageHinting:
              data.response.ShowPaymentMethodsMessageHinting
            }
          }

          console.log("PAYMENT_MAKE_PENDING_DONE data -> ", { temp });

          dispatch({
            type: actionTypes.PAYMENT_MAKE_PENDING_DONE,
            payload: temp,
          })

          if (typeof successCallback === "function") {
            successCallback(data.response);
          }
        }
      })
      .catch((err) => {
        console.log("goes to catch block GET_ORDER_PAYMENT_INFO_FAIL ", err);
        dispatch({
          type: actionTypes.GET_ORDER_PAYMENT_INFO_FAIL,
          error: _get(
            err,
            ["response", "data", "error", "message"],
            "Something went wrong!"
          ),
        });
      });
  };
}

function accquireLockOnPaymentGuid(identifier, cb) {
  const requestData = {
    "EncryptedGuid": identifier,
  };
  return (dispatch) => {
    dispatch({ type: actionTypes.ACQUIRE_LOCK_BEGIN });
      getAxios(true)
          .post(APIendpoints.accquireLock, requestData)
          .then(() => {
              cb();
          })
          .catch((err) => {
              dispatch({
                  type: actionTypes.ACQUIRE_LOCK_FAIL,
                  error: _get(
                      err,
                      ["response", "data", "error", "message"],
                      "Something went wrong!"
                  ),
              });
          });
  };
}

export {
  getPaymentAccessCode,
  getPaymentHash,
  updatePaymentStatus,
  getPaymentOptions,
  makePendingPayment,
  getPaymentNudge,
  clearPaymentDetail,
  getInvoiceDetails,
  getPaymentInfo,
  updatePaymentInfo,
  clearPaymentInfo,
  createInfoPopup,
  clearInfoPopup,
  getConsumerRefundJourney,
  clearConsumerRefundJourney,
  getPaymentDetails,
  accquireLockOnPaymentGuid,
};
