import React from "react";
import Modal from "sushiweb/Modal";
import Checkbox from "sushiweb/InputTypes/Checkbox";
import Button from "sushiweb/Button";
import Toast from "sushiweb/Toast";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import styles from "./TermsForBuyer.module.scss";
import stateTypes from "../../../utils/constants/stateTypes";
import { acceptTnC , setErrorToast } from "../../../redux/dispatchers/userDispatcher";
import { CTA_COLOUR } from "../../../utils/constants/others";
import { ToastHandler } from "../../shared/Toast/ToastHandler";

class TermsForBuyer extends React.Component {
  state = {
    showModal: false,
    accept: false,
    toast: {
      show: false,
      text: null,
      type: "default",
    },
  };

  /**
   * Lifecycle methods
   */
  componentDidUpdate(prevProps,prevState) {
    const { userData, acceptanceTnCStatus } = this.props;
    if (prevState.toast !== this.state.toast && this.state.toast.show && this.state.toast.text) {
      this.props.actions.setErrorToast(this.state.toast.text)
    }

    if (!prevProps.userData && userData && userData.account) {
      if (userData.account.tcSignedDated === null) {
        this.setState((prevState) => ({
          ...prevState,
          showModal: true,
        }));
      }
    }

    if (prevProps.acceptanceTnCStatus.state !== acceptanceTnCStatus.state) {
      if (acceptanceTnCStatus.state === stateTypes.STATE_DONE) {
        this.setState((prevState) => ({
          ...prevState,
          showModal: false,
          toast: {
            ...prevState.toast,
            show: true,
            text: "Terms signed successfully! You can start ordering.",
            type: "success",
          },
        }));
      } else if (acceptanceTnCStatus.state === stateTypes.STATE_FAIL) {
        this.setState((prevState) => ({
          ...prevState,
          toast: {
            ...prevState.toast,
            show: true,
            text: "Couldn't sign terms. Please try again",
            type: "errorSolid",
          },
        }));
      }
    }
  }

  /**
   * Handler methods
   */
  modalCloseHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      showModal: false,
    }));
  };

  toastCloseHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      toast: {
        ...prevState.toast,
        show: false,
        text: null,
        type: "default",
      },
    }));
  };

  acceptBtnClickHandler = () => {
    const { actions, userData } = this.props;
    actions.acceptTnC(userData.outlet.id);
  };

  checkboxToggleHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      accept: !prevState.accept,
    }));
  };

  render() {
    const { acceptanceTnCStatus } = this.props;

    return (
      <React.Fragment>
        <Modal
          visible={this.state.showModal}
          size="medium"
          title="Terms and Conditions"
          padding="2rem"
          showCloseIcon={false}
        >
          <div className={styles.content}>
            <p>
              I confirm and undertake that I have read and understood the terms
              and conditions for Hyperpure (“Terms”) offered by Zomato Internet
              Private Limited (“Zomato”) which are displayed on &nbsp;
              <a
                className="text-black"
                href="https://www.hyperpure.com/buyerterms"
                target="_blank"
                rel="noopener noreferrer"
              >
                <u>https://www.hyperpure.com/buyerterms</u>
              </a>
              , the website maintained by Zomato. Further, I agree and
              acknowledge to be subject to and comply with all the provisions of
              the Terms and the Terms constitute a legally binding agreement
              between the parties.
            </p>
            <div>
              <Checkbox
                checked={this.state.accept}
                color={CTA_COLOUR}
                value="tnc"
                onChange={this.checkboxToggleHandler}
                label="Accept Terms and Conditions"
              />
            </div>
            <div className="d-flex justify-content-end">
              <Button
                btnColor="red"
                className={`w-400 ${!this.state.accept ? '' : 'solid'}`}
                onClick={this.acceptBtnClickHandler}
                loading={acceptanceTnCStatus.state === stateTypes.STATE_BEGIN}
                disabled={!this.state.accept}
              >
                Start Ordering
              </Button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default connect(
  (state) => ({
    // data
    userData: state.user.data,
    // status
    acceptanceTnCStatus: state.status.buyer_accept_tnc,
  }),
  (dispatch) => ({ actions: bindActionCreators({ acceptTnC ,setErrorToast}, dispatch) })
)(TermsForBuyer);
