import { combineReducers } from "redux";

import authReducer from "./authReducer";
import statusReducer from "./statusReducer";
import userReducer from "./userReducer";
import catalogReducer from "./catalogReducer";
import cartReducer from "./cartReducer";
import creditReducer from "./creditReducer";
import orderReducer from "./orderReducer";
import paymentReducer from "./paymentReducer";
import registerReducer from "./registerReducer";
import happyRegisterReducer from "./happyRegisterReducer";
import dashboardReducer from "./ownersDashboardReducer";
import aerobars from "./aerobars";
import acquisitionRail from "./acquisition";
import downloadDaApp from "./downloadDaApp";
import toast from "./toastReducer";
import campaignReducer from "./campaignReducer";
import common from "./common";
import errorHandler from "./errorHandler";
import corporateAnnouncements from "./corporateAnnouncementsReducer";
import orderIssueTicket from "./orderIssueReducer";
import configReducer from "./configReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  status: statusReducer,
  user: userReducer,
  catalog: catalogReducer,
  cart: cartReducer,
  credit: creditReducer,
  dashboard: dashboardReducer,
  order: orderReducer,
  payment: paymentReducer,
  register: registerReducer,
  happyRegister: happyRegisterReducer,
  aerobars: aerobars,
  acquisitionRail: acquisitionRail,
  downloadDaApp: downloadDaApp,
  toast: toast,
  brandCampaign: campaignReducer,
  common: common,
  errorHandler: errorHandler,
  corporateAnnouncements: corporateAnnouncements,
  orderIssueTicket : orderIssueTicket,
  settingsConfig:configReducer,
});

export default rootReducer;
