import React, { Component } from "react";

import ChevronUp from "sushiweb/Icons/all/ChevronUp";
import { getScroll } from "../../../utils/helpers";

export class ScrollToTopBtn extends Component {
  state = {
    enableScrollTop: false
  };

  componentDidMount = () => {
    window.addEventListener("scroll", this.listenScroll);
  };

  listenScroll = () => {
    const currTopScrollVal = getScroll();
    if (currTopScrollVal > 300 && this.state.enableScrollTop === false) {
      this.setState(prevState => ({
        ...prevState,
        enableScrollTop: true
      }));
    } else if (currTopScrollVal < 300 && this.state.enableScrollTop === true) {
      this.setState(prevState => ({
        ...prevState,
        enableScrollTop: false
      }));
    }
  };

  scrollTo(element, to, duration) {
    let start = element.scrollTop,
      change = to - start,
      increment = 20;

    let animateScroll = elapsedTime => {
      elapsedTime += increment;
      let position = this.easeInOut(elapsedTime, start, change, duration);
      element.scrollTop = position;
      if (elapsedTime < duration) {
        setTimeout(() => {
          animateScroll(elapsedTime);
        }, increment);
      }
    };

    animateScroll(0);
  }

  easeInOut = (currentTime, start, change, duration) => {
    currentTime /= duration / 2;
    if (currentTime < 1) {
      return (change / 2) * currentTime * currentTime + start;
    }
    currentTime -= 1;
    return (-change / 2) * (currentTime * (currentTime - 2) - 1) + start;
  };

  render() {
    return (
      <React.Fragment>
        {document.documentElement && this.state.enableScrollTop ? (
          <div className="show-on-desktop">
            <div
              className="scroll-to-top"
              onClick={() => this.scrollTo(document.documentElement, 0, 600)}
            >
              <ChevronUp color={"#fff"} />
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default ScrollToTopBtn;
