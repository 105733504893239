import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FacebookCircle, TwitterCircle } from "sushiweb/Icons";
import axios from "axios";
import queryString from "query-string";
import {
  trackPopUp,
  trackingWithoutAuth,
} from "../../../redux/dispatchers/userDispatcher";
import styles from "./Footer.module.scss";
import LinkedinCircle from "sushiweb/Icons/all/LinkedinCircle";
import { HYPERPURE_HELPLINE_NUMBER } from "../../../utils/constants/others";

class Footer extends React.Component {
  openNewWebsite = () => {
    let expireDate = new Date();
    expireDate.setFullYear(expireDate.getFullYear() + 1);

    document.cookie =
      "RouterCookie=v1;expires=" + expireDate.toUTCString() + ";path=/";
    axios({
      url: window.location.origin + "/",
      headers: {
        Pragma: "no-cache",
        Expires: -1,
        "Cache-Control": "no-cache",
      },
    }).then(() => {
      document.cookie =
        "RouterCookie=v1;expires=" + expireDate.toUTCString() + ";path=/";
      window.location.replace("https://www.hyperpure.com/");
    });
  };

  sendTrackingIdForSocialMedia = (type) => {
    let params = {
      ActionType: "CLICK",
      Element: "SOCIAL_MEDIA_LINKS",
      ReferenceId: 0,
      ReferenceType: type,
      Source: !this.props.isAuthenticated ? "FOOTER_LOG_OUT" : "FOOTER_LOG_IN",
    };
    if (this.props.isAuthenticated) {
      this.props.actions.trackPopUp(params);
    } else {
      this.props.actions.trackingWithoutAuth(params);
    }
  };

  render() {
    const searchParam = queryString.parse(window.location.search);
    const isMobScreenSize = window.outerWidth < 992 ? true : false;
    if (
      (searchParam.source === "app" && isMobScreenSize) ||
      searchParam.source === "webOnboardingPage" ||
      searchParam.offer === "false" ||
      window.location.pathname.includes("/a/links/") ||
      window.location.pathname.includes("/z/links/")
    ) {
      return null;
    }

    return (
      <footer className={styles.footer}>
        <div className={`container `}>
          {isMobScreenSize ? (
            <div className="row d-flex justify-content-center align-items-center">
              <div>
                <div className={styles.textIcon}>
                  <div className="text-align-right">
                    <img
                      className={styles.hpIcon}
                      src="../../../assets/images/hp-red-white-icon-footer.png"
                      alt="HP icon"
                    />
                  </div>

                  <div className="text-align-right">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/logo_hp_black.png"
                      }
                      alt="Logo"
                      className={styles.hpTextIcon}
                    />
                  </div>
                </div>
              </div>
              <div className="line-light"></div>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className={`container ${styles.content} fs-14 mb-30px`}>
          <div className="d-flex justify-content-between">
            <div className="row wd-100">
              <div className={styles.companyDetails}>
                <div>
                  <div className="fs-14 w-600 mb-12px">Company</div>
                  <div className="w-400 mb-4px">
                    Zomato Hyperpure Private Limited
                  </div>
                  <div className="text-cool-gray-600 mb-4px w-400">
                    Ground Floor, 12A, 94 Meghdoot, Nehru Place, New Delhi -
                    110019{" "}
                  </div>
                  <div className="text-cool-gray-600 mb-12px w-400">
                    CIN: U74900DL2015PTC286208
                  </div>
                  <div className="d-flex align-items-center mb-12px">
                    <img
                      className={styles.phoneIcon}
                      src="../../../assets/images/phoneFooter.png"
                      alt="Phone icon"
                    />
                    {HYPERPURE_HELPLINE_NUMBER}
                  </div>

                  <div className="d-flex align-items-center mb-12px">
                    <img
                      className={styles.mailIcon}
                      src="../../../assets/images/mailFooter.png"
                      alt="Mail icon"
                    />
                    help@hyperpure.com
                  </div>
                </div>
              </div>

              <div className={styles.knowMore}>
                <div>
                  <div className="fs-14 w-600 mb-12px">Know More</div>
                  <div className="w-400 mb-12px">
                    <Link
                      to={"/z/corporate-announcements"}
                      className="text-dark-blue"
                    >
                      Corporate Announcements
                    </Link>
                  </div>
                  <div className="w-400 mb-12px">
                    <div
                      onClick={() => (window.location.href = "/z/governance")}
                      className="text-dark-blue pointer"
                    >
                      Governance
                    </div>
                  </div>

                  <div className="w-400 mb-12px">
                    <a
                      className="text-dark-blue"
                      href="https://www.zomato.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy
                    </a>
                  </div>

                  <div className="w-400 mb-12px">
                    <Link to={"/Terms"} className="text-dark-blue">
                      Terms of use
                    </Link>
                  </div>

                  <div className="w-400">
                    <a
                      className="text-dark-blue"
                      href="../../../assets/pdf/supplier_code_of_conduct.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Supplier Code of Conduct
                    </a>
                  </div>
                </div>
              </div>

              <div className={styles.followUs}>
                <div>
                  <div className="fs-14 w-600 mb-12px">Follow us on</div>
                  <div className="w-400 mb-12px">
                    <a
                      onClick={() =>
                        this.sendTrackingIdForSocialMedia("LINKEDIN")
                      }
                      className="text-dark-blue"
                      href="https://www.linkedin.com/company/hyperpurebyzomato/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Linkedin
                    </a>
                  </div>

                  <div className="w-400 mb-12px">
                    <a
                      onClick={() =>
                        this.sendTrackingIdForSocialMedia("YOUTUBE")
                      }
                      className="text-dark-blue"
                      href="https://www.youtube.com/@hyperpurebyzomato"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Youtube
                    </a>
                  </div>
                </div>
              </div>

              {isMobScreenSize ? (
                <div className={`d-flex ${styles.store}`}>
                  <div className="mr-16">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.wotu.app"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="../../../assets/images/PlayStoreBadgeFooter.png"
                        alt="Logo"
                        className={styles.playStoreIcon}
                      />
                    </a>
                  </div>

                  <div>
                    <a
                      href="https://apps.apple.com/in/app/hyperpure/id1203646221"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="../../../assets/images/AppStoreBadgeFooter.png"
                        alt="Logo"
                        className={styles.appStoreIcon}
                      />
                    </a>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>

            {!isMobScreenSize ? (
              <div className="column">
                <div className={styles.centerPos}>
                  <img
                    className={styles.hpIcon}
                    src="../../../assets/images/hp-red-white-icon-footer.png"
                    alt="HP icon"
                  />
                </div>
                <div className={styles.centerPos}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/logo_hp_black.png"
                    }
                    alt="Logo"
                    className={styles.hpTextIcon}
                  />
                </div>

                <div className={`d-flex ${styles.store}`}>
                  <div className="mr-16">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.wotu.app"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="../../../assets/images/PlayStoreBadgeFooter.png"
                        alt="Logo"
                        className={styles.playStoreIcon}
                      />
                    </a>
                  </div>

                  <div>
                    <a
                      href="https://apps.apple.com/in/app/hyperpure/id1203646221"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="../../../assets/images/AppStoreBadgeFooter.png"
                        alt="Logo"
                        className={styles.appStoreIcon}
                      />
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="container mb-20">
          <div className="line-light" />
          <div className="d-flex row mt-14 justify-content-between">
            <div className="d-flex align-items-center justify-content-center">
              <img
                src="../../../assets/images/fssaiFooter.png"
                className={styles.fssaiIcon}
                alt="fssai icon"
              />
              <span
                className={`fs-14 fw-400 text-indigo-500 ${styles.license}`}
              >
                License No. 10020064002537
              </span>
            </div>
            <div className={`fs-14 fw-400 text-indigo-500 ${styles.copyright}`}>
              Copyright &copy; Hyperpure All Rights Reserved
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
let actions = {
  trackPopUp,
  trackingWithoutAuth,
};

// export default Footer;
export default connect(
  (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
  }),
  (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
  })
)(Footer);
