import React, { useEffect, useState } from "react";
import _isEmpty from "lodash-es/isEmpty";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  updateIngredientList,
} from "../../../redux/dispatchers/catalogDispatcher";
import Modal from "sushiweb/Modal";
import styles from "./MyList.module.scss";
import Image from "../Image/Image";
import Button from "../Button";

const MyList = (props) => {
  const[ingredientListMeta,setIngredientListMeta] = useState(props?.product?.IngredientListMeta)
  const[infoModal,setInfoModal] = useState({
    visible: false,
  })
  return (
    <>
      <Modal
            visible={infoModal.visible}
            title=""
            showCloseIcon={false}
            isCentered={true}
            onClose={() => {
              setInfoModal({
                ...infoModal,
                visible: false,
              })
            }}
            className={`modal24pxRadius`}
        >
            <div className={`${styles.myListContainer}`}>
                <Image classname={`${styles.myListBackgroundImage}`} source={ingredientListMeta?.InfoBottomSheet?.BackgroundImage} />
                <div className="px-5">
                  {ingredientListMeta?.InfoBottomSheet?.Heading? <div className="d-flex justify-content-center fs-30 fw-700 text-align-center mt-2 mb-2">{ingredientListMeta?.InfoBottomSheet?.Heading}</div> : <></>}
                  {ingredientListMeta?.InfoBottomSheet?.SubHeading? <div className={`${styles.greytext} fs-16 w-300 d-flex justify-content-center text-align-center mt-3 mb-5 `}>{ingredientListMeta?.InfoBottomSheet?.SubHeading}</div> : <></>}
                </div>
                <div className={`${styles.myListCtaContainer}`}>
                    <div className="px-5" >
                        {ingredientListMeta?.InfoBottomSheet?.PrimaryCTA? <div className={`d-flex justify-content-center mt-5 mb-5 wd-100 `}><Button className={`wd-100 d-flex justify-content-center fs-18 ${styles.myListCta}`} 
                        onClick={() => setInfoModal({
                        ...infoModal,
                        visible: false,
                      })}> {ingredientListMeta?.InfoBottomSheet?.PrimaryCTA?.Text}</Button></div> : null}
                    </div>
                </div>
            </div>
        </Modal >
        <div className={`d-flex align-items-center relative ${props.source === "PDP" ? styles.pdpcartItem : styles.catalogcartItem}`} >
        <img 
            src={ingredientListMeta?.ImageIcon}
            className={`${props.source === "PDP" ? styles.pdpheaderIcon : styles.catalogheaderIcon} pointer`}
            onClick={(e) => {
              e.stopPropagation();
              let params = {
                  "ProductId" : props?.product?.Id,
                  "Action" : ingredientListMeta?.Action,
              }
              props.actions.updateIngredientList(params, (data) => {
                  setIngredientListMeta(data?.response)
                  if (data?.response?.InfoBottomSheet) {
                    setInfoModal({
                      ...infoModal,
                      visible: true,
                    })
                  }
              })
            }}
            />
          </div>

    </>
  )
}

export default withRouter(
    connect(
      (state) => ({
        showSOAModal: state.user.showSOAModal,
        soaOptions : state.user.soaOptions,
        errorToast: state.user.errorToast,
      }),
      (dispatch) => ({
        actions: bindActionCreators(
          {
            updateIngredientList,
          },
          dispatch
        ),
      })
    )(MyList)
  );