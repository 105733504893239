import React, { useEffect, useState } from "react";
import Input from "sushiweb/InputTypes/Input"
import { DOCUMENT_FSSAI, DOCUMENT_PAN, PROFILE_INPUT_BORDER } from "../../../utils/constants/others";
import styles from "./CustomInputs.module.scss";
import Button from "sushiweb/Button";
import { verifyPanAndLegalEntityName } from "../../../redux/dispatchers/userDispatcher";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../loader/Loader";
import SpinnerCircular from "sushiweb/Spinner/SpinnerCircular";
import { red, yellow, green, orange, purple, teal } from "sushiweb/tokens/color";
import { HTMLDiv } from "../HTMLDiv";
import Image from "../Image/Image";
import { GET_GST_DETAILS,GET_PAN_DETAILS,GET_FSSAI_DETAILS } from "../../../utils/constants/others";
import { STATUS_VERIFY,STATUS_VERIFIED,KEY_FLOOR,KEY_LIFT,VALUE_GROUND} from "../../../utils/constants/others";
import { isEmpty, isNull } from "lodash-es";
import { Border } from "victory";


function MultipleInputs (props) {

    const [tag,setTag] = useState({})

    const handleIconButtonClick = (endpoint,queryKey,buttonText) => {
        
        if(buttonText === STATUS_VERIFIED){

            return
        }
        let value = props?.values.map((data)=>{
            if (data?.key===queryKey){
                return data?.value
            }
        })
        if (value?.length>=1){
            let type = ""
            if (props?.inputType===GET_PAN_DETAILS){
                type = DOCUMENT_PAN
            }else if (props?.inputType===GET_FSSAI_DETAILS){
                type = DOCUMENT_FSSAI
            }

            props.reset("",true,type,STATUS_VERIFY,"")
            props.actions.verifyPanAndLegalEntityName(endpoint+value[0],(sucessdata)=>{
               
                if(sucessdata?.VerificationStatus?.IsValid){
                    props.reset(sucessdata?.DocumentDetails?.Data?.LegalEntityName,false,type,sucessdata?.VerificationStatus?.Text,"")
                }else{
                    let errorTextWithColour = <HTMLDiv html={sucessdata?.VerificationStatus?.Text}/>
                    props.reset(sucessdata?.DocumentDetails?.Data?.LegalEntityName,false,type,STATUS_VERIFY,errorTextWithColour)
                }
            },(error)=>{})
        }    
    }

    const getIconOnInput = (data) => {
        return <div className="pointer" style={{color:data?.text === STATUS_VERIFIED ? green.z500 :`${data?.color}`}}>
            {data?.loaderVisible?<SpinnerCircular
                          color={red.z500}
                          size="small"/>:data?.visible?<span onClick={()=>{handleIconButtonClick(data?.action,data?.queryKey,data?.text)}}>{data?.text}</span>:<></>}
        </div>
    }

    const optionsConditions = (allfields,currentKey) =>{ 
        let floorField = []
        if(currentKey === KEY_LIFT){
            floorField = allfields.filter((field)=>{
                return field.key===KEY_FLOOR
            })

            if(floorField[0].value.length>0 && floorField[0].value ===VALUE_GROUND || floorField[0].value.length==0){
                return false
            }
        }

        return true;
    }

    return (
    <div className={`${styles.multipleInputScrollable}`}>
        {props?.entityDetails?.BackgroundImage && props?.entityDetails?.AvatarIcon && props?.entityDetails?.Heading && props?.entityDetails?.SubHeading &&<div className={`position-relative ${styles.detailsBox}`}>
        <Image classname={` wd-100`} source={props?.entityDetails?.BackgroundImage}/>
        <Image classname={`position-absolute ${styles.documentAvatar}`} source={props?.entityDetails?.AvatarIcon}/>

        <div >
            <span className={`w-600 fs-18 position-absolute ${styles.documentDetails}`}>{props?.entityDetails?.Heading}</span>
            <span className={`w-400 fs-14 position-absolute ${styles.documentDetailsSub}`}>{props?.entityDetails?.SubHeading}</span>
        </div>
    </div>}
    
      <div className="relative">
            {
                props?.values && props?.values.map((data,index)=>{
                    return optionsConditions(props?.values,data.key) && <div >
                        {data?.Heading?<div className="fs-20 w-800 mb-8">{data?.Heading}</div>:null}
                        {data?.options?.length?<div><div  className={`${styles.multipleInputsOptions} mb-8 d-flex justify-content-start align-items-center`}>
                        {
                            data?.options?.map((option,idx)=>{
                                return  <div onClick={()=> {props && props.onChange(typeof(option)==='object' ? option?.TextId:option,index); setTag(option?.Tag)}} className={ (typeof(option)==='object' ? data?.value===option?.TextId : data?.value===option)? idx+1 === data.options.length ?`pointer ${styles.optionsBox} ${styles.selectedOptionBox}`:`mr-12px pointer ${styles.optionsBox} ${styles.selectedOptionBox}`:idx+1 === data.options.length ?`pointer ${styles.optionsBox}`:`mr-12px pointer ${styles.optionsBox}`}>{typeof(option)==='object' ? option?.Text :option}</div>
                            })
                        }
                        </div>
                        {
                         tag?.Text?.length && <div style={{background:tag?.BgColor,borderRadius:tag?.Radius?.UniversalRadius}} className="d-flex align-items-center pd-8">
                            <div ><Image classname={"img24 mr-2"} source={tag?.ImagePath}/></div>
                            <div style={{color: tag?.TextColor}}>{tag?.Text}</div>
                            </div>
                        }
                        </div>: data?.visible!==false ? <Input
                        value={data.value}
                        onChange={(val)=>props?.onChange(val,index)}
                        label = {data.label}
                        icon = {data?.Button ? getIconOnInput(data?.Button):""}
                        className={data?.disabled ? `mb-15 ${styles.disabledInput} ${styles.genericInput} `:`mb-15 ${styles.genericInput} `}
                        onKeyDown={props.onKeyDown}
                        errorMsg = {data.error}
                        disabled = {data?.disabled}
                    />:<></>}
                    </div>
                })
            }
      </div>
    </div>
    ) 
}

export default withRouter(
    connect(
      (state) => ({
      }),
      (dispatch) => ({
        actions: bindActionCreators({
            verifyPanAndLegalEntityName
        },
          dispatch
        ),
      })
    )(MultipleInputs)
);