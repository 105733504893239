import React, { useState } from "react";
import Input from "sushiweb/InputTypes/Input"
import { PROFILE_INPUT_BORDER } from "../../../utils/constants/others";
import styles from "./CustomInputs.module.scss";

function PasswordInput ({value , onChange , label , error ,onKeyDown}) {
    const [passwordVisible , setPasswordVisible] = useState(false)
  return (
    <div className="relative">
    <Input
            type={passwordVisible ? "text" : "password"}
            label = {label}
            errorMsg = {error}
            borderColor={PROFILE_INPUT_BORDER}
            value={value}
            onChange={onChange}
            className={`${styles.genericInput} `}
            onKeyDown={onKeyDown}
            icon={
               !passwordVisible ? (
                <img src ={process.env.PUBLIC_URL + "/assets/images/eye-open.png"}
                className="img20 pointer"
                  onClick={() =>
                    setPasswordVisible(true)
                  }
                />
              ) : (
                <img src ={process.env.PUBLIC_URL + "/assets/images/eye-close.png"}
                className="img20 pointer"
                  onClick={() =>
                    setPasswordVisible(false)
                  }
                />
              )
            }
          />
    </div>
  ) 
}

export default PasswordInput;
