import _get from "lodash-es/get";

import getAxios from "../../utils/axios";
import actionTypes from "../actionTypes";
import APIendpoints from "../../utils/constants/APIendpoints";
import { makeAndSaveBlob, saveLink } from "../../utils/helpers";

function downloadStatement(url, maxNoOfRetries = 15, retryGapInSeconds = 4, fileName, fileExtension, data = {}, successCallback, errCallBack, isDashboardRequest = false) {
  return (dispatch) => {
    dispatch({ type: actionTypes.STATEMENT_DOWNLOAD_BEGIN });
    let retryCount = 0;
    const axiosInstance = getAxios(false);
    function doAPIcall() {
      retryCount++;
      axiosInstance
        .get(url)
        .then(() => {
          if (!isDashboardRequest) {
            saveLink(url, fileName, fileExtension)
          }

          dispatch({
            type: actionTypes.STATEMENT_DOWNLOAD_DONE,
          });
          if (typeof successCallback === "function") {
            successCallback();
          }

          if (!isDashboardRequest) {
            dispatch({
              type: actionTypes.ERROR_HANDLE,
              payload: {
                errToast: {
                  Text: data?.Text,
                  ImagePath: data?.ImagePath,
                }
              },
            });
          }
        })
        .catch(() => {
          if (retryCount <= maxNoOfRetries) {
            setTimeout(() => doAPIcall(), retryGapInSeconds * 1000);
          } else {
            dispatch({
              type: actionTypes.STATEMENT_DOWNLOAD_FAIL,
              error: {
                errorToast: {
                  Text: isDashboardRequest ? "Couldn't download FoodSummary Report" : "Couldn't download PDF statement",
                }
              }
            });
            if (typeof errCallBack === "function") {
              errCallBack();
            }
          }
        });
    }
    doAPIcall();
  };
}

function emailStatement() {
  return (dispatch) => {
    dispatch({ type: actionTypes.STATEMENT_EMAIL_BEGIN });
    getAxios(true)
      .get(APIendpoints.emailStatement)
      .then(() => {
        dispatch({ type: actionTypes.STATEMENT_EMAIL_DONE });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.STATEMENT_EMAIL_FAIL,
          error: _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  };
}

export { downloadStatement, emailStatement };
