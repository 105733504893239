import { COOKIE_TTL_FOR_WEB } from "../../utils/constants/others";
import { getCookieForEntity, setCookieForEntity } from "../../utils/helpers";
import actionTypes from "../actionTypes";

const isAuthDone = () => {
  let token = getCookieForEntity("token","posToken")
  if (window.localStorage.getItem("token")) {
    let tokenLocal = window.localStorage.getItem("token");
    setCookieForEntity("token","posToken", tokenLocal, COOKIE_TTL_FOR_WEB)
    token = tokenLocal
    window.localStorage.removeItem("token")
    return true
  }
  if (token){
    return true
  }
  return false
}


const initialState = {
  isAuthenticated: isAuthDone() ? true : false,
  signInResponse: null,
  salesLeadId: null,
  serviceablePinCode: null,
  showLocationModal:false,
  locationModalData:null,
  registerData: {
    1: {
      initial: true,
      value: {
        outletName: "",
        mobileNo: "",
        email: "",
        address: "",
        city: "City",
        cityState: "",
        pinCode: "",
        deliveryTime: "Delivery Time",
        referralCode: undefined
      }
    },
    2: {
      initial: true,
      value: {
        entityName: "",
        panCardNumber: "",
        panCardImage: "",
        fssaiNumber: "",
        fssaiCardImage: "",
        gstNumber: undefined,
        gstCardImage: undefined
      }
    },
    3: {
      initial: true,
      value: {
        name: "",
        email: "",
        phoneNumber: "",
        password: "",
        confirmPassword: undefined,
        designation: "Designation"
      }
    }
  },
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.AUTH_SIGN_IN_DONE:
      return {
        ...state,
        isAuthenticated: true,
        signInResponse: { ...state.signInResponse, ...action.signInResponse },
        showLocationModal: action.showLocationModal,
        locationModalData:{...state.locationModalData,...action.locationModalData}
      };
    case actionTypes.AUTH_SIGN_IN_FAIL: 
      return {
        ...state,
        signInResponse: { ...state.signInResponse, errorData: action.errorData }
      };
    case actionTypes.SET_REGISTER_DATA_BEGIN:
      const registerDataTemp = {
        [action.stepNo]: {
          initial: false,
          value: action.data.value
        }
      };
      const registerDataNew = { ...state.registerData, ...registerDataTemp };

      return {
        ...state,
        registerData: { ...registerDataNew }
      };

    case actionTypes.REGISTER_STEP_ONE_DONE:
      return {
        ...state,
        salesLeadId: action.salesLeadId
      };

    case actionTypes.UPDATE_REG_STEP_ONE_DONE:
      return {
        ...state,
        salesLeadId: action.salesLeadId
      };

    case actionTypes.UPLOAD_PAN_IMAGE_DONE:
      return {
        ...state,
        panImageData: action.data
      };

    case actionTypes.UPLOAD_FSSAI_IMAGE_DONE:
      return {
        ...state,
        fssaiImageData: action.data
      };

    case actionTypes.UPLOAD_GST_IMAGE_DONE:
      return {
        ...state,
        gstImageData: action.data
      };

    case actionTypes.VALIDATE_REFERRAL_CODE_DONE:
      return {
        ...state,
        referralCode: action.referralCode
      };

    case actionTypes.VALIDATE_REFERRAL_CODE_FAIL:
      return {
        ...state,
        referralCode: null
      };

    case actionTypes.PROMOTION_VISIBILITY_DONE:
      return {
        ...state,
        bonusAmount: action.bonusAmount
      };

    case actionTypes.VALIDATE_PINCODE_DONE:
      return {
        ...state,
        isPinCodeValid: action.isPinCodeValid,
        serviceablePinCode: action.serviceablePinCode
      };

    case actionTypes.SET_REFERRAL_CODE_DONE:
      const registerReferralDataTemp = {
        1: {
          initial: false,
          value: {
            ...state.registerData[1].value,
            referralCode: action.code
          }
        }
      };
      const registerReferralDataNew = {
        ...state.registerData,
        ...registerReferralDataTemp
      };

      return {
        ...state,
        registerData: { ...registerReferralDataNew }
      };

    case actionTypes.REGISTER_ALL_STEPS_DONE:
      return {
        ...initialState,
        promotionData: action.promotionData
      };
    
    case actionTypes.AUTO_ONBOARDING_ZOMATO_DONE:
    return {
      ...initialState,
      autoOnboarding: action.payload,
    };
    default:
      return state;
  }
}

export default authReducer;
