/**
 * Represents the structure of a body item in the popup data.
 *
 * This object is used to define the content for a popup's body section, which may include
 * an image and text.
 *
 * @typedef {Object} PopupDataBody
 * @property {string} image - The URL or path of the image to display in the popup.
 * @property {string} text - The text content to display alongside the image in the popup.
 */

/**
 * Default structure for the data used in a popup.
 *
 * This object defines the properties required for configuring a popup, including its visibility,
 * heading, body content, and the text for confirm and cancel buttons.
 *
 * @typedef {Object} PopupData
 * @property {boolean} visible - Indicates whether the popup is visible (`true`) or hidden (`false`).
 * @property {string} heading - The title or heading displayed at the top of the popup.
 * @property {PopupDataBody[]} body - An array of body items (image and text) displayed in the popup.
 * @property {string} confirmButtonText - The text for the confirm button.
 * @property {string} cancelButtonText - The text for the cancel button.
 */

/**
 * Represents the default structure of a body item in the popup data.
 *
 * @type {PopupDataBody}
 */
export const APP_MODE_POPUP_DATA_BODY = {
  image: "",
  text: "",
};

/**
 * Represents the default configuration of the popup.
 *
 * @type {PopupData}
 */
export const APP_MODE_POPUP_DATA = {
  visible: false,
  heading: "",
  body: [APP_MODE_POPUP_DATA_BODY],
  confirmButtonText: "",
  cancelButtonText: "",
};


const getAppModeModalDataFromResponse = (body) => {
  const modalData = { ...APP_MODE_POPUP_DATA };
  if (body) {
    modalData.visible = true;
    modalData.heading = body?.Heading || "";
    modalData.body = prepareAppModeModalBody(body?.WidgetList || []);
    modalData.confirmButtonText = body?.PrimaryCTA?.Text || "Okay";
  }
  return modalData;
};

const prepareAppModeModalBody = (data) => {
  if (!data || data.length === 0) {
    return [APP_MODE_POPUP_DATA_BODY];
  }
  let modalBody = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i]?.SectionData?.Heading && data[i]?.SectionData?.Heading !== "") {
      modalBody.push({
        image: data[i]?.SectionData?.Image || "",
        text: data[i]?.SectionData?.Heading || "",
      });
    }
  }
  return modalBody;
}


// exports
export {
  getAppModeModalDataFromResponse,
};
