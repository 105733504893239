import actionTypes from "../actionTypes";

const initialState = {
  paymentServiceType: null,
  paymentRedirectURL: null,
  paymentAccessCode: null,
  paymentHash: null,
  paymentGUID: null,
  paymentNumber: null,
  paymentPlan: {
    online: null,
    wallet: null,
    credit: null,
        cod: null,
  },
  transaction: {
    status: null,
    message: null,
    forPlacedOrders: null,
    source: null
  },
  paymentOptions: {
    refundHead: "",
    refundDetail: "",
    refundToWallet: false,
    credit: {
      totalAmount: null,
      accountId: null,
      isOnlinePaymentEnabled: null,
      onlinePaymentExceedingMessage: null,
    },
    wallet: {
      totalAmount: null,
      walletEntries: [],
    },
    payLater: {
      availableCreditLimit: 0,
      isEligible: false,
      optInStatus: false,
      isDeactivated: false,
      new: false
    },
    cod: null
  },
  paymentNudge: {
    activeNudge: null,
    nudge: null
  },
  rupifiPaymentRedirectUrl: "",
};

function paymentReducer(state = initialState, action) {
  const payload = action.payload;

    switch (action.type) {
        /**
         * PAYMENT_MAKE_PENDING
         */
        case actionTypes.HP_PLUS_PAYMENT_INFO_DONE:
            return{
                ...state,
                paymentPlan: {
                    ...state.paymentPlan,
                    online: payload.PaymentPlan.online,
                    wallet: payload.PaymentPlan.wallet,
                    credit: payload.PaymentPlan.credit,
                    cod: 0,
                },
                paymentGUID: payload.PaymentGUID,
            }
        case actionTypes.PAYMENT_MAKE_PENDING_DONE:
            return {
                ...state,
                paymentPlan: {
                    ...state.paymentPlan,
                    online: payload.paymentPlan.online,
                    wallet: payload.paymentPlan.wallet,
                    credit: 0,
                    cod: payload.paymentPlan.cod,
                },
                paymentGUID: payload.paymentGUID,
                encryptedPaymentGuid: payload.encryptedPaymentGuid,
                gatewayInfo: payload.gatewayInfo,
                showPaymentMethodsMessageHinting:payload.showPaymentMethodsMessageHinting
            };

            /**
             * ORDER_PLACE
             */
        case actionTypes.ORDER_PLACE_DONE:
            return {
                ...state,
                paymentPlan: {
                    ...state.paymentPlan,
                    online: payload.paymentPlan.online,
                    wallet: payload.paymentPlan.wallet,
                    credit: payload.paymentPlan.credit,
                    cod: payload.paymentPlan.cod,
                },
                paymentGUID: payload.paymentGUID,
                rupifiPaymentRedirectUrl: payload.rupifiPaymentRedirectUrl,
                gatewayInfo: payload.gatewayInfo
            };

    case actionTypes.WALLET_RECHARGE_DONE:
      return {
        ...state,
        paymentServiceType: action.payment.ServiceType,
        paymentGUID: action.payment.PaymentGuid,
        paymentRedirectURL: action.payment.RedirectUrl,
        paymentPlan: {
          ...state.paymentPlan,
          online: action.payment.OnlineAmount
        }
      };

    case actionTypes.PAYMENT_GET_OPTIONS_FOR_DROPDOWN_DONE:
    case actionTypes.PAYMENT_GET_OPTIONS_DONE:
      return {
        ...state,
        paymentOptions: {
          ...state.paymentOptions,
          refundHead: payload.refundHead,
          refundDetail: payload.refundDetail,
          refundToWallet: payload.refundToWallet,
          credit: {
            ...state.paymentOptions.credit,
            totalAmount: payload.creditTotalAmount,
            accountId: payload.creditAccountId,
            isOnlinePaymentEnabled: payload.isOnlinePaymentEnabled,
            onlinePaymentExceedingMessage: payload.onlinePaymentExeedingMessage,
            isDisabled : payload.isCreditDisabled,
            message : payload.creditMessage
          },
          wallet: {
            ...state.paymentOptions.wallet,
            totalAmount: payload.walletTotalAmount,
            walletEntries: payload.walletEntries,
            walletFilters: payload.walletFilters,
            walletNearestExpiry: payload.walletNearestExpiry,
            walletTopUpRail: payload.walletTopUpRail,
            walletTopupHeading: payload.walletTopupHeading,
            isWalletTopupActive: payload.isWalletTopupActive,
            maxTopupLimit: payload.maxTopupLimit,
            minTopupLimit: payload.minTopupLimit
          },
          payLater: {
            ...state.paymentOptions.payLater,
            availableCreditLimit: payload.payLaterAvailableCreditLimit,
            isEligible: payload.payLaterIsEligible,
            optInStatus: payload.payLaterOptInStatus,
            isDeactivated: payload.payLaterIsDeactivated,
            new: payload.payLaterNew,
            trackingParams: payload.trackingParams
          },
          cod: payload.cod
        },
      };

      /**
       * PAYMENT_GET_ACCESS_CODE
       */
    case actionTypes.PAYMENT_GET_ACCESS_CODE_BEGIN:
      return {
        ...state,
        paymentAccessCode: null,
      };

    case actionTypes.PAYMENT_GET_ACCESS_CODE_DONE:
      return {
        ...state,
        paymentAccessCode: action.accessCode,
        paymentServiceType: action.serviceType,
        paymentRedirectURL: action.redirectURL,
        paymentAdditionalParams: action.additionalParams,
        paymentAdditionalParamsForWallet: action.additionalParamsForWallet,
      };

      /**
       * PAYMENT_GET_HASH
       */
    case actionTypes.PAYMENT_GET_HASH_BEGIN:
      return {
        ...state,
        paymentHash: null,
      };

    case actionTypes.PAYMENT_GET_HASH_DONE:
      return {
        ...state,
        paymentHash: action.paymentHash,
      };

            /**
             * PAYMENT_UPDATE_STATUS
             */
        case actionTypes.PAYMENT_UPDATE_STATUS_DONE:
            return {
                ...state,
                transaction: {
                    ...state.transaction,
                    status: payload.transactionStatus,
                    source: payload.source,
                    message: payload.message,
                    orders: payload.orders,
                    //message: payload.transactionMessage,
                    forPlacedOrders: payload.forPlacedOrders,
                    text: payload.text,
                    subText: payload.subText,
                    image: payload.image,
                    mediaURL: payload.mediaURL,
                    totalOutstandingAmount: payload.totalOutstandingAmount,
                    paymentTxnMessage: payload.paymentTxnMessage,
                    onlineAmount: payload.onlineAmount,
                    mainCTA: payload.mainCTA,
                    loyaltyReward: payload.loyaltyReward,
                    info: payload.info,
                    orderInfo: payload.orderInfo
                },
                paymentNumber: payload.paymentNumber,
                responseV3: payload.responseV3,
            };

        case actionTypes.CLEAR_TRANSACTION_DETAILS:
            return {
                ...state,
                transaction: initialState.transaction
            }
        case actionTypes.GET_PAYMENT_NUDGES:
            return {
                ...state,
                paymentNudge: {
                    ...state.paymentNudge,
                    nudge: payload.nudge,
                    activeNudge: payload.activeNudge
                }
            }
        case actionTypes.CLEAR_PAYMENT_NUDGE:
            return {
                ...state,
                paymentNudge: {
                    ...state.paymentNudge,
                    ...initialState.paymentNudge
                }
            }
        case actionTypes.GET_INVOICE_DETAILS_DONE:
            return {
                ...state,
                invoiceDetails: payload,
            }
        case actionTypes.GET_PAYMENT_INFO_NO_RELOAD:
        case actionTypes.GET_PAYMENT_INFO_DONE:
            return {
                ...state,
                paymentInfo: payload,
            }
        case actionTypes.UPDATE_PAYMENT_INFO_DONE:
            return {
                ...state,
                paymentInfo: payload,
            }
        case actionTypes.CLEAR_PAYMENT_INFO:
            return {
                ...state,
                paymentInfo: {},
            }
        case actionTypes.PAYMENT_INFO_POPUP: 
            return {
                ...state,
                paymentInfoPopup: payload,
            }
        case actionTypes.GET_CONSUMER_REFUND_JOURNEY_DONE: 
            return {
                ...state,
                refundJourney: payload,
            }

        case actionTypes.GET_CONSUMER_REFUND_JOURNEY_INIT: 
            return {
                ...state,
                refundJourney: {},
            }
        default:
            return state;
    }
}

export default paymentReducer;
