import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: '568b707a-7931-49f7-a683-ec4da9b2c668',
    clientToken: 'pub944c743a8bd2f89f03a2ef396117435b',
    site: 'datadoghq.com',
    service: 'hp-web',
    env: "prod",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask',
});