import actionTypes from "../actionTypes";

function acquisitionRail(state = {}, action) {
  const { payload, type } = action;

  switch (type) {
    case actionTypes.GET_ACQUISITION_RAIL_DONE:
      if(action.client) {
        return { ...state, rewardsRail: payload}
      } else {
        return {
          ...state,
          acquisitionRail: payload
        }
      }
    case actionTypes.CLEAR_ACQUISITION_RAIL:
      return {
        ...state,
        acquisitionRail: {}
      }

    default:
      return state;
  }
}

export default acquisitionRail;
