import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { HTMLDiv } from "../HTMLDiv";
import Image from "../Image/Image";
import styles from "./Header.module.scss";


const HeaderDelivery = (props) => {
    const deliveryRef = useRef();
    const outletNameRef = useRef();
    const outletAddress = useRef();
    const infoRef = useRef();
    const [showInfo, setShowInfo] = useState(false)

    useEffect(() => {
        if (props.header?.DeliveryInfo?.InfoPopup) {
            props.showHeaderDeliveryNudge(true)
        }
    }, [props.header])


    useEffect(() => {
        if (props.history.location.pathname !== "/") {
            setShowInfo(true);
        } else {
            window.addEventListener("scroll", (e) => handleNavigation(e));

            return () => {
                window.removeEventListener("scroll", (e) => handleNavigation(e));
            };
        }
    }, []);


    const handleNavigation = (e) => {
        const window = e.currentTarget;
        if (window.scrollY > 10) {
            setShowInfo(true);
        } else {
            setShowInfo(false);
        }
    }

    const renderInfoIcon = (color) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9.375 9.375H10V13.75H10.625" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9.84375 7.5C10.3615 7.5 10.7812 7.08027 10.7812 6.5625C10.7812 6.04473 10.3615 5.625 9.84375 5.625C9.32598 5.625 8.90625 6.04473 8.90625 6.5625C8.90625 7.08027 9.32598 7.5 9.84375 7.5Z" fill={color} />
            </svg>
        )
    }

    let {
        DeliveryInfo = {},
        OutletInfo = {}
    } = props.header;
    return (
        <div className={`ml-4 ${styles.deliveryHeader}`} ref={deliveryRef}>
            {props.header ? 
            <div className={`d-flex flex-column ${styles.headerTime}`}>
                <div className="d-flex"> 
                    {DeliveryInfo?.Image ? <img src={DeliveryInfo.Image} className={`mr-1 ${styles.headerDeliveryInfoImg}`} alt={DeliveryInfo?.DateAndTime} /> : <></>}
                    
                    <div className="d-flex align-items-center">
                        <HTMLDiv
                            html={DeliveryInfo?.DateAndTime}
                            className={`fs-20 w-800 mb-2`}
                        />
                        <div className={`ml-2`} ref={infoRef}>{renderInfoIcon(!showInfo ? "white" : DeliveryInfo?.BgColor)}</div>
                        {DeliveryInfo?.InfoPopup && showInfo ? <div
                            style={{ left: infoRef.current?.offsetLeft ? (infoRef.current.offsetLeft - 137) + "px" : "373px" }}
                            className={`${styles.headerPopup} ${showInfo ? "" : "d-none"}`} >
                            <div className={styles.upTriangleOffersV2}></div>
                            <div>{DeliveryInfo?.InfoPopup}</div>
                        </div> : <></>}
                    </div>
                </div>
                <div>
                    <div onClick={props.ctaAction} className={`fs-14 d-flex wd-max-content align-items-center pointer`}>
                        <div ref={outletNameRef}
                            style={{ width: deliveryRef.current?.offsetWidth <= outletNameRef.current?.scrollWidth ? deliveryRef.current?.offsetWidth + "px" : "" }}
                            className={` ${styles.truncate} ${styles.grey900}`}
                        >
                            <HTMLDiv html={OutletInfo?.Name} />
                        </div>

                        <div ref={outletAddress}
                            style={{ maxWidth: deliveryRef.current?.offsetWidth > outletNameRef.current?.scrollWidth ? (deliveryRef.current?.offsetWidth - outletNameRef.current?.scrollWidth) + "px" : "" }}
                            className={`  ml-2 ${styles.grey600} ${deliveryRef.current?.clientWidth > outletNameRef.current?.scrollWidth ? styles.truncateName : "d-none"}`}
                        >
                            <HTMLDiv html={OutletInfo?.Address} />
                        </div>
                        {OutletInfo?.ActionCTA && outletAddress ? <Image classname={`${styles.img12} ml-1`} source={process.env.PUBLIC_URL + "/assets/images/ChevronDownRed.png"} /> : <></>}
                    </div>
                    {DeliveryInfo?.InfoPopup ? <div className={`${styles.fixedSubHeader} ${showInfo ? "d-none" : ""}`} style={{ backgroundColor: DeliveryInfo?.BgColor }}>{DeliveryInfo?.InfoPopup}</div> : <></>}
                </div>
            </div> :
                <></>
            }
        </div>
    )
}

export default HeaderDelivery;