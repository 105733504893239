import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import envEndpoints from "./env";
import { deviceId, deviceName } from "./browserInfo.js";

// helpers
import {
  deleteCookieForEntity,
  getCookieForEntity,
  isIframe,
  setCookieForEntity,
  getAuthHeader,
  getOutletIdFromBrowser,
  getApiVersionForWeb,
  getAppTypeForWeb,
  getAppMode,
  isPetPoojaClient,
  RemoveLocalStorageForEntity
} from "./helpers";

// constants
import {
  HEADER_KEYS,
  COOKIE_TTL_FOR_WEB,
  COOKIE_KEYS,
  CLIENT_TYPE,
  CLIENT_TYPE_PETPOOJA,
  LOCAL_STORE_KEYS
} from "./constants/others";

/**
 * Creates and returns an Axios instance with custom configuration, including headers and interceptors.
 *
 * The configuration includes authentication headers, base URL, device details, API version, and other context-specific headers.
 * It also handles session expiration and error logging.
 *
 * @param {boolean} auth - Indicates whether authentication is required. If true, the Authorization header will be included.
 * @param {string} baseURL - The base URL for the Axios instance. Requests made through this instance will use this as the root endpoint.
 * @param {Object|null} customHeader - Custom headers to be added to the request. If null, no additional headers will be included.
 * @param {boolean} isPowerRanger - A flag to determine if special Power Ranger authentication is required. It affects the `X-Client` and `Authorization` headers.
 *
 * @returns {AxiosInstance} - An Axios instance configured with the specified headers and interceptors for handling requests and responses.
 *
 */
const createAxios = (auth, baseURL, customHeader, isPowerRanger) => {
  // get outletId from browser
  const outletId = getOutletIdFromBrowser();

  // **** set headers **** //

  // request headers
  const headers = {};

  // 1. base headers
  headers[HEADER_KEYS.CLIENT] = isPowerRanger ? CLIENT_TYPE.USER : CLIENT_TYPE.CONSUMER;
  headers[HEADER_KEYS.TRACKING_ID] = uuidv4();
  headers[HEADER_KEYS.HEADER_ROUTE] = "v2";
  headers[HEADER_KEYS.DEVICE_ID] = window.localStorage.getItem("deviceId") ? window.localStorage.getItem("deviceId") + "_uuid" : deviceId;
  headers[HEADER_KEYS.DEVICE_NAME] = deviceName;

  // 2. specific headers

    // 2.1. outletId
    if (outletId !== null) {
        headers[HEADER_KEYS.OUTLET_ID] = outletId;
    }

    // 2.2. API version
    headers[COOKIE_KEYS.API_VERSION] = getApiVersionForWeb();

    // 2.3. App Type
    headers[HEADER_KEYS.APP_TYPE] = getAppTypeForWeb(); 
  
    // 2.4. Routing context
    let routingContext = getCookieForEntity(COOKIE_KEYS.ROUTING_CONTEXT, COOKIE_KEYS.ROUTING_CONTEXT);
    if (routingContext !== "") {
        headers[HEADER_KEYS.ROUTING_CONTEXT] = routingContext;
    }

    // 2.5. Authorization
    if (auth) {
        let token = getAuthHeader(isPowerRanger);
        if (window.localStorage.getItem(COOKIE_KEYS.TOKEN)) {
        let tokenLocal = window.localStorage.getItem(COOKIE_KEYS.TOKEN);
        setCookieForEntity(COOKIE_KEYS.TOKEN, COOKIE_KEYS.POS_TOKEN, tokenLocal, COOKIE_TTL_FOR_WEB);
        token = tokenLocal;
        window.localStorage.removeItem(COOKIE_KEYS.TOKEN);
        }
        if (getCookieForEntity(COOKIE_KEYS.TOKEN, COOKIE_KEYS.POS_TOKEN)) {
        token = getCookieForEntity(COOKIE_KEYS.TOKEN, COOKIE_KEYS.POS_TOKEN);
        }
        headers[HEADER_KEYS.AUTHORIZATION] = token;
    }

    // 2.6. App Mode
    headers[HEADER_KEYS.APP_MODE] = getAppMode();

    // 2.7. Custom headers
    if (customHeader) {
        for (let i in customHeader) {
        headers[i] = customHeader[i];
        }
    }

  // **** end of headers **** //

    // axios instance
    const instance = axios.create({
        baseURL,
        headers,
    });

    // response interceptors
    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            // Exclude s3 urls with 403 code
            const isS3Url =
                error.config &&
                /(s3-|s3\.)?(.*)\.amazonaws\.com/.test(error.config.url);
            if (!isS3Url && [401, 403, 410].includes(error.response.status)) {
                let token = getCookieForEntity(COOKIE_KEYS.TOKEN, COOKIE_KEYS.POS_TOKEN);
                let outletId = getCookieForEntity(COOKIE_KEYS.OUTLET_ID, COOKIE_KEYS.POS_OUTLET_ID);
                let currentPos = window.location.href;
                let isIframePresent = isIframe();
                const formattedError = new Error(
                    "err: Session Expired " +
                    " ,url: " +
                    error.config.url +
                    ",errorCode:" +
                    error.response.status +
                    ",curentURL:" +
                    currentPos +
                    ", isIframe: " +
                    isIframePresent +
                    ", outletId: " +
                    outletId +
                    ", token: " +
                    token +
                    " errorLog:  " +
                    JSON.stringify(error)
                );
                window.DD_RUM.addError(formattedError);
                setTimeout(function () {
                    alert("Session Expired! Please login again");

                    // clear token and outletId from cookies
                    deleteCookieForEntity(COOKIE_KEYS.TOKEN, COOKIE_KEYS.POS_TOKEN);
                    deleteCookieForEntity(COOKIE_KEYS.OUTLET_ID, COOKIE_KEYS.POS_OUTLET_ID);
                    deleteCookieForEntity(COOKIE_KEYS.ROUTING_CONTEXT, COOKIE_KEYS.ROUTING_CONTEXT);
                    deleteCookieForEntity(COOKIE_KEYS.APP_MODE, COOKIE_KEYS.APP_MODE);

                    // clear pet pooja specific data only when current client is not pet pooja
                    if (!isPetPoojaClient()) {
                        deleteCookieForEntity("", "clientType");
                        deleteCookieForEntity("", "clientData");
                        deleteCookieForEntity(COOKIE_KEYS.IS_IFRAME, COOKIE_KEYS.IS_IFRAME);
                        deleteCookieForEntity(COOKIE_KEYS.POS_SESSION_ID, COOKIE_KEYS.POS_SESSION_ID);
                        RemoveLocalStorageForEntity(LOCAL_STORE_KEYS.POS_SESSION_ID, true);
                    }
                    // redirect to login
                    window.location.href = "/?signIn=true";
                   
                }, 1000);
            }
            return Promise.reject(error);
        }
    );

  return instance;
};

/**
 * Returns an Axios instance configured with authentication, base URL, and custom headers.
 *
 * @param {boolean} [auth=false] - Determines whether to include authentication in the request.
 * @param {string} [baseUrl=envEndpoints.urls.hyperpureHost] - The base URL for the Axios instance.
 * @param {Object|null} [customHeader=null] - Custom headers to be added to the Axios instance. If null, no custom headers are added.
 * @param {boolean} [isPowerRanger=false] - A flag to determine if special Power Ranger authentication is required.
 * @returns {AxiosInstance} An Axios instance configured with the provided parameters.
 */
const getAxios = (
  auth = false,
  baseUrl = envEndpoints.urls.hyperpureHost,
  customHeader = null,
  isPowerRanger = false
) => {
  return createAxios(auth, baseUrl, customHeader, isPowerRanger);
};

export default getAxios;
