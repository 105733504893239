import React from "react";
import Toast from "sushiweb/Toast";

import CartIssueModal from "../cartIssueModal/CartIssueModal";
import { CartUpdateSource_SimilarProducts } from "../../../utils/constants/others";
import { ToastHandler } from "../Toast/ToastHandler";

const cartIssueManager =
  (cartUpdateSourceModal, cartUpdateSourceAlternateRail) =>
  (WrappedComponent) => {
    class HOC extends React.Component {
      state = {
        issues: [],
        toast: {
          show: false,
          text: null,
          type: "default",
          className: "",
        },
        showNoOfIssuesHeader: false,
      };

      toastOpenHandler = ({ show, text, type, className }) => {
        this.setState((prevState) => ({
          ...prevState,
          toast: {
            ...prevState.toast,
            show: show,
            text: text,
            type: type,
            className: className,
          },
        }));
      };

      toastCloseHandler = () => {
        this.setState((prevState) => ({
          ...prevState,
          toast: {
            ...prevState.toast,
            show: false,
            text: null,
            type: "default",
            className: "",
          },
        }));
      };

      addUpdateManyIssues = (
        issues,
        showNoOfIssuesHeader = false,
        updateOnly = false
      ) => {
        this.setState((prevState) => {
          const newIssues = [...prevState.issues];
          for (const issue of issues) {
            const cartIssue = { ...issue, hasError: true };

            const existingIndex = newIssues.findIndex(
              (el) => el.id === cartIssue.id
            );
            if (existingIndex === -1) {
              if (!updateOnly) {
                newIssues.push(cartIssue);
              }
            } else {
              newIssues.splice(existingIndex, 1, cartIssue);
            }
          }

          return {
            ...prevState,
            issues: newIssues,
            showNoOfIssuesHeader: showNoOfIssuesHeader,
          };
        });
      };

      updateOneIssue = (id, changes) => {
        this.setState((prevState) => ({
          ...prevState,
          issues: prevState.issues.map((issue) => {
            if (issue.id === id) {
              return {
                ...issue,
                ...changes,
                issueData: {
                  ...issue.issueData,
                  IssueProduct: { 
                    ...issue.issueData?.IssueProduct,
                    ...changes 
                  },
                },
              };
            } else {
              return issue;
            }
          }),
        }));
      };

      removeManyIssues = (ids) => {
        this.setState((prevState) => ({
          ...prevState,
          issues: prevState.issues.filter((issue) => !ids.includes(issue.id)),
        }));
      };

      clearIssues = () => {
        this.setState((prevState) => ({
          ...prevState,
          issues: [],
        }));
      };

      setError = (id) => {
        this.setState((prevState) => ({
          ...prevState,
          issues: prevState.issues.map((issue) => {
            if (issue.id === id) {
              return { ...issue, hasError: true };
            } else {
              return issue;
            }
          }),
        }));
      };

      clearError = (id) => {
        this.setState((prevState) => ({
          ...prevState,
          issues: prevState.issues.map((issue) => {
            if (issue.id === id) {
              return { ...issue, hasError: false };
            } else {
              return issue;
            }
          }),
        }));
      };

      render() {
        const issue =
          this.state.issues.length > 0 ? this.state.issues[0] : null;
        //explicit handling for out of stock products, to reuse the issue modal for NIS products we create object manually
        const alternateSrc =
          issue &&
          issue.issueProduct &&
          !issue.issueProduct.IsInStock &&
          CartUpdateSource_SimilarProducts;

        return (
          <React.Fragment>
            <WrappedComponent
              {...this.props}
              addUpdateManyIssues={this.addUpdateManyIssues}
              removeManyIssues={this.removeManyIssues}
              updateOneIssue={this.updateOneIssue}
              clearIssues={this.clearIssues}
              isIssuesModalOpen={this.state.issues.length !== 0}
            />
            {issue ? (
              <CartIssueModal
                key={issue.id}
                issueData={issue.issueData}
                cartUpdateSourceModal={
                  this.props.modalCardUpdateSource || cartUpdateSourceModal
                }
                cartUpdateSourceAlternateRail={
                  alternateSrc ||
                  this.props.alternateCardUpdateSource ||
                  cartUpdateSourceAlternateRail
                }
                issueProduct={
                  issue.issueData ? issue.issueData.IssueProduct : {}
                }
                closeModal={() => this.removeManyIssues([issue.id])}
                clearIssues={this.clearIssues}
                issueSelectedQuantity={issue.issueSelectedQuantity}
                issueAvailableQuantity={issue.issueAvailableQuantity}
                issueNudgeMessage={issue.issueNudgeMessage}
                toastOpenHandler={this.toastOpenHandler}
                hasError={issue.hasError}
                setError={this.setError}
                clearError={this.clearError}
                issueId={issue.id}
                noOfUnresolvedIssues={this.state.issues.length}
                showNoOfIssuesHeader={this.state.showNoOfIssuesHeader}
                isIssuesModalOpen={this.state.issues.length !== 0}
              />
            ) : null}
            <ToastHandler
              show={this.state.toast.show}
              text={this.state.toast.text}
              type={this.state.toast.type}
              onClose={this.toastCloseHandler}
              className={this.state.toast.className}
            />
          </React.Fragment>
        );
      }
    }

    return HOC;
  };

export default cartIssueManager;
