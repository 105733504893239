import React from "react";
import ExclamationLine from "sushiweb/Icons/all/ExclamationLine";

import { PageMessage } from "../message/Message";
import styles from "./ErrorBoundary.module.scss";

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // TODO: log to error service
    // console.log("error: ", error);
    // console.log("errorInfo: ", errorInfo);
  }

  render() {
    const { show, message, isPage } = this.props;

    const content = (
      <div className="p-3">
        <div className={`d-inline-block ${styles.icon}`}>
          <ExclamationLine color="red" />
        </div>
        <div className="ml-2 d-inline-block">
          {message || `This ${isPage ? "page" : "section"} couldn't be loaded`}
        </div>
      </div>
    );

    if (this.state.hasError && show) {
      if (isPage) {
        return <PageMessage content={[content]} />;
      } else {
        return (
          <div className="d-flex justify-content-center align-items-center h-100 wd-100">
            {content}
          </div>
        );
      }
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
