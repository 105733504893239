import React from "react";
import Spinner from "sushiweb/Spinner";

import stateTypes from "../../../utils/constants/stateTypes";
import styles from "./Loader.module.scss";
import { CTA_COLOUR } from "../../../utils/constants/others";

function Loader(props) {
  const { status } = props;
  return (
    <div className={styles.container}>
      {status && status.state === stateTypes.STATE_FAIL ? (
        <span className="text-red">
          {status.error && status.error.Message}!
        </span>
      ) : (
        <Spinner size="large" color={CTA_COLOUR} />
      )}
    </div>
  );
}

export default Loader;
