import React from "react";
import Spinner from "sushiweb/Spinner";

import styles from "./PageLoader.module.scss";
import { CTA_COLOUR } from "../../../utils/constants/others";

function FullPageLoader(props) {
  return (
    <div className={styles.loaderFull}>
      <Spinner size="large" color={CTA_COLOUR}/>
      <h4>{props.message}</h4>
    </div>
  )
}

export default FullPageLoader;
