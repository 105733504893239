import React from "react";
import DOMPurify from "dompurify";

function StyledDiv(props)
{
    return <div 
        style={{backgroundImage: `url(${process.env.PUBLIC_URL+props.bgImage})`}}
        className={props?.className}
        dangerouslySetInnerHTML={{
            __html : DOMPurify.sanitize(props?.html)
        }}
    />
}

export default StyledDiv