import { isRequired } from "./helpers";


export function handleErrorType(errorCode = isRequired("errorCode"), actionData = isRequired("action"), history = isRequired("history"), dispatch) {
    switch (errorCode) {
        case "PAYMENT_STATUS_PENDING":
            handleCartBlocking(errorCode,actionData, dispatch)
            break
        default:
            break

    }
}

function handleCartBlocking(errorCode, actionData, dispatch) {
    if (dispatch) {
        dispatch({
            type: "ERROR_HANDLE",
            payload: {
                cartBlock: {
                    errorCode: errorCode,
                    actionData: actionData,
                }
            }
        })
    }
}