import { APP_MODE } from "../../utils/constants/others";

/**
 * @typedef {Object} CartSummary
 * @property {null} AppBarContainer - The AppBar container (currently null).
 * @property {null} CartData - Data related to the cart (currently null).
 * @property {null} CartHeading - Heading for the cart (currently null).
 * @property {null} CartIssues - Any issues related to the cart (currently null).
 * @property {null} CartSummary - Summary information about the cart (currently null).
 * @property {string} CartTitleText - The title text for the cart (e.g., number of items in the cart).
 * @property {Object} CheckoutParams - Parameters for the checkout process (e.g., checkout mode).
 * @property {null} MoveItemsCartCta - CTA (Call-to-Action) for moving items in the cart (currently null).
 * @property {null} MoveItemsTag - Tags for items being moved in the cart (currently null).
 * @property {null} NudgeInfoBottom - Information to nudge users at the bottom of the cart (currently null).
 * @property {null} NudgeInfoTop - Information to nudge users at the top of the cart (currently null).
 */

/**
 * @typedef {Object} CartData
 * @property {CartSummary} CartSummary - Summary data for the cart.
 * @property {null} Product - Product-related data (currently null).
 * @property {Object} CombinedCartCount - Combined cart information (an empty object by default).
 */

/**
 * The summary of the cart, containing various details about the cart's current state.
 * @type {CartSummary}
 */
const CART_SUMMARY = {
    AppBarContainer: null,
    CartData: null,
    CartHeading: null,
    CartIssues: null,
    CartSummary: null,
    CartTitleText: "",
    CheckoutParams: {},
    MoveItemsCartCta: null,
    MoveItemsTag: null,
    NudgeInfoBottom: null,
    NudgeInfoTop: null
};

/**
 * Data structure representing the overall cart data, including product and combined cart information.
 * @type {CartData}
 */
const CART_DATA = {
    CartSummary: CART_SUMMARY,
    Product: null,
    CombinedCartCount: {}
};

// exports
export {

    // states
    CART_SUMMARY,
    CART_DATA,
};