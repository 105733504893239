import CryptoJS from "crypto-js";

function getBrowserDetails() {
    let details = {
        name: null,
        version: null,
        v: null,
        userAgent: null,
        app: null,
        os: null,
    };

    let agt = navigator.userAgent;
    let browserName = navigator.appName;
    let fullVersion = "" + parseFloat(navigator.appVersion);
    let majorVersion = parseInt(navigator.appVersion, 10);
    let nameOffset, verOffset, ix;

    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset = agt.indexOf("Opera")) != -1) {
        browserName = "Opera";
        fullVersion = agt.substring(verOffset + 6);
        if ((verOffset = agt.indexOf("Version")) != -1) {
            fullVersion = agt.substring(verOffset + 8);
        }
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = agt.indexOf("MSIE")) != -1) {
        browserName = "Microsoft Internet Explorer";
        fullVersion = agt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset = agt.indexOf("Chrome")) != -1) {
        browserName = "Chrome";
        fullVersion = agt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset = agt.indexOf("Safari")) != -1) {
        browserName = "Safari";
        fullVersion = agt.substring(verOffset + 7);
        if ((verOffset = agt.indexOf("Version")) != -1) {
            fullVersion = agt.substring(verOffset + 8);
        }
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset = agt.indexOf("Firefox")) != -1) {
        browserName = "Firefox";
        fullVersion = agt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if ((nameOffset = agt.lastIndexOf(" ") + 1) < (verOffset = agt.lastIndexOf("/"))) {
        browserName = agt.substring(nameOffset, verOffset);
        fullVersion = agt.substring(verOffset + 1);
        if (browserName.toLowerCase() == browserName.toUpperCase()) {
            browserName = navigator.appName;
        }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) != -1)
        fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) != -1)
        fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt("" + fullVersion, 10);
    if (isNaN(majorVersion)) {
        fullVersion = "" + parseFloat(navigator.appVersion);
        majorVersion = parseInt(navigator.appVersion, 10);
    }
    details.name = browserName;
    details.version = fullVersion;
    details.v = majorVersion;
    details.app = navigator.appName;
    details.userAgent = navigator.userAgent;
    let osName = "Unknown OS";
    if (navigator.appVersion.indexOf("Win") != -1) { osName = "Windows";}
    if (navigator.appVersion.indexOf("Mac") != -1) { osName = "MacOS";}
    if (navigator.appVersion.indexOf("X11") != -1) { osName = "UNIX";}
    if (navigator.appVersion.indexOf("Linux") != -1) { osName = "Linux";}

    details.os = osName;
    return details;
}

function getDeviceId(browserDetails) {
    const details = JSON.stringify(browserDetails);
    return CryptoJS.MD5(details).toString();
}

function getDeviceName(browserDetails) {
    return browserDetails.os + "_" + browserDetails.name;
}

const browserDetails = getBrowserDetails();
const deviceId = getDeviceId(browserDetails);
const deviceName = getDeviceName(browserDetails);

export { deviceId, deviceName };
