import {useQuery} from "react-query"
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pollDocuments } from "../../../redux/dispatchers/userDispatcher";

 function DocumentPolling(props){
    const {payload} = useQuery(
        [], ()=>{
            const param = {
                "DocInfo":props?.documentJobs
            }
            
            props?.runPolling && props.actions.pollDocuments(param,(data)=>{
                
                props.setUploadDetailsFields("","",0,true,data?.response?.DocInfo,data?.response?.BottomMessage)
                if (!data.response.PollEnabled){
                    props.setRunPolling(false)
                }
                props.setUploadCTAEnabled(data?.response?.IsCTAEnabled)
            })
        },{
            refetchInterval:Number(props?.PollingDuration)
        }  
    );
    return null
}

export default withRouter(
    connect(
      (state) => ({
      }),
      (dispatch) => ({
        actions: bindActionCreators({
            pollDocuments
        },
          dispatch
        ),
      })
    )(DocumentPolling)
);