// react
import { useState, useEffect } from "react";


// imports
import { withRouter } from "react-router-dom";

// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// sushi
import Modal from "sushiweb/Modal";
import { Accordion, Panel } from "sushiweb/Accordion";
import Button from "sushiweb/Button/Button"
import Radio from "../shared/RadioButton";

// styles
import styles from "./modals.module.scss";

// constants
import {PET_POOJA_WHITELISTED_ORIGIN, } from "../../utils/constants/others";
import {getCookieForEntity, parseJson, GetPetPoojaClientConfiguration} from "../../utils/helpers";
import {PETPOOJA_STORE_REGISTERED} from "../../redux/pub-sub/events";
import {setPosLinkStatus, getPosLinkStatus} from "../../redux/dispatchers/userDispatcher";

const wrapPosOutletAddress = (address) => {
  if (address && address.length > 0) {

    if (address.length > 40) {
      return address.substring(0, 40) + "...";
    }
    return address;
  }
  return "";
};

const getFormattedPosOutletAddress = (address, pincode) => {
  const formattedAddress =  address ? address: "" + pincode ? ", " + pincode: "";
  return wrapPosOutletAddress(formattedAddress);
};

const mapPosOutletData = (outletsData) => {
  return outletsData?.map((outlet) => {
    return {
      OutletId: outlet?.store_id,
      OutletName: outlet?.restaurant_name,
      GST: outlet?.restaurant_gstno,
      OutletAddress: getFormattedPosOutletAddress(outlet?.store_address, outlet?.store_pincode),
    };
  });
};

const getSelectedPosOutlet = (currentOutlet) => {
  return mapPosOutletData(GetPetPoojaClientConfiguration()?.all_store_obj?.all_store_details)?.find(
    (outlet) => outlet.OutletId === currentOutlet?.PosStoreId
  );
};

const PosOutletSelectionModal = ({
  visible,
  onClose,
  actions,
  posUserData,
  posLinkData,
  isAuthenticated,
  outletSwitchId,
  validSwitchOutlets,
  source = "HOME",
  userOutlet,
  history,
}) => {

  // states
  const [selectOutlet, setSelectOutlet] = useState(null);
  const [posSelectOutlet, setPosSelectOutlet] = useState(null);
  const [isAlreadyLinked, setIsAlreadyLinked] = useState(false);

  // handlers
  const handleHpOutletSelection = (outlet) => {
    setSelectOutlet(outlet);
    setPosSelectOutlet(getSelectedPosOutlet(outlet));
    setIsAlreadyLinked(getSelectedPosOutlet(outlet)?.OutletId === outlet?.PosStoreId);

    if (isAuthenticated) {
      let posClientData = GetPetPoojaClientConfiguration();
      actions.getPosLinkStatus(
        {
          outlet_id: outlet?.Id,
          store_id: outlet?.PosStoreId || posClientData?.all_store_obj?.store_id,
          check_link: true,
          pos_session_id: posClientData?.session_id,
        }
      );
    }
  };

  const handlePosOutletSelection = (isChecked, posOutlet) => {
    let posClientData = GetPetPoojaClientConfiguration();
    if (isChecked) {
      actions.getPosLinkStatus(
        {
          outlet_id: selectOutlet?.Id,
          store_id: posOutlet?.OutletId,
          check_link: true,
          pos_session_id: posClientData?.session_id,
        },
        (response) => {
          setIsAlreadyLinked(response?.is_pos_store_linked);
        }
      );

      isChecked ? setPosSelectOutlet(posOutlet) : setPosSelectOutlet(null);
    }
  };

  const handleHpPosOutletLink = () => {
    // we will not allow to close the modal is pos outlet is not linked
    if (!posSelectOutlet?.OutletId) {
      return;
    }

    let posClientData = GetPetPoojaClientConfiguration();

    actions.setPosLinkStatus(
      {
        outlet_id: selectOutlet?.Id,
        store_id: posSelectOutlet?.OutletId,
        pos_session_id: posClientData?.session_id,
      },
      (response) => {
        // create payload
        const eventPayloadForLinking = {
          Topic: PETPOOJA_STORE_REGISTERED,
          Data: {
            store_id: response?.store_id,
            store_uuid: response?.store_uuid,
            token: response?.token,
          },
        };

        // dispatch event to parent
        window.parent.postMessage(
          JSON.stringify(eventPayloadForLinking),
          PET_POOJA_WHITELISTED_ORIGIN.SUPPLIER
        );
        setSelectOutlet(null);
        setPosSelectOutlet(null);
        onClose(history);
      }
    );
  };

  const getOutletNotPresentInfo = (images) => {
    const infoImage = images?.find((img) => img.ImageName === "POS_INFO_CIRCLE_IMAGE");
    return (
      <div className="px24 py24">
        <div className={`d-flex gap-2 ${styles.noOutletInfo}`}>
          <div>
            <img
              src={infoImage?.ImageURL}
              alt="link outlets"
              height={64}
              width={64}
              loading="lazy"
            />
          </div>
          <div className="d-flex flex-column gap-1">
            <div className="fw-600 text-gray-900">Why link outlets?</div>
            <div className="fw-400 fs-14 text-grey-600">
              Linking outlet helps you place orders at the correct outlet.
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getModalTitle = () => {
    return (
      <span className="d-flex gap-2 align-items-center">
        <span>Switch Petpooja outlet</span>
      </span>
    );
  };

  const getPosLinkOutletComponent = (posOutlets, images) => {
    const linkImage = images?.find(
      (img) => img.ImageName === "POS_OUTLET_LINK_IMAGE"
    );
    return (
      <div className="px24">
        {/* HP OUTLET */}
        <div
          className={`d-flex flex-column bg-white px24 py24 gap-3 border-radius-24px`}
        >
          <div className="fw-700 fs-22 text-gray-900">Hyperpure outlet</div>
          <div className="d-flex flex-column gap-1">
            <div className="text-gray-900 fw-600 fs-18">
              {selectOutlet?.OutletName}
            </div>
            <div className="text-grey-600 fs-16 fw-400">
              {selectOutlet?.FormattedOutletAddress}
            </div>
          </div>
        </div>
        {/* HP OUTLET */}

        {/* OUTLET LINK UI */}
        <div className="d-flex justify-content-start align-items-center px24">
          <div
            className={`d-flex align-items-center ${styles.posLinkContainer}`}
          >
            <div className={`bg-white ${styles.posLinkIcon}`}>
              <img
                src={linkImage?.ImageURL}
                alt="link"
                width={24}
                height={24}
                loading="lazy"
              />
            </div>
            <div className="text-gray-900 fw-500 fs-18">
              Link this to a Petpooja outlet
            </div>
          </div>
        </div>
        {/* OUTLET LINK UI */}

        {/* POS OUTLETS LIST*/}
        <div className={`${styles.posOutletsContainer}`}>
          <Accordion allowMultiple>
            <Panel expanded title="Petpooja outlet">
              <div className="d-flex flex-column gap-2">
                {posOutlets?.map((outlet, idx) => {
                  return (
                    <div
                      className="d-flex flex-column gap-2"
                      key={outlet.OutletId}
                    >
                      <div
                        className={`d-flex flex-row justify-content-between align-items-center ${styles.posOutletContainer}`}
                      >
                        <div className="d-flex flex-column gap-1">
                          <div className="text-gray-900 fw-700 fs-18">
                            {outlet.OutletName}
                          </div>
                          <div className="text-grey-600 fs-16 fw-400">
                            {outlet.OutletAddress}
                          </div>
                        </div>
                        <Radio
                          onClick={() => handlePosOutletSelection(true, outlet)}
                          checked={outlet.OutletId === posSelectOutlet?.OutletId}
                          color="red"
                        />
                      </div>
                      {idx < posOutlets.length - 1 ? (
                        <div className={styles.dashedLine}></div>
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                })}
              </div>
            </Panel>
          </Accordion>
        </div>
        {/* POS OUTLETS LIST */}
      </div>
    );
  };

  const getFooter = () => {
    return (
      <div
        className={`d-flex flex-row justify-content-between align-items-center px24 py24 bg-white ${styles.posOutletSelectionModalFooter}`}
      >
        <Button
          disabled={!posSelectOutlet?.OutletId}
          borderRadius="12px"
          loading={posUserData?.loading || posLinkData?.loading}
          onClick={() => handleHpPosOutletLink()}
        >
          {isAlreadyLinked ? "Confirm" : "Link outlet"}
        </Button>
      </div>
    );
  };

  useEffect(() => {
    if (isAuthenticated && outletSwitchId && visible) {
      validSwitchOutlets?.Accounts?.map((account) => {
        account.Outlets.map((outlet) => {
          if (outletSwitchId === outlet.Id) {
            handleHpOutletSelection({
              Id: outlet?.Id,
              OutletName: outlet?.OutletName,
              FormattedOutletAddress: outlet?.FormattedOutletAddress,
              PosStoreId: outlet?.PosStoreId,
              Status: outlet?.Status,
            });
          }
          return null;
        });
        return null;
      });
    }

    if (visible && isAuthenticated && source === "CHECKOUT"){
      handleHpOutletSelection({
        Id: userOutlet?.id,
        OutletName: userOutlet?.outletName,
        FormattedOutletAddress: userOutlet?.formattedOutletAddress,
        PosStoreId: userOutlet?.posStoreId,
        Status: userOutlet?.status,
      });
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      onClose={handleHpPosOutletLink}
      title={getModalTitle()}
      showCloseIcon={false}
      isCentered={true}
      className={`${styles.posOutletSelectionModal}`}
      showFooter={true}
      renderFooter={getFooter}
    >
      <div className={`d-flex flex-column wd-100`}>
        {getPosLinkOutletComponent(mapPosOutletData(GetPetPoojaClientConfiguration()?.all_store_obj?.all_store_details), posUserData?.images)}
        {getOutletNotPresentInfo(posUserData?.images)}
      </div>
    </Modal>
  );
};

// actions
const actions = {
  setPosLinkStatus,
  getPosLinkStatus,
};

export default withRouter(
  connect(
    (state) => ({
      posLinkData: state.user?.posData,
      posUserData: state.user?.posUserData?.data,
      isAuthenticated: state.auth.isAuthenticated,
      outletSwitchId: state.user.outletSwitchId,
      userOutlet: state.user?.data?.outlet,
      validSwitchOutlets: state.credit.validSwitchOutlets || {},
    }),
    (dispatch) => ({
      actions: bindActionCreators(actions, dispatch),
    })
  )(PosOutletSelectionModal)
);
