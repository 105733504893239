import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setAppMode } from "../../../../redux/dispatchers/userDispatcher";

// styles
import styles from "./AppModeToggle.module.scss";

// helper components
import AppModeToggleItem from "./AppModeToggleItem";
import AppModePopup from "./AppModePopup";

const prepareHeadingIcon = (icon, appmode) => {
  if (!icon) {
    return null;
  }
  return <img src={icon} alt={appmode} className={styles.appModeToggleHeadingIcon}/>
};

const getToggleItems = (segments) => {
  if (segments && segments.length > 0) {
    return segments.map((segment) => {
      return {
        heading: segment?.SelectedView?.Title?.text,
        headingIcon: prepareHeadingIcon(segment?.SelectedView?.Title?.prefix_image?.url, segment?.SelectedView?.Header),
        subHeading: segment?.SelectedView?.SubTitle?.text,
        appMode: segment.SelectedView?.Header,
      }
    });
  }
  return [];
};

// main component
const AppModeToggle = (props) => {

  // unwrap props 
  const {
    appMode,
    appModeInfo,
  } = props;

  return (
    appModeInfo?.CartSections?.Segments ? 
    <div className={`ml-5 ${styles.appModeToggle}`}>
      <div className={`d-flex gap-0 flex-row ${styles.appModeToggleContainer}`}>
        {getToggleItems(appModeInfo?.CartSections?.Segments).map((item, index) => {
          return (
            <AppModeToggleItem
              key={index}
              heading={item.heading}
              subHeading={item.subHeading}
              headingIcon={item.headingIcon}
              isActive={appMode === item.appMode}
              appMode={item.appMode}
            />
          );
        })}
      </div>
      <AppModePopup/>
    </div> : <></>
  );
};

// actions
const actions = {
  setAppMode,
};

// redux binding
export default withRouter(
  connect(
    (state) => ({
      appMode: state.user?.config?.AppMode,
      appModeInfo: state.user?.appModeInfo,
    }),
    (dispatch) => ({
      actions: bindActionCreators(actions, dispatch),
    })
  )(AppModeToggle)
);
