import _get from "lodash-es/get";

import { handleActionType, isReduxBasedAction } from "../../utils/helpers";
import actionTypes from "../actionTypes";

export const handleActionTypesViaRedux = (
    actionType,
    actionData,
    history,
    otherData = {},
    trackingParams,
    trackParamsCall
    ) => {
    return (dispatch) => {
        if (isReduxBasedAction(actionType)){
            switch (actionType){
                case "CATALOG": 
                    handleCatalogActionType(actionType,actionData,history,otherData,dispatch)
                    break
                default: 
                    // console.log("invalid action")
                    break
            }
        }else {
            handleActionType( actionType,
                actionData,
                history,
                otherData,
                trackingParams,
                trackParamsCall)
        }
    }
}

export const handleSearchToggleAction = (isToggleSearch) => {
    return dispatch => {
        dispatch({
            type: actionTypes.TOGGLE_SEARCH,
            payload: {toggleSearch: isToggleSearch}
          });
    }
}

function handleCatalogActionType(actionType,actionData, history, otherData ,dispatch) {
    const {
        EntityType,
        EntityId,
        SubEntityId,
        ReferenceType,
        ReferenceId
    } = actionData
    dispatch({type: actionTypes.CATALOG_ACTION_SET, 
    payload: {
        ActionType: actionType,
        ActionData: actionData
    }})
    let redirectUrl = `/buyer/entity/${EntityType}/${EntityId}/${SubEntityId}`;
    if (otherData.sendReference) {
        redirectUrl = redirectUrl + `?referenceId=${ReferenceId}&referenceType=${ReferenceType}`
    }
    if (otherData.reload) {
        window.location.href = redirectUrl
    } else {
        history.push(
            redirectUrl
        );
    }
}