import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import ToastContainer from "sushiweb/ToastContainer";

import configureStore from "./redux/store";
import Footer from "./components/shared/footer/Footer";
import ScrollToTop from "./components/shared/scroll/ScrollToTop";
import RouteHandler from "./RouteHandler";
import "./services/datadog"
const store = configureStore();

class App extends Component {
  state = {
    offline: !navigator.onLine,
  };

  setOfflineStatus = () => {
    this.setState({ offline: !navigator.onLine });
  };

  componentDidMount = () => {
    window.addEventListener("online", this.setOfflineStatus);
    window.addEventListener("offline", this.setOfflineStatus);
  };

  componentWillUnmount = () => {
    window.removeEventListener("online", this.setOfflineStatus);
    window.removeEventListener("offline", this.setOfflineStatus);
  };

  render() {
    const noInternetMessage = "No Internet Connection";
    return (
      <ToastContainer>
        <Provider store={store}>
          <BrowserRouter>
            <ScrollToTop>
              {this.state.offline && (
                <div className="no-intenet-wrapper">
                  <span>{noInternetMessage}</span>
                </div>
              )}
              <RouteHandler />
            </ScrollToTop>
          </BrowserRouter>
        </Provider>
      </ToastContainer>
    );
  }
}

export default App;
