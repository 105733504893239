import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setAppMode } from "../../../../redux/dispatchers/userDispatcher";

// styles
import styles from "./AppModeToggle.module.scss";

// main component
const AppModeToggleItem = (props) => {

  // unwrap props
    const {
        actions,
        heading,
        subHeading,
        headingIcon,
        isActive,
        appMode,
    } = props;


    // handle click
    const handleClick = () => {
      actions.setAppMode(appMode, window.location.pathname);
    };
  
  return (
    <div className={`d-flex flex-column justify-content-center align-items center ${styles.appModeToggleItem} ${isActive ? styles.appModeToggleItemActive : null}`} onClick={handleClick}>
      <div className={`d-flex justify-content-center align-items-center ${styles.appModeToggleHeading}`}>
        {headingIcon ? <span className="mr-2">{headingIcon}</span> : <></>}
        <span>{heading}</span>
      </div>
      <div className={styles.appModeToggleSubHeading}>{subHeading}</div>
    </div>
  );
};

// actions
const actions = {
  setAppMode,
};

// redux binding
export default withRouter(
  connect(
    (state) => ({}),
    (dispatch) => ({
      actions: bindActionCreators(actions, dispatch),
    })
  )(AppModeToggleItem)
);
