import { debounce } from "lodash-es";
import React, {useState, useEffect, useRef, useCallback } from "react";
import {
  withGoogleMap,
  GoogleMap,
  Polygon,
  withScriptjs,
} from "react-google-maps";
import { HTMLDiv } from "../HTMLDiv";

// components
import Input from "sushiweb/InputTypes/Input/Input";
import Textarea from "sushiweb/InputTypes/Textarea/Textarea";
import Modal from "sushiweb/Modal";
import SpinnerCircular from "sushiweb/Spinner/SpinnerCircular";
import Button from "../Button";
import Image from "../Image/Image";
import Loader from "../loader/Loader";
import { red } from "sushiweb/tokens/color";

// styles
import styles from "./LocationModal.module.scss";

// utils
import envEndpoints from "../../../utils/env";
import stateTypes from "../../../utils/constants/stateTypes";
import { ADD_LOCATION_SIGN_UP, GET_GST_DETAILS, GET_RES_LOCATION_DETAILS, MANUALLY, SWITCH_COLOUR, DISABLED_COLOR} from "../../../utils/constants/others";


/* GLOBALS */
const addressDetailsModalMap = {
  ADDRESSPATH : "addressPath",
  FLOOR: "floor",
  LIFT: "lift",
  LANDMARK: "landmark",
  PINCODE: "pincode",
  LIFTREQUIRED : "liftRequired"
}

const addressDetailsModal = {
  addressPath: "",
  floor: "",
  lift: "",
  landmark: "",
  pincode: "",
  liftRequired: false
}

/* LOCATION MAP */
const LocationMap = withScriptjs(
    withGoogleMap(
      ({
        center,
        polygonPaths,
        handleMapClick,
        handleFocusOnCurrentPosition,
        getAddressDetails,
        zoom,
        setZoom,
        handleSearchItemClick
      }) => {
        const mapOptions = {
          clickableIcons: false,
          fullscreenControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          zoomControl: false,
        };
  
        const mapRef = useRef(null);
  
        const handleMapDragEnd = () => {
          const map = mapRef.current;
          if (map) {
            const center = map.getCenter();
            const latitude = center.lat();
            const longitude = center.lng();
            getAddressDetails(latitude,longitude,true)
            setZoom(map.getZoom())
            handleSearchItemClick(latitude,longitude)
          }
        };

        return (
          <div className="relative">
            <GoogleMap
              zoom={zoom}
              center={center}
              onClick={handleMapClick}
              options={mapOptions}
              onDragEnd={handleMapDragEnd}
              ref={mapRef}
              className=""
              onZoomChanged={handleMapDragEnd}
            >
              {polygonPaths && polygonPaths.length > 0 && (
                <Polygon
                  paths={polygonPaths}
                  options={{
                    strokeColor: "#256FEF",
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    fillColor: "#256FEF",
                    fillOpacity: 0.16,
                  }}
                />
              )}
  
       
            </GoogleMap>
            <div className={`${styles.gpsFocusMobile} absolute `}>
              <Image
                classname={`${styles.gpsFocusMobileImage} pointer`}
                onClick={handleFocusOnCurrentPosition}
                source={process.env.PUBLIC_URL + "/assets/images/GPSFocusMobile.png"} 
              />
            </div>
            
            <Image
               onClick={handleFocusOnCurrentPosition}
            source={process.env.PUBLIC_URL + "/assets/images/GpsFocus.png"} classname={`${styles.gpsFocus} absolute pointer`}/>
          </div>
        );
      }
    )
  );
  
/** DRIVER COMPONENT **/
const LocationModal = (props) => {

  // props
  const { isOpen, onClose, className, positionArr, latLongObject, onPinChange, onVerify, getAddressDetails, addressDetails, getSearchLocations, searchLocations, locationMeta, updateOutletLocation, updateOutletLocationStatus, updateOutletLocationResponse, poiId, history, dontLoadFromLatLong, loadGPSLocation, getAddressDetailsStatus, getSearchLocationStatus, trackPopUp, currentInputType, nextInputType, setActionType, parentToggler, handleParentConfirmLocation, handleSetLatitudeAndLongitude, outletId } = props;

  // states
  const [mapCenter, setMapCenter] = useState({  lat: latLongObject.lat, lng: latLongObject.lng });
  const [markerPosition, setMarkerPosition] = useState({ lat: latLongObject.lat, lng: latLongObject.lng });
  const [polygonPaths, setPolygonPaths] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [currentStep , setCurrentStep] = useState(1);
  const [addressEnteredDetails , setAddressEnteredDetails] = useState(addressDetailsModal);
  const [dontShowMap , setDontShowMap] = useState(false);
  const [zoom, setZoom] = useState(17);
  const [pinCodeError, setPinCodeError] = useState("");
  const [addressPathError, setAddressPathError] = useState("");


  // handlers
  const handleAssetURL = (image_name) => {
    return process.env.PUBLIC_URL + "/assets/images/" + image_name
  };

  const handleOutletLocationResponse = (outletActionType) => {
    const actionTypeMapping = {
      "REFRESH" : 0,
      "CART" : "/buyer/cart",
      "PROFILE" : "/in/profile"
    }
    const response = actionTypeMapping[outletActionType] || 0;
    if (response !== window.location.pathname){
      history.push(response)
    }
    onClose()
  };

  const checkValidPincode = (pinCode) => {
    let isValidPincode = true;
    let errorMsg = ""

    if (!pinCode) {
      isValidPincode = false;
      errorMsg = "Pin Code is required";
    }

    if (pinCode.length == 0){
        errorMsg = "";
        isValidPincode = false;
    } else {
      if (!/^[0-9]{6}$/.test(pinCode)) {
        errorMsg = "Pin Code is incorrect";
        isValidPincode = false;
      }
    }
    return {isValidPincode, errorMsg};
  }

  const handlePincodeChange = (pinCode) =>{
    const pincodeResponse = checkValidPincode(pinCode);
    setPinCodeError(pincodeResponse.errorMsg);
    addressHandler(pinCode, addressDetailsModalMap.PINCODE)
  }

  const handleAddressPathChange = (address) => {
    addressHandler(address, addressDetailsModalMap.ADDRESSPATH);
    setAddressPathError(()=>{
      return address && address?.length < 10 ? "Address should be min. 10 characters" : ""
    });
  }

  const addressHandler = (val, field) => {
    const addressFields = {
      FLOOR : "floor",
      LIFT : "lift"
    }
    setAddressEnteredDetails(prevState => {
      if (field === addressFields.FLOOR) {
        const filteredFloor = locationMeta.Floors.find(i => i.DisplayText === val);
        const ifLiftRequired = filteredFloor ? filteredFloor.LiftInputRequired : false;
        return {
          ...prevState,
          [field]: val,
          lift: null,
          liftRequired: ifLiftRequired
        };
      }
      if (field === addressFields.LIFT) {
        return {
          ...prevState,
          [field]: val
        };
      }
      return {
        ...prevState,
        [field]: val
      };
    });
  };

  const handleCheckIfValidForSave = () => {
    const { addressPath, floor, liftRequired, lift, pincode } = addressEnteredDetails;
    if (mapCenter && addressPath && floor && addressPath.length >= 10 && checkValidPincode(pincode).isValidPincode) {
      if (liftRequired && !lift) {
        return false;
      }
      return true;
    }
    return false;
  }
  
  const handleBtnColor = () => {
    return handleCheckIfValidForSave() && updateOutletLocationStatus.state !== stateTypes.STATE_BEGIN ? SWITCH_COLOUR : DISABLED_COLOR
  }

  const handleSaveOutletInfo = () => {
    let params = {
      "Address": addressEnteredDetails.addressPath,
      "Latitude": mapCenter.lat,
      "Longitude": mapCenter.lng,
      "Floor": addressEnteredDetails.floor,
      "LiftAvailable": addressEnteredDetails.lift === "Yes",
      "NearbyLandmark": addressEnteredDetails.landmark,
      "PoiId": poiId,
      "Source": "outlet_update",
      "CityId": addressDetails?.CityId,
      "LocationHeading": addressDetails?.LocationHeading,
      "LocationSubheading": addressDetails?.LocationSubHeading,
      "SubZoneId": addressDetails?.SubZoneId,
      "ZoneId": addressDetails?.ZoneId,
      "Pincode" : addressEnteredDetails.pincode,
    }

    if (outletId > 0) {
      params.OutletId = outletId
    }
    updateOutletLocation(params);
  }


  const handleSave = () => {
    if (handleCheckIfValidForSave() && updateOutletLocationStatus.state !== stateTypes.STATE_BEGIN) {
      handleSaveOutletInfo();
      trackPopUp();
    }
  }

  // use effect
  const getSearchLocationsCall = useCallback(debounce((searchQuery)=>{
    if (searchQuery?.length){
      getSearchLocations(searchQuery)
    }
    },500),[]);

  useEffect(()=>{
    if (searchValue?.length){
        getSearchLocationsCall(searchValue)
    }
  },[searchValue])

  useEffect(() => {
    if (updateOutletLocationStatus.state === stateTypes.STATE_DONE) {
      handleOutletLocationResponse(updateOutletLocationResponse.ActionType)
    }
  }, [updateOutletLocationStatus])

  useEffect(() => {

    if (positionArr && positionArr.length > 0) {
      setPolygonPaths(positionArr);
    }else {
        setPolygonPaths([]);
        if (!mapCenter.lat && !mapCenter.lng){
        if(dontLoadFromLatLong && loadGPSLocation){
          navigator.permissions.query({ name: 'geolocation' })
          .then((permission)=> {
            if (permission.state === "granted"){
              setDontShowMap(false)
              handleFocusOnCurrentPosition()
            }else{
              setDontShowMap(true)
            }
          })
        }else if (dontLoadFromLatLong && !loadGPSLocation){
          setDontShowMap(true)
        }
      }
    }
  }, [isOpen,positionArr]);

  const handleMapClick = (e) => {
    if (!markerPosition) {
      const { latLng } = e;
      const latitude = latLng.lat();
      const longitude = latLng.lng();
      setMarkerPosition({ lat: latitude, lng: longitude });
      if (typeof onPinChange === "function") {
        onPinChange({ lat: latitude, lng: longitude });
      }
    }
  };

  const handleMarkerDragEnd = (e) => {
    const { latLng } = e;
    if (latLng) {
      const latitude = latLng.lat();
      const longitude = latLng.lng();
      setMarkerPosition({ lat: latitude, lng: longitude });
      setPolygonPaths([]);
      if (typeof onPinChange === "function") {
        onPinChange({ lat: latitude, lng: longitude });
      }
    }
  };

  const handleFocusOnCurrentPosition = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setMapCenter({ lat: latitude, lng: longitude });
          setMarkerPosition({ lat: latitude, lng: longitude });
          getAddressDetails(latitude,longitude,true)
          setPolygonPaths([]);
          setZoom(17)
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported in your browser.");
    }
  };

  const handleVerify = () => {
    if (markerPosition && onVerify) {
      const { lat, lng } = markerPosition;
      onVerify(lat, lng);
    }
  };

  const handleSearchItemClick = (lat , lng) =>{
    setSearchValue("")
    setDontShowMap(false)
    setMapCenter({ lat: lat, lng: lng });
    setMarkerPosition({ lat: lat, lng: lng });
    getAddressDetails(lat,lng,true)
    setZoom(17)
    setPolygonPaths([]);
    if (typeof(handleSetLatitudeAndLongitude) === 'function'){
      handleSetLatitudeAndLongitude(lat,lng)
    }
  }

  const handleConfirmLocation = () => {
    if (currentInputType === GET_RES_LOCATION_DETAILS) {
      if (parentToggler === ADD_LOCATION_SIGN_UP || parentToggler === ADD_LOCATION_SIGN_UP + "_" + MANUALLY || parentToggler === GET_GST_DETAILS) {
        handleParentConfirmLocation(mapCenter.lat, mapCenter.lng);
      } else {
        setActionType(nextInputType);
        if (typeof handleSetLatitudeAndLongitude === 'function') {
          handleSetLatitudeAndLongitude(mapCenter.lat, mapCenter.lng);
        }
      }
    } else {
      if (addressDetails.Serviceable?.Status !== "UNSERVICEABLE") {
        setCurrentStep(2);
      }
    }
  }
  


  return (
    <Modal
      visible={isOpen}
      showCloseIcon={true}
      isCentered
      type="default"
      className={"modal24pxRadius"}
    >
      <div className={`modal-dialog ${className} ${styles.wd600px} ${styles.h688px}`}>
        {currentStep === 1 ? 
        <div className="modal-content">
          <div className={`${styles.modalHeader}`}>
            <div  className="d-flex justify-content-between align-items-center px24 py24">
              <div  className="fs-30 w-600">{addressDetails?.ToolBarHeading?.length ? addressDetails?.ToolBarHeading : "Delivery location"}</div>
              <div >
                <Image
                  source={process.env.PUBLIC_URL + "/assets/images/cross.png"}
                  classname={"img32 pointer"}
                  onClick={onClose}
                />
              </div>
            </div>
            <div className={`absolute ${styles.modalHeaderSearchCover}`}>
              <div  className={`px24 ${styles.paddingBottom0} relative `}>
                <img 
                  className={`img16 ${styles.iconSearch}`}
                  src= {process.env.PUBLIC_URL+"/assets/images/searchThick.png"}
                  alt="cross"
                />
                <Input className={`${styles.searchableInput}`} label={"Search for area, street name"} hideLabel value= {searchValue} onChange={(val)=> {setSearchValue(val)}}/> 
              </div> 
            </div>
          </div>
          <div className="modal-body relative">
            <div  className={`absolute ${styles.searchCover}`}>
              <div  className={`px24 ${styles.paddingBottom0} relative `}>
                <img 
                  className={`img16 ${styles.iconSearch}`}
                  src= {process.env.PUBLIC_URL+"/assets/images/searchThick.png"}
                />
                <Input className={`${styles.searchableInput}`} label={"Search for area, street name"} hideLabel value= {searchValue} onChange={(val)=> {setSearchValue(val)}}/> 
              </div> 
            </div>
            {
              !searchValue && !dontShowMap ?
            <div>
              <div className={`${styles.mapPage}`}><LocationMap
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${envEndpoints.urls.mapKey}&libraries=places`}
                loadingElement={<div className={addressDetails?.InfoText?.length?`${styles.mapDimensionWithTag}`:`${styles.mapDimensionWithoutTag}`} ></div>} //356px // 290px
                containerElement={<div className={addressDetails?.InfoText?.length?`${styles.mapDimensionWithTag}`:`${styles.mapDimensionWithoutTag}`} />}
                mapElement={<div style={{ height: "100%" }} />}
                center={mapCenter}
                markerPosition={markerPosition}
                polygonPaths={polygonPaths}
                onClick={handleMapClick}
                onDragEnd={handleMarkerDragEnd}
                onVerify={handleVerify}
                handleFocusOnCurrentPosition={handleFocusOnCurrentPosition}
                getAddressDetails={getAddressDetails}
                zoom={zoom}
                setZoom ={setZoom}
                handleSearchItemClick={handleSearchItemClick}
              />
              </div>
               {getAddressDetailsStatus.state === stateTypes.STATE_BEGIN ?  <div className={styles.spinnerPosition}><SpinnerCircular size="large" color={red.z500} /></div> :<></>}
              <div  className={`absolute d-flex flex-column align-items-center ${styles.centerAlignPointer}`}>
                <div className={`${styles.pointerPosition}`}>
                  <div className="relative">
                      {addressDetails.Serviceable?.Status !== "UNSERVICEABLE" ? 
                      <div className={styles.mapPopup}>
                          <div className={`fs-14 w-400 text-align-center`}>Your order will be delivered here</div>
                          <div className={` fs-12 w-400 ${styles.opacity8} text-align-center`}>Move pin to adjust exact location</div>
                      </div>
                      : 
                      <div className={styles.mapPopupUnservicable}>
                          <div className={`fs-14 w-400 text-align-center`}>We do not deliver to this area yet</div>
                          <div className={` fs-12 w-400 ${styles.opacity8} text-align-center`}>Move pin to adjust exact location</div>
                      </div>
                      }
                    <div className={addressDetails.Serviceable?.Status !== "UNSERVICEABLE" ? styles.downTriangleLocation : styles.downTriangleLocationUnservicable}></div>
                    <div className={styles.whiteRectangleBlur}></div>
                  </div>
                </div>
                <img className={styles.mapPointer} src={process.env.PUBLIC_URL + "/assets/images/Union.png"} />
              </div>
            </div> : <></>
            }
            {addressDetails?.InfoText?.length && !searchValue  && !dontShowMap ? <div className={`${styles.tagline}`} style={{backgroundColor:addressDetails?.InfoBgColor}}><HTMLDiv html={addressDetails?.InfoText}></HTMLDiv></div> : <></>}
          </div>
          {!searchValue && !dontShowMap? 
            <div >
            {addressDetails?.InfoText?.length && !searchValue  && !dontShowMap ? <div className={`${styles.taglineMobile}`} style={{backgroundColor:addressDetails?.InfoBgColor}}><HTMLDiv html={addressDetails?.InfoText}></HTMLDiv></div>:<></>}

              <div  className={`${styles.modalFooter} relative`}>
                <div   className="d-flex text-align-center px-24 flex-column py24">
                    <div className={`mb-1 ${styles.locationHeading} w-800`} >{addressDetails.LocationHeading}</div>
                    <div className="fs-16 w-400 text-gray-600 mb-2">{addressDetails.LocationSubHeading}</div>
                </div>
              </div>
              <div className={`px24 py24 ${styles.bottomButtonFixed}`}>
                <Button
                  disabled = {!mapCenter || addressDetails.Serviceable?.Status === "UNSERVICEABLE"}
                  btncolor={!mapCenter || addressDetails.Serviceable?.Status === "UNSERVICEABLE" ? "#D0D4DC" : "#EF4F5F"} className={`wd-100 ${styles.confirmLocationButton}`} onClick={()=> {handleConfirmLocation()}}>
                  Confirm Location
                </Button>
              </div>
            </div> :
            <div className={styles.searchBox}>
              {getSearchLocationStatus.state === stateTypes.STATE_BEGIN ? <div className={styles.spinnerPosition}><SpinnerCircular size="large" color={red.z500} /></div> : <></>}
              { searchLocations?.AddressData?.length ?
                  searchLocations.AddressData.map((item,index)=>
                  <div onClick={()=>{handleSearchItemClick(item.Latitude, item.Longitude)}}
                  className={`d-flex pointer mx24 py24 ${index === searchLocations.AddressData.length -1 ? "" : styles.addressDatapart} align-items-center`}>
                      <div className={`${styles.gpsBox} mr-3`}><img className="img20" src={process.env.PUBLIC_URL + "/assets/images/location.png"}/></div>
                      <div>
                          <div className="fs-16 w-600">{item.LocationHeading}</div>
                          <div className="fs-14 w-400 text-gray-400">{item.LocationSubHeading}</div>
                      </div>
                  </div>
                  ) : <></>
              }
            </div>
          }
        </div> : 
       
          <div>
            <div className="d-flex justify-content-between align-items-center px24 py24">
              <div className="d-flex align-items-center">
                <img className="img32 mr-3 pointer" onClick={() => setCurrentStep(1)} src={handleAssetURL("chevronLeftBlack.png")} />
                <div className="fs-30 w-600 ">
                  Enter complete address
                </div>
              </div>
              <div>
                <Image
                  source={handleAssetURL("cross.png")}
                  classname={`img32 pointer`}
                  onClick={onClose}
                />
              </div>
            </div>

            <div className={`px24 py24 ${styles.borderTopGrey}`}>
              <Textarea 
              className={styles.textAreaLocation} 
              label="Enter your complete address" 
              value={addressEnteredDetails.addressPath} 
              onChange={handleAddressPathChange} 
              errorMsg={addressPathError} />

              <Textarea
                className={styles.textAreaPincode + " mt-3"}
                autocomplete={false}
                fullWidth={true} 
                label="Pincode"  
                value={addressEnteredDetails.pincode} 
                onChange={handlePincodeChange}
                errorMsg={pinCodeError} />

              <div className="mt-3">
                <div className="fs-20 w-800">Floor</div>
                <div className="d-flex mt-3">
                  {
                    locationMeta.Floors.map(i =>
                      <div className={i.DisplayText === addressEnteredDetails.floor ? styles.floorTabSelected : styles.floorTab} onClick={() => addressHandler(i.DisplayText, addressDetailsModalMap.FLOOR)}>
                        {i.DisplayText}
                      </div>)
                  }
                </div>
              </div>

              {addressEnteredDetails.liftRequired ?
                <div className="mt-3">
                  <div className="fs-20 w-800">Is lift available</div>
                  <div className="d-flex mt-3">
                    <div className={"Yes" === addressEnteredDetails.lift ? styles.floorTabSelected : styles.floorTab} onClick={() => addressHandler("Yes", addressDetailsModalMap.LIFT)}>
                      {"Yes"}
                    </div>
                    <div className={"No" === addressEnteredDetails.lift ? styles.floorTabSelected : styles.floorTab} onClick={() => addressHandler("No", addressDetailsModalMap.LIFT)}>
                      {"No"}
                    </div>
                  </div>
                </div> : <></>
              }

              <div className="mt-3">
                <div className="fs-20 w-800">Nearby landmark</div>
                <div className="mt-3">
                  <Textarea
                    className={styles.textAreaLocation} label="Add any nearby landmark (optional)" value={addressEnteredDetails.landmark} onChange={(val) => { addressHandler(val, addressDetailsModalMap.LANDMARK) }} />
                </div>
              </div>

            </div>

            <div className="d-flex justify-content-between">{updateOutletLocationStatus.state === stateTypes.STATE_BEGIN ? <Loader /> : <></>}</div>

            <div className={`px24 py24 ${styles.bottomButtonFixed}`}>
              <Button btncolor={handleBtnColor()} className="wd-100" onClick={handleSave}>
                Save and continue
              </Button>
            </div>
    
          </div>      
        }
      </div>
    </Modal>
  );
};

export default LocationModal;
