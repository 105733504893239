import React, { Component } from "react";
import Remove from "sushiweb/Icons/all/Remove";
import Plus from "sushiweb/Icons/all/Plus";
import Spinner from "sushiweb/Spinner/Spinner";
import { grey } from 'sushiweb/tokens/color';
import styles from "./Counter.module.scss";
import { parseIntOr0 } from "../../../utils/helpers"

class CounterInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            counterVal: 0,
        };
    }

    componentDidMount() {
        this.setState((prevState) => ({
            ...prevState,
            counterVal: this.props.defaultVal || this.props.minLimit || 0,
        }));
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            (prevProps.defaultVal &&
                this.state.counterVal &&
                this.props.defaultVal !== this.state.counterVal) ||
            (!prevProps.defaultVal && prevProps.defaultVal !== this.props.defaultVal)
        ) {
            this.setState((prevState) => ({
                ...prevState,
                counterVal: this.props.defaultVal,
            }));
        } else if (this.props.defaultVal !== this.state.counterVal) {
            this.setState((prevState) => ({
                ...prevState,
                counterVal: this.props.defaultVal,
            }));
        }
    }

    quantityChangeHandler(e, val) {
        e.stopPropagation()
        let numberRegex = /^\d+$/;
        if (val === "" || val === parseInt(0) || numberRegex.test(val)) {
            this.props.quantityChangeHandler(parseIntOr0(val));
        }
    }

    setCount(e, type) {
        e.stopPropagation()
        if (type === "inc") {
            this.setState(
                (prevState) => ({
                    ...prevState,
                    counterVal: parseInt(this.state.counterVal ? this.state.counterVal : 0) + 1,
                }),
                () => {
                    this.quantityChangeHandler(e, parseInt(this.state.counterVal));
                }
            );
        } else if (type === "dec" && parseInt(this.state.counterVal) === this.props.minOrderQty) {
            this.setState(
                (prevState) => ({
                    ...prevState,
                    counterVal: 0 ,
                }),
                () => {
                    this.quantityChangeHandler(e, parseInt(this.state.counterVal));
                }
            );
        } else if (type === "dec" && parseInt(this.state.counterVal) >= 0) {
            this.setState(
                (prevState) => ({
                    ...prevState,
                    counterVal: parseInt(this.state.counterVal) - 1,
                }),
                () => {
                    this.quantityChangeHandler(e, parseInt(this.state.counterVal));
                }
            );
        }
    }

    render() {
            const { placeholder } = this.props;
            const disableDecQty = this.props.disabled ||
                this.props.showLoader ||
                (this.props.minLimit && parseInt(this.state.counterVal) === this.props.minLimit) ||
                (!this.props.minLimit && this.state.counterVal === 0) ||
                this.props.maxLimit === 0;
            const disableIncQty = this.props.disabled ||
                this.props.showLoader ||
                this.props.maxLimit === 0 ||
                (this.props.maxLimit && parseInt(this.state.counterVal) === this.props.maxLimit);
            return ( <
                    div className = "relative d-inline-block" >
                    <
                    div className = {
                        `${this.props.size === "small" || this.props.size === "smallest" || this.props.size === "large" || this.props.size === "rail"
              ? `${styles.quantityChangeHandler} ${styles[this.props.size]}`
              : `${styles.quantityChangeHandler}`} ${this.props.maxLimit === 0 ? styles.disableCounter  : ''}`
          }
        >
          <div
            className={
                disableDecQty
                ? `${styles.tab} ${styles.dec} disable disable-bg`
                : `${styles.tab} ${styles.dec} pointer`
            }
            onClick={(e) => this.setCount(e, "dec")}
          >
            <Remove color={disableDecQty ? grey.z500 : '#ef4f5f'}/>
          </div>
          <input
            placeholder={placeholder}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => this.quantityChangeHandler(e, e.target.value)}
            value={this.state.counterVal}
            disabled={this.props.showLoader || this.props.disabled || this.props.maxLimit === 0}
            className={styles.counterInput}
            maxLength={4}
          />
          <div
            className={
              disableIncQty
                ? `${styles.tab} ${styles.inc} disable disable-bg`
                : `${styles.tab} ${styles.inc} pointer`
            }
            onClick={(e) => this.setCount(e, "inc")}
          >
            <Plus color={disableIncQty ? grey.z500 : "#ef4f5f"}/>
          </div>
        </div>
        {this.props.showLoader && (
          <span className={`fs-10 ${styles.loaderMsg}`}>
            <Spinner color="#EC4654" />
          </span>
        )}
      </div>
    );
  }
}

export default CounterInput;