import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import queryString from "query-string";
import { DecodeUrlSearchParams, getCookieForEntity, handleActionType, isTabSelected}  from "../../../utils/helpers";
import { v4 as uuidv4 } from "uuid";
// sushi
import Button from "../../shared/Button";
import ButtonCore from "../../shared/Button"
import DirectionLine from "sushiweb/Icons/all/DirectionLine";
import OrderHistory from "sushiweb/Icons/all/OrderHistory";
import ExclamationLine from "sushiweb/Icons/all/ExclamationLine";
import Modal from "sushiweb/Modal";
import Dropdown from "sushiweb/Dropdown";
import MenuItem from "sushiweb/MenuItem";
import LoginInput from "sushiweb/InputTypes/LoginInput";
import ChatBot from "./../../shared/chatBot/ChatBot";
import { CLIENT_TYPE_PETPOOJA, COMPLETE_ONBOARDING_SETUP } from "../../../utils/constants/others";

import {
  emailStatement,
  downloadStatement,
} from "../../../redux/dispatchers/statementDispatcher";
import {
  getUserData,
  getConfig,
  getOverDue,
  closeAppInstallPrompt,
  getPopup,
  getAppLink,
  sideMenu ,
  toggleDAModal,
  getMaskedNumber, 
  trackPopUp,
  setErrorToast,
  getHomePage,
  showNotification,
  showHeaderDeliveryNudge,
  showOutletSwitchModal,
  showChatBot,
  getBottomTabs,
} from "../../../redux/dispatchers/userDispatcher";

import { signOut } from "../../../redux/dispatchers/authDispatcher";
import { getPaymentOptions } from "../../../redux/dispatchers/paymentDispatcher";
import stateTypes from "../../../utils/constants/stateTypes";
import SearchInput from "./searchInput/SearchInput";
import AuthOptions from "./authOptions/AuthOptions";
import styles from "./Header.module.scss";
import ErrorBoundary from "../errorBoundary/ErrorBoundary";
import FullPageLoader from "../../shared/pageLoader/FullPageLoader";
import PopupModal from "../../shared/popup/PopupModal";
import { getScroll, toggleScroll } from "../../../utils/helpers";
import {
  getNotificationsStatus,
  resetNotificationCount,
} from "../../../redux/dispatchers/notificationDispatcher";
import NotificationPanel from "./notificationPanel/NotificationPanel";
import { INPUT_BORDER_COLOR } from "../../../utils/constants/others";
import FeedbackModal from "../feebackSuccessModal";
import Lottie from "lottie-web";
import Spinner from "sushiweb/Spinner/Spinner";
import { validatePhoneNumber } from "../../../utils/validationUtils";
import HeaderDelivery from "./HeaderDelivery";
import SwitchOutletModal from "./SwitchOutletModal";
import SignInFlow from "./SignInFlow";
import AccountStatement from "../accountStatment/AccountStatement";
import Image from "../../shared/Image/Image";
import { isMobile } from "../../../utils/helpers";
import AppModeToggle from "./appModeToggle/AppModeToggle";
import PosOutletSelectionModal from "../../modals/PosOutletSelectionModal";

const isMWeb = isMobile(992);
const validPopupTypes = ["PAYMENT_DUE_POPUP", "NPS", "CONSENT", "HP_PLUS_BOTTOMSHEET"];

class CartLink extends React.Component {
  state = {
    animationClass: "",
    maskedNumber: null,
    enteredMaskedNumber: null,
    changeNumber: false,
    maskedLoading: false,
    loginModalOpen: false ,
    loginModalRedirect: false,
    locationModalOpen:false,
    completeOnboardingModalData: {},
    completeOnboardingModalOpen:false
  };
    


  componentDidUpdate(prevProps, prevState) {
    const { userData } = this.props;
    if (prevState.toast !== this.state.toast && this.state.toast.show && this.state.toast.text) {
      this.props.actions.setErrorToast(this.state.toast.text)
    }

    if(this.props.userData?.user?.isSalesOpsUser){
      window.location.href = '/z/4pl/outlet-orders?isSalesOpsUser=true'
        const params = {
          "isSalesOpsUser": "true"
        }
        const url = new URL(window.location.href);
        Object.keys(params).forEach(key => url.searchParams.set(key, params[key]));

        window.history.pushState({}, '', url);
    }

    if (this.props.isAuthenticated && this.props.userData && (prevProps.userDataStatus?.state !== stateTypes.STATE_DONE) && this.props.userDataStatus?.state === stateTypes.STATE_DONE ) {
      this.props.actions.getBottomTabs()
    }
 
    if (
      prevProps.userData &&
      userData &&
      userData.cartProductsCount !== prevProps.userData.cartProductsCount
    ) {
      // set animation class
      this.setState(
        (prevState) => ({
          ...prevState,
          animationClass: styles.cartLinkAnimation,
        }),
        () => {
          // remove animation class
          setTimeout(() => {
            this.setState((prevState) => ({
              ...prevState,
              animationClass: "",
            }));
          }, 1000);
        }
      );
    }
  }
  
  render() {
    const { forMobile, userData } = this.props;
    if (forMobile && userData) {
      return (
        <div
          className={`${styles.cartCountContainer} ${this.state.animationClass}`}
        >
          <span className={styles.cartCount}>{userData.cartProductsCount}</span>
        </div>
      );
    } else {
      return (
        <div className={`d-flex align-items-center relative ${styles.pdIconRight}`}>
        <Link
          className={`d-flex align-items-center ${this.props.className || ""} ${this.state.animationClass} ${
            !userData ? "disable" : ""
            } ${styles.cartItem}
            ${(userData && userData.cartProductsCount) ? styles.blackCart: ""}
            `}
          to="/buyer/cart"
        >
          <img 
            src={(userData && userData.cartProductsCount) ? process.env.PUBLIC_URL + "/assets/images/CartWhiteIcon.png": process.env.PUBLIC_URL + "/assets/images/CartBlackIcon.png"}
            alt="Cart icon" 
            className={`${styles.cartIcon} ${styles.headerIcon}`}
          />
        </Link>
        {(userData && userData.cartProductsCount) ? (
            <strong className={`fs-12 ${styles.cartProductCount}`}>
              {userData?.combinedCartCheckout?.isEnabled? userData?.CombinedCartCount : userData.cartProductsCount}
            </strong>
          ) : null}
        </div>
      );
    }
  }
}

class Header extends React.Component {
  /**
   * Data members
   */

  constructor(props) {
    super(props)
    this.animationContainerRef = React.createRef()
    this.animationRef = React.createRef()
    this.timerRef = React.createRef()
  }

  state = {
    isMenuOpen: false,
    showDropdown: false,
    showSidebar: false,
    showEmailModal: false,
    showDownloadModal: false,
    downloadFileType: 0,
    toggleHeader: "white",
    updatedCartQuantity: 0,
    toast: {
      show: false,
      text: "",
      type: "default",
    },
    showNotificationPanel: false,
    showPopup: false,
    showFeedbackSuccess: false,
    showOosNotify: false,
    isSourceSideMenu: false,
    openChatBotDeeplink : false,
    showPosOutletSelectionModal: false,
  };

  /**
   * Lifecycle methods
   */

  fetchPopup = () => {
    const { actions } = this.props
    if(this.props.location.pathname === "/") {
      actions.getPopup("HOME",(data)=>{
        if (data?.PopupData?.CTA?.Action?.ActionType === COMPLETE_ONBOARDING_SETUP){
            this.setState({...this.state,completeOnboardingModalData:data?.PopupData,completeOnboardingModalOpen:true})
        }
      });
    } 
    else actions.getPopup("",(data)=>{
      if (data?.PopupData?.CTA?.Action?.ActionType === COMPLETE_ONBOARDING_SETUP){
          this.setState({...this.state,completeOnboardingModalData:data?.PopupData,completeOnboardingModalOpen:true})
      }
    });
  }

  fetchOverdueSource = () => {
    const { location : { pathname = "" } = {} } = this.props
    const CHECKOUT = "/buyer/checkout"
    const HOME = "/"
    const SEARCH_SOURCES = ["/buyer/search", "/buyer/query"]
    const CATALOG = "/buyer/catalog"
    const CART = "/buyer/cart"
    if (pathname === CHECKOUT) {
      return "checkout_dedt"
    }
    if(pathname === CART) {
      return "cart"
    }
    if (pathname === HOME) {
      return "home"
    }
    if (pathname.search(CATALOG) !== -1) {
      return "listing"
    }
    if (SEARCH_SOURCES.filter(s => pathname.search(s) !== -1).length) {
      return "search_results"
    }
    return ""
  }

  setSourceSideMenuTrue(){
    this.setState({
      isSourceSideMenu: true
    }) 
  }

  setSourceSideMenuFalse(){
    this.setState({
      isSourceSideMenu: false
    }) 
  }

  componentDidMount() {
    const { actions } = this.props;
    if (!this.props.callAuthenticatedApi) {
      if (this.props.isAuthenticated) {
        if (!this.props.userData) {
          this.props.actions.getUserData();
          if (this.props.history.location.pathname !== "/") {
         this.props.actions.getHomePage();
          }
        } else {
          !this.props.config && actions.getConfig();
          actions.getNotificationsStatus();
        }
      }
    }

    if (this.props.isAuthenticated && this.props.userData && this.props.userDataStatus?.state === stateTypes.STATE_DONE) {
      this.props.actions.getBottomTabs()
    }

    if (window.localStorage.getItem("switchOutletToast")) {
      this.setState((prevState) => ({
        ...prevState,
        toast: {
          ...prevState.toast,
          show: true,
          text: window.localStorage.getItem("switchOutletToast"),
          type: "success",
        },
      }), () => {
        window.localStorage.removeItem("switchOutletToast")
      });
    }

    if (this.props.userData && this.props.location.pathname !== "/buyer/cart" && this.props.location.pathname !== "/buyer/checkout") {
      this.fetchPopup()
    }

    if (!this.props.applinks) {
      this.props.actions.getAppLink();
    }

    this.unlisten = this.props.history.listen((data) => {
      this.setState((prevState) => ({
        ...prevState,
        showDropdown: false,
        showSidebar: false,
      }));
    });

    if (this.props.theme === "blackStatic") {
      this.setState((prevState) => ({
        ...prevState,
        toggleHeader: "black",
      }));
      window.addEventListener("scroll", () => this.handleHeader());
    }

    let showChatBoxEveryWhere = window.localStorage.getItem("renderChatBoxEverywhere")
   
    if (showChatBoxEveryWhere){
      this.props.actions.showChatBot(this.props.isSummaryPage  , true)
    }
   this.checkURLPatternForOrderSummaryPage()
   this.checkDeepLinkForChatBot()
}

 checkURLPatternForOrderSummaryPage() {
  const currentURL = window.location.href;
  const pattern = /https?:\/\/[^\/]+\/buyer\/order\/[\w-]+(\/number)?/i;
    if (pattern.test(currentURL)) {
      this.props.actions.showChatBot(true  , this.props.renderChatBotEverywhere)
    } else {
      this.props.actions.showChatBot(false  , this.props.renderChatBotEverywhere)
    }
  }

  myListDetails() {
    let myListTab = this.props.bottomTabs?.Tabs?.filter((ele) => {
      return ele?.Action?.ActionType === "MY_LIST"
    })

    if (myListTab?.length > 0) {
      return myListTab[0]
    }
  }

  checkDeepLinkForChatBot(){
    let paramString = DecodeUrlSearchParams("Action_type");
    if (paramString == "OPEN_CHATBOT" ){
      this.props.actions.showChatBot()
      this.setState({ openChatBotDeeplink :  true} )
    }
  }

  componentWillUnmount() {
    this.unlisten();
    if (this.props.theme === "blackStatic") {
      window.removeEventListener("scroll", () => this.handleHeader());
    }
  }

  toastCloseHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      toast: {
        ...prevState.toast,
        show: false,
        text: "",
        type: "default",
      },
    }));
  };

  toastOpenHandler = ({text, type}) => {
    this.setState((prevState) => ({
      ...prevState,
      toast: {
        ...prevState.toast,
        show: true,
        text: text,
        type: type,
      },
    }));
  };

  getUpdatedQuantity() {
    const { categories } = this.props;
    let count = 0;

    for (const category of categories) {
      for (const product of category.Products) {
        if (product.AlreadyAddedQuantityCount !== 0) {
          count++;
        }
      }
    }

    return count;
  }

  handleHeader() {
    const currTopScrollVal = getScroll();
    if (currTopScrollVal < this.props.heightToScroll - 60) {
      if (this.state.toggleHeader === "white") {
        this.setState((prevState) => ({
          ...prevState,
          toggleHeader: "black",
        }));
      }
    } else {
      if (this.state.toggleHeader === "black") {
        this.setState((prevState) => ({
          ...prevState,
          toggleHeader: "white",
        }));
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      emailStatementStatus,
      downloadStatementStatus,
      actions,
      userData,
      callAuthemticatedApi,
      getPopupStatus,
      getPopupHomeStatus,
      popupData,
      overdueStatus,
      oosNotifyStatus
    } = this.props;
    if (!window.localStorage.getItem("deviceId")){
      window.localStorage.setItem("deviceId", uuidv4())
    }
    //This is done so that the parent component scroll gets disabled on modal opening
    let search = queryString.parse(this.props.history.location.search) 
    if (search.signIn && !this.state.loginModalRedirect) {
      this.setState({
        ...this.state,
        loginModalOpen: true,
        loginModalRedirect: true
      })
    }

    if (!prevProps.userData && userData && !callAuthemticatedApi) {
      actions.getConfig();
      actions.getNotificationsStatus();
    }

    if (this.props.isAuthenticated && this.props.userData && (prevProps.userDataStatus?.state !== stateTypes.STATE_DONE) && this.props.userDataStatus?.state === stateTypes.STATE_DONE ) {
      this.props.actions.getBottomTabs()
    }

    if (
      prevProps.downloadStatementStatus.state !== downloadStatementStatus.state
    ) {
      if (downloadStatementStatus.state === stateTypes.STATE_DONE) {
        this.setState((prevState) => ({
          ...prevState,
          showDownloadModal: false,
          toast: {
            ...prevState.toast,
            show: true,
            text: "Statement successfully downloaded",
            type: "success",
          },
        }));
      } else if (downloadStatementStatus.state === stateTypes.STATE_FAIL) {
        this.setState((prevState) => ({
          ...prevState,
          toast: {
            ...prevState.toast,
            show: true,
            text: downloadStatementStatus.error,
            type: "error ",
          },
        }));
      }
    }

    if (prevProps.emailStatementStatus.state !== emailStatementStatus.state) {
      if (emailStatementStatus.state === stateTypes.STATE_DONE) {
        this.setState((prevState) => ({
          ...prevState,
          showEmailModal: false,
          showDropdown: false,
          showSidebar: false,
          toast: {
            ...prevState.toast,
            show: true,
            text: "Email sent successfully",
            type: "success",
          },
        }));
      } else if (emailStatementStatus.state === stateTypes.STATE_FAIL) {
        this.setState((prevState) => ({
          ...prevState,
          toast: {
            ...prevState.toast,
            show: true,
            text: emailStatementStatus.error,
            type: "error",
          },
        }));
      }
    }
    if (
      ((!prevProps.userData && this.props.userData && this.props.userData.account && this.props.userData.account.tcSignedDated) ||
        (this.props.userData && !prevProps.acceptanceData && this.props.acceptanceData)) &&
      !this.props.globalSignedInFromZomato && (this.props.location.pathname !== "/buyer/cart") && (this.props.location.pathname !== "/buyer/checkout")
    ) {
      this.fetchPopup()
    }

    if(prevProps.getPopupHomeStatus.state !== getPopupHomeStatus.state) {
      if (getPopupHomeStatus.state === stateTypes.STATE_DONE &&
        (popupData &&
          popupData.PopupType &&
          validPopupTypes.includes(popupData.PopupType)
        )) {
        this.setState({ showPopup: true });
      }
    }

    if(prevProps.oosNotifyStatus.state !== oosNotifyStatus.state && 
      oosNotifyStatus.state === stateTypes.STATE_DONE
    ) {
      this.animationRef.current = Lottie.loadAnimation({
        container: this.animationContainerRef.current,
        path: "/assets/animations/oos_notify_success.json", 
        renderer: "canvas",
        loop: false,
        autoplay: true,
      })
      this.setState({ showOosNotify: true });
      this.timerRef.current = setTimeout(() => {
        this.closeOosNotifyModal()
      }, 2500)
    }

    if (prevProps.getPopupStatus.state !== getPopupStatus.state) {
      if (getPopupStatus.state === stateTypes.STATE_DONE &&
        (this.props.popupData &&
          this.props.popupData.PopupType &&
          this.props.popupData.PopupType !== "RATING"
        )) {
        if (this.props.popupData.PopupType === "BASE_POPUP" && this.props.location.pathname !== "/") {
          return;
        }
        this.setState({ showPopup: true });
      }
    }

    if (
      prevProps.overdueStatus.state === stateTypes.STATE_BEGIN && 
      overdueStatus.state === stateTypes.STATE_DONE &&
      this.props.location.pathname === "/buyer/checkout"
    ){
      this.setState({
        showSiteMessage: true
      })
    }

  }

  /**
   * Handler methods
   */
  toggleDropdownHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      showDropdown: !prevState.showDropdown,
    }));
  }

  toggleSidebarHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      showSidebar: !prevState.showSidebar,
    }), () => {
      toggleScroll(this.state.showSidebar)
      this.state.showSidebar && this.props.isAuthenticated &&  this.props.actions.sideMenu()
    });
  }

  toggleEmailModalHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      showEmailModal: !prevState.showEmailModal,
    }));
  }

  toggleDownloadModalHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      showDownloadModal: !prevState.showDownloadModal,
    }));
  }

  downloadFileTypeChangeHandler = (value) => {
    if (this.state.downloadFileType !== value) {
      this.setState((prevState) => ({
        ...prevState,
        downloadFileType: value,
      }));
    }
  };

  /**
   * Utility methods
   */
  getQuery(search) {
    const params = queryString.parse(search);
    const id = params.id ? params.id.trim().toLowerCase() : "";
    const q = params.q ? params.q.trim().toLowerCase() : "";

    if (id || q) {
      return { id, q };
    } else {
      return null;
    }
  }

  /**
   * Render methods
   */
  renderEmailModal() {
    return (
      <Modal
        visible={this.state.showEmailModal}
        onClose={() => this.toggleEmailModalHandler()}
        showCloseIcon={true}
        title="Email Statement"
        isCentered
        padding="2rem"
      >
        <p>Do you want to send statement via email?</p>
        <p>
          <Button
            onClick={() => this.toggleEmailModalHandler()}
            btnColor="red"
            className="mr-3 mb-2 w-400"
            appearance="outline"
          >
            No
          </Button>
          <Button
            onClick={() => {
              if (this.props.userData) {
                this.props.actions.emailStatement();
              }
            }}
            loading={
              this.props.emailStatementStatus.state === stateTypes.STATE_BEGIN
            }
            btnColor="red"
            className="solid w-400"
          >
            Yes
          </Button>
        </p>
      </Modal>
    );
  }

  renderDownloadModal() {
    return (
      <Modal
        visible={this.state.showDownloadModal}
        onClose={() => this.toggleDownloadModalHandler()}
        showCloseIcon={true}
        title="Download Statement"
        isCentered
        padding="2rem"
      >
        <p>In which format do you want to download the statement?</p>
        <p>
          <Dropdown
            isCompact={true}
            value={this.state.downloadFileType === 0 ? "Excel" : "PDF"}
            onSelect={this.downloadFileTypeChangeHandler}
            showArrow
            width="100%"
            borderColor={INPUT_BORDER_COLOR}
          >
            <MenuItem value={0} label="Excel" />
            <MenuItem value={1} label="PDF" />
          </Dropdown>
        </p>
        <p>
          <Button
            onClick={() => {
              if (this.props.userData) {
                this.props.actions.downloadStatement(
                  this.state.downloadFileType === 1
                );
              }
            }}
            loading={
              this.props.downloadStatementStatus.state ===
              stateTypes.STATE_BEGIN
            }
            btnColor="red"
            className="solid w-400"
          >
            Download
          </Button>
        </p>
      </Modal>
    );
  }

  // Auth buttons
  renderSignupLoginBtn(isSideBar = false) {
    if (!this.props.isAuthenticated) {
      if (isSideBar) {
        return (
          <div className={styles.sideBarPadding}>
            <Button
              appearance="outline"
              size="small"
              className="wd-100 mb-3 mt-3 mr-3 w-400"
              btnColor="red"
              onClick={() => this.props.history.push("/register")}
            >
              Register
            </Button>
            <Button
              size="small"
              btnColor="red"
              className="wd-100 solid w-400"
              onClick={() => this.props.history.push("/signin")}
            >
              Log In
            </Button>
          </div>
        );
      }
      return (
        <React.Fragment>
          <Button
            className={`mr-3 w-400 ${styles.loginButton}`}
            size="small"
            btnColor={"#EF4F5F"}
            onClick={() => this.setState({
              ...this.state,
              loginModalOpen: true
            })}
          >
            <div className="fs-16">Login/Signup</div>
          </Button>
        </React.Fragment>
      );
    }
    return null;
  }

  // searchBar
  renderSearchBar() {
    const query = this.getQuery(this.props.location.search);

    return (
      <div className={`${styles.padding24Xaxis} ${styles.wdFillAvailable} `}>
        <ErrorBoundary nullError="true">
          <SearchInput
            isAuthenticated={false}
            userData={this.props.userData}
            searchValue={query && query.q}
            autoFocus={this.props.autoFocus}
          />
        </ErrorBoundary>
      </div>
    );
  }


  // mobile and web sidebar
  renderSidebar() {
    return (
      <React.Fragment>
        {this.state.showSidebar && (
          <div
            className={`${styles.background} ${styles.visible}`}
            onClick={() => this.toggleSidebarHandler()}
          ></div>
        )}
        <div
          className={`d-flex flex-column ${styles.panel} ${
            this.state.showSidebar ? styles.visible : ""
          }`}
        >
          {this.props.isAuthenticated ? (
            <AuthOptions
              toggleDownloadModalHandler={() =>
                this.toggleDownloadModalHandler()
              }
              toggleEmailModalHandler={() => this.toggleEmailModalHandler()}
              toggleMenuOpen={() => this.toggleSidebarHandler()}
              device="mobile"
              setSourceSideMenuTrue={() => this.setSourceSideMenuTrue()}
            />
          ) : (
            <div
              className={`${styles.sideBarPadding} ${
                isMobile(992) ? styles.topBarPading : ""
              }`}
            >
              <div
                className={`absolute show-on-mobile pointer ${styles.crossImagePosition}`}
              >
                <img
                  onClick={() => this.toggleSidebarHandler()}
                  className="img20"
                  src={process.env.PUBLIC_URL + "/assets/images/Cross.png"}
                />
              </div>
              <ul className="prevent-default">
                <li>
                  <a href="/#how-it-works" className="text-gray-dark">
                    <DirectionLine size={17} className="sushi-icon-gray" /> How
                    it works
                  </a>
                </li>
                <li>
                  <a href="/#products" className="text-gray-dark">
                    <OrderHistory size={16} className="sushi-icon-gray" />{" "}
                    Products
                  </a>
                </li>
                <li>
                  <a href="/#FAQs" className="text-gray-dark">
                    <ExclamationLine size={16} className="sushi-icon-gray" />{" "}
                    FAQs
                  </a>
                </li>
              </ul>
            </div>
          )}

          {this.renderSignupLoginBtn(true)}
        </div>
      </React.Fragment>
    );
  }

  getUserName(name) {
    if (name.length > 8) {
      return name.slice(0, 8) + "...";
    }
    return name;
  }

  renderAuthRightContent() {
    return (
      <div className={`${this.myListDetails() ? styles.wdMinSaveWithMyList : styles.wdMinSave} d-flex justify-content-around align-items-center show-on-desktop`}>
        {
          !this.props.notShowCart && this.props.userData && this.props.userData.outlet && this.props.userData.outlet.serviceabilityStatus !== "NOT_SERVICEABLE" &&
          <CartLink userData={this.props.userData} />
        }
        {
          !this?.props?.userData?.showLocationModal && !this.props.notShowNotification && this.props.userData && this.props.userData.outlet && this.props.userData.outlet.serviceabilityStatus !== "NOT_SERVICEABLE" &&
          this.renderMyListButton()
        }
         {
          !this?.props?.userData?.showLocationModal && !this.props.notShowNotification && this.props.userData && this.props.userData.outlet && this.props.userData.outlet.serviceabilityStatus !== "NOT_SERVICEABLE" && 
          this.renderNotificationBell()
        }
        {
          !this.props.notShowUserDropdown && !this.props.showLocationModal &&
          <div className={` ${styles.cartItem} ${styles.pdIconRight} d-flex align-items-center pointer`}  onClick={() => this.toggleSidebarHandler()}>
          
          <img className={`${styles.headerIcon}`} src= {process.env.PUBLIC_URL + "/assets/images/Menu.png"} alt="" />
          </div>
        }
        {this.state.showDropdown ? (
          <AuthOptions
            toggleDownloadModalHandler={this.toggleDownloadModalHandler}
            toggleEmailModalHandler={this.toggleEmailModalHandler}
            toggleMenuOpen={this.toggleDropdownHandler}
            device="desktop"
            setSourceSideMenuTrue={()=>this.setSourceSideMenuTrue()}
          />
        ) : null}
        
      </div>
    );
  }

  renderNotificationBell = () => {
    const { unseenNotificationsCount, userData } = this.props;

    if (!userData) {
      return null;
    }

    return (
      <div
        onClick={() => {
          this.props.actions.showNotification(true)
          this.props.actions.resetNotificationCount();
        }}
        className={`pointer relative d-flex align-items-center ${styles.pdIconRight} ${styles.cartItem} `}
      >
       <img src={process.env.PUBLIC_URL + "/assets/images/Bell.png"} className={`${styles.headerIcon}`}/>
       {unseenNotificationsCount ? (
          <div className={styles.unseenNotificationsCount}>
          </div>
        ) : <></>}
      </div>
    );
  };

  renderMyListButton = () => {
    const { userData } = this.props;
    if (!userData) {
      return null;
    }
    let myListDetails = this.myListDetails()
    return (
      myListDetails?.Action?.ActionData &&  myListDetails.Action.ActionData.EntityType && myListDetails.Action.ActionData.EntityId !== null && myListDetails.Action.ActionData.SubEntityId ?
      <>
      <div
      className={`d-flex align-items-center ${styles.pdIconRight}  ${styles.cartItem} pointer`}
      onClick={() => {
        handleActionType("CATALOG",myListDetails.Action.ActionData,this.props.history)
        this.props.actions.getBottomTabs()
      }}
    >
      <Image 
        source={process.env.PUBLIC_URL + "/assets/images/Like2.png"}
        alt="Like icon" 
        classname={`${styles.cartIcon} ${styles.headerIcon}`}
      />
    </div>
    </> : <></>
    )
  };

  closePopup = () => {
    this.setState({ showPopup: false })
  }

  toggleFeedbackSuccess = () => {
    this.setState(prevState => {
      return {
        ...prevState, 
        showFeedbackSuccess: !prevState.showFeedbackSuccess
      }
    })
  }
  
  handleGetDaNumber = () =>{
    this.setState({maskedLoading: true})
    let params = {
      OrderNumber:  this.props.deliveryData?.CTA?.Action?.ActionData?.CTA?.Action?.ActionData?.OrderNumber,
      PhoneNumber: this.state.enteredMaskedNumber,
    }
    this.props.actions.getMaskedNumber(params, (data)=>{
      this.setState({maskedNumber: data?.response?.Number , changeNumber: false ,maskedLoading: false})
    })
  }

  renderOosNotifyModal = () => {
    return (
      <Modal
        visible={this.state.showOosNotify}
        onClose={() => this.closeOosNotifyModal()}
        showCloseIcon={false}
        isCentered
      >
        <div className={styles.modalWrapper}>
          <div className={styles.animation} ref={this.animationContainerRef}></div>
          <div className={`text-align-center w-600 fs-18 text-gray-900 ${styles.oosText}`}>
            We will notify you when it's back in stock.
          </div>
      </div> 
      </Modal>
    )
  }

  closeOosNotifyModal = () => {
    if(this.timerRef.current) {
      clearTimeout(this.timerRef.current)
    }
    this.setState({
      showOosNotify: false
    }, () => this.animationRef.current.destroy())
  }

  isAppModeToggleVisible = () => {
    return (
      this.props.location.pathname !== "/buyer/checkout" 
      && this.props.location.pathname !== "/buyer/order"
      && this.props.location.pathname !== "/buyer/order/success"
      && this.props.location.pathname !== "/buyer/payment"
    )
  }

  render() {
    const { showEmailModal, showDownloadModal } = this.state;
    const {
      isAuthenticated,
      notShowSearch,
      showAppInstallPrompt,
      userData,
    } = this.props;
    const compositeCTA = this.props.deliveryData?.CTA?.Action?.ActionData?.CompositeCTA;
    let newTextComponent = <div></div>
    if (compositeCTA?.FullText){
    let getClickableText = compositeCTA?.FullText.split(compositeCTA?.Text);
    newTextComponent = <div className="d-flex justify-content-center"><div style ={{color: compositeCTA?.NonTappableTextColor}}>{getClickableText[0]}</div> <div  onClick= {()=>{this.setState({changeNumber: true})}} style ={{color: compositeCTA?.TextColor}} className="ml-2 mr-2 w-600">{compositeCTA?.Text} </div><div  style ={{color: compositeCTA?.NonTappableTextColor}} className="ml-2 mr-2">{getClickableText[1]} </div> </div>
    }

    return (
      <React.Fragment>
        {!isAuthenticated && this.state.loginModalOpen ? (
          <SignInFlow
            isOpen={this.state.loginModalOpen}
            onClose={() =>
              this.setState({ ...this.state, loginModalOpen: false })
            }
            setLocationModal={(val) =>
              this.setState({ ...this.state, locationModalOpen: val })
            }
            locationModalOpen={this.state.locationModalOpen}
          />
        ) : (
          <></>
        )}
        {this.state.showPopup &&
        this.props.location.pathname !== "/buyer/cart" &&
        this.props.location.pathname !== "/buyer/checkout" ? (
          <PopupModal
            popup={this.props.popupData}
            closePopup={this.closePopup}
            history={this.props.history}
            source={this.props.location.pathname}
            toastOpenHandler={this.toastOpenHandler}
            toggleFeedbackSuccess={this.toggleFeedbackSuccess}
          />
        ) : null}
        {
          <Modal
            visible={this.props.viewDAModal}
            onClose={() => {
              this.setState({
                maskedNumber: "",
                changeNumber: false,
                enteredMaskedNumber: "",
              });
              this.props.actions.toggleDAModal(false);
            }}
            isCentered={true}
            showCloseIcon={true}
            slideOnMobile={isMWeb}
            titleComponent={
              <div className="fs-24 w-800 mb-3">Call delivery agent</div>
            }
            className={styles.daModalContainer}
          >
            <div>
              <div className={styles.greyBorderLine}>
                <div className={styles.daModal}>
                  {this.state.changeNumber ? (
                    <div>
                      <div className={`${isMWeb ? "fs- w-400 mb-5" : "fs-16 w-600 mb-5"}  ${styles.gray700} `}>
                        Enter the phone number you are calling from
                      </div>
                      <LoginInput
                        className={styles.loginInput}
                        defaultIsdCode={91}
                        placeholder={"Enter phone number"}
                        countries={[
                          {
                            countryId: "91",
                            name: "india",
                            isdCode: "91",
                            flagImgUrl:
                              "../../../../assets/images/indianFlag.png",
                          },
                        ]}
                        value={this.state.enteredMaskedNumber}
                        onChange={(val) => {
                          this.setState({ enteredMaskedNumber: val });
                        }}
                      />
                      {this.state.maskedLoading ? (
                        <div className="d-flex justify-content-center mb-2">
                          <Spinner color={"red"} />
                        </div>
                      ) : (
                        <></>
                      )}
                      <div>
                        <ButtonCore
                          disabled={
                            !(
                              !this.state.maskedLoading &&
                              !validatePhoneNumber(
                                this.state.enteredMaskedNumber
                              )
                            )
                          }
                          onClick={() => {
                            this.props.actions.trackPopUp(
                              this.props.deliveryData?.CTA?.Action?.ActionData
                                ?.CompositeCTA?.Action?.TrackingParams
                            );
                            this.handleGetDaNumber();
                          }}
                          className={`${styles.btnWidth} mt-4 w-600`}
                        >
                          {
                            this.props.deliveryData?.CTA?.Action?.ActionData
                              ?.CompositeCTA?.Text
                          }
                        </ButtonCore>{" "}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className={`fs-16 w-600 ${styles.gray700} `}>
                        {
                          this.props.deliveryData?.CTA?.Action?.ActionData
                            ?.Message
                        }
                      </div>
                      {this.state.maskedNumber ? (
                        <div
                          className={`${styles.maskedNumberCTA} ${styles.paddingTop}  fs-20 w-600 flex`}
                        >
                          {" "}
                          <div>Call at</div>{" "}
                          <div className="w-800 ml-2">
                            {this.state.maskedNumber}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div
                            className={`fs-14 w-600 pointer ${styles.gray700} ${styles.paddingTop} mb-3 text-align-center`}
                          >
                            {newTextComponent}
                          </div>
                          {this.state.maskedLoading ? (
                            <div className="d-flex justify-content-center mb-2">
                              <Spinner color={"red"} />
                            </div>
                          ) : (
                            <></>
                          )}
                          <div>
                            <ButtonCore
                              disabled={this.state.maskedLoading}
                              onClick={() => {
                                this.props.actions.trackPopUp(
                                  this.props.deliveryData?.CTA?.Action
                                    ?.ActionData?.CTA?.Action?.TrackingParams
                                );
                                this.handleGetDaNumber();
                              }}
                              className={`${styles.btnWidth}`}
                            >
                              {this.props.deliveryData?.CTA?.Text}
                            </ButtonCore>{" "}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal>
        }
        {this.state.completeOnboardingModalOpen &&
        !this.props.showLocationModal ? (
          <SignInFlow
            completeSetupModalData={this.state.completeOnboardingModalData}
            completeSetupModalOpen={this.state.completeOnboardingModalOpen}
            onClose={() =>
              this.setState({
                ...this.state,
                completeOnboardingModalOpen: false,
              })
            }
            actionType={COMPLETE_ONBOARDING_SETUP}
          />
        ) : (
          <></>
        )}
        {isAuthenticated && this.props.userData?.outlet?.id ? (
          <SwitchOutletModal
            IsOpen={this.props.outletSwitch.show}
            OnClose={(history, isReloadRequired) => {
              this.props.actions.showOutletSwitchModal(false);
              if (getCookieForEntity("","clientType") === CLIENT_TYPE_PETPOOJA && this.props.outletSwitchId) {
                this.setState((prev) => { return {...prev, showPosOutletSelectionModal: true} });
              } else if (isReloadRequired && history) {
                history.go(0);
              }
            }}
            Source={this.props.outletSwitch?.data?.Source}
          />
        ) : (
          <></>
        )}

        {/* --- POS OUTLET SELECTION ---- */}
        {
            isAuthenticated && this.props.userData?.outlet?.id && getCookieForEntity("","clientType") === CLIENT_TYPE_PETPOOJA ? 
            <PosOutletSelectionModal
              visible={this.state.showPosOutletSelectionModal}
              onClose={(history) => {
                this.setState((prev) => { return {...prev, showPosOutletSelectionModal: false} });
                history.go(0);
              }}
            /> : <></>
          }
        {/* --- POS OUTLET SELECTION ---- */}

        {this.state.toggleFeedbackSuccess && (
          <FeedbackModal visible={true} onClose={this.toggleFeedbackSuccess} />
        )}
        {this.renderOosNotifyModal()}
        {this.props.signOutStatus.state === stateTypes.STATE_BEGIN && (
          <FullPageLoader message="Signing Out" />
        )}
        {!this.props.notShowHeader ? 
        <nav role="navigation" aria-label="main navigation">
          <header
            className={
              this.state.toggleHeader === "black"
                ? `${styles.headerWrapper} d-flex align-items-center ${styles.transparentBackground}`
                : `${styles.headerWrapper} d-flex align-items-center ${
                    isMobile(992) ? styles.shiftTopHeader : ""
                  }`
            }
            style={
              isMobile(992) && !notShowSearch && this.props.toggleSearch
                ? { borderRadius: "0px" }
                : {}
            }
          >
            <div className="wd-100 d-flex align-items-center justify-content-between">
              
                {(!isAuthenticated || !isMobile(992)) && (
                  <div className={styles.headerLogo}>
                    <div onClick={() => {
                      window.location.href = "/";
                    }}>
                      {this.state.toggleHeader === "black" ? (
                        isMobile(992) ? (
                          <></>
                        ) : (
                          <img
                            src="/../../../../assets/images/logo_hp_white.png"
                            alt="Logo"
                            className={styles.logoImg}
                            width={130}
                            height={50}
                          />
                        )
                      ) : (
                        <img
                          src="/../../../../assets/images/logo_hp_black.png"
                          alt="Logo"
                          className={styles.logoImg}
                          width={130}
                          height={50}
                        />
                      )}
                    </div>
                  </div>
                )}
                {!notShowSearch ? (
                  !isMobile(992) && (
                    <HeaderDelivery
                      showHeaderDeliveryNudge={
                        this.props.actions.showHeaderDeliveryNudge
                      }
                      header={this.props.header}
                      history={this.props.history}
                      ctaAction={() => {
                        this.props.actions.trackPopUp(
                          this.props.header?.OutletInfo?.ActionCTA
                            ?.TrackingParams
                        );
                        this.props.actions.showOutletSwitchModal(
                          this.props.header?.OutletInfo?.ActionCTA
                            ?.ActionType === "SWITCH_OUTLET"
                            ? !this.props.outletSwitch?.show
                            : false,
                          this.props.header?.OutletInfo?.ActionCTA?.ActionData
                        );
                      }}
                    />
                  )
                ) : (
                  <div className={styles.deliveryHeader}></div>
                )}

                {this.props.isAuthenticated 
                && !isMobile(992) 
                && !this.props.header?.AppModeInfo?.AppModeSwitch?.CTA?.IsDisabled
                && this.isAppModeToggleVisible() ? <AppModeToggle /> : <></>}

                {!notShowSearch && this.renderSearchBar()}

                {isAuthenticated ? (
                  !isMobile(992) ? (
                    this.renderAuthRightContent()
                  ) : (
                    !this.props.notShowCart &&
                    this.props.userData &&
                    this.props.userData.outlet &&
                    this.props.userData.outlet.serviceabilityStatus !==
                      "NOT_SERVICEABLE" && (
                      <CartLink userData={this.props.userData} />
                    )
                  )
                ) : (
                  <div
                    className={`d-flex align-items-center justify-content-sm-center pdRight16 ${
                      this.state.toggleHeader === "black"
                        ? "show-on-desktop"
                        : null
                    }`}
                  >
                    {this.renderSignupLoginBtn()}
                  </div>
                )}
              
            </div>
          </header>
        </nav> : <></>}
        {showEmailModal && this.renderEmailModal()}
        {showDownloadModal && this.renderDownloadModal()}
        {this.renderSidebar()}
        <NotificationPanel
          isVisibleProp={this.props.showNotification}
          close={() => {
            this.props.actions.showNotification(false);
          }}
        />

        {/*Bottom footer*/}
        {this.props.isAuthenticated && !this.props.notShowBottomHeader ? (
          <div className={`${styles.bottomHeader} show-on-mobile`}>
            {this.props.bottomTabs?.Tabs?.filter(tab => {
                if (isMobile(992) || this.props.isMobile) {
                  return tab?.Action?.ActionType !== '4PL_DASHBOARD';  
                }
                  return true;
            }).map((tab, idx) => (
              <span key={`bottomBarIcon-${idx}`} className="text-gray-500">
                <div
                  onClick={() => {
                    handleActionType(
                      tab?.Action?.ActionType,
                      tab?.Action?.ActionData,
                      null,
                      tab?.Title === "Account"
                        ? { toggleSidebarHandler: this.toggleSidebarHandler }
                        : null,
                      tab?.Action?.TrackingParams
                    );
                  }}
                  className="link-black d-flex justify-content-center flex-column align-items-center"
                  style={{
                    color: isTabSelected(tab?.Title)
                      ? "#1C1C1C"
                      : tab?.TitleColor,
                  }}
                >
                  <Image
                    source={
                      isTabSelected(tab?.Title)
                        ? tab?.SelectedImage
                        : tab?.Image
                    }
                    classname={styles.bottomBarIcon}
                    alt=""
                  />

                  <p className={styles.bottomBarIconText}>{tab?.Title}</p>
                </div>
              </span>
            ))}
          </div>
        ) : null}
        {this.props.showChatBot && this.props.isAuthenticated && !isMWeb ? (
          <ChatBot
            isSourceSideMenu={this.state.isSourceSideMenu}
            openChatBotDeeplink={this.state.openChatBotDeeplink}
            setSourceSideMenuFalse={() => this.setSourceSideMenuFalse()}
          />
        ) : (
          <></>
        )}
        {this.props.showSOAModal && this.props.isAuthenticated ? (
          <AccountStatement
            isSourceSideMenu={!this.props.showSOAModal}
            openChatBotDeeplink={this.state.openChatBotDeeplink}
            setSourceSideMenuFalse={() => this.setSourceSideMenuFalse()}
          />
        ) : (
          <></>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      // data
      viewDAModal : state.user.viewDAModal,
      deliveryData : state.user.deliveryData,
      overdue: state.user.overdue,
      acceptanceData: state.user.acceptanceData,
      userData: state.user.data,
      showNotification: state.user.showNotification || false,
      popupData: state.user.popupData,
      showAppInstallPrompt: state.user.showAppInstallPrompt,
      isAuthenticated: state.auth.isAuthenticated,
      categories: state.cart.categories,
      outletSwitchId: state.user.outletSwitchId,
      unseenNotificationsCount: state.user.notifications.unseenCount,
      applinks: state.user.appLinks,
      tour: state.user.tour,
      config: state.user.config,
      header: state.user.homeData?.header || {},
      outletSwitch: state.user.outletSwitch || {},
      showChatBot : state.user.showChatBotConfig?.showChatBot,
      isSummaryPage: state.user.showChatBotConfig?.isSummaryPage,
      renderChatBotEverywhere : state.user.showChatBotConfig?.renderChatBotEverywhere ,
      showLocationModal: state.auth.showLocationModal ?state.auth.showLocationModal : state.user.showLocationModal,      
      bottomTabs: state.user.bottomTabs,
      toggleSearch: state.user.toggleSearch,
      // status
      signOutStatus: state.status.auth_signOut,
      getPopupStatus: state.status.getPopupStatus,
      getPopupHomeStatus: state.status.getPopupHomeStatus,
      downloadStatementStatus: state.status.statement_download,
      globalSignedInFromZomato: state.happyRegister.globalSignedInFromZomato,
      emailStatementStatus: state.status.statement_email,
      overdueStatus: state.status.user_overdue,
      oosNotifyStatus: state.status.oosNotify,
      locationModalData: state.auth.showLocationModal ? state.auth.locationModalData:state.user.locationModalData,
      showSOAModal: state.user.showSOAModal,
      userDataStatus: state.status.user_getData
    }),
    (dispatch) => ({
      actions: bindActionCreators({
        emailStatement,
        downloadStatement,
        getPaymentOptions,
        signOut,
        getUserData,
        getConfig,
        getOverDue,
        getNotificationsStatus,
        resetNotificationCount,
        closeAppInstallPrompt,
        getPopup,
        getAppLink,
        sideMenu,
        toggleDAModal,
        getMaskedNumber,
        trackPopUp,
        setErrorToast,
        getHomePage,
        showNotification,
        showHeaderDeliveryNudge,
        showOutletSwitchModal,
        showChatBot,
        getBottomTabs,
      },
        dispatch
      ),
    })
  )(Header)
);
