import React from 'react';
import DOMPurify from 'dompurify';

export const HTMLDiv=({
    html,
    className,
    style
})=>{
    return (
        <div
            style = {style}
            className={className}
            dangerouslySetInnerHTML={{
                __html : DOMPurify.sanitize(html)
            }}
        />
    )
}