import { useEffect } from "react";
import { HP_WEB_SERVICE_PORT } from "../../utils/constants/others";

const CouponRedirection = () => {
    useEffect(() => {
        if (process.env.NODE_ENV === "development") {
            window.location.href = `http://localhost:${HP_WEB_SERVICE_PORT}/in/coupon`;
        } else {
            window.location.href = "/in/coupon";
        }
    }, []);

    return null;
};

export default CouponRedirection;
