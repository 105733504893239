import React, { useRef } from "react";
import { useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function CarouselWrapper(props) {

    return (
        <div className={props.className}>
            <Carousel
            draggable={false}
             {...props}
            />
        </div>
    )
}

export default CarouselWrapper;