import React from "react";
import Modal from "sushiweb/Modal/Modal";
import Image from "../Image/Image";
import Button from "../Button";
import { isMobile } from "../../../utils/helpers";

import styles from "./PopupModal.module.scss";
const GenericPopup = props => {
    const getImageStyle = ()=> {
            if (props.ImageStyle === "200"){
                return styles.img200px
            }else if (props.ImageStyle === "120"){
                return styles.img120px
            }else{
              return styles.gstpopup   
            }
    }
    return (
        // type 1 CONSENT POPUP GST
        // type 2 YES NO MODAL
        // type 3 CONSENT POPUP VERIFY LOCATION
        // type 4 UNIVERSAL POPUP
        <Modal
          visible={props.visible}
          title={props.title}
          showCloseIcon={props.showCloseIcon}
          isCentered={true}
          onClose={(e) => !props.Blocking && props.onClose(e)}
          className={`${styles.modalRadius} ${(isMobile(500) && props.type === 1) ? styles.modalWidth250 : ''}`}
          >
            {props.type === 1  ? <div className={`${styles.gstmodalWidth}`}>
                <Image classname={`${getImageStyle()}`} source= {props.data?.PopupData?.HeaderImage}/>
                <div className="px-4 py-3">
                <div className="d-flex justify-content-center fs-24 w-800 text-align-center mt-2 mb-2">{props.data?.PopupData?.Heading}</div>
                {props.data?.PopupData?.SubHeading ? <div className={`${styles.greytext} fs-16 w-600 d-flex justify-content-center text-align-center mt-3 mb-5 `}>{props.data?.PopupData?.SubHeading}</div>: <></>}
                {props.data?.PopupData?.CTA && <div className="d-flex justify-content-center mt-4 mb-3 wd-100"><Button className="wd-100 d-flex justify-content-center" onClick = {() => props.handleCTA()}> {props.data?.PopupData?.CTA?.Text}</Button></div>}
                <div className={`fs-12 w-400 text-align-center mt-4  mb-4 ${styles.lightGreyFooterText}`}>{props.data?.PopupData?.FooterText}</div>
                </div>
            </div>: <></>}
            {props.type === 2  ? <div className={`${styles.verifyLoctionWidth}`}>
                <div className="d-flex justify-content-center">
                <Image classname={`${getImageStyle()}`} source= {props.data?.PopupData?.HeaderImage}/>
                </div>
                <div className="px24 py-3">
                <div className="d-flex justify-content-center fs-30 w-800 text-align-center mt-2 mb-2">{props.data?.PopupData?.Heading}</div>
                {props.data?.PopupData?.SubHeading ? <div className={`${styles.greytext} fs-16 w-600 d-flex justify-content-center text-align-center mt-3 mb-5 `}>{props.data?.PopupData?.SubHeading}</div>: <></>}
                <div className="d-flex justify-content-between">
                {props.data?.PopupData?.CTA?.NegativeCTA && <div className="d-flex justify-content-center mt-4 mb-3 wd-100 mr-4"><Button className="wd-100 d-flex justify-content-center" onClick = {() => props.handleNegativeCTA()} appearance="outline"> {props.data?.PopupData?.CTA?.NegativeCTA}</Button></div>}
                {props.data?.PopupData?.CTA?.PositiveCTA && <div className="d-flex justify-content-center mt-4 mb-3 wd-100"><Button className="wd-100 d-flex justify-content-center" onClick = {() => props.handlePositiveCTA()}> {props.data?.PopupData?.CTA?.PositiveCTA}</Button></div>}
                </div>
                <div className={`fs-12 w-400 text-align-center mt-4  mb-4 ${styles.lightGreyFooterText}`}>{props.data?.PopupData?.FooterText}</div>
                </div>
            </div>: <></>}
            {props.type === 3  ? <div className={`${styles.verifyLoctionWidth}`}>
                <div className="d-flex justify-content-center">
                <Image classname={`${getImageStyle()}`} source= {props.data?.PopupData?.HeaderImage}/>
                </div>
                <div className="px24 py-3">
                <div className="d-flex justify-content-center fs-30 w-800 text-align-center mt-2 mb-2">{props.data?.PopupData?.Heading}</div>
                {props.data?.PopupData?.SubHeading ? <div className={`${styles.greytext} fs-16 w-600 d-flex justify-content-center text-align-center mt-3 mb-5 `}>{props.data?.PopupData?.SubHeading}</div>: <></>}
                {props.data?.PopupData?.CTA && <div className="d-flex justify-content-center mt-4 mb-3 wd-100"><Button className="wd-100 d-flex justify-content-center" onClick = {() => props.handleCTA()}> {props.data?.PopupData?.CTA?.Text}</Button></div>}
                <div className={`fs-12 w-400 text-align-center mt-4  mb-4 ${styles.lightGreyFooterText}`}>{props.data?.PopupData?.FooterText}</div>
                </div>
            </div>: <></>}
            {
                props.type === 4 ?  <div className={`${styles.universalModalWidth}`}>
                <div className="d-flex justify-content-center" style={{ width: '-webkit-fill-available' }}>
                <Image classname={`${getImageStyle()}`} source= {props.data?.PopupData?.Image ?props.data?.PopupData?.Image: props.data?.PopupData?.HeaderBackgroundImage}/>
                </div>
                <div className="px16 py-3">
                <div className="d-flex justify-content-center fs-30 w-800 text-align-center mt-2 ">{props.data?.PopupData?.Heading}</div>
                {props.data?.PopupData?.SubHeading ? <div className={`${styles.greytext} fs-16 w-400 d-flex justify-content-center text-align-center mt-3 mb-5 `}>{props.data?.PopupData?.SubHeading}</div>: <></>}
                <div className="d-flex flex-column">
                {props.data?.PopupData?.CTA && <div className="d-flex justify-content-center mt-4 mb16 wd-100 mr-4"><Button size="large" className={`wd-100 d-flex justify-content-center fs-16 ${styles.btnBorder}`} onClick = {() => props.handlePositiveCTA()} > {props.data?.PopupData?.CTA?.Text}</Button></div>}
                {props.data?.PopupData?.SecondaryCTA && <div className="d-flex justify-content-center  mb-3 wd-100"><div className="wd-100 d-flex justify-content-center fs-16 text-red mt-2 pointer" onClick = {() => props.handleNegativeCTA()}> {props.data?.PopupData?.SecondaryCTA?.Text}</div></div>}
                </div>
                <div className={`fs-12 w-400 text-align-center mt-4  mb-4 ${styles.lightGreyFooterText}`}>{props.data?.PopupData?.FooterText}</div>
                </div>
            </div>: <></>
            }
        
        </Modal>
    )
}

export default GenericPopup;