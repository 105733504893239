import React, { useState } from "react";
import OtpInput from "react-otp-input";
import Input from "sushiweb/InputTypes/Input"
import { PROFILE_INPUT_BORDER } from "../../../utils/constants/others";
import styles from "./CustomInputs.module.scss";

function OTPInput ({value , onChange,error ,onKeyDown}) {
  return (
    <div className="relative" onKeyDown={onKeyDown}>
                    <OtpInput
                       className={`${styles.otpInput}`}
                        value={value}
                        onChange={onChange}
                        numInputs={6}
                        seperator = {"-"}
                        onKeyDown={onKeyDown}
                    />
                    <div className="text-red mt-1 fs-14">{error}</div>
    </div>
  ) 
}

export default OTPInput;
