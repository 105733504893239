import React from "react";
import { MODAL_TYPE_VERIFY_FORGET_PASSWORD_OTP, MODAL_TYPE_VERIFY_FORGET_PASSWORD_PASSWORD_MATCH, MODAL_TYPE_VERIFY_NUMBER, MODAL_TYPE_VERIFY_OTP, MODAL_TYPE_VERIFY_PASSWROD,MODAL_TYPE_SIGN_UP_PASSWORD,MODAL_TYPE_SIGN_UP_OTP, GET_PERSONAL_DETAILS, GET_RES_LOCATION_DETAILS_2,GET_RES_LOCATION_DETAILS, GET_PAN_DETAILS, GET_GST_DETAILS, GET_FSSAI_DETAILS, GET_UPLOAD_DETAILS,COMPLETE_ONBOARDING_SETUP} from "../../../utils/constants/others";
import MobileNumberInput from "./MobileNumberInput";
import OTPInput from "./OTPInput";
import PasswordInput from "./PasswordInput";
import MultipleInputs from './MultipleInputs'
import GstInputs from "./GstInputs";
import UploadInputs from "./UploadInputs";
import PanInputs from "./PanInputs";
import FssaiInputs from "./FSSAIInputs";

function CustomInputs ({value , onChange , inputType , label , error ,onKeyDown, values,reset,setGstValueAndSelectedParam,setUploadDetailsFields,setDocumentJobs,documentJobs,PollingDuration,uploadCTAEnabled,setUploadCTAEnabled,entityDetails,handleFileSelections}) {
  const getInputType = () => {
    switch (inputType) {
      case MODAL_TYPE_VERIFY_NUMBER: 
        return <MobileNumberInput
        value={value}
        onChange={onChange}
        label ={label}
        error= {error}
        onKeyDown={onKeyDown}
        />
      case MODAL_TYPE_VERIFY_PASSWROD: 
      case MODAL_TYPE_VERIFY_FORGET_PASSWORD_PASSWORD_MATCH:
      case MODAL_TYPE_SIGN_UP_PASSWORD:
        return <PasswordInput
        value={value}
        onChange={onChange}
        label ={label}
        error= {error}
        onKeyDown={onKeyDown}
        />
      case MODAL_TYPE_VERIFY_OTP: 
      case MODAL_TYPE_VERIFY_FORGET_PASSWORD_OTP:
      case MODAL_TYPE_SIGN_UP_OTP:
        return <OTPInput
        value={value}
        onChange={onChange}
        label ={label}
        error= {error}
        onKeyDown={onKeyDown}
        />
      case GET_PERSONAL_DETAILS: 
        return <MultipleInputs
        values={values}
        onKeyDown={onKeyDown}
        onChange={(val,index)=>onChange(val,index)}
        />
      case GET_RES_LOCATION_DETAILS_2:
        return <MultipleInputs
        values={values}
        onKeyDown={onkeydown}
        onChange={(val,index)=>onChange(val,index)}/>
      case GET_PAN_DETAILS:
        return <PanInputs
          values={values}
          inputType={inputType}
          onChange={(val,index)=>onChange(val,index)}
        />
      case GET_FSSAI_DETAILS:
          return <FssaiInputs
          reset={reset}
          values={values}
          onKeyDown={onkeydown}
          inputType={inputType}
          onChange={(val,index)=>onChange(val,index)}
          entityDetails={entityDetails}
        />
      case GET_GST_DETAILS:
        return <GstInputs
          values={values}
          onKeyDown={onkeydown}
          reset={reset}
          inputType={inputType}
          onChange={(val,index)=>onChange(val,index)}
          setGstValueAndSelectedParam={(index,text,latitude,longitude,actionType)=>setGstValueAndSelectedParam(index,text,latitude,longitude,actionType)}
          entityDetails={entityDetails}
        />
      case GET_UPLOAD_DETAILS:
        return <UploadInputs
        values={values}
        onkeydown={onkeydown}
        onChange={(val,index)=>onChange(val,index)}
        setUploadDetailsFields={(key,val,index,finalStateResponse,finalStatesArr,bottomMessage)=>setUploadDetailsFields(key,val,index,finalStateResponse,finalStatesArr,bottomMessage)}
        documentJobs={documentJobs}
        setDocumentJobs={(documentType,jobId)=>setDocumentJobs(documentType,jobId)}
        PollingDuration={PollingDuration}
        uploadCTAEnabled={uploadCTAEnabled}
        setUploadCTAEnabled={setUploadCTAEnabled}
        handleFileSelections={handleFileSelections}
        />
      default: 
      return <></>
    }
  }
  return (
    <>
    {getInputType()}
    </>
  ) 
}

export default CustomInputs;
