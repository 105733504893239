import _get from "lodash-es/get";

import getAxios from "../../utils/axios";
import APIendpoints from "../../utils/constants/APIendpoints";
import actionTypes from "../actionTypes";

function getNotificationsStatus() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.NOTIFICATIONS_GET_STATUS_BEGIN,
    });
    getAxios(true)
      .get(APIendpoints.getNotificationsStatus)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.NOTIFICATIONS_GET_STATUS_DONE,
          payload: {
            unseenCount: data.response.UnseenCount,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.NOTIFICATIONS_GET_STATUS_FAIL,
          error: _get(
            err,
            ["response", "data", "error", "message"],
            "Something went wrong!"
          ),
        });
      });
  };
}

function getNotifications(pageNumber, isFirstTime) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.NOTIFICATIONS_GET_BEGIN,
      payload: {
        isFirstTime: isFirstTime,
      },
    });
    getAxios(true)
      .get(APIendpoints.getNotifications(pageNumber))
      .then(({ data }) => {
        const notificationsData = data.response.UserNotifications;
        const dataToSend = {
          notifications: notificationsData
            .filter((notification) => {
              if (notification.Payload) {
                try {
                  JSON.parse(notification.Payload);
                  return true;
                } catch (error) {
                  console.warn(
                    "Could not parse notification payload of notification ",
                    notification.NotificationId
                  );
                  return false;
                }
              }
              return true;
            })
            .map((notification) => {
              return {
                ...notification,
                Payload: notification.Payload
                  ? JSON.parse(notification.Payload)
                  : [],
              };
            }),
          totalCount: data.response.TotalCount,
        };
        dispatch({
          type: actionTypes.NOTIFICATIONS_GET_DONE,
          payload: {
            notifications: dataToSend.notifications,
            isFirstTime: isFirstTime,
            totalCount: dataToSend.totalCount,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.NOTIFICATIONS_GET_FAIL,
          error: _get(
            err,
            ["response", "data", "error", "message"],
            "Something went wrong!"
          ),
        });
      });
  };
}

function markNotificationsRead(notificationIds, markAll) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.NOTIFICATIONS_MARK_READ_BEGIN,
    });
    getAxios(true)
      .put(
        APIendpoints.markNotificationsRead,
        markAll ? {
          MarkAllRead: true,
        } : {
          NotificationIds: notificationIds,
        }
      )
      .then(() => {
        dispatch({
          type: actionTypes.NOTIFICATIONS_MARK_READ_DONE,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.NOTIFICATIONS_MARK_READ_FAIL,
          error: _get(
            err,
            ["response", "data", "error", "message"],
            "Something went wrong!"
          ),
        });
      });
  };
}

function resetNotificationCount() {
  return dispatch => {
    dispatch({ type: actionTypes.RESET_NOTIFICATION_COUNT })
  }
}

export {
  getNotificationsStatus,
  resetNotificationCount,
  getNotifications,
  markNotificationsRead
};
