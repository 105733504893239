import React from 'react';
import ToastSushi from "sushiweb/Toast";
import styles from "./Toast.module.scss";

export const ToastHandler = (props) => {
    return (
        <ToastSushi
        {...props}
        className={styles.blackToast}
        type={"darkDefault"}
        align="bottom"
        text ={<div className="d-flex align-items-center">
        {props.Image ? <img src= {props.Image} className="mr-2"/> : <></>}
         {props.text}</div>}></ToastSushi>
    )
}