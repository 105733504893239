import actionTypes from "../actionTypes";

const initialState = {};

function configReducer(state = initialState, action) {
  const data = action.data;
  switch (action.type) {
    case actionTypes.GET_SETTINGS_CONFIG_DONE:
      return {
        ...state,
        ...data,
      };
    case actionTypes.UPDATE_TAX_TOGGLE_SELECTION_DONE:
      return{
        ...state,
        data,
      }
    default:
      return state;
  }
}
export default configReducer;
