import React, { useState, useRef, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styles from "./NpsRatingModal.module.scss";
import Cross from "sushiweb/Icons/all/Cross";
import Textarea from "sushiweb/InputTypes/Textarea";
import Button from "sushiweb/Button";
import { SectionLoader } from "../../../shared/loader2/Loader2";
import Lottie from "lottie-web";
import stateTypes from "../../../../utils/constants/stateTypes";
import { usePrevious } from "../../../../utils/helpers";
import { addNpsFeedback, trackPopUp } from "../../../../redux/dispatchers/userDispatcher";

function NpsRatingModal({
  closeModal,
  getConfigStatus,
  addNpsRatingStatus,
  toggleFeedbackSuccess,
  actions,
  popupData,
  rating: {
    NPSMeta: {
      RatingMeta = [],
      ReasonsMeta = []
    } = {}
  } = {}
  
}) {
  const [ratingData, setRatingData] = useState({
    rating: -1,
    reasonIds: new Set(),
    comment: "",
  });

  let selectedMeta = RatingMeta.find(
    (r) =>
      ratingData.rating === r.Rating
  );
  
  let selectedReasons = selectedMeta && ReasonsMeta.filter(r => {
    return selectedMeta.ReasonIds.includes(r.ReasonId)
  })

  // const ratingColorMap = RatingColors.length && RatingColors.reduce((acc, val) => {
  //   acc[val.Rating] = val.RatingColor
  //   return acc
  // }, {})

  const prevRatingState = usePrevious(addNpsRatingStatus.state);

  let animationRef = useRef();
  let animationContainerRef = useRef();

  function handleClose() {
    if(ratingData.rating === -1) {
      actions.trackPopUp(popupData.NPSPage1DismissTrackingParams)
    }
    else {
      actions.trackPopUp(popupData.NPSPage2DismissTrackingParams)
    }
    closeModal()
  }

  useEffect(() => {
    actions.trackPopUp(popupData.NPSPage1DisplayTrackingParams)
  }, [])

  useEffect(() => {
    if (
      prevRatingState === stateTypes.STATE_BEGIN &&
      addNpsRatingStatus.state === stateTypes.STATE_DONE
    ) {
      closeModal();
      actions.trackPopUp(popupData.NPSPage2SubmitTrackingParams)
      toggleFeedbackSuccess();
    }
  }, [addNpsRatingStatus.state]);

  function handleRatingChange(value) {
    let selectedMeta = RatingMeta && RatingMeta.find(
      (r) => value === r.Rating
    );
    if(ratingData.rating === -1) {
      actions.trackPopUp(popupData.NPSPage1RatingTrackingParams)
      actions.trackPopUp(popupData.NPSPage2DisplayTrackingParams)
    }
    else {
      actions.trackPopUp(popupData.NPSPage2RatingTrackingParams)
    }
    setRatingData({
      ...ratingData,
      rating: value,
      reasonIds: new Set(),
      comment: "",
    });
    if (animationRef.current) {
      animationRef.current.destroy();
    }
    animationRef.current = Lottie.loadAnimation({
      container: animationContainerRef.current,
      path: selectedMeta.MediaUrl,
      renderer: "canvas",
      loop: false,
      autoplay: true,
    });
  }

  function reasonClickHandler(id) {
    const { reasonIds } = ratingData;
    if (reasonIds.has(id)) {
      setRatingData({
        ...ratingData,
        reasonIds: new Set([
          ...Array.from(reasonIds).filter((rid) => id !== rid),
        ]),
      });
    } else {
      setRatingData({
        ...ratingData,
        reasonIds: new Set(reasonIds).add(id),
      });
    }
  }

  function commentChangeHandler(value) {
    setRatingData({
      ...ratingData,
      comment: value,
    });
  }

  function renderColoredRatings() {
    return [...Array(11)].map((_, idx) => {
      return (
        <div
          key={idx}
          onClick={() => handleRatingChange(idx)}
          className={styles.ratingFixed}
          style={
            idx <= ratingData.rating
              ? {
                  backgroundColor: RatingMeta[idx].RatingColor,
                  color: "#ffffff",
                }
              : {}
          }
        >
          {idx}
        </div>
      );
    });
  }

  function submitHandler() {
    actions.addNpsFeedback({
      Rating: ratingData.rating,
      ReasonIds: [...ratingData.reasonIds],
      Comment: ratingData.comment,
    });
  }

  return (
    <div>
      <div className={styles.rateContainer}>
        <div className={styles.rateContainerBg} onClick={handleClose}></div>
        <SectionLoader statuses={[getConfigStatus]}>
          <div className={styles.rateContainerInner}>
            <div className={`${styles.header} fs-18 w-600 text-gray-900`}>
              {popupData.Heading}
              <Cross
                className={styles.cross}
                size={20}
                onClick={handleClose}
              />
            </div>
            <div className={styles.rateContainerCenter}>
              <div className="d-flex align-items-center flex-column">
                <img src={popupData.ImageUrl} className={styles.image} alt="" />
                <div className="fs-20 w-600 text-align-center">
                  How likely are you to recommend us?
                </div>
                <div className={styles.ratingCont}>
                  <div
                    ref={animationContainerRef}
                    style={{
                      marginLeft: `${ratingData.rating * 42 + 5}px`,
                    }}
                    className={styles.animation}
                  ></div>
                  <div className="d-flex justify-content-center">
                    {renderColoredRatings()}
                  </div>
                  <div className="d-flex justify-content-between text-gray-700 fs-12 mt-2">
                    <span>Not likely</span>
                    <span className={styles.marginFix}>Very likely</span>
                  </div>
                </div>
              </div>
              {ratingData.rating >= 0 ? (
                <div>
                  <div className="fs-16 mt-5 mb-3 w-600 text-gray-900">
                    {selectedMeta.RatingReasonHeading}
                  </div>
                  <div>
                    {selectedReasons.map((reason) => (
                      <span
                        className={`${styles.reason} ${
                          ratingData.reasonIds.has(reason.ReasonId)
                            ? styles.reasonActive
                            : ""
                        }`}
                        onClick={() => reasonClickHandler(reason.ReasonId)}
                        key={reason.ReasonId}
                      >
                        {reason.Reason}
                        {ratingData.reasonIds.has(reason.ReasonId) ? (
                          <Cross
                            size={14}
                            color="#EF4F5F"
                            className={`${styles.icon} ml-1`}
                          />
                        ) : null}
                      </span>
                    ))}
                  </div>
                  <div className={styles.textAreaTitle}>
                    <div className="mb-3 w-600 fs-16 text-gray-900">
                      Anything else you would like to tell us?
                    </div>
                    <Textarea
                      label="Your Feedback"
                      value={ratingData.comment}
                      onChange={commentChangeHandler}
                    />
                  </div>
                </div>
              ) : null}
              <div className={styles.submitCTA}>
                <Button
                  btnColor="red"
                  className={`${styles.button} ${
                    ratingData.rating < 0 ? "" : "solid"
                  }`}
                  loading={addNpsRatingStatus.state === stateTypes.STATE_BEGIN}
                  onClick={submitHandler}
                  disabled={ratingData.rating < 0}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </SectionLoader>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    // data
    rating: state.user.config?.Rating || {},
    // status
    getConfigStatus: state.status.config_get,
    addNpsRatingStatus: state.status.addNpsFeedback,
  }),
  (dispatch) => ({
    actions: bindActionCreators(
      {
        addNpsFeedback,
        trackPopUp
      },
      dispatch
    ),
  })
)(NpsRatingModal);
