import React from "react";
import Input from "sushiweb/InputTypes/Input"
import styles from "./CustomInputs.module.scss";

function MobileNumberInput ({value , onChange,error,onKeyDown }) {
  return (
    <div className="relative">
    <div className={`d-flex ${styles.flagPosition} align-items-center`}>
    <img src={process.env.PUBLIC_URL + "/assets/images/indianFlag.png"} className={`${styles.mobileNumberFlag} mr-3`}/>
    <div className="fs-16">+91</div>
    </div>
    <Input
      value={value}
      onChange={onChange}
      className={`${styles.genericInput} ${styles.mobileNumberInput}`}
      errorMsg = {error}
      onKeyDown={onKeyDown}
    />
    </div>
  ) 
}

export default MobileNumberInput;
