import  { useEffect } from "react";
import { HP_WEB_SERVICE_PORT } from "../../../utils/constants/others";

const ProfileSettingsRedirection = () => {
    useEffect(() => {
        if (process.env.NODE_ENV === "development") {
            window.location.href = `http://localhost:${HP_WEB_SERVICE_PORT}/in/profile`;
        } else {
            window.location.href = "/in/profile";
        }
    }, []);

    return null;
};

export default ProfileSettingsRedirection;
