import Lottie from 'lottie-web'
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'

function AnimationRenderer({
  animationClass,
  animationUrl,
  loop,
  destroyOnLoopComplete,
}) {

  const animationContRef = useRef(null)
  const animationRef = useRef(null)
  useEffect(() => {
    if (animationUrl) {
      animationRef.current = Lottie.loadAnimation({
        renderer: "canvas",
        path: animationUrl,
        container: animationContRef.current,
        autoPlay: true,
        loop: true ,
      })
      if (destroyOnLoopComplete){
        animationRef.current.addEventListener('loopComplete', function() {
            animationRef.current.destroy()
        })
      }
    }
    return () => {
      if(animationRef.current) {
        animationRef.current.destroy()
      }

    }
  }, [animationUrl, animationContRef])

  if(!animationUrl){
    return null
  }

  return (
    <div className={`${animationClass}` } ref={animationContRef}>
    </div>
  )
}

export default AnimationRenderer
