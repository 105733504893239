import _get from "lodash-es/get";

import getAxios from "../../utils/axios";
import { DYNAMIC_ENDPOINT } from "../../utils/constants/APIendpoints";
import APIendpoints from "../../utils/constants/APIendpoints";
import actionTypes from "../actionTypes";
import { handleCallBack } from "../../utils/helpers";

function getOrders(requestData, ordersPerPage, isFirstTime) {
    return (dispatch) => {
        dispatch({
            type: actionTypes.ORDER_GET_ALL_BEGIN,
            isFirstTime: isFirstTime,
        });
        getAxios(true)
            .get(
                DYNAMIC_ENDPOINT.getOrders(
                    requestData.PageNo,
                    ordersPerPage,
                    requestData.OrdersType
                )
            )
            .then(({ data }) => {
                dispatch({
                    type: actionTypes.ORDER_GET_ALL_DONE,
                    orders: data.response.ListOfOrderDetail || [],
                    orderTypes: data.response.ListOfOrderType,
                    isFirstTime: isFirstTime,
                    ordersPerPage: ordersPerPage,
                });
            })
            .catch((err) =>
                dispatch({
                    type: actionTypes.ORDER_GET_ALL_FAIL,
                    error: _get(err, ["response", "data", "error"], {}),
                })
            );
    };
}

function getOrder(orderID, type, callBack) {
    return (dispatch) => {
        dispatch({ type: actionTypes.ORDER_GET_ONE_BEGIN });
        let endPoint = DYNAMIC_ENDPOINT.getOrder(orderID, type);
        getAxios(true)
            .get(endPoint)
            .then(({ data, headers }) => {
                dispatch({
                    type: actionTypes.ORDER_GET_ONE_DONE,
                    order: data.response,
                });
                if (typeof callBack === "function") {
                    callBack(data);
                }
            })
            .catch((err) =>
                dispatch({
                    type: actionTypes.ORDER_GET_ONE_FAIL,
                    error: _get(err, ["response", "data", "error"], {}),
                })
            );
    };
}

function getRatingOrder(orderID, type) {
    return (dispatch) => {
        dispatch({ type: actionTypes.RATING_ORDER_GET_BEGIN });
        let endPoint = DYNAMIC_ENDPOINT.getOrder(orderID, type);
        getAxios(true)
            .get(endPoint)
            .then(({ data, headers }) => {
                dispatch({
                    type: actionTypes.RATING_ORDER_GET_DONE,
                    orderRating: data.response,
                });
            })
            .catch((err) =>
                dispatch({
                    type: actionTypes.RATING_ORDER_GET_FAIL,
                    error: _get(err, ["response", "data", "error"], {}),
                })
            );
    };
}

function refreshReorder() {
    return (dispatch) => {
        dispatch({ type: actionTypes.ORDER_REORDER_INIT });
    };
}

function reOrder(orderId, isMulti = false, source = "") {
    return (dispatch) => {
        dispatch({
            type: actionTypes.ORDER_REORDER_BEGIN,
            payload: {
                orderId: orderId,
            },
        });

        let orderToSend = {};

        if (isMulti) {
            orderToSend.OrderIds = orderId;
        } else {
            orderToSend.OrderId = orderId;
        }

        orderToSend.Source = source;

        getAxios(true)
            .post(APIendpoints.reOrder, orderToSend)
            .then(({ data }) => {
                dispatch({
                    type: actionTypes.ORDER_REORDER_DONE,
                    payload: {
                        orderId: orderId,
                        message: (data.response || {}).message,
                    },
                });
            })
            .catch((err) => {
                dispatch({
                    type: actionTypes.ORDER_REORDER_FAIL,
                    payload: {
                        orderId: orderId,
                    },
                    error: _get(err, ["response", "data", "error"], {}),
                });
            });
    };
}

function getCreditNote(creditNoteNumber) {
    return (dispatch) => {
        dispatch({
            type: actionTypes.CREDIT_NOTE_GET_BEGIN,
        });
        getAxios(true)
            .get(APIendpoints.getCreditNote(creditNoteNumber))
            .then(({ data }) => {
                const response = data.response;

                dispatch({
                    type: actionTypes.CREDIT_NOTE_GET_DONE,
                    payload: {
                        number: response.CreditNoteNumber,
                        creationDateTime: response.CreationDateTime,
                        amount: response.CreditAmount,
                        products: response.Products,
                        deliveryChargeRefundString:
                            response.DeliveryChargeRefundString,
                    },
                });
            })
            .catch((err) => {
                dispatch({
                    type: actionTypes.CREDIT_NOTE_GET_FAIL,
                    error: _get(err, ["response", "data", "error"], {}),
                });
            });
    };
}

function addOrderRating(requestData, orderId, orderRating) {
    return (dispatch) => {
        dispatch({ type: actionTypes.ORDER_ADD_RATING_BEGIN });
        getAxios(true)
            .post(APIendpoints.addOrderRating, requestData)
            .then(() => {
                dispatch({
                    type: actionTypes.ORDER_ADD_RATING_DONE,
                    payload: {
                        orderId,
                        orderRating,
                    },
                });
            })
            .catch((err) => {
                dispatch({
                    type: actionTypes.ORDER_ADD_RATING_FAIL,
                    error: _get(err, ["response", "data", "error"], {}),
                });
            });
    };
}

function cancelOrder(requestData, successCallback, errorCallBack) {
    return (dispatch) => {
        dispatch({ type: actionTypes.CANCEL_ORDER_BEGIN });
        getAxios(true)
            .put(APIendpoints.cancelOrder, requestData)
            .then(({ data }) => {
                const response = data.response;

                dispatch({
                    type: actionTypes.CANCEL_ORDER_DONE,
                    payload: response,
                });
                if (typeof successCallback === "function") {
                    successCallback(response);
                }
            })
            .catch((err) => {
                dispatch({
                    type: actionTypes.CANCEL_ORDER_FAIL,
                    error: _get(
                        err,
                        ["response", "data", "error", "message"],
                        "Something went wrong!"
                    ),
                });
                if (typeof errorCallBack === "function") {
                    errorCallBack(err);
                }
            });
    };
}

function getReasons(apiEndpoint, successCallback, errorCallBack) {
    return (dispatch) => {
        dispatch({ type: actionTypes.GET_REASONS_BEGIN });
        getAxios(true)
            .get(apiEndpoint)
            .then(({ data }) => {
                const response = data.response;
                dispatch({
                    type: actionTypes.GET_REASONS_DONE,
                    payload: response.Reasons,
                });
                if (typeof successCallback === "function") {
                    successCallback(data.response.Reasons);
                }
            })
            .catch((err) => {
                dispatch({
                    type: actionTypes.GET_REASONS_FAIL,
                    error: _get(err, ["response", "data", "error"], {}),
                    code: _get(
                        err,
                        ["response", "data", "error", "code"],
                        null
                    ),
                });
                if (typeof errorCallBack === "function") {
                    errorCallBack(err);
                }
            });
    };
}

function getOrderPaymentInfo(requestData, successCallback, errorCallBack) {
    return (dispatch) => {
        dispatch({ type: actionTypes.GET_ORDER_PAYMENT_INFO_BEGIN });
        getAxios(true)
            .post(APIendpoints.orderPaymentInfo, requestData)
            .then(({ data }) => {
                dispatch({
                    type: actionTypes.GET_ORDER_PAYMENT_INFO_DONE,
                    payload: data.response,
                });
                if (typeof successCallback === "function") {
                    successCallback(data.response);
                }
            })
            .catch((err) => {
                dispatch({
                    type: actionTypes.GET_ORDER_PAYMENT_INFO_FAIL,
                    error: _get(err, ["response", "data", "error"], {}),
                    code: _get(
                        err,
                        ["response", "data", "error", "code"],
                        null
                    ),
                });
                if (typeof errorCallBack === "function") {
                    errorCallBack(err);
                }
            });
    };
}

function updateOrderPaymentInfo(requestData, successCallback, errorCallBack) {
    return (dispatch) => {
        dispatch({ type: actionTypes.UPDATE_ORDER_PAYMENT_INFO_BEGIN });
        getAxios(true)
            .put(APIendpoints.orderPaymentInfo, requestData)
            .then(({ data }) => {
                dispatch({
                    type: actionTypes.UPDATE_ORDER_PAYMENT_INFO_DONE,
                    payload: data.response,
                });
                if (typeof successCallback === "function") {
                    successCallback(data.response);
                }
            })
            .catch((err) => {
                dispatch({
                    type: actionTypes.UPDATE_ORDER_PAYMENT_INFO_FAIL,
                    error: _get(err, ["response", "data", "error"], {}),
                    code: _get(
                        err,
                        ["response", "data", "error", "code"],
                        null
                    ),
                });
                if (typeof errorCallBack === "function") {
                    errorCallBack(err);
                }
            });
    };
}

const adjustPgCharge = (reqData, successCallback) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.ADJUST_PG_CHARGE_BEGIN });
        getAxios(true)
            .post(APIendpoints.adjustPgCharge, reqData)
            .then(({ data, headers }) => {
                dispatch({
                    type: actionTypes.ADJUST_PG_CHARGE_DONE,
                    payload: data.response,
                });
                if (typeof successCallback === "function") {
                    successCallback(data.response);
                }
            })
            .catch((err) => {
                dispatch({
                    type: actionTypes.ADJUST_PG_CHARGE_FAIL,
                    error: _get(
                        err,
                        ["response", "data", "error", "message"],
                        "Something went wrong!"
                    ),
                });
            });
    };
};

const getAndUpdateOrderInBackground = (orderID, type, callBack, loadingSections) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_ORDER_DETAILS_BEGIN,
      loadingSections: loadingSections,
    });
    getAxios(true)
      .get(DYNAMIC_ENDPOINT.getOrder(orderID, type))
      .then(({ data }) => {
        dispatch({
          type: actionTypes.GET_ORDER_DETAILS_DONE,
          order: data.response,
        });
        handleCallBack(callBack, data);
      })
      .catch((err) =>
        dispatch({
          type: actionTypes.GET_ORDER_DETAILS_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
          errorData: _get(err, ["response", "data", "error", "message"], {}),
        })
      );
  };
};

export {
    getOrders,
    getOrder,
    reOrder,
    getCreditNote,
    addOrderRating,
    refreshReorder,
    getRatingOrder,
    cancelOrder,
    getReasons,
    getOrderPaymentInfo,
    updateOrderPaymentInfo,
    adjustPgCharge,
    getAndUpdateOrderInBackground
};
