import { useEffect } from "react";
import { HP_WEB_SERVICE_PORT } from "../../../utils/constants/others";

const OrderNumberRedirection = ({ orderNumber, type }) => {
  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      window.location.href = `http://localhost:${HP_WEB_SERVICE_PORT}/z/buyer/order/${orderNumber}?type=${type}`;
    } else {
      window.location.href = `/z/buyer/order/${orderNumber}?type=${type}`;
    }
  }, []);

  return null;
};

export default OrderNumberRedirection;
