import React, { useRef } from "react";
import { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ButtonGroup } from "../CarouselArrow/CarouselArrow";
import styles from "./Carousel.module.scss";
import CarouselWrapper from "./CarouselWrapper";


function MultiCarousel({
    styleClasses,
    row1,
    row2,
    config,
    type
}) {
    const rowLeftRef = useRef();
    const rowRightRef = useRef();
    function handleClick (direction){
        if (direction === "right" && rowRightRef?.current) {
            rowRightRef.current.click()
        }else if (direction === "left" && rowLeftRef?.current){
            rowLeftRef.current.click()
        }
    }
return (
    <div className={styleClasses}>
    <CarouselWrapper
            containerClass={styles.cont}
            deviceType="desktop"
            arrows={false}
            className="mb24"
            customButtonGroup={
              <ButtonGroup
              handleClick = {handleClick}
                dimension={40}
                type={type !== "alternateProducts" ? "productRail" : ""}
              />
            }
            swipeable={false}
    draggable={false}
            responsive={config}
            renderButtonGroupOutside
            ssr
            shouldResetAutoplay={false}
          >
            {row1}
          </CarouselWrapper>
          <CarouselWrapper
            containerClass={styles.cont}
            deviceType="desktop"
            arrows={false}
            swipeable={false}
  draggable={false}
            customButtonGroup={
                
              <ButtonGroup
              rowLeftRef = {rowLeftRef}
              rowRightRef ={rowRightRef}
                notVisible = {true}
                dimension={40}
                type={type !== "alternateProducts" ? "productRail" : ""}
              />
            }
            responsive={config}
            renderButtonGroupOutside
            ssr
            shouldResetAutoplay={false}
          >
            {row2}
          </CarouselWrapper>
    </div>
)
}
export default MultiCarousel;