import  { useEffect } from "react";
import { HP_WEB_SERVICE_PORT } from "../../../utils/constants/others";

const OrderDetailRedirection = ({ orderId }) => {
  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      window.location.href = `http://localhost:${HP_WEB_SERVICE_PORT}/z/buyer/order/${orderId}`;
    } else {
      window.location.href = `/z/buyer/order/${orderId}`;
    }
  }, []);

  return null;
};

export default OrderDetailRedirection;
