import React from "react";
import StarFill from "sushiweb/Icons/all/StarFill";
import Cross from "sushiweb/Icons/all/Cross";
import Textarea from "sushiweb/InputTypes/Textarea";
import Button from "sushiweb/Button";
import Toast from "sushiweb/Toast";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { RATING_MAP } from "../../../../utils/constants/others";
import {
  addOrderRating,
  getRatingOrder,
} from "../../../../redux/dispatchers/orderDispatcher";
import {
  setErrorToast
} from "../../../../redux/dispatchers/userDispatcher";
import styles from "./RatingModal.module.scss";
import ratingStyles from "../Rating.module.scss";
import stateTypes from "../../../../utils/constants/stateTypes";
import { SectionLoader } from "../../../shared/loader2/Loader2";
import lottie from "lottie-web";
import { ToastHandler } from "../../../shared/Toast/ToastHandler";
import { isMobile } from "../../../../utils/helpers";
import StyledDiv from "../../../shared/StyledDiv";

const isMWeb = isMobile(991)
class RatingModal extends React.Component {
  state = {
    rating: null,
    orderReasons: new Set(),
    ratingType: "order",
    comment: "",
    toast: {
      show: false,
      text: null,
      type: "default",
    },
    showLottie: false,
    activeAnim: null,
  };

  prevRef = React.createRef()
  animationContainerRef = React.createRef()
  

  /**
   * Lifecycle methods
   */
  componentDidUpdate(prevProps, prevState) {
    const { orderRating, addRatingStatus, closeModal, visible } = this.props;
    if (prevState.toast !== this.state.toast && this.state.toast.show && this.state.toast.text) {
      this.props.actions.setErrorToast(this.state.toast.text)
    }
    
    if (prevProps.orderRating !== orderRating) {
  
      this.setState((prevState) => ({
        ...prevState,
        rating: orderRating,
      }));
    }

    if (!prevProps.visible && this.props.visible) {
      this.props.actions.getRatingOrder(this.props.order.OrderNo, "number");
    }

    if (visible) {
      if (prevProps.addRatingStatus.state !== addRatingStatus.state) {
        if (addRatingStatus.state === stateTypes.STATE_DONE) {
          this.props.toggleSuccessModal()
          closeModal();
        } else if (addRatingStatus.state === stateTypes.STATE_FAIL) {
          this.setState((prevState) => ({
            ...prevState,
            toast: {
              show: true,
              text: addRatingStatus.error,
              type: "errorSolid",
            },
          }));
        }
      }
    }
    if (prevState.rating !== this.state.rating || prevProps.orderRating !== orderRating || this.prevRef !== this.animationContainerRef.current) {
      if (this.state.activeAnim) {
        lottie.destroy(this.state.activeAnim.name);
      }
      const anim = lottie.loadAnimation({
        container: this.animationContainerRef.current,
        path: `/assets/animations/rate${this.state.rating}.json`,
        renderer: "canvas",
        loop: true,
        autoplay: true,
      });
      if (this.prevRef !== this.animationContainerRef.current) {
        this.prevRef = this.animationContainerRef.current
      }
      this.setState({ activeAnim: anim });
    }
  }

  componentDidMount() {

    this.prevRef = this.animationContainerRef.current

    if (this.props.visible) {
      this.props.actions.getRatingOrder(this.props.order.OrderNo, "number");
    }
  }

  /**
   * Utility methods
   */
  getRatingClass(rating) {
    const { rating: stateRating } = this.state;
    if (!stateRating) {
      return ratingStyles.ratingUnrated;
    } else {
      if (stateRating < rating) {
        return ratingStyles.ratingUnrated;
      } else {
        if (stateRating < 3) {
          return ratingStyles.ratingRed;
        } else if (stateRating === 3) {
          return ratingStyles.ratingYellow;
        } else {
          return ratingStyles.ratingGreen;
        }
      }
    }
  }

  /**
   * Handler methods
   */
  ratingClickHandler = (rating) => {
    const { rating: stateRating } = this.state;
    if (stateRating !== rating) {
      this.setState((prevState) => ({
        ...prevState,
        rating,
        orderReasons: new Set(),
        comment: "",
      }));
    }
  };

  reasonClickHandler = (reasonId) => {
    const { orderReasons } = this.state;
    if (orderReasons.has(reasonId)) {
      this.setState((prevState) => ({
        ...prevState,
        orderReasons: new Set([
          ...Array.from(orderReasons).filter((id) => id !== reasonId),
        ]),
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        orderReasons: new Set(orderReasons).add(reasonId),
      }));
    }
  };

  commentChangeHandler = (val) => {
    this.setState((prevState) => ({
      ...prevState,
      comment: val,
    }));
  };

  toastCloseHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      toast: {
        ...prevState.toast,
        show: false,
        text: null,
        type: "default",
      },
    }));
  };

  submitHandler = () => {
    const { actions, orderDetail } = this.props;
    const { orderReasons: stateOrderReasons, rating: stateRating } = this.state;
    const orderReasons = Array.from(stateOrderReasons).map((reasonId) => ({
      ReasonId: reasonId,
      RatingType: this.state.ratingType,
    }));

    actions.addOrderRating(
      {
        OrderId: orderDetail.OrderId,
        OrderRating: stateRating,
        Comment: this.state.comment,
        Reasons: [...orderReasons],
      },
      orderDetail.OrderId,
      stateRating
    );
  };

  checkForSubmitDisable = () => {
    const { rating = {} } = this.props;
    const { rating: ratingState, orderReasons, comment } = this.state;
    if (!ratingState) {
      return true;
    } else {
      if (
        rating.CompulsoryReasonThreshold && 
        ratingState <= rating.CompulsoryReasonThreshold &&
        !comment &&
        !orderReasons.size
      ) {
        return true;
      }
    }
    return false;
  };

  /**
   * Render methods
   */
  render() {
    const {
      visible,
      order,
      rating,
      rating: {
        OrderRatingMeta = [],
        CompulsoryReasonThreshold = 0
      } = {},
      addRatingStatus,
      getConfigStatus,
      orderDetail,
      orderRateStatus,
    } = this.props;
    const { rating: stateRating, orderReasons } = this.state;

    if (!order) {
      return null;
    }

    const orderRatingMeta = (OrderRatingMeta || []).find((el) => el.Rating === stateRating);

    if (!visible) {
      return null;
    }

    return (
      <div className={styles.rateContainer}>
        <div
          className={styles.rateContainerBg}
          onClick={() => this.props.closeModal()}
        ></div>
        <SectionLoader statuses={[getConfigStatus, orderRateStatus]}>
          <div className={styles.rateContainerInner}>
            <div className={`${styles.header} fs-18 w-600 text-gray-900`}>
              How was your order experience?
              <Cross
                className={styles.cross}
                size={20}
                onClick={() => this.props.closeModal()}
              />
            </div>
            <div className={styles.rateContainerCenter}>
              <div>
                {orderDetail && (
                  <div className={styles.orderDetailCard}>
                    <div className="d-flex justify-content-between">
                      <div className="mr-2">
                        <div className="fs-16 text-gray-900 mb-2 w-500">
                          Order ID:&nbsp;
                          <span className="w-600">{order.OrderId}</span>
                        </div>
                        <div className="fs-14 text-gray-700 mb-2 w-500">
                          Ordered&nbsp;
                          <span>
                            {moment(orderDetail.CreationDatetime).format(
                              "Do MMM ‘YY, h:m a"
                            )}
                          </span>
                        </div>
                      </div>
                      <div className={styles.productCardWrapper}>
                        {orderDetail &&
                          orderDetail.RatingModalImages &&
                          orderDetail.RatingModalImages.map((img, index) => {
                            return (
                              <div
                                key={index}
                                className={styles.productCard}
                                style={{ backgroundImage: `url(${img})` }}
                              >
                                <div className={styles.productCardBg}></div>
                              </div>
                            );
                          })}
                        <span className="ml-3 text-gray-900 fs-16 w-600">
                          {orderDetail.RatingModalItemString}
                        </span>
                      </div>
                    </div>
                    <div
                      className={`${styles.deliveryDate} w-600 fs-16 d-flex align-items-center`}
                    >
                      <StyledDiv bgImage={"/assets/images/carbon_delivery.svg"} className={styles.deliveryIcon} />
                      Delivered on&nbsp;
                      <span className={`${isMWeb ? "fs-16" : "fs-18"} w-800`}>
                        {moment(orderDetail.TargetDeliveryDate).format(
                          "Do MMM, ddd"
                        )}
                      </span>
                    </div>
                  </div>
                )}
                <div className="mt-5 fs-16 w-600 text-gray-900 text-align-center">
                  Please give us your rating
                </div>
                <div className="mt-4 text-align-center">
                  {[1, 2, 3, 4, 5].map((rating) => (
                    <button
                      className={`fw-600 ${
                        ratingStyles.rating
                      } ratingHover${rating} ${this.getRatingClass(rating)}`}
                      onClick={() => this.ratingClickHandler(rating)}
                      key={rating}
                    >
                      <span
                        className={
                          !stateRating || rating >= stateRating
                            ? ""
                            : ratingStyles.invisible
                        }
                      >
                        {rating}&nbsp;
                        <StarFill
                          color={stateRating === rating ? "white" : "#b8b8b8"}
                          size={12}
                        />
                      </span>
                    </button>
                  ))}
                </div>
                {stateRating && (
                  <div
                    className={`${styles.animWrapper} d-flex  justify-content-center align-items-center`}
                  >
                    <div
                      ref={this.animationContainerRef}
                      className={styles.ratingAnimation}
                    />
                    <div
                      className={`ml-2 fs-18 w-600 ${RATING_MAP[stateRating].style}`}
                    >
                      {RATING_MAP[stateRating].text}
                    </div>
                  </div>
                )}
              </div>
              {stateRating && <hr className={styles.seprator} />}
              {stateRating ? (
                <div
                  className={`${styles.expandAnim} ${
                    stateRating <= CompulsoryReasonThreshold
                      ? styles.enableShaking
                      : ""
                  }`}
                >
                  <div className="fs-16 mt-4 mb-4 w-600 text-gray-900">
                    {orderRatingMeta && orderRatingMeta.RatingReasonHeading}
                  </div>
                  <div className="mb-4">
                    <div className="mb-3">
                      {orderRatingMeta &&
                        orderRatingMeta.RatingReasonSubHeading}
                    </div>
                    <div>
                      {(rating && rating.OrderRatingReasonsMeta)
                        .filter((reason) => reason.Rating === stateRating)
                        .map((reason) => (
                          <span
                            className={`${styles.reason} ${
                              orderReasons.has(reason.ReasonId)
                                ? styles.reasonActive
                                : ""
                            }`}
                            onClick={() =>
                              this.reasonClickHandler(reason.ReasonId)
                            }
                            key={reason.ReasonId}
                          >
                            {reason.Reason}
                            {orderReasons.has(reason.ReasonId) ? (
                              <Cross
                                size={14}
                                color="#EF4F5F"
                                className={`${styles.icon} ml-1`}
                              />
                            ) : null}
                          </span>
                        ))}
                    </div>
                  </div>
                  <div className={styles.textAreaTitle}>
                    <div className="mb-3 w-600 fs-16 text-gray-900">
                      Anything else you would like to tell us?
                    </div>
                    <Textarea
                      label="Your Feedback"
                      value={this.state.comment}
                      onChange={this.commentChangeHandler}
                    />
                  </div>
                </div>
              ) : null}
              <div className={styles.submitCTA}>
                <Button
                  btnColor="red"
                  className={`${styles.button} ${
                    this.checkForSubmitDisable() ? "" : "solid"
                  }`}
                  loading={addRatingStatus.state === stateTypes.STATE_BEGIN}
                  onClick={this.submitHandler}
                  disabled={this.checkForSubmitDisable()}
                >
                  Submit
                </Button>
              </div>

            </div>
          </div>
        </SectionLoader>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    // data
    rating: state.user.config?.Rating,
    // status
    addRatingStatus: state.status.order_add_rating,
    getConfigStatus: state.status.config_get,
    orderRateStatus: state.status.order_rateDetail,
    orderDetail: state.order.orderRating,
  }),
  (dispatch) => ({
    actions: bindActionCreators({ addOrderRating, getRatingOrder ,setErrorToast}, dispatch),
  })
)(RatingModal);
