import React, { useRef, useEffect, useState } from 'react';
import lottie from 'lottie-web';
import Button from "sushiweb/Button";

import styles from './BasePopUp.module.scss';
import { isLottie } from "./../../../../utils/constants/utils";
import { HTMLDiv } from '../../HTMLDiv';

export default function BasePopup({ popUp, onClose, isMobileScreenSize, trackPopUp }) {
  const animationContainer = useRef();
  const coinAnimationContainer = useRef();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [showLottie, setShowLottie] = useState(true);

  function submitHandler(e) {
    e.preventDefault();
    trackPopUp()
  }

  useEffect(() => {
    if (popUp.PopupData.Animation) {
      const { current } = animationContainer;
      lottie.loadAnimation({
        container: current,
        path: popUp.PopupData.Animation,
        renderer: 'canvas',
        loop: false,
        autoplay: true,
      });

      setTimeout(() => {
        setShowLottie(false);
      }, 5000);
    }

    if (popUp.PopupData.Header.IconURL) {
      const isLottieBool = isLottie(popUp.PopupData.Header.IconURL)
      if (isLottieBool) {
        const { current } = coinAnimationContainer;
        lottie.loadAnimation({
          container: current,
          path: popUp.PopupData.Header.IconURL,
          renderer: 'canvas',
          loop: true,
          autoplay: true,
        })
      }
    }
  }, [popUp]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      const ismobile = window.outerWidth < 992;
      if (ismobile !== isMobile) setIsMobile(ismobile);
    }, false);
  }, [isMobile])

  return (
    <div className="relative">
      {showLottie && <div className={styles.animationContainer} ref={animationContainer} id="animationZPL"></div>}
    
    <div
      className={styles.modal}
      style={(popUp.PopupData.Background.URL || popUp.PopupData.Background.color) && {
        backgroundImage: `url(${popUp.PopupData.Background.URL ? popUp.PopupData.Background.URL : ''})`,
        color: `${popUp.PopupData.Background.color ? popUp.PopupData.Background.color : ''}`
      }}
    >
      <div
        className={styles.close}
        onClick={(e) => onClose(e)}
      >
        <img src={popUp.PopupData.DismissIcon} color="#B8B8B8" alt="dismiss" />
      </div>
      <div className={`d-flex ${styles.header} align-items-center justify-content-center`}>
        {popUp.PopupData.Header && popUp.PopupData.Header.IconURL &&
          <div className='wd-50'>
            {isLottie(popUp.PopupData.Header.IconURL) ?
              <div ref={coinAnimationContainer} className={styles.coinAnimation}></div>
              : <img src={popUp.PopupData.Header.IconURL} alt="Zomato logo" />}
          </div>

        }
        <div className={`d-flex flex-column text-align-left ${styles.headerTexts} wd-50`}>
          {popUp.PopupData.Header && popUp.PopupData.Header.Text1 &&
            <HTMLDiv
              html={popUp.PopupData.Header.Text1}
              className={`${isMobile ? 'fs-30' : 'fs-44'}`}
            />
          }
          {popUp.PopupData.Header && popUp.PopupData.Header.Text2 &&
           <HTMLDiv
           html={popUp.PopupData.Header.Text2}
           className={`${isMobile ? 'fs-18' : 'fs-24'}`}
         />
          }
        </div>
      </div>
      {popUp.PopupData.Body && popUp.PopupData.Body.Title &&
         <HTMLDiv
         html={popUp.PopupData.Body.Title}
         className={`${isMobile ? 'fs-24' : 'fs-36'} text-align-center pb-4`}
       />}
      {popUp.PopupData.Body && popUp.PopupData.Body.Text &&
        <HTMLDiv
        html={ popUp.PopupData.Body.Text}
        className={`${isMobile ? 'fs-15' : 'fs-24'} text-align-center`}
      />}
      {popUp.PopupData.CTA && popUp.PopupData.CTA.Text &&
        <div className={styles.learnMoreContainer}><div className={styles.learnMore} >
          <Button
          appearance="outline"
          onClick={submitHandler}
          btnColor={'red'}>
          <span className="w-400">{popUp.PopupData.CTA.Text}</span>
        </Button></div></div>
      }
    </div>
    </div>
  )
}
