import { useEffect } from "react";
import { HP_WEB_SERVICE_PORT } from "../../utils/constants/others";

const ContactRedirection = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      window.location.href = `http://localhost:${HP_WEB_SERVICE_PORT}/in/contact`;
    } else {
      window.location.href = "/in/contact";
    }
  }, []);

  return null;
};

export default ContactRedirection;
