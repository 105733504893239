import React from 'react'
import styles from "./RequestBanner.module.scss"
import Button from "sushiweb/Button"
import StyledDiv from '../../../shared/StyledDiv'

function RequestBanner({
  title,
  source,
  searchText,
  classname
}) {

  function handleRedirect() {
    window.location.href = `/in/request-product?source=${source}&searchText=${searchText || ""}`
  }

  const sourceBasedClass = source === "search" ? {
    cont: styles.bannerContSm,
    img: styles.imageSm,
    content: styles.content,
    btn: styles.submitBtnSm,
    title: "fs-16",
    subtitle: "mt-2 fs-14",
    btnType: "small"
  } : {
    cont: styles.bannerCont,
    img: styles.image,
    content: styles.content,
    btn: styles.submitBtn,
    title: "fs-20",
    subtitle: "mt-2 fs-16",
    btnType: "small"
  }

  return (
    <div className={`${sourceBasedClass.cont} ${classname}`}>
      <StyledDiv bgImage={"/assets/images/product_request_banner.png"} className={sourceBasedClass.img}/>
      <div className={sourceBasedClass.content}>
        <div>
        <div className={`w-800 text-gray-900 ${sourceBasedClass.title}`}>
          {title}
        </div>
        <div className={`w-400 text-gray-900 mb-3 ${sourceBasedClass.subtitle}`}>
          Tell us and we’ll add it to the shop.
        </div>
        </div>
        <Button
          btnColor="red"
          appearance="outline"
          size={sourceBasedClass.btnType}
          className={sourceBasedClass.btn}
          onClick={handleRedirect}
        >
          <span className="w-400">Request {["search", "search_results"].includes(source) ? "this" : "a"} product</span>
        </Button>
      </div>
    </div>
  )
}

export default RequestBanner;
