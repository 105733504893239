import _get from "lodash-es/get";

import getAxios from "../../utils/axios";
import APIendpoints from "../../utils/constants/APIendpoints";
import actionTypes from "../actionTypes";

function verifyOtp(requestData) {
  return dispatch => {
    dispatch({ type: actionTypes.OTP_VERIFY_BEGIN });
    getAxios(true)
      .post(APIendpoints.verifyOtp, requestData)
      .then(() => {
        dispatch({ type: actionTypes.OTP_VERIFY_DONE });
      })
      .catch(err => {
        dispatch({
          type: actionTypes.OTP_VERIFY_FAIL,
          error: _get(
            err,
            ["response", "data", "error", "message"],
            "Something went wrong!"
          )
        });
      });
  };
}

function forgotPasswordVerifyOtp(requestData , callBack) {
  return dispatch => {
    dispatch({ type: actionTypes.FORGOT_PASSWORD_VERIFY_OTP_BEGIN });
    getAxios()
      .post(APIendpoints.forgotPasswordVerifyOtp, requestData)
      .then(({ data, headers }) => {
        dispatch({ type: actionTypes.FORGOT_PASSWORD_VERIFY_OTP_DONE });
        if (typeof callBack === "function") {
          callBack()
        }
      })
      .catch(err => {
        dispatch({
          type: actionTypes.FORGOT_PASSWORD_VERIFY_OTP_FAIL,
          error: _get(
            err,
            ["response", "data", "error", "message"],
            "Something went wrong!"
          )
        });
      });
  };
}

function resetForgotPasswordVerifyOtp(requestData) {
  return dispatch => {
    dispatch({ type: actionTypes.FORGOT_PASSWORD_VERIFY_OTP_INIT });
  };
}

function forgotPasswordResendOtp(phoneNumber) {
  return dispatch => {
    dispatch({ type: actionTypes.FORGOT_PASSWORD_RESEND_OTP_BEGIN });
    getAxios()
      .post(APIendpoints.forgotPasswordEnterPhone(phoneNumber), {
        isForgotPassword: true,
        userPhoneNumber: phoneNumber
      })
      .then(({ data, headers }) => {
        dispatch({ type: actionTypes.FORGOT_PASSWORD_RESEND_OTP_DONE });
      })
      .catch(err => {
        dispatch({
          type: actionTypes.FORGOT_PASSWORD_RESEND_OTP_FAIL,
          error: _get(
            err,
            ["response", "data", "error", "message"],
            "Something went wrong!"
          )
        });
      });
  };
}

function addOrUpdateOutletPhoneNumber(requestBody, errorCallBack) {
  return (dispatch) => {
    dispatch({ type: actionTypes.ADD_UPDATE_PHONE_BEGIN });
    getAxios(true)
      .put(APIendpoints.addOrUpdateOutletPhoneNumber, requestBody)
      .then(() => {
        dispatch({
          type: actionTypes.ADD_UPDATE_PHONE_DONE,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ADD_UPDATE_PHONE_FAIL,
          error: _get(
            err,
            ["response", "data", "error", "message"],
            "Something went wrong!"
          ),
        });
        if (typeof errorCallBack === "function") {
          errorCallBack(err);
        }
      });
  };
}

export {
  verifyOtp,
  forgotPasswordVerifyOtp,
  forgotPasswordResendOtp,
  resetForgotPasswordVerifyOtp,
  addOrUpdateOutletPhoneNumber
};
