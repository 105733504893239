import React from "react";

import {
  SectionError,
  SectionLoading,
  PageError,
  PageLoading
} from "../message/Message";
import ErrorBoundary2 from "../errorBoundary/ErrorBoundary2";
import stateTypes from "../../../utils/constants/stateTypes";

class Loader extends React.Component {
  render() {
    const { statuses, conditions, Loading, renderError } = this.props;

    // check pre conditions
    if (conditions && conditions.pre) {
      for (const condition of conditions.pre) {
        const bool =
          typeof condition.check === "function"
            ? condition.check()
            : condition.check;

        if (!bool)
          return typeof condition.render === "function"
            ? condition.render(condition.error)
            : renderError(condition.error);
      }
    }

    // check statuses
    let count = 0;

    for (const status of statuses) {
      if (status.state === stateTypes.STATE_FAIL) {
        return typeof status.render === "function"
          ? status.render(status.error)
          : renderError(status.error);
      } else if (status.state === stateTypes.STATE_DONE) {
        count++;
      } else {
        if (status.check === false) {
          count++;
        }
      }
    }

    if (statuses.length !== count) {
      return Loading;
    }

    // check post conditions
    if (conditions && conditions.post) {
      for (const condition of conditions.post) {
        const bool =
          typeof condition.check === "function"
            ? condition.check()
            : condition.check;

        if (!bool)
          return typeof condition.render === "function"
            ? condition.render(condition.error)
            : renderError(condition.error);
      }
    }

    return (
      <ErrorBoundary2 show isPage>
        {this.props.children}
      </ErrorBoundary2>
    );
  }
}

function SectionLoader(props) {
  return (
    <Loader
      {...props}
      Loading={<SectionLoading />}
      renderError={err => <SectionError error={err} />}
    />
  );
}

function PageLoader(props) {
  return (
    <Loader
      {...props}
      Loading={<PageLoading />}
      renderError={err => <PageError error={err} />}
    />
  );
}

export { SectionLoader, PageLoader };
