import actionTypes from "../actionTypes";
import stateTypes from "../../utils/constants/stateTypes";

function getInitialState(keys) {
    const initialState = {};
    for (const key of keys) {
        initialState[key] = {
            state: stateTypes.STATE_INIT,
            error: null,
            cancelTokenSource: null,
        };
    }
    return initialState;
}

const initialState = getInitialState([
  "auth_signIn",
  "auth_signOut",
  "user_getData",
  "user_resendEmail",
  "user_outlet_switch",
  "user_changeName",
  "user_changeEmail",
  "user_changePhone",
  "user_changePassword",
  "user_overdue",
  "user_getCities",
  "user_getDeliveryTimeSlots",
  "user_getDesignations",
  "user_getOutlets",
  "user_getReferral",
  "user_getCommercialBanners",
  "catalog_getAllProducts",
  "catalog_getAllProductsForBuyer",
  "catalog_getCategoryProducts",
  "catalog_getCategoryProductsForBuyer",
  "catalog_getBannerProductsForBuyer",
  "catalog_getCategoriesAndSubCategories",
  "catalog_v2GetCategoriesAndSubCategories",
  "catalog_searchProductsForBuyer",
  "catalog_getSearchSuggestions",
  "request_getSearchSuggestions",
  "cart_add",
  "cart_update",
  "cart_get",
  "cart_delete",
  "cart_checkout",
  "credit_get",
  "order_get",
  "order_get_one",
  "order_add_rating",
  "statement_download",
  "statement_email",
  "order_place",
  "cart_clear",
  "order_reorder",
  "payment_getAccessCode",
  "payment_getHash",
  "payment_updateStatus",
  "payment_getOptions",
  "payment_getOptionsForDropdown",
  "payment_makePending",
  "otp_verify",
  "otp_resend",
  "common_getEnum",
  "register_StepOne",
  "register_StepOne2",
  "register_happyStepOne",
  "register_validatePinCode",
  "register_updateStepOne",
  "register_StepTwo",
  "register_StepTwo2",
  "register_StepThree",
  "register_setData",
  "register_uploadPanImage",
  "register_uploadFssaiImage",
  "register_uploadGstImage",
  "register_uploadPanImage2",
  "register_uploadFssaiImage2",
  "register_uploadGstImage2",
  "register_validateGst",
  "register_validatePhone",
  "register_verifyOtp",
  "register_getOtp",
  "register_allSteps",
  "fogotPassword_enterPhone",
  "forgotPassword_verifyOtp",
  "forgotPassword_resendOtp",
  "profile_sendReferralEmailInvite",
  "buyer_accept_tnc",
  "referral_validateCode",
  "referral_setCode",
  "register_promotionVisibility",
  "offers_getFeatured",
  "offers_getAll",
  "offers_getTrial",
  "testimonials",
  "creditNote_get",
  "config_get",
  "email_verify",
  "zomato_fetchMerchant",
  "zomato_merchantOnbaord",
  "zomato_panUpload",
  "zomato_verifyPhone",
  "notifications_get",
  "notifications_getStatus",
  "notifications_markRead",
  "locationValidation",
  "updateLocation",
  "products_alternate",
  "validatePan",
  "refundToWallet",
  "updateWhatsappNotificationStatus",
  "getPopupStatus",
  "getPopupCheckoutStatus",
  "getPopupHomeStatus",
  "user_getPastOrders",
  "user_shareAppLink",
  "user_getAppLink",
  "credit_walletTransactions",
  "credit_walletRecharge",
  "rewards",
  "payLater_update",
  "user_trackClick",
  "order_rateDetail",
  "consumer_rail",
  "consumer_setDeliverySlot",
  "consumer_setPincode",
  "consumer_semiServiceable",
  "user_getFAQ",
  "user_getFAQAnswer",
  "user_authOptions",
  "user_getOutletsOnly",
  "get_acquisition",
  "add_nps_rating",
  "update_nps_rating",
  "requestProduct",
  "oosNotify",
  "addNpsFeedback",
  "get_home",
  "vertical_products",
  "catalog_v3GetCategories",
  "catalog_getBrandSubCategories",
  "product_detail",
  "update_account",
  "update_outlet",
  "order_invoice_details",
  "autoOnboardingZomato",
  "get_milestone_details",
  "get_historical_milestone_details",
  "get_zomato_status",
  "delivery_slots",
  "update_checkout",
  "update_user_preference",
  "communications",
  "creditTransactions",
  "creditTransactionDetail",
  "credit_onboarding",
  "credit_plans",
  "get_consumer_otp",
  "verify_consumer_otp",
  "campaign_getBrandCampaign",
  "get_valid_outlets",
  "validateCoupon",
  "claimCoupon",
  "paymentInfo",
  "addOrUpdateOutletPhoneNumber",
  "deleteContact",
  "get_filters",
  "get_refund_journey",
  "get_search_locations",
  "update_outlet_location",
  "check_outlet_location",
  "get_offer_details",
  "cancelOrder",
  "getReasons",
  "get_home_page_category_rails",
  "changeDeliverySlot",
  "generatePayloadToken",
  "generateAccessToken",
  "verify_user",
  "verify_user_otp",
  "get_delivery_slots_for_package",
  "getSOAOptions",
  "getSOAFilePath",
  "update_ingredint_list",
  "bottom_tabs",
  "getOwnersDashboard",
  "getOwnersDashboardFilePath",
  "modifyUserOutletMapping",
  "getCorporateAnnouncements",
  "owner_details",
  "document_verification_details",
  "document_number_verification_details",
  "upload_document_details",
  "poll_document_details",
  "addOrderTicket",
  "uploadSingleImage",
  "add_user_phone_number_and_reonboard_user",
  "add_user_phone_number_and_reonboard_user_verify_otp",
  "get_wallet_recharge",
  "getMoveCartProducts",
  "setMoveCartProducts",
  "getSettingsConfig",
  "taxToggle",
  "setPosLinkStatus",
  "hp_plus",
  "hpPlusPayementInfo",
  "acquireLock",
  "cart_get_v2",
  "getReceiverDetails",
  "setReceiverDetails",
  "getOrderDetails",
]);

function init(state, key) {
    return {
        ...state,
        [key]: {
            state: stateTypes.STATE_INIT,
            error: null,
            cancelTokenSource: null,
        },
    };
}

function begin(state, key, cancelTokenSource) {
    return {
        ...state,
        [key]: {
            state: stateTypes.STATE_BEGIN,
            error: null,
            cancelTokenSource: cancelTokenSource || null,
        },
    };
}

function done(state, key) {
    return {
        ...state,
        [key]: {
            state: stateTypes.STATE_DONE,
            error: null,
            cancelTokenSource: null,
        },
    };
}

function fail(state, key, error) {
    error = error || "Something went wrong!";
    return {
        ...state,
        [key]: {
            state: stateTypes.STATE_FAIL,
            error: error,
            cancelTokenSource: null,
        },
    };
}

function statusReducer(state = initialState, action) {
  switch (action.type) {
      case actionTypes.AUTH_SIGN_IN_BEGIN:
          return begin(state, "auth_signIn");
      case actionTypes.AUTH_SIGN_IN_DONE:
          return done(state, "auth_signIn");
      case actionTypes.AUTH_SIGN_IN_FAIL:
          return fail(state, "auth_signIn", action.error?.message);

      case actionTypes.AUTH_SIGN_OUT_BEGIN:
          return begin(state, "auth_signOut");
      case actionTypes.AUTH_SIGN_OUT_FAIL:
          return fail(state, "auth_signOut", action.error?.message);

      case actionTypes.USER_GET_DATA_BEGIN:
          return begin(state, "user_getData");
      case actionTypes.USER_GET_DATA_DONE:
          return done(state, "user_getData");
      case actionTypes.USER_GET_DATA_FAIL:
          return fail(state, "user_getData", action.error?.message);

      case actionTypes.VERIFY_USER_INIT:
          return init(state, "verify_user");
      case actionTypes.VERIFY_USER_BEGIN:
          return begin(state, "verify_user");
      case actionTypes.VERIFY_USER_DONE:
          return done(state, "verify_user");
      case actionTypes.VERIFY_USER_FAIL:
          return fail(state, "verify_user", action.error?.message);

      case actionTypes.VERIFY_USER_OTP_INIT:
          return init(state, "verify_user_otp");
      case actionTypes.VERIFY_USER_OTP_BEGIN:
          return begin(state, "verify_user_otp");
      case actionTypes.VERIFY_USER_OTP_DONE:
          return done(state, "verify_user_otp");
      case actionTypes.VERIFY_USER_OTP_FAIL:
          return fail(state, "verify_user_otp", action.error?.message);

      case actionTypes.GET_HOME_PAGE_CATEGORY_RAILS_INIT:
          return init(state, "get_home_page_category_rails");
      case actionTypes.GET_HOME_PAGE_CATEGORY_RAILS_BEGIN:
          return begin(state, "get_home_page_category_rails");
      case actionTypes.GET_HOME_PAGE_CATEGORY_RAILS_DONE:
          return done(state, "get_home_page_category_rails");
      case actionTypes.GET_HOME_PAGE_CATEGORY_RAILS_FAIL:
          return fail(
              state,
              "get_home_page_category_rails",
              action.error?.message
          );

      case actionTypes.GET_DELIVERY_SLOTS_FOR_PACKAGE_INIT:
          return init(state, "get_delivery_slots_for_package");
      case actionTypes.GET_DELIVERY_SLOTS_FOR_PACKAGE_BEGIN:
          return begin(state, "get_delivery_slots_for_package");
      case actionTypes.GET_DELIVERY_SLOTS_FOR_PACKAGE_DONE:
          return done(state, "get_delivery_slots_for_package");
      case actionTypes.GET_DELIVERY_SLOTS_FOR_PACKAGE_FAIL:
          return fail(
              state,
              "get_delivery_slots_for_package",
              action.error?.message
          );

      case actionTypes.GET_FILTERS_BEGIN:
          return begin(state, "get_filters");
      case actionTypes.GET_FILTERS_DONE:
          return done(state, "get_filters");
      case actionTypes.GET_FILTERS_FAIL:
          return fail(state, "get_filters", action.error?.message);

      case actionTypes.UPDATE_USER_PREFERENCE_INIT:
          return init(state, "update_user_preference");
      case actionTypes.UPDATE_USER_PREFERENCE_BEGIN:
          return begin(state, "update_user_preference");
      case actionTypes.UPDATE_USER_PREFERENCE_DONE:
          return done(state, "update_user_preference");
      case actionTypes.UPDATE_USER_PREFERENCE_FAIL:
          return fail(state, "update_user_preference", action.error?.message);

      case actionTypes.GET_COMMUNICATIONS_BEGIN:
          return begin(state, "communications");
      case actionTypes.GET_COMMUNICATIONS_DONE:
          return done(state, "communications");
      case actionTypes.GET_COMMUNICATIONS_FAIL:
          return fail(state, "communications", action.error?.message);
      case actionTypes.GET_COMMUNICATIONS_INIT:
          return init(state, "communications");

      case actionTypes.GET_CREDIT_TRANSACTIONS_BEGIN:
          return begin(state, "creditTransactions");
      case actionTypes.GET_CREDIT_TRANSACTIONS_DONE:
          return done(state, "creditTransactions");
      case actionTypes.GET_CREDIT_TRANSACTIONS_FAIL:
          return fail(state, "creditTransactions", action.error?.message);

      case actionTypes.GET_CREDIT_TRANSACTION_DETAIL_BEGIN:
          return begin(state, "creditTransactionDetail");
      case actionTypes.GET_CREDIT_TRANSACTION_DETAIL_DONE:
          return done(state, "creditTransactionDetail");
      case actionTypes.GET_CREDIT_TRANSACTION_DETAIL_FAIL:
          return fail(state, "creditTransactionDetail", action.error?.message);
      case actionTypes.GET_CREDIT_TRANSACTION_DETAIL_INIT:
          return init(state, "creditTransactionDetail");

      case actionTypes.GET_CREDIT_PAYMENTS_BEGIN:
          return begin(state, "creditPayments");
      case actionTypes.GET_CREDIT_PAYMENTS_DONE:
          return done(state, "creditPayments");
      case actionTypes.GET_CREDIT_PAYMENTS_FAIL:
          return fail(state, "creditPayments", action.error?.message);
      case actionTypes.GET_CREDIT_PAYMENTS_INIT:
          return init(state, "creditPayments");

      case actionTypes.GET_CREDIT_PAYMENTS_V2_BEGIN:
          return begin(state, "creditPaymentsv2");
      case actionTypes.GET_CREDIT_PAYMENTS_V2_DONE:
          return done(state, "creditPaymentsv2");
      case actionTypes.GET_CREDIT_PAYMENTS_V2_FAIL:
          return fail(state, "creditPaymentsv2", action.error?.message);
      case actionTypes.GET_CREDIT_PAYMENTS_V2_INIT:
          return init(state, "creditPaymentsv2");

      case actionTypes.CREATE_SERVICE_ORDER_BEGIN:
          return begin(state, "createServiceOrder");
      case actionTypes.CREATE_SERVICE_ORDER_DONE:
          return done(state, "createServiceOrder");
      case actionTypes.CREATE_SERVICE_ORDER_FAIL:
          return fail(state, "createServiceOrder", action.error?.message);
      case actionTypes.CREATE_SERVICE_ORDER_INIT:
          return init(state, "createServiceOrder");

      case actionTypes.GET_MAKE_CREDIT_PAYMENT_BEGIN:
          return begin(state, "makeCreditPayments");
      case actionTypes.GET_MAKE_CREDIT_PAYMENT_DONE:
          return done(state, "makeCreditPayments");
      case actionTypes.GET_MAKE_CREDIT_PAYMENT_FAIL:
          return fail(state, "makeCreditPayments", action.error?.message);

      case actionTypes.GET_INVOICE_DETAILS_BEGIN:
          return begin(state, "order_invoice_details");
      case actionTypes.GET_INVOICE_DETAILS_DONE:
          return done(state, "order_invoice_details");
      case actionTypes.GET_INVOICE_DETAILS_FAIL:
          return fail(state, "order_invoice_details", action.error?.message);

      case actionTypes.USER_RESEND_EMAIL_BEGIN:
          return begin(state, "user_resendEmail");
      case actionTypes.USER_RESEND_EMAIL_DONE:
          return done(state, "user_resendEmail");
      case actionTypes.USER_RESEND_EMAIL_FAIL:
          return fail(state, "user_resendEmail", action.error?.message);

      case actionTypes.USER_GET_CITIES_BEGIN:
          return begin(state, "user_getCities");
      case actionTypes.USER_GET_CITIES_DONE:
          return done(state, "user_getCities");
      case actionTypes.USER_GET_CITIES_FAIL:
          return fail(state, "user_getCities", action.error?.message);

      case actionTypes.USER_GET_DELIVERY_TIME_SLOTS_BEGIN:
          return begin(state, "user_getDeliveryTimeSlots");
      case actionTypes.USER_GET_DELIVERY_TIME_SLOTS_DONE:
          return done(state, "user_getDeliveryTimeSlots");
      case actionTypes.USER_GET_DELIVERY_TIME_SLOTS_FAIL:
          return fail(
              state,
              "user_getDeliveryTimeSlots",
              action.error?.message
          );

      case actionTypes.USER_GET_DESIGNATIONS_BEGIN:
          return begin(state, "user_getDesignations");
      case actionTypes.USER_GET_DESIGNATIONS_DONE:
          return done(state, "user_getDesignations");
      case actionTypes.USER_GET_DESIGNATIONS_FAIL:
          return fail(state, "user_getDesignations", action.error?.message);

      case actionTypes.USER_GET_OUTLETS_BEGIN:
          return begin(state, "user_getOutlets");
      case actionTypes.USER_GET_OUTLETS_DONE:
          return done(state, "user_getOutlets");
      case actionTypes.USER_GET_OUTLETS_FAIL:
          return fail(state, "user_getOutlets", action.error?.message);

      case actionTypes.GET_CONSUMER_REFUND_JOURNEY_BEGIN:
          return begin(state, "get_refund_journey");
      case actionTypes.GET_CONSUMER_REFUND_JOURNEY_DONE:
          return done(state, "get_refund_journey");
      case actionTypes.GET_CONSUMER_REFUND_JOURNEY_FAIL:
          return fail(state, "get_refund_journey", action.error?.message);
      case actionTypes.GET_CONSUMER_REFUND_JOURNEY_INIT:
          return init(state, "get_refund_journey");

      case actionTypes.USER_GET_REFERRAL_BEGIN:
          return begin(state, "user_getReferral");
      case actionTypes.USER_GET_REFERRAL_DONE:
          return done(state, "user_getReferral");
      case actionTypes.USER_GET_REFERRAL_FAIL:
          return fail(state, "user_getReferral", action.error?.message);

      case actionTypes.USER_CHANGE_NAME_BEGIN:
          return begin(state, "user_changeName");
      case actionTypes.USER_CHANGE_NAME_DONE:
          return done(state, "user_changeName");
      case actionTypes.USER_CHANGE_NAME_FAIL:
          return fail(state, "user_changeName", action.error?.message);

      case actionTypes.USER_CHANGE_EMAIL_BEGIN:
          return begin(state, "user_changeEmail");
      case actionTypes.USER_CHANGE_EMAIL_DONE:
          return done(state, "user_changeEmail");
      case actionTypes.USER_CHANGE_EMAIL_FAIL:
          return fail(state, "user_changeEmail", action.error?.message);

      case actionTypes.USER_CHANGE_PHONE_BEGIN:
          return begin(state, "user_changePhone");
      case actionTypes.USER_CHANGE_PHONE_DONE:
          return done(state, "user_changePhone");
      case actionTypes.USER_CHANGE_PHONE_FAIL:
          return fail(state, "user_changePhone", action.error?.message);

      case actionTypes.USER_CHANGE_PASSWORD_BEGIN:
          return begin(state, "user_changePassword");
      case actionTypes.USER_CHANGE_PASSWORD_DONE:
          return done(state, "user_changePassword");
      case actionTypes.USER_CHANGE_PASSWORD_FAIL:
          return fail(state, "user_changePassword", action.error?.message);

      case actionTypes.CATALOG_GET_ALL_PRODUCTS_BEGIN:
          return begin(state, "catalog_getAllProducts");
      case actionTypes.CATALOG_GET_ALL_PRODUCTS_DONE:
          return done(state, "catalog_getAllProducts");
      case actionTypes.CATALOG_GET_ALL_PRODUCTS_FAIL:
          return fail(state, "catalog_getAllProducts", action.error?.message);

      case actionTypes.CATALOG_GET_CATEGORIES_AND_SUB_CATEGORIES_BEGIN:
          return begin(state, "catalog_getCategoriesAndSubCategories");
      case actionTypes.CATALOG_GET_CATEGORIES_AND_SUB_CATEGORIES_DONE:
          return done(state, "catalog_getCategoriesAndSubCategories");
      case actionTypes.CATALOG_GET_CATEGORIES_AND_SUB_CATEGORIES_FAIL:
          return fail(
              state,
              "catalog_getCategoriesAndSubCategories",
              action.error?.message
          );

      case actionTypes.CATALOG_GET_CATEGORY_PRODUCTS_BEGIN:
          return begin(state, "catalog_getCategoryProducts");
      case actionTypes.CATALOG_GET_CATEGORY_PRODUCTS_DONE:
          return done(state, "catalog_getCategoryProducts");
      case actionTypes.CATALOG_GET_CATEGORY_PRODUCTS_FAIL:
          return fail(
              state,
              "catalog_getCategoryProducts",
              action.error?.message
          );

      case actionTypes.CATALOG_GET_ALL_PRODUCTS_FOR_BUYER_BEGIN:
          return begin(state, "catalog_getAllProductsForBuyer");
      case actionTypes.CATALOG_GET_ALL_PRODUCTS_FOR_BUYER_DONE:
          return done(state, "catalog_getAllProductsForBuyer");
      case actionTypes.CATALOG_GET_ALL_PRODUCTS_FOR_BUYER_FAIL:
          return fail(
              state,
              "catalog_getAllProductsForBuyer",
              action.error?.message
          );

      case actionTypes.CATALOG_GET_CATEGORY_PRODUCTS_FOR_BUYER_BEGIN:
          return begin(state, "catalog_getCategoryProductsForBuyer");
      case actionTypes.CATALOG_GET_CATEGORY_PRODUCTS_FOR_BUYER_DONE:
          return done(state, "catalog_getCategoryProductsForBuyer");
      case actionTypes.CATALOG_GET_CATEGORY_PRODUCTS_FOR_BUYER_FAIL:
          return fail(
              state,
              "catalog_getCategoryProductsForBuyer",
              action.error?.message
          );

      case actionTypes.GET_PAYMENT_INFO_BEGIN:
          return begin(state, "paymentInfo");
      case actionTypes.GET_PAYMENT_INFO_DONE:
          return done(state, "paymentInfo");
      case actionTypes.GET_PAYMENT_INFO_FAIL:
          return fail(state, "paymentInfo", action.error?.message);

      case actionTypes.UPDATE_PAYMENT_INFO_BEGIN:
          return begin(state, "updatePaymentInfo");
      case actionTypes.UPDATE_PAYMENT_INFO_DONE:
          return done(state, "updatePaymentInfo");
      case actionTypes.UPDATE_PAYMENT_INFO_FAIL:
          return fail(state, "updatePaymentInfo", action.error?.message);

      case actionTypes.CATALOG_GET_BANNER_PRODUCTS_FOR_BUYER_BEGIN:
          return begin(state, "catalog_getBannerProductsForBuyer");
      case actionTypes.CATALOG_GET_BANNER_PRODUCTS_FOR_BUYER_DONE:
          return done(state, "catalog_getBannerProductsForBuyer");
      case actionTypes.CATALOG_GET_BANNER_PRODUCTS_FOR_BUYER_FAIL:
          return fail(
              state,
              "catalog_getBannerProductsForBuyer",
              action.error?.message
          );

      case actionTypes.CATALOG_SEARCH_PRODUCTS_FOR_BUYER_BEGIN:
          return begin(state, "catalog_searchProductsForBuyer");
      case actionTypes.CATALOG_SEARCH_PRODUCTS_FOR_BUYER_DONE:
          return done(state, "catalog_searchProductsForBuyer");
      case actionTypes.CATALOG_SEARCH_PRODUCTS_FOR_BUYER_FAIL:
          return fail(
              state,
              "catalog_searchProductsForBuyer",
              action.error?.message
          );

      case actionTypes.CATALOG_GET_SEARCH_SUGGESTIONS_BEGIN:
          return begin(state, "catalog_getSearchSuggestions");
      case actionTypes.CATALOG_GET_SEARCH_SUGGESTIONS_DONE:
          return done(state, "catalog_getSearchSuggestions");
      case actionTypes.CATALOG_GET_SEARCH_SUGGESTIONS_FAIL:
          return fail(state, "catalog_getSearchSuggestions");

      case actionTypes.REQUEST_PRODUCT_SUGGESION_BEGIN:
          return begin(state, "request_getSearchSuggestions");
      case actionTypes.REQUEST_PRODUCT_SUGGESION_DONE:
          return done(state, "request_getSearchSuggestions");
      case actionTypes.REQUEST_PRODUCT_SUGGESION_FAIL:
          return fail(
              state,
              "request_getSearchSuggestions",
              action.error?.message
          );

      case actionTypes.CART_ADD_BEGIN:
          return begin(state, "cart_add");
      case actionTypes.CART_ADD_DONE:
          return done(state, "cart_add");
      case actionTypes.CART_ADD_FAIL:
          return fail(state, "cart_add", action.error?.message);

      case actionTypes.CART_UPDATE_BEGIN:
          return begin(state, "cart_update");
      case actionTypes.CART_UPDATE_DONE:
          return done(state, "cart_update");
      case actionTypes.CART_UPDATE_FAIL:
          return fail(state, "cart_update", action.error?.message);

      case actionTypes.CART_GET_BEGIN:
          return begin(state, "cart_get");
      case actionTypes.CART_GET_DONE:
          return done(state, "cart_get");
      case actionTypes.CART_GET_FAIL:
          return fail(state, "cart_get", action.error?.message);

      case actionTypes.CART_V2_GET_BEGIN:
          return begin(state, "cart_get_v2");
      case actionTypes.CART_V2_GET_DONE:
          return done(state, "cart_get_v2");
      case actionTypes.CART_V2_GET_FAIL:
          return fail(state, "cart_get_v2", action.error?.message);

      case actionTypes.CART_DELETE_BEGIN:
          return begin(state, "cart_delete");
      case actionTypes.CART_DELETE_DONE:
          return done(state, "cart_delete");
      case actionTypes.CART_DELETE_FAIL:
          return fail(state, "cart_delete", action.error?.message);

      case actionTypes.CART_CHECKOUT_BEGIN:
          return begin(state, "cart_checkout");
      case actionTypes.CART_CHECKOUT_DONE:
          return done(state, "cart_checkout");
      case actionTypes.CART_CHECKOUT_FAIL:
          return fail(state, "cart_checkout", action.error?.message);

      case actionTypes.GET_DELIVERY_SLOTS_BEGIN:
          return begin(state, "delivery_slots");
      case actionTypes.GET_DELIVERY_SLOTS_DONE:
          return done(state, "delivery_slots");
      case actionTypes.GET_DELIVERY_SLOTS_FAIL:
          return fail(state, "delivery_slots", action.error?.message);

      case actionTypes.UPDATE_CHECKOUT_BEGIN:
          return begin(state, "update_checkout");
      case actionTypes.UPDATE_CHECKOUT_DONE:
          return done(state, "update_checkout");
      case actionTypes.UPDATE_CHECKOUT_FAIL:
          return fail(state, "update_checkout", action.error?.message);

      case actionTypes.OUTLET_SWITCH_BEGIN:
          return begin(state, "user_outlet_switch");
      case actionTypes.OUTLET_SWITCH_DONE:
          return done(state, "user_outlet_switch");
      case actionTypes.OUTLET_SWITCH_FAIL:
          return fail(state, "user_outlet_switch", action.error?.message);

      case actionTypes.GET_SEARCH_LOCATIONS_BEGIN:
          return begin(state, "get_search_locations");
      case actionTypes.GET_SEARCH_LOCATIONS_DONE:
          return done(state, "get_search_locations");
      case actionTypes.GET_SEARCH_LOCATIONS_FAIL:
          return fail(state, "get_search_locations", action.error?.message);

      case actionTypes.UPDATE_OUTLET_LOCATION_BEGIN:
          return begin(state, "update_outlet_location");
      case actionTypes.UPDATE_OUTLET_LOCATION_DONE:
          return done(state, "update_outlet_location");
      case actionTypes.UPDATE_OUTLET_LOCATION_FAIL:
          return fail(state, "update_outlet_location", action.error?.message);

      case actionTypes.CHECK_OUTLET_LOCATION_BEGIN:
          return begin(state, "check_outlet_location");
      case actionTypes.CHECK_OUTLET_LOCATION_DONE:
          return done(state, "check_outlet_location");
      case actionTypes.CHECK_OUTLET_LOCATION_FAIL:
          return fail(state, "check_outlet_location", action.error?.message);
      case actionTypes.CHECK_OUTLET_LOCATION_INIT:
          return init(state, "check_outlet_location");

      case actionTypes.GET_OFFER_DETAILS_BEGIN:
          return begin(state, "get_offer_details");
      case actionTypes.GET_OFFER_DETAILS_DONE:
          return done(state, "get_offer_details");
      case actionTypes.GET_OFFER_DETAILS_FAIL:
          return fail(state, "get_offer_details", action.error?.message);
      case actionTypes.GET_OFFER_DETAILS_INIT:
          return init(state, "get_offer_details");

      case actionTypes.GET_ADDRESS_DETAILS_INIT:
          return init(state, "get_address_details");
      case actionTypes.GET_ADDRESS_DETAILS_BEGIN:
          return begin(state, "get_address_details");
      case actionTypes.GET_ADDRESS_DETAILS_DONE:
          return done(state, "get_address_details");
      case actionTypes.GET_ADDRESS_DETAILS_FAIL:
          return fail(state, "get_address_details", action.error?.message);

      case actionTypes.CREDIT_GET_BEGIN:
          return begin(state, "credit_get");
      case actionTypes.CREDIT_GET_DONE:
          return done(state, "credit_get");
      case actionTypes.CREDIT_GET_FAIL:
          return fail(state, "credit_get", action.error?.message);

      case actionTypes.CREDIT_ONBOARDING_BEGIN:
          return begin(state, "credit_onboarding");
      case actionTypes.CREDIT_ONBOARDING_DONE:
          return done(state, "credit_onboarding");
      case actionTypes.CREDIT_ONBOARDING_FAIL:
          return fail(state, "credit_onboarding", action.error?.message);

      case actionTypes.GET_VALID_OUTLETS_INIT:
          return init(state, "get_valid_outlets");
      case actionTypes.GET_VALID_OUTLETS_BEGIN:
          return begin(state, "get_valid_outlets");
      case actionTypes.GET_VALID_OUTLETS_DONE:
          return done(state, "get_valid_outlets");
      case actionTypes.GET_VALID_OUTLETS_FAIL:
          return fail(state, "get_valid_outlets", action.error?.message);

      case actionTypes.GET_CONSUMER_OTP_BEGIN:
          return begin(state, "get_consumer_otp");
      case actionTypes.GET_CONSUMER_OTP_DONE:
          return done(state, "get_consumer_otp");
      case actionTypes.GET_CONSUMER_OTP_FAIL:
          return fail(state, "get_consumer_otp", action.error?.message);
      case actionTypes.GET_CONSUMER_OTP_INIT:
          return init(state, "get_consumer_otp");

      case actionTypes.VERIFY_CONSUMER_OTP_BEGIN:
          return begin(state, "verify_consumer_otp");
      case actionTypes.VERIFY_CONSUMER_OTP_DONE:
          return done(state, "verify_consumer_otp");
      case actionTypes.VERIFY_CONSUMER_OTP_FAIL:
          return fail(state, "verify_consumer_otp", action.error?.message);
      case actionTypes.VERIFY_CONSUMER_OTP_INIT:
          return init(state, "verify_consumer_otp");

      case actionTypes.CREDIT_PLANS_BEGIN:
          return begin(state, "credit_plans");
      case actionTypes.CREDIT_PLANS_DONE:
          return done(state, "credit_plans");
      case actionTypes.CREDIT_PLANS_FAIL:
          return fail(state, "credit_plans", action.error?.message);
      case actionTypes.CREDIT_PLANS_INIT:
          return init(state, "credit_plans");

      case actionTypes.STATEMENT_DOWNLOAD_BEGIN:
          return begin(state, "statement_download");
      case actionTypes.STATEMENT_DOWNLOAD_DONE:
          return done(state, "statement_download");
      case actionTypes.STATEMENT_DOWNLOAD_FAIL:
          return fail(state, "statement_download", action.error?.message);

      case actionTypes.STATEMENT_EMAIL_BEGIN:
          return begin(state, "statement_email");
      case actionTypes.STATEMENT_EMAIL_DONE:
          return done(state, "statement_email");
      case actionTypes.STATEMENT_EMAIL_FAIL:
          return fail(state, "statement_email", action.error?.message);

      case actionTypes.ORDER_GET_ALL_BEGIN:
          return begin(state, "order_get");
      case actionTypes.ORDER_GET_ALL_DONE:
          return done(state, "order_get");
      case actionTypes.ORDER_GET_ALL_FAIL:
          return fail(state, "order_get", action.error?.message);

      case actionTypes.ORDER_PLACE_BEGIN:
          return begin(state, "order_place");
      case actionTypes.ORDER_PLACE_DONE:
          return done(state, "order_place");
      case actionTypes.ORDER_PLACE_FAIL:
          return fail(state, "order_place", action.error?.message);

      case actionTypes.ORDER_ADD_RATING_BEGIN:
          return begin(state, "order_add_rating");
      case actionTypes.ORDER_ADD_RATING_DONE:
          return done(state, "order_add_rating");
      case actionTypes.ORDER_ADD_RATING_FAIL:
          return fail(state, "order_add_rating", action.error?.message);

      case actionTypes.CART_CLEAR_BEGIN:
          return begin(state, "cart_clear");
      case actionTypes.CART_CLEAR_DONE:
          return done(state, "cart_clear");
      case actionTypes.CART_CLEAR_FAIL:
          return fail(state, "cart_clear", action.error?.message);

      case actionTypes.ORDER_REORDER_BEGIN:
          return begin(state, "order_reorder");
      case actionTypes.ORDER_REORDER_DONE:
          return done(state, "order_reorder");
      case actionTypes.ORDER_REORDER_FAIL:
          return fail(state, "order_reorder", action.error?.message);
      case actionTypes.ORDER_REORDER_INIT:
          return init(state, "order_reorder");

      case actionTypes.PAYMENT_GET_ACCESS_CODE_BEGIN:
          return begin(state, "payment_getAccessCode");
      case actionTypes.PAYMENT_GET_ACCESS_CODE_DONE:
          return done(state, "payment_getAccessCode");
      case actionTypes.PAYMENT_GET_ACCESS_CODE_FAIL:
          return fail(state, "payment_getAccessCode", action.error?.message);

      case actionTypes.PAYMENT_GET_HASH_BEGIN:
          return begin(state, "payment_getHash");
      case actionTypes.PAYMENT_GET_HASH_DONE:
          return done(state, "payment_getHash");
      case actionTypes.PAYMENT_GET_HASH_FAIL:
          return fail(state, "payment_getHash", action.error?.message);

      case actionTypes.PAYMENT_UPDATE_STATUS_BEGIN:
          return begin(state, "payment_updateStatus");
      case actionTypes.PAYMENT_UPDATE_STATUS_DONE:
          return done(state, "payment_updateStatus");
      case actionTypes.PAYMENT_UPDATE_STATUS_FAIL:
          return fail(state, "payment_updateStatus", action.error?.message);

      case actionTypes.PAYMENT_GET_OPTIONS_BEGIN:
          return begin(state, "payment_getOptions");
      case actionTypes.PAYMENT_GET_OPTIONS_DONE:
          return done(state, "payment_getOptions");
      case actionTypes.PAYMENT_GET_OPTIONS_FAIL:
          return fail(state, "payment_getOptions", action.error?.message);

      case actionTypes.PAYMENT_GET_OPTIONS_FOR_DROPDOWN_BEGIN:
          return begin(state, "payment_getOptionsForDropdown");
      case actionTypes.PAYMENT_GET_OPTIONS_FOR_DROPDOWN_DONE:
          return done(state, "payment_getOptionsForDropdown");
      case actionTypes.PAYMENT_GET_OPTIONS_FOR_DROPDOWN_FAIL:
          return fail(
              state,
              "payment_getOptionsForDropdown",
              action.error?.message
          );

      case actionTypes.PAYMENT_MAKE_PENDING_BEGIN:
          return begin(state, "payment_makePending");
      case actionTypes.PAYMENT_MAKE_PENDING_DONE:
          return done(state, "payment_makePending");
      case actionTypes.PAYMENT_MAKE_PENDING_FAIL:
          return fail(state, "payment_makePending", action.error?.message);

      case actionTypes.OTP_VERIFY_BEGIN:
          return begin(state, "otp_verify");
      case actionTypes.OTP_VERIFY_DONE:
          return done(state, "otp_verify");
      case actionTypes.OTP_VERIFY_FAIL:
          return fail(state, "otp_verify", action.error?.message);

      case actionTypes.GET_ENUM_BEGIN:
          return begin(state, "common_getEnum");
      case actionTypes.GET_ENUM_DONE:
          return done(state, "common_getEnum");
      case actionTypes.GET_ENUM_FAIL:
          return fail(state, "common_getEnum", action.error?.message);

      case actionTypes.REGISTER_STEP_ONE_BEGIN:
          return begin(state, "register_StepOne");
      case actionTypes.REGISTER_STEP_ONE_DONE:
          return done(state, "register_StepOne");
      case actionTypes.REGISTER_STEP_ONE_FAIL:
          return fail(state, "register_StepOne", action.error?.message);

      case actionTypes.REGISTER_STEP_ONE_2_BEGIN:
          return begin(state, "register_StepOne2");
      case actionTypes.REGISTER_STEP_ONE_2_DONE:
          return done(state, "register_StepOne2");
      case actionTypes.REGISTER_STEP_ONE_2_FAIL:
          return fail(state, "register_StepOne2", action.error?.message);

      case actionTypes.HAPPY_REGISTER_STEP_ONE_BEGIN:
          return begin(state, "register_happyStepOne");
      case actionTypes.HAPPY_REGISTER_STEP_ONE_DONE:
          return done(state, "register_happyStepOne");
      case actionTypes.HAPPY_REGISTER_STEP_ONE_FAIL:
          return fail(state, "register_happyStepOne", action.error?.message);

      case actionTypes.UPDATE_REG_STEP_ONE_BEGIN:
          return begin(state, "register_updateStepOne");
      case actionTypes.UPDATE_REG_STEP_ONE_DONE:
          return done(state, "register_updateStepOne");
      case actionTypes.UPDATE_REG_STEP_ONE_FAIL:
          return fail(state, "register_updateStepOne", action.error?.message);

      case actionTypes.REGISTER_STEP_TWO_BEGIN:
          return begin(state, "register_StepTwo");
      case actionTypes.REGISTER_STEP_TWO_DONE:
          return done(state, "register_StepTwo");
      case actionTypes.REGISTER_STEP_TWO_FAIL:
          return fail(state, "register_StepTwo", action.error?.message);

      case actionTypes.REGISTER_STEP_TWO_2_BEGIN:
          return begin(state, "register_StepTwo2");
      case actionTypes.REGISTER_STEP_TWO_2_DONE:
          return done(state, "register_StepTwo2");
      case actionTypes.REGISTER_STEP_TWO_2_FAIL:
          return fail(state, "register_StepTwo2", action.error?.message);

      case actionTypes.REGISTER_STEP_THREE_BEGIN:
          return begin(state, "register_StepThree");
      case actionTypes.REGISTER_STEP_THREE_DONE:
          return done(state, "register_StepThree");
      case actionTypes.REGISTER_STEP_THREE_FAIL:
          return fail(state, "register_StepThree", action.error?.message);

      case actionTypes.SET_REGISTER_DATA_BEGIN:
          return begin(state, "register_setData");
      case actionTypes.SET_REGISTER_DATA_DONE:
          return done(state, "register_setData");
      case actionTypes.SET_REGISTER_DATA_FAIL:
          return fail(state, "register_setData", action.error?.message);

      case actionTypes.UPLOAD_PAN_IMAGE_BEGIN:
          return begin(state, "register_uploadPanImage");
      case actionTypes.UPLOAD_PAN_IMAGE_DONE:
          return done(state, "register_uploadPanImage");
      case actionTypes.UPLOAD_PAN_IMAGE_FAIL:
          return fail(state, "register_uploadPanImage", action.error?.message);

      case actionTypes.UPLOAD_FSSAI_IMAGE_BEGIN:
          return begin(state, "register_uploadFssaiImage");
      case actionTypes.UPLOAD_FSSAI_IMAGE_DONE:
          return done(state, "register_uploadFssaiImage");
      case actionTypes.UPLOAD_FSSAI_IMAGE_FAIL:
          return fail(
              state,
              "register_uploadFssaiImage",
              action.error?.message
          );

      case actionTypes.UPLOAD_GST_IMAGE_BEGIN:
          return begin(state, "register_uploadGstImage");
      case actionTypes.UPLOAD_GST_IMAGE_DONE:
          return done(state, "register_uploadGstImage");
      case actionTypes.UPLOAD_GST_IMAGE_FAIL:
          return fail(state, "register_uploadGstImage", action.error?.message);

      case actionTypes.UPLOAD_PAN_IMAGE_2_BEGIN:
          return begin(state, "register_uploadPanImage2");
      case actionTypes.UPLOAD_PAN_IMAGE_2_DONE:
          return done(state, "register_uploadPanImage2");
      case actionTypes.UPLOAD_PAN_IMAGE_2_FAIL:
          return fail(state, "register_uploadPanImage2", action.error?.message);

      case actionTypes.UPLOAD_FSSAI_IMAGE_2_BEGIN:
          return begin(state, "register_uploadFssaiImage2");
      case actionTypes.UPLOAD_FSSAI_IMAGE_2_DONE:
          return done(state, "register_uploadFssaiImage2");
      case actionTypes.UPLOAD_FSSAI_IMAGE_2_FAIL:
          return fail(
              state,
              "register_uploadFssaiImage2",
              action.error?.message
          );

      case actionTypes.UPLOAD_GST_IMAGE_2_BEGIN:
          return begin(state, "register_uploadGstImage2");
      case actionTypes.UPLOAD_GST_IMAGE_2_DONE:
          return done(state, "register_uploadGstImage2");
      case actionTypes.UPLOAD_GST_IMAGE_2_FAIL:
          return fail(state, "register_uploadGstImage2", action.error?.message);

      case actionTypes.VALIDATE_GST_BEGIN:
          return begin(state, "register_validateGst");
      case actionTypes.VALIDATE_GST_DONE:
          return done(state, "register_validateGst");
      case actionTypes.VALIDATE_GST_FAIL:
          return fail(state, "register_validateGst", action.error?.message);

      case actionTypes.VALIDATE_PHONE_NUMBER_BEGIN:
          return begin(state, "register_validatePhone");
      case actionTypes.VALIDATE_PHONE_NUMBER_DONE:
          return done(state, "register_validatePhone");
      case actionTypes.VALIDATE_PHONE_NUMBER_FAIL:
          return fail(state, "register_validatePhone", action.error?.message);

      case actionTypes.REGISTER_VERIFY_OTP_BEGIN:
          return begin(state, "register_verifyOtp");
      case actionTypes.REGISTER_VERIFY_OTP_DONE:
          return done(state, "register_verifyOtp");
      case actionTypes.REGISTER_VERIFY_OTP_FAIL:
          return fail(state, "register_verifyOtp", action.error?.message);

      case actionTypes.GET_OTP_BEGIN:
          return begin(state, "register_getOtp");
      case actionTypes.GET_OTP_DONE:
          return done(state, "register_getOtp");
      case actionTypes.GET_OTP_FAIL:
          return fail(state, "register_getOtp", action.error?.message);

      case actionTypes.FORGOT_PASSWORD_ENTER_PHONE_BEGIN:
          return begin(state, "fogotPassword_enterPhone");
      case actionTypes.FORGOT_PASSWORD_ENTER_PHONE_DONE:
          return done(state, "fogotPassword_enterPhone");
      case actionTypes.FORGOT_PASSWORD_ENTER_PHONE_FAIL:
          return fail(state, "fogotPassword_enterPhone", action.error?.message);

      case actionTypes.FORGOT_PASSWORD_VERIFY_OTP_BEGIN:
          return begin(state, "forgotPassword_verifyOtp");
      case actionTypes.FORGOT_PASSWORD_VERIFY_OTP_DONE:
          return done(state, "forgotPassword_verifyOtp");
      case actionTypes.FORGOT_PASSWORD_VERIFY_OTP_FAIL:
          return fail(state, "forgotPassword_verifyOtp", action.error?.message);
      case actionTypes.FORGOT_PASSWORD_VERIFY_OTP_INIT:
          return init(state, "forgotPassword_verifyOtp");

      case actionTypes.FORGOT_PASSWORD_RESEND_OTP_BEGIN:
          return begin(state, "forgotPassword_resendOtp");
      case actionTypes.FORGOT_PASSWORD_RESEND_OTP_DONE:
          return done(state, "forgotPassword_resendOtp");
      case actionTypes.FORGOT_PASSWORD_RESEND_OTP_FAIL:
          return fail(state, "forgotPassword_resendOtp", action.error?.message);

      case actionTypes.SEND_REFERRAL_EMAIL_INVITE_BEGIN:
          return begin(state, "profile_sendReferralEmailInvite");
      case actionTypes.SEND_REFERRAL_EMAIL_INVITE_DONE:
          return done(state, "profile_sendReferralEmailInvite");
      case actionTypes.SEND_REFERRAL_EMAIL_INVITE_FAIL:
          return fail(
              state,
              "profile_sendReferralEmailInvite",
              action.error?.message
          );

      case actionTypes.ACCEPT_TNC_BEGIN:
          return begin(state, "buyer_accept_tnc");
      case actionTypes.ACCEPT_TNC_DONE:
          return done(state, "buyer_accept_tnc");
      case actionTypes.ACCEPT_TNC_FAIL:
          return fail(state, "buyer_accept_tnc", action.error?.message);

      case actionTypes.VALIDATE_REFERRAL_CODE_BEGIN:
          return begin(state, "referral_validateCode");
      case actionTypes.VALIDATE_REFERRAL_CODE_DONE:
          return done(state, "referral_validateCode");
      case actionTypes.VALIDATE_REFERRAL_CODE_FAIL:
          return fail(state, "referral_validateCode", action.error?.message);

      case actionTypes.SET_REFERRAL_CODE_BEGIN:
          return begin(state, "referral_setCode");
      case actionTypes.SET_REFERRAL_CODE_DONE:
          return done(state, "referral_setCode");
      case actionTypes.SET_REFERRAL_CODE_FAIL:
          return fail(state, "referral_setCode", action.error?.message);

      case actionTypes.REGISTER_ALL_STEPS_BEGIN:
          return begin(state, "register_allSteps");
      case actionTypes.REGISTER_ALL_STEPS_DONE:
          return done(state, "register_allSteps");
      case actionTypes.REGISTER_ALL_STEPS_FAIL:
          return fail(state, "register_allSteps", action.error?.message);
      case actionTypes.REGISTER_ALL_STEPS_INIT:
          return init(state, "register_allSteps");

      case actionTypes.ORDER_GET_ONE_BEGIN:
          return begin(state, "order_get_one");
      case actionTypes.ORDER_GET_ONE_DONE:
          return done(state, "order_get_one");
      case actionTypes.ORDER_GET_ONE_FAIL:
          return fail(state, "order_get_one", action.error?.message);

      case actionTypes.PROMOTION_VISIBILITY_BEGIN:
          return begin(state, "register_promotionVisibility");
      case actionTypes.PROMOTION_VISIBILITY_DONE:
          return done(state, "register_promotionVisibility");
      case actionTypes.PROMOTION_VISIBILITY_FAIL:
          return fail(
              state,
              "register_promotionVisibility",
              action.error?.message
          );

      case actionTypes.OFFERS_GET_ALL_BEGIN:
          return begin(state, "offers_getAll");
      case actionTypes.OFFERS_GET_ALL_DONE:
          return done(state, "offers_getAll");
      case actionTypes.OFFERS_GET_ALL_FAIL:
          return fail(state, "offers_getAll", action.error?.message);

      case actionTypes.OFFERS_GET_FEATURED_BEGIN:
          return begin(state, "offers_getFeatured");
      case actionTypes.OFFERS_GET_FEATURED_DONE:
          return done(state, "offers_getFeatured");
      case actionTypes.OFFERS_GET_FEATURED_FAIL:
          return fail(state, "offers_getFeatured", action.error?.message);

      case actionTypes.VALIDATE_PINCODE_BEGIN:
          return begin(state, "register_validatePinCode");
      case actionTypes.VALIDATE_PINCODE_DONE:
          return done(state, "register_validatePinCode");
      case actionTypes.VALIDATE_PINCODE_FAIL:
          return fail(state, "register_validatePinCode", action.error?.message);

      case actionTypes.GET_COMMERCIAL_BANNER_BEGIN:
          return begin(state, "user_getCommercialBanners");
      case actionTypes.GET_COMMERCIAL_BANNER_DONE:
          return done(state, "user_getCommercialBanners");
      case actionTypes.GET_COMMERCIAL_BANNER_FAIL:
          return fail(
              state,
              "user_getCommercialBanners",
              action.error?.message
          );

      case actionTypes.GET_CART_DISCOUNT_BEGIN:
          return begin(state, "get_cart_discount");
      case actionTypes.GET_CART_DISCOUNT_DONE:
          return done(state, "get_cart_discount");
      case actionTypes.GET_CART_DISCOUNT_FAIL:
          return fail(state, "get_cart_discount", action.error?.message);

      case actionTypes.GET_BRAND_CAMPAIGN_BEGIN:
          return begin(state, "campaign_getBrandCampaign");
      case actionTypes.GET_BRAND_CAMPAIGN_DONE:
          return done(state, "campaign_getBrandCampaign");
      case actionTypes.GET_BRAND_CAMPAIGN_FAIL:
          return fail(
              state,
              "campaign_getBrandCampaign",
              action.error?.message
          );

      case actionTypes.GET_FEATURE_TICKER_BEGIN:
          return begin(state, "user_getFeatureTicker");
      case actionTypes.GET_FEATURE_TICKER_DONE:
          return done(state, "user_getFeatureTicker");
      case actionTypes.GET_FEATURE_TICKER_FAIL:
          return fail(state, "user_getFeatureTicker", action.error?.message);

      case actionTypes.GET_COLLABORATIVE_BANNERS_BEGIN:
          return begin(state, "user_getCollaborativeBanners");
      case actionTypes.GET_COLLABORATIVE_BANNERS_DONE:
          return done(state, "user_getCollaborativeBanners");
      case actionTypes.GET_COLLABORATIVE_BANNERS_FAIL:
          return fail(
              state,
              "user_getCollaborativeBanners",
              action.error?.message
          );

      case actionTypes.GET_EXIGENCY_BANNERS_BEGIN:
          return begin(state, "user_getExigencyBanners");
      case actionTypes.GET_EXIGENCY_BANNERS_DONE:
          return done(state, "user_getExigencyBanners");
      case actionTypes.GET_EXIGENCY_BANNERS_FAIL:
          return fail(state, "user_getExigencyBanners", action.error?.message);

      case actionTypes.V2_CATALOG_GET_CATEGORIES_AND_SUB_CATEGORIES_BEGIN:
          return begin(state, "catalog_v2GetCategoriesAndSubCategories");
      case actionTypes.V2_CATALOG_GET_CATEGORIES_AND_SUB_CATEGORIES_DONE:
          return done(state, "catalog_v2GetCategoriesAndSubCategories");
      case actionTypes.V2_CATALOG_GET_CATEGORIES_AND_SUB_CATEGORIES_FAIL:
          return fail(
              state,
              "catalog_v2GetCategoriesAndSubCategories",
              action.error?.message
          );

      case actionTypes.GET_CATEGORIES_BEGIN:
          return begin(state, "catalog_v3GetCategories");
      case actionTypes.GET_CATEGORIES_DONE:
          return done(state, "catalog_v3GetCategories");
      case actionTypes.GET_CATEGORIES_FAIL:
          return fail(state, "catalog_v3GetCategories", action.error?.message);

      case actionTypes.GET_BRAND_SUBCATEGORIES_BEGIN:
          return begin(state, "catalog_getBrandSubCategories");
      case actionTypes.GET_BRAND_SUBCATEGORIES_DONE:
          return done(state, "catalog_getBrandSubCategories");
      case actionTypes.GET_BRAND_SUBCATEGORIES_FAIL:
          return fail(
              state,
              "catalog_getBrandSubCategories",
              action.error?.message
          );

      case actionTypes.GET_ENTITIES_INIT:
          return init(state, "catalog_getEntities");
      case actionTypes.GET_ENTITIES_BEGIN:
          return begin(state, "catalog_getEntities");
      case actionTypes.GET_ENTITIES_DONE:
          return done(state, "catalog_getEntities");
      case actionTypes.GET_ENTITIES_FAIL:
          return fail(state, "catalog_getEntities", action.error?.message);

      case actionTypes.TESTIMONIALS_BEGIN:
          return begin(state, "testimonials");
      case actionTypes.TESTIMONIALS_DONE:
          return done(state, "testimonials");
      case actionTypes.TESTIMONIALS_FAIL:
          return fail(state, "testimonials", action.error?.message);

      case actionTypes.GET_OVERDUE_BEGIN:
          return begin(state, "user_overdue");
      case actionTypes.GET_OVERDUE_DONE:
          return done(state, "user_overdue");
      case actionTypes.GET_OVERDUE_FAIL:
          return fail(state, "user_overdue", action.error?.message);

      case actionTypes.GET_USER_DEVICE_VERIFICATION_BEGIN:
          return begin(state, "user_device_verification");
      case actionTypes.GET_USER_DEVICE_VERIFICATION_DONE:
          return done(state, "user_device_verification");
      case actionTypes.GET_USER_DEVICE_VERIFICATION_FAIL:
          return fail(state, "user_device_verification", action.error?.message);

      case actionTypes.GET_ENTITY_OWNERS_BEGIN:
          return begin(state, "entity_owners");
      case actionTypes.GET_ENTITY_OWNERS_DONE:
          return done(state, "entity_owners");
      case actionTypes.GET_ENTITY_OWNERS_FAIL:
          return fail(state, "entity_owners", action.error?.message);

      case actionTypes.VERIFY_DEVICE_OTP_BEGIN:
          return begin(state, "verify_device");
      case actionTypes.VERIFY_DEVICE_OTP_DONE:
          return done(state, "verify_device");
      case actionTypes.VERIFY_DEVICE_OTP_FAIL:
          return fail(state, "verify_device", action.error?.message);

      case actionTypes.GET_OWNER_DETAILS_BEGIN:
          return begin(state, "owner_details");
      case actionTypes.GET_OWNER_DETAILS_DONE:
          return done(state, "owner_details");
      case actionTypes.GET_OWNER_DETAILS_FAIL:
          return fail(state, "owner_details", action.error?.message);

      case actionTypes.DOCUMENT_VERIFICATION_DETAILS_BEGIN:
          return begin(state, "document_verification_details");
      case actionTypes.DOCUMENT_VERIFICATION_DETAILS_DONE:
          return done(state, "document_verification_details");
      case actionTypes.DOCUMENT_VERIFICATION_DETAILS_FAIL:
          return fail(
              state,
              "document_verification_details",
              action.error?.message
          );

      case actionTypes.POLL_DOCUMENT_DETAILS_BEGIN:
          return begin(state, "poll_document_details");
      case actionTypes.POLL_DOCUMENT_DETAILS_DONE:
          return done(state, "poll_document_details");
      case actionTypes.POLL_DOCUMENT_DETAILS_FAIL:
          return fail(state, "poll_document_details", action.error?.message);

      case actionTypes.UPLOAD_DOCUMENT_DETAILS_BEGIN:
          return begin(state, "upload_document_details");
      case actionTypes.UPLOAD_DOCUMENT_DETAILS_DONE:
          return done(state, "upload_document_details");
      case actionTypes.UPLOAD_DOCUMENT_DETAILS_FAIL:
          return fail(state, "upload_document_details", action.error?.message);

      case actionTypes.DOCUMENT_NUMBER_VERIFICATION_DETAILS_BEGIN:
          return begin(state, "document_number_verification_details");
      case actionTypes.DOCUMENT_NUMBER_VERIFICATION_DETAILS_DONE:
          return done(state, "document_number_verification_details");
      case actionTypes.DOCUMENT_NUMBER_VERIFICATION_DETAILS_FAIL:
          return fail(
              state,
              "document_number_verification_details",
              action.error?.message
          );

      case actionTypes.CREDIT_NOTE_GET_BEGIN:
          return begin(state, "creditNote_get");
      case actionTypes.CREDIT_NOTE_GET_DONE:
          return done(state, "creditNote_get");
      case actionTypes.CREDIT_NOTE_GET_FAIL:
          return fail(state, "creditNote_get", action.error?.message);

      case actionTypes.CONFIG_GET_BEGIN:
          return begin(state, "config_get");
      case actionTypes.CONFIG_GET_DONE:
          return done(state, "config_get");
      case actionTypes.CONFIG_GET_FAIL:
          return fail(state, "config_get", action.error?.message);

      case actionTypes.VERIFY_EMAIL_BEGIN:
          return begin(state, "email_verify");
      case actionTypes.VERIFY_EMAIL_DONE:
          return done(state, "email_verify");
      case actionTypes.VERIFY_EMAIL_FAIL:
          return fail(state, "email_verify", action.error?.message);

      case actionTypes.FETCH_MERCHANT_ZOMATO_BEGIN:
          return begin(state, "zomato_fetchMerchant");
      case actionTypes.FETCH_MERCHANT_ZOMATO_DONE:
          return done(state, "zomato_fetchMerchant");
      case actionTypes.FETCH_MERCHANT_ZOMATO_FAIL:
          return fail(state, "zomato_fetchMerchant", action.error?.message);

      case actionTypes.AUTO_ONBOARDING_ZOMATO_BEGIN:
          return begin(state, "autoOnboardingZomato");
      case actionTypes.AUTO_ONBOARDING_ZOMATO_DONE:
          return done(state, "autoOnboardingZomato");
      case actionTypes.AUTO_ONBOARDING_ZOMATO_FAIL:
          return fail(state, "autoOnboardingZomato", action.error?.message);

      case actionTypes.MILESTONE_DETAILS_BEGIN:
          return begin(state, "get_milestone_details");
      case actionTypes.MILESTONE_DETAILS_DONE:
          return done(state, "get_milestone_details");
      case actionTypes.MILESTONE_DETAILS_FAIL:
          return fail(state, "get_milestone_details", action.error?.message);

      case actionTypes.MILESTONE_HISTORICAL_DETAILS_BEGIN:
          return begin(state, "get_historical_milestone_details");
      case actionTypes.MILESTONE_HISTORICAL_DETAILS_DONE:
          return done(state, "get_historical_milestone_details");
      case actionTypes.MILESTONE_HISTORICAL_DETAILS_FAIL:
          return fail(
              state,
              "get_historical_milestone_details",
              action.error?.message
          );

      case actionTypes.GET_ZOMATO_STATUS_BEGIN:
          return begin(state, "get_zomato_status");
      case actionTypes.GET_ZOMATO_STATUS_DONE:
          return done(state, "get_zomato_status");
      case actionTypes.GET_ZOMATO_STATUS_FAIL:
          return fail(state, "get_zomato_status", action.error?.message);

      case actionTypes.HAPPY_REGISTER_STEP_THREE_BEGIN:
          return begin(state, "zomato_merchantOnbaord");
      case actionTypes.HAPPY_REGISTER_STEP_THREE_DONE:
          return done(state, "zomato_merchantOnbaord");
      case actionTypes.HAPPY_REGISTER_STEP_THREE_FAIL:
          return fail(state, "zomato_merchantOnbaord", action.error?.message);

      case actionTypes.UPLOAD_ZONBOARD_PAN_IMAGE_BEGIN:
          return begin(state, "zomato_panUpload");
      case actionTypes.UPLOAD_ZONBOARD_PAN_IMAGE_DONE:
          return done(state, "zomato_panUpload");
      case actionTypes.UPLOAD_ZONBOARD_PAN_IMAGE_FAIL:
          return fail(state, "zomato_panUpload", action.error?.message);

      case actionTypes.VERIFY_ZOMATO_PHONE_BEGIN:
          return begin(state, "zomato_verifyPhone");
      case actionTypes.VERIFY_ZOMATO_PHONE_DONE:
          return done(state, "zomato_verifyPhone");
      case actionTypes.VERIFY_ZOMATO_PHONE_FAIL:
          return fail(state, "zomato_verifyPhone", action.error?.message);

      case actionTypes.NOTIFICATIONS_GET_BEGIN:
          return begin(state, "notifications_get");
      case actionTypes.NOTIFICATIONS_GET_DONE:
          return done(state, "notifications_get");
      case actionTypes.NOTIFICATIONS_GET_FAIL:
          return fail(state, "notifications_get", action.error?.message);

      case actionTypes.NOTIFICATIONS_GET_STATUS_BEGIN:
          return begin(state, "notifications_getStatus");
      case actionTypes.NOTIFICATIONS_GET_STATUS_DONE:
          return done(state, "notifications_getStatus");
      case actionTypes.NOTIFICATIONS_GET_STATUS_FAIL:
          return fail(state, "notifications_getStatus", action.error?.message);

      case actionTypes.NOTIFICATIONS_MARK_READ_BEGIN:
          return begin(state, "notifications_markRead");
      case actionTypes.NOTIFICATIONS_MARK_READ_DONE:
          return done(state, "notifications_markRead");
      case actionTypes.NOTIFICATIONS_MARK_READ_FAIL:
          return fail(state, "notifications_markRead", action.error?.message);

      case actionTypes.LOCATION_VALIDATE_BEGIN:
          return begin(state, "locationValidation");
      case actionTypes.LOCATION_VALIDATE_DONE:
          return done(state, "locationValidation");
      case actionTypes.LOCATION_VALIDATE_FAIL:
          return fail(state, "locationValidation", action.error?.message);

      case actionTypes.UPDATE_LOCATION_BEGIN:
          return begin(state, "updateLocation");
      case actionTypes.UPDATE_LOCATION_DONE:
          return done(state, "updateLocation");
      case actionTypes.UPDATE_LOCATION_FAIL:
          return fail(state, "updateLocation", action.error?.message);

      case actionTypes.ALTERNATE_PRODUCTS_BEGIN:
          return begin(state, "products_alternate");
      case actionTypes.ALTERNATE_PRODUCTS_DONE:
          return done(state, "products_alternate");
      case actionTypes.ALTERNATE_PRODUCTS_FAIL:
          return fail(state, "products_alternate", action.error?.message);

      case actionTypes.VALIDATE_PAN_BEGIN:
          return begin(state, "validatePan");
      case actionTypes.VALIDATE_PAN_DONE:
          return done(state, "validatePan");
      case actionTypes.VALIDATE_PAN_FAIL:
          return fail(state, "validatePan", action.error?.message);

      case actionTypes.REFUND_TO_WALLET_BEGIN:
          return begin(state, "refundToWallet");
      case actionTypes.REFUND_TO_WALLET_DONE:
          return done(state, "refundToWallet");
      case actionTypes.REFUND_TO_WALLET_FAIL:
          return fail(state, "refundToWallet", action.error?.message);

      case actionTypes.UPDATE_WHATSAPP_NOTIFICATION_STATUS_BEGIN:
          return begin(state, "updateWhatsappNotificationStatus");
      case actionTypes.UPDATE_WHATSAPP_NOTIFICATION_STATUS_DONE:
          return done(state, "updateWhatsappNotificationStatus");
      case actionTypes.UPDATE_WHATSAPP_NOTIFICATION_STATUS_FAIL:
          return fail(
              state,
              "updateWhatsappNotificationStatus",
              action.error?.message
          );

      case actionTypes.GET_POPUP_BEGIN:
          return begin(state, "getPopupStatus");
      case actionTypes.GET_POPUP_DONE:
          return done(state, "getPopupStatus");
      case actionTypes.GET_POPUP_FAIL:
          return fail(state, "getPopupStatus", action.error?.message);
      case actionTypes.GET_POPUP_INIT:
          return init(state, "getPopupStatus");

      case actionTypes.GET_POPUP_CHECKOUT_BEGIN:
          return begin(state, "getPopupCheckoutStatus");
      case actionTypes.GET_POPUP_CHECKOUT_DONE:
          return done(state, "getPopupCheckoutStatus");
      case actionTypes.GET_POPUP_CHECKOUT_FAIL:
          return fail(state, "getPopupCheckoutStatus", action.error?.message);

      case actionTypes.GET_POPUP_HOME_BEGIN:
          return begin(state, "getPopupHomeStatus");
      case actionTypes.GET_POPUP_HOME_DONE:
          return done(state, "getPopupHomeStatus");
      case actionTypes.GET_POPUP_HOME_FAIL:
          return fail(state, "getPopupHomeStatus", action.error?.message);

      case actionTypes.GET_PAST_ORDERS_BEGIN:
          return begin(state, "user_getPastOrders");
      case actionTypes.GET_PAST_ORDERS_DONE:
          return done(state, "user_getPastOrders");
      case actionTypes.GET_PAST_ORDERS_FAIL:
          return fail(state, "user_getPastOrders", action.error?.message);

      case actionTypes.SHARE_APP_LINK_BEGIN:
          return begin(state, "user_shareAppLink");
      case actionTypes.SHARE_APP_LINK_DONE:
          return done(state, "user_shareAppLink");
      case actionTypes.SHARE_APP_LINK_FAIL:
          return fail(state, "user_shareAppLink", action.error?.message);

      case actionTypes.GET_APP_LINK_BEGIN:
          return begin(state, "user_getAppLink");
      case actionTypes.GET_APP_LINK_DONE:
          return done(state, "user_getAppLink");
      case actionTypes.GET_APP_LINK_FAIL:
          return fail(state, "user_getAppLink", action.error?.message);

      case actionTypes.GET_WALLET_TRANSACTIONS_BEGIN:
          return begin(state, "credit_walletTransactions");
      case actionTypes.GET_WALLET_TRANSACTIONS_DONE:
          return done(state, "credit_walletTransactions");
      case actionTypes.GET_WALLET_TRANSACTIONS_FAIL:
          return fail(
              state,
              "credit_walletTransactions",
              action.error?.message
          );

      case actionTypes.WALLET_RECHARGE_BEGIN:
          return begin(state, "credit_walletRecharge");
      case actionTypes.WALLET_RECHARGE_DONE:
          return done(state, "credit_walletRecharge");
      case actionTypes.WALLET_RECHARGE_FAIL:
          return fail(state, "credit_walletRecharge", action.error?.message);

      case actionTypes.GET_REWARDS_BEGIN:
          return begin(state, "rewards");
      case actionTypes.GET_REWARDS_DONE:
          return done(state, "rewards");
      case actionTypes.GET_REWARDS_FAIL:
          return fail(state, "rewards", action.error?.message);

      case actionTypes.PAY_LATER_UPDATE_BEGIN:
          return begin(state, "payLater_update");
      case actionTypes.PAY_LATER_UPDATE_DONE:
          return done(state, "payLater_update");
      case actionTypes.PAY_LATER_UPDATE_FAIL:
          return fail(state, "payLater_update", action.error?.message);

      case actionTypes.TRACK_CLICK_BEGIN:
          return begin(state, "user_trackClick");
      case actionTypes.TRACK_CLICK_DONE:
          return done(state, "user_trackClick");
      case actionTypes.TRACK_CLICK_FAIL:
          return fail(state, "user_trackClick", action.error?.message);

      case actionTypes.RATING_ORDER_GET_BEGIN:
          return begin(state, "order_rateDetail");
      case actionTypes.RATING_ORDER_GET_DONE:
          return done(state, "order_rateDetail");
      case actionTypes.RATING_ORDER_GET_FAIL:
          return fail(state, "order_rateDetail", action.error?.message);

      case actionTypes.GET_CONSUMER_RAIL_BEGIN:
          return begin(state, "consumer_rail");
      case actionTypes.GET_CONSUMER_RAIL_DONE:
          return done(state, "consumer_rail");
      case actionTypes.GET_CONSUMER_RAIL_FAIL:
          return fail(state, "consumer_rail", action.error?.message);

      case actionTypes.SET_DELIVERY_SLOTS_BEGIN:
          return begin(state, "consumer_setDeliverySlot");
      case actionTypes.SET_DELIVERY_SLOTS_DONE:
          return done(state, "consumer_setDeliverySlot");
      case actionTypes.SET_DELIVERY_SLOTS_FAIL:
          return fail(state, "consumer_setDeliverySlot", action.error?.message);

      case actionTypes.SET_PINCODE_BEGIN:
          return begin(state, "consumer_setPincode");
      case actionTypes.SET_PINCODE_DONE:
          return done(state, "consumer_setPincode");
      case actionTypes.SET_PINCODE_FAIL:
          return fail(state, "consumer_setPincode", action.error?.message);

      case actionTypes.SEMI_SERVICEABLE_BEGIN:
          return begin(state, "consumer_semiServiceable");
      case actionTypes.SEMI_SERVICEABLE_DONE:
          return done(state, "consumer_semiServiceable");
      case actionTypes.SEMI_SERVICEABLE_FAIL:
          return fail(state, "consumer_semiServiceable", action.error?.message);

      case actionTypes.GET_FAQ_BEGIN:
          return begin(state, "user_getFAQ");
      case actionTypes.GET_FAQ_DONE:
          return done(state, "user_getFAQ");
      case actionTypes.GET_FAQ_FAIL:
          return fail(state, "user_getFAQ", action.error?.message);

      case actionTypes.GET_FAQ_ANSWER_BEGIN:
          return begin(state, "user_getFAQAnswer");
      case actionTypes.GET_FAQ_ANSWER_DONE:
          return done(state, "user_getFAQAnswer");
      case actionTypes.GET_FAQ_ANSWER_FAIL:
          return fail(state, "user_getFAQAnswer", action.error?.message);

      case actionTypes.OFFERS_GET_TRIAL_BEGIN:
          return begin(state, "offers_getTrial");
      case actionTypes.OFFERS_GET_TRIAL_DONE:
          return done(state, "offers_getTrial");
      case actionTypes.OFFERS_GET_TRIAL_FAIL:
          return fail(state, "offers_getTrial", action.error?.message);

      case actionTypes.GET_AUTH_OPTIONS_BEGIN:
          return begin(state, "user_authOptions");
      case actionTypes.GET_AUTH_OPTIONS_DONE:
          return done(state, "user_authOptions");
      case actionTypes.GET_AUTH_OPTIONS_FAIL:
          return fail(state, "user_authOptions", action.error?.message);

      case actionTypes.USER_GET_OUTLETS_ONLY_BEGIN:
          return begin(state, "user_getOutletsOnly");
      case actionTypes.USER_GET_OUTLETS_ONLY_DONE:
          return done(state, "user_getOutletsOnly");
      case actionTypes.USER_GET_OUTLETS_ONLY_FAIL:
          return fail(state, "user_getOutletsOnly", action.error?.message);

      case actionTypes.GET_ACQUISITION_RAIL_BEGIN:
          return begin(state, "get_acquisition");
      case actionTypes.GET_ACQUISITION_RAIL_DONE:
          return done(state, "get_acquisition");
      case actionTypes.GET_ACQUISITION_RAIL_FAIL:
          return fail(state, "get_acquisition", action.error?.message);

      case actionTypes.ADD_NPS_RATING_BEGIN:
          return begin(state, "add_nps_rating");
      case actionTypes.ADD_NPS_RATING_DONE:
          return done(state, "add_nps_rating");
      case actionTypes.ADD_NPS_RATING_FAIL:
          return fail(state, "add_nps_rating", action.error?.message);

      case action.UPDATE_NPS_RATING_BEGIN:
          return begin(state, "update_nps_rating");
      case action.UPDATE_NPS_RATING_DONE:
          return done(state, "update_nps_rating");
      case action.UPDATE_NPS_RATING_FAIL:
          return done(state, "update_nps_rating");

      case actionTypes.REQUEST_PRODUCT_BEGIN:
          return begin(state, "requestProduct");
      case actionTypes.REQUEST_PRODUCT_DONE:
          return done(state, "requestProduct");
      case actionTypes.REQUEST_PRODUCT_FAIL:
          return fail(state, "requestProduct", action.error?.message);

      case actionTypes.OUT_OF_STOCK_NOTIFICATION_BEGIN:
          return begin(state, "oosNotify");
      case actionTypes.OUT_OF_STOCK_NOTIFICATION_DONE:
          return done(state, "oosNotify");
      case actionTypes.OUT_OF_STOCK_NOTIFICATION_FAIL:
          return fail(state, "oosNotify", action.error?.message);

      case actionTypes.ADD_NPS_FEEDBACK_BEGIN:
          return begin(state, "addNpsFeedback");
      case actionTypes.ADD_NPS_FEEDBACK_DONE:
          return done(state, "addNpsFeedback");
      case actionTypes.ADD_NPS_FEEDBACK_FAIL:
          return fail(state, "addNpsFeedback", action.error?.message);

      case actionTypes.GET_HOME_BEGIN:
          return begin(state, "get_home");
      case actionTypes.GET_HOME_DONE:
          return done(state, "get_home");
      case actionTypes.GET_HOME_FAIL:
          return fail(state, "get_home", action.error?.message);

      case actionTypes.GET_VERTICAL_PRODUCTS_BEGIN:
          return begin(state, "vertical_products");
      case actionTypes.GET_VERTICAL_PRODUCTS_DONE:
          return done(state, "vertical_products");
      case actionTypes.GET_VERTICAL_PRODUCTS_FAIL:
          return fail(state, "vertical_products", action.error?.message);

      case actionTypes.GET_PRODUCT_DETAIL_BEGIN:
          return begin(state, "product_detail");
      case actionTypes.GET_PRODUCT_DETAIL_DONE:
          return done(state, "product_detail");
      case actionTypes.GET_PRODUCT_DETAIL_FAIL:
          return fail(state, "product_detail", action.error?.message);

      case actionTypes.UPDATE_ACCOUNT_BEGIN:
          return begin(state, "update_account");
      case actionTypes.UPDATE_ACCOUNT_DONE:
          return done(state, "update_account");
      case actionTypes.UPDATE_ACCOUNT_FAIL:
          return fail(state, "update_account", action.error?.message);

      case actionTypes.UPDATE_OUTLET_BEGIN:
          return begin(state, "update_outlet");
      case actionTypes.UPDATE_OUTLET_DONE:
          return done(state, "update_outlet");
      case actionTypes.UPDATE_OUTLET_FAIL:
          return fail(state, "update_outlet", action.error?.message);

      case actionTypes.VALIDATE_COUPON_BEGIN:
          return begin(state, "validateCoupon");
      case actionTypes.VALIDATE_COUPON_DONE:
          return done(state, "validateCoupon");
      case actionTypes.VALIDATE_COUPON_FAIL:
          return fail(state, "validateCoupon", action.error?.message);

      case actionTypes.CLAIM_COUPON_BEGIN:
          return begin(state, "claimCoupon");
      case actionTypes.CLAIM_COUPON_DONE:
          return done(state, "claimCoupon");
      case actionTypes.CLAIM_COUPON_FAIL:
          return fail(state, "claimCoupon", action.error?.message);

      case actionTypes.ADD_UPDATE_PHONE_BEGIN:
          return begin(state, "addOrUpdateOutletPhoneNumber");
      case actionTypes.ADD_UPDATE_PHONE_DONE:
          return done(state, "addOrUpdateOutletPhoneNumber");
      case actionTypes.ADD_UPDATE_PHONE_FAIL:
          return fail(state, "addOrUpdateOutletPhoneNumber", action.error);

      case actionTypes.DELETE_CONTACT_BEGIN:
          return begin(state, "deleteContact");
      case actionTypes.DELETE_CONTACT_DONE:
          return done(state, "deleteContact");
      case actionTypes.DELETE_CONTACT_FAIL:
          return fail(state, "deleteContact", action.error);

      case actionTypes.CANCEL_ORDER_BEGIN:
          return begin(state, "cancelOrder");
      case actionTypes.CANCEL_ORDER_DONE:
          return done(state, "cancelOrder");
      case actionTypes.CANCEL_ORDER_FAIL:
          return fail(state, "cancelOrder", action.error);

      case actionTypes.GET_REASONS_BEGIN:
          return begin(state, "getReasons");
      case actionTypes.GET_REASONS_DONE:
          return done(state, "getReasons");
      case actionTypes.GET_REASONS_FAIL:
          return fail(state, "getReasons", action.error);

      case actionTypes.CHANGE_DELIVERY_SLOT_BEGIN:
          return begin(state, "changeDeliverySlot");
      case actionTypes.CHANGE_DELIVERY_SLOT_DONE:
          return done(state, "changeDeliverySlot");
      case actionTypes.CHANGE_DELIVERY_SLOT_FAIL:
          return fail(state, "changeDeliverySlot", action.error);

      case actionTypes.GENERATE_PAYLOAD_TOKEN_BEGIN:
          return begin(state, "generatePayloadToken");
      case actionTypes.GENERATE_PAYLOAD_TOKEN_DONE:
          return done(state, "generatePayloadToken");
      case actionTypes.GENERATE_PAYLOAD_TOKEN_FAIL:
          return fail(state, "generatePayloadToken", action.error);
      case actionTypes.GENERATE_ACCESS_TOKEN_BEGIN:
          return begin(state, "generateAccessToken");
      case actionTypes.GENERATE_ACCESS_TOKEN_DONE:
          return done(state, "generateAccessToken");
      case actionTypes.GENERATE_ACCESS_TOKEN_FAIL:
          return fail(state, "generateAccessToken", action.error);

      case actionTypes.GET_SOA_OPTIONS_BEGIN:
          return begin(state, "getSOAOptions");
      case actionTypes.GET_SOA_OTPIONS_DONE:
          return done(state, "getSOAOptions");
      case actionTypes.GET_SOA_OPTIONS_FAIL:
          return fail(state, "getSOAOptions");

      case actionTypes.GET_SOA_FILE_PATH_BEGIN:
          return begin(state, "getSOAFilePath");
      case actionTypes.GET_SOA_FILE_PATH_DONE:
          return done(state, "getSOAFilePath");
      case actionTypes.GET_SOA_FILE_PATH_FAIL:
          return fail(state, "getSOAFilePath");

      case actionTypes.UPDATE_INGREDIENT_LIST_BEGIN:
          return begin(state, "update_ingredint_list");
      case actionTypes.UPDATE_INGREDIENT_LIST_DONE:
          return done(state, "update_ingredint_list");
      case actionTypes.UPDATE_INGREDIENT_LIST_FAIL:
          return fail(state, "update_ingredint_list");

      case actionTypes.BOTTOM_TABS_BEGIN:
          return begin(state, "bottom_tabs");
      case actionTypes.BOTTOM_TABS_DONE:
          return done(state, "bottom_tabs");
      case actionTypes.BOTTOM_TABS_FAIL:
          return fail(state, "bottom_tabs");

      case actionTypes.GET_OWNERS_DASHBOARD_BEGIN:
          return begin(state, "getOwnersDashboard");
      case actionTypes.GET_OWNERS_DASHBOARD_DONE:
          return done(state, "getOwnersDashboard");
      case actionTypes.GET_OWNERS_DASHBOARD_FAIL:
          return fail(state, "getOwnersDashboard", "Something went wrong!");

      case actionTypes.SUBMIT_FEEDBACK_BEGIN:
          return begin(state, "submitFeedback");
      case actionTypes.SUBMIT_FEEDBACK_DONE:
          return done(state, "submitFeedback");

      case actionTypes.GET_OWNERS_DASHBOARD_FILE_PATH_BEGIN:
          return begin(state, "getOwnersDashboardFilePath");
      case actionTypes.GET_OWNERS_DASHBOARD_FILE_PATH_DONE:
          return done(state, "getOwnersDashboardFilePath");
      case actionTypes.GET_OWNERS_DASHBOARD_FILE_PATH_FAIL:
          return fail(
              state,
              "getOwnersDashboardFilePath",
              "Something went wrong!"
          );

      case actionTypes.MODIFY_USER_OUTLET_MAPPING_BEGIN:
          return begin(state, "modifyUserOutletMapping");
      case actionTypes.MODIFY_USER_OUTLET_MAPPING_DONE:
          return done(state, "modifyUserOutletMapping");
      case actionTypes.MODIFY_USER_OUTLET_MAPPING_FAIL:
          return fail(state, "modifyUserOutletMapping", action.error?.message);

      case actionTypes.GET_CORPORATE_ANNOUNCEMENTS_BEGIN:
          return begin(state, "getCorporateAnnouncements");
      case actionTypes.GET_CORPORATE_ANNOUNCEMENTS_DONE:
          return done(state, "getCorporateAnnouncements");
      case actionTypes.GET_CORPORATE_ANNOUNCEMENTS_FAIL:
          return fail(
              state,
              "getCorporateAnnouncements",
              action.error?.message
          );

      case actionTypes.GET_ORDER_PAYMENT_INFO_BEGIN:
          return begin(state, "orderPaymentInfo");
      case actionTypes.GET_ORDER_PAYMENT_INFO_DONE:
          return done(state, "orderPaymentInfo");
      case actionTypes.GET_ORDER_PAYMENT_INFO_FAIL:
          return fail(state, "orderPaymentInfo", action.error?.message);

      case actionTypes.HP_PLUS_PAYMENT_INFO_BEGIN:
          return begin(state, "hpPlusPayementInfo");
      case actionTypes.HP_PLUS_PAYMENT_INFO_DONE:
          return done(state, "hpPlusPayementInfo");
      case actionTypes.HP_PLUS_PAYMENT_INFO_FAIL:
          return fail(state, "hpPlusPayementInfo", action.error?.message);

      case actionTypes.UPDATE_ORDER_PAYMENT_INFO_BEGIN:
          return begin(state, "orderPaymentInfo");
      case actionTypes.UPDATE_ORDER_PAYMENT_INFO_DONE:
          return done(state, "orderPaymentInfo");
      case actionTypes.UPDATE_ORDER_PAYMENT_INFO_FAIL:
          return fail(state, "orderPaymentInfo", action.error?.message);

      case actionTypes.ADD_ORDER_ISSUE_BEGIN:
          return begin(state, "addOrderTicket");
      case actionTypes.ADD_ORDER_ISSUE_DONE:
          return done(state, "addOrderTicket");
      case actionTypes.ADD_ORDER_ISSUE_FAIL:
          return fail(state, "addOrderTicket", action.error?.message);

      case actionTypes.UPLOAD_SINGLE_IMAGE_BEGIN:
          return begin(state, "uploadSingleImage");
      case actionTypes.UPLOAD_SINGLE_IMAGE_DONE:
          return done(state, "uploadSingleImage");
      case actionTypes.UPLOAD_SINGLE_IMAGE_FAIL:
          return fail(state, "uploadSingleImage", action.error?.message);

      case actionTypes.ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER_BEGIN:
          return begin(state, "add_user_phone_number_and_reonboard_user");
      case actionTypes.ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER_DONE:
          return done(state, "add_user_phone_number_and_reonboard_user");
      case actionTypes.ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER_FAIL:
          return fail(state, "add_user_phone_number_and_reonboard_user");

      case actionTypes.ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER_VERIFY_OTP_BEGIN:
          return begin(
              state,
              "add_user_phone_number_and_reonboard_user_verify_otp"
          );
      case actionTypes.ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER_VERIFY_OTP_DONE:
          return done(
              state,
              "add_user_phone_number_and_reonboard_user_verify_otp"
          );
      case actionTypes.ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER_VERIFY_OTP_FAIL:
          return fail(
              state,
              "add_user_phone_number_and_reonboard_user_verify_otp"
          );

      case actionTypes.GET_WALLET_RECHARGE_DATA_BEGIN:
          return begin(state, "get_wallet_recharge");
      case actionTypes.GET_WALLET_RECHARGE_DATA_DONE:
          return done(state, "get_wallet_recharge");
      case actionTypes.GET_WALLET_RECHARGE_DATA_FAIL:
          return fail(state, "get_wallet_recharge");

      case actionTypes.GET_MOVE_CART_PRODUCTS_BEGIN:
          return begin(state, "getMoveCartProducts");
      case actionTypes.GET_MOVE_CART_PRODUCTS_DONE:
          return done(state, "getMoveCartProducts");
      case actionTypes.GET_MOVE_CART_PRODUCTS_FAIL:
          return fail(state, "getMoveCartProducts");

      case actionTypes.SET_MOVE_CART_PRODUCTS_BEGIN:
          return begin(state, "setMoveCartProducts");
      case actionTypes.SET_MOVE_CART_PRODUCTS_DONE:
          return done(state, "setMoveCartProducts");
      case actionTypes.SET_MOVE_CART_PRODUCTS_FAIL:
          return fail(state, "setMoveCartProducts");

      case actionTypes.GET_SETTINGS_CONFIG_BEGIN:
          return begin(state, "getSettingsConfig");
      case actionTypes.GET_SETTINGS_CONFIG_DONE:
          return done(state, "getSettingsConfig");
      case actionTypes.GET_SETTINGS_CONFIG_FAIL:
          return fail(state, "getSettingsConfig");

      case actionTypes.UPDATE_TAX_TOGGLE_SELECTION_BEGIN:
          return begin(state, "taxToggle");
      case actionTypes.UPDATE_TAX_TOGGLE_SELECTION_DONE:
          return done(state, "taxToggle");
      case actionTypes.UPDATE_TAX_TOGGLE_SELECTION_FAIL:
          return fail(state, "taxToggle", action.error?.message);

      case actionTypes.SET_POS_LINK_BEGIN:
          return begin(state, "setPosLinkStatus");
      case actionTypes.SET_POS_LINK_DONE:
          return done(state, "setPosLinkStatus");
      case actionTypes.SET_POS_LINK_FAIL:
          return fail(state, "setPosLinkStatus", action.error?.message);

      case actionTypes.ADJUST_PG_CHARGE_BEGIN:
          return begin(state, "adjustPgCharge");
      case actionTypes.ADJUST_PG_CHARGE_DONE:
          return done(state, "adjustPgCharge");
      case actionTypes.ADJUST_PG_CHARGE_FAIL:
          return fail(state, "adjustPgCharge", action.error?.message);

      case actionTypes.HP_PLUS_ADD_REMOVE_BEGIN:
          return begin(state, "hp_plus");
      case actionTypes.HP_PLUS_ADD_REMOVE_DONE:
          return done(state, "hp_plus");
      case actionTypes.HP_PLUS_ADD_REMOVE_FAIL:
          return fail(state, "hp_plus", action.error?.message);

      case actionTypes.HP_PLUS_PAYMENT_INFO_BEGIN:
          return begin(state, "hpPlusPayementInfo");
      case actionTypes.HP_PLUS_PAYMENT_INFO_DONE:
          return done(state, "hpPlusPayementInfo");
      case actionTypes.HP_PLUS_PAYMENT_INFO_FAIL:
          return fail(state, "hpPlusPayementInfo", action.error?.message);

      case actionTypes.ACQUIRE_LOCK_BEGIN:
          return begin(state, "acquireLock");
      case actionTypes.ACQUIRE_LOCK_DONE:
          return done(state, "acquireLock");
      case actionTypes.ACQUIRE_LOCK_FAIL:
          return fail(state, "acquireLock", action.error?.message);

      case actionTypes.GET_RECEIVER_DETAILS_BEGIN:
          return begin(state, "getReceiverDetails");
      case actionTypes.GET_RECEIVER_DETAILS_DONE:
          return done(state, "getReceiverDetails");
      case actionTypes.GET_RECEIVER_DETAILS_FAIL:
          return fail(state, "getReceiverDetails", action.error?.message);

      case actionTypes.SET_RECEIVER_DETAILS_BEGIN:
          return begin(state, "setReceiverDetails");
      case actionTypes.SET_RECEIVER_DETAILS_DONE:
          return done(state, "setReceiverDetails");
      case actionTypes.SET_RECEIVER_DETAILS_FAIL:
          return fail(state, "setReceiverDetails", action.error?.message);

      case actionTypes.GET_ORDER_DETAILS_BEGIN:
      return begin(state, "getOrderDetails");
    case actionTypes.GET_ORDER_DETAILS_DONE:
      return done(state, "getOrderDetails");
    case actionTypes.GET_ORDER_DETAILS_FAIL:
      return fail(state, "getOrderDetails", action.error?.message);

    default:
          return state;
  }
}

export default statusReducer;
