import actionTypes from "../actionTypes";

const initialState = {
  creditData: null,
  walletTransactions: {
    transactions: {},
    nextPage: 1,
    hasMore: true,
  },
};

function creditReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREDIT_GET_DONE:
      return {
        ...state,
        creditData: action.creditData,
      };

    case actionTypes.CREDIT_ONBOARDING_DONE:
      return {
        ...state,
        creditOnboarding: action.creditData,
      };

    case actionTypes.CREDIT_PLANS_DONE:
      return {
        ...state,
        creditPlans: action.creditPlans,
      };

    case actionTypes.GET_VALID_OUTLETS_DONE:
      return {
        ...state,
        validSwitchOutlets: action.validOutletData,
      };

    case actionTypes.GET_VALID_OUTLETS_INIT:
      return {
        ...state,
        validSwitchOutlets: {},
      };

    case actionTypes.GET_CONSUMER_OTP_DONE: {
      return {
        ...state,
        consumerOTP: action.consumerOTP,
      };
    }

    case actionTypes.GET_CONSUMER_OTP_INIT: {
      return {
        ...state,
        consumerOTP: {},
      };
    }

    case actionTypes.VERIFY_CONSUMER_OTP_INIT: {
      return {
        ...state,
        verifyOTP: {},
      };
    }

    case actionTypes.CREDIT_PLANS_INIT: {
      return {
        ...state,
        creditPlans: {},
      };
    }

    case actionTypes.VERIFY_CONSUMER_OTP_DONE: {
      return {
        ...state,
        verifyOTP: action.verifyOTP,
      };
    }
    case actionTypes.GET_WALLET_TRANSACTIONS_DONE:
      const transactions = action.isFirstTime
        ? action.walletTransactions
        : {
            ...state.walletTransactions.transactions,
            WalletEntries: [
              ...state.walletTransactions.transactions.WalletEntries,
              ...action.walletTransactions.WalletEntries,
            ],
          };

      return {
        ...state,
        walletTransactions: {
          ...state.walletTransactions,
          transactions,
          nextPage: action.isFirstTime
            ? 2
            : state.walletTransactions.nextPage + 1,
          hasMore: action.hasNextPage,
        },
      };

    case actionTypes.GET_CREDIT_TRANSACTIONS_DONE:
      if (action.params.PageNo && action.params.PageNo > 1) {
        return {
          ...state,
          creditTxnData: {
            ...state.creditTxnData,
            CreditTransactions: {
              ...state.creditTxnData.CreditTransactions,
              Transactions: [
                ...state.creditTxnData.CreditTransactions.Transactions,
                ...action.payload?.CreditTransactions?.Transactions,
              ],
            },
            HasNextPage: action.payload?.HasNextPage,
          },
        };
      } else {
        return {
          ...state,
          creditTxnData: action.payload,
        };
      }

    case actionTypes.GET_CREDIT_TRANSACTION_DETAIL_DONE:
      return {
        ...state,
        creditTxnDetailData: action.payload,
      };

    case actionTypes.GET_MAKE_CREDIT_PAYMENT_DONE:
      return {
        ...state,
        makeCreditPaymentData: action.payload,
      };

    case actionTypes.GET_CREDIT_TRANSACTION_DETAIL_INIT:
      return {
        ...state,
        creditTxnDetailData: {},
      };

    case actionTypes.GET_CREDIT_PAYMENTS_DONE:
      return {
        ...state,
        creditPayments: action.payload,
      };

    case actionTypes.GET_CREDIT_PAYMENTS_INIT:
      return {
        ...state,
        creditPayments: {},
      };

    case actionTypes.TOGGLE_CREDIT_MODAL_DONE:
      return {
        ...state,
        showCreditPaymentModal: action.payload,
      };

    case actionTypes.GET_CREDIT_PAYMENTS_V2_INIT:
      return {
        ...state,
        creditPaymentsV2: {},
      };

    case actionTypes.GET_CREDIT_PAYMENTS_V2_DONE:
      return {
        ...state,
        creditPaymentsV2: action.payload,
      };

    case actionTypes.GET_CREDIT_PAYMENTS_V2_FAIL:
      return {
        ...state,
        creditPaymentsV2: {},
      };

    case actionTypes.CREATE_SERVICE_ORDER_BEGIN:
      return {
        ...state,
        createServiceOrder: {},
      };

    case actionTypes.CREATE_SERVICE_ORDER_DONE:
      return {
        ...state,
        createServiceOrder: action.payload,
      };

    case actionTypes.CREATE_SERVICE_ORDER_FAIL:
      return {
        ...state,
        createServiceOrder: {},
      };

    case actionTypes.GET_WALLET_RECHARGE_DATA_BEGIN:
      return {
        ...state,
        walletRechargeData: {},
      };

    case actionTypes.GET_WALLET_RECHARGE_DATA_DONE:
      return {
        ...state,
        walletRechargeData: action.payload,
      };

    case actionTypes.GET_WALLET_RECHARGE_DATA_FAIL:
      return {
        ...state,
        walletRechargeData: {},
      };

    default:
      return state;
  }
}

export default creditReducer;
