import _get from "lodash-es/get";

import getAxios from "../../utils/axios";
import APIendpoints from "../../utils/constants/APIendpoints";
import actionTypes from "../actionTypes";

import { getCookie, getESV2FlagFromQueryParam, getBase64, getCookieForEntity } from "../../utils/helpers";
import EsV2QueryParams from "../../utils/constants/EsV2QueryParams";

// const esV2CategoriesFlag = getESV2FlagFromQueryParam(EsV2QueryParams.ES_V2_CATEGORIES_FLAG)
// const esV2AutosuggestFlag = getESV2FlagFromQueryParam(EsV2QueryParams.ES_V2_AUTOSUGGEST_FLAG)
// const esV2SearchFlag = getESV2FlagFromQueryParam(EsV2QueryParams.ES_V2_SEARCH_FLAG)
const esV2SearchFlag = true

function addPageToProducts(products = [], pageNo) {
  return products.map(product => ({...product, pageNo}))
}

function getAllProducts(
  pageNo,
  isFirstTime,
  productId = "",
  searchKeyword = "",
  referenceId = "",
  referenceType = ""
) {
  return (dispatch) => {
    dispatch({ type: actionTypes.CATALOG_GET_ALL_PRODUCTS_BEGIN });
    getAxios()
      .get(
        APIendpoints.getProducts({
          query: productId ? "" : searchKeyword,
          pageNo: pageNo,
          productIds: productId ? productId : "",
          referenceType: referenceType,
          referenceId: referenceId,
          esV2Flag: esV2SearchFlag,
        })
      )
      .then(({ data }) => {
        dispatch({
          type: actionTypes.CATALOG_GET_ALL_PRODUCTS_DONE,
          products: addPageToProducts(data.response.Products || [], pageNo),
          hasNextPage: data.response.HasNextPage,
          isFirstTime: isFirstTime,
          totalCartCount: data.response.TotalCartCount,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.CATALOG_GET_ALL_PRODUCTS_FAIL,
          error:  _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  };
}

function getAllProductsForBuyer(
  outletId,
  pageNo,
  isFirstTime,
  referenceId = "",
  referenceType = ""
) {
  return (dispatch) => {
    dispatch({ type: actionTypes.CATALOG_GET_ALL_PRODUCTS_FOR_BUYER_BEGIN });
    getAxios(true)
      .get(
        APIendpoints.getProducts({
          outletId: outletId,
          pageNo: pageNo,
          referenceType: referenceType,
          referenceId: referenceId,
          esV2Flag: esV2SearchFlag
        })
      )
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.CATALOG_GET_ALL_PRODUCTS_FOR_BUYER_DONE,
          products: addPageToProducts(data.response.Products || [], pageNo),
          isFirstTime: isFirstTime,
          hasNextPage: data.response.HasNextPage,
          totalCartCount: data.response.TotalCartCount,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.CATALOG_GET_ALL_PRODUCTS_FOR_BUYER_FAIL,
          error:  _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  };
}

function getCategoryProducts(
  selectedCategoryId,
  pageNo,
  isFirstTime,
  referenceId = "",
  referenceType = ""
  ) {
  const productsRequestStartTime = new Date().getTime();

  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.CATALOG_GET_CATEGORY_PRODUCTS_BEGIN,
      isFirstTime: isFirstTime,
      categoryProductsRequestStartTime: productsRequestStartTime,
    });
    getAxios()
      .get(
        APIendpoints.getProducts({
          pageNo: pageNo,
          categoryIds: "" + selectedCategoryId,
          referenceType: referenceType,
          referenceId: referenceId,
          esV2Flag: esV2SearchFlag,
        })
      )
      .then(({ data, headers }) => {
        const state = getState();

        if (
          state.catalog.categoryProductsRequestStartTime ===
          productsRequestStartTime
        ) {
          dispatch({
            type: actionTypes.CATALOG_GET_CATEGORY_PRODUCTS_DONE,
            products: addPageToProducts(data.response.Products || [], pageNo),
            isFirstTime: isFirstTime,
            hasNextPage: data.response.HasNextPage,
            totalCartCount: data.response.totalCartCount
          });
        }
      })
      .catch((err) => {
        const state = getState();

        if (
          state.catalog.categoryProductsRequestStartTime ===
          productsRequestStartTime
        ) {
          dispatch({
            type: actionTypes.CATALOG_GET_CATEGORY_PRODUCTS_FAIL,
            error: _get(
              err,
              ["response", "data", "error", "message"],
              "Something went wrong!"
            ),
          });
        }
      });
  };
}

function getCategoryProductsForBuyer(
  outletId,
  selectedCategoryId,
  pageNo,
  isFirstTime,
  referenceId = "",
  referenceType = "",
  superSaver = false
) {
  const productsRequestStartTime = new Date().getTime();

  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.CATALOG_GET_CATEGORY_PRODUCTS_FOR_BUYER_BEGIN,
      isFirstTime: isFirstTime,
      categoryProductsRequestStartTime: productsRequestStartTime,
    });
    getAxios(true)
      .get(
        APIendpoints.getProducts({
          outletId: outletId,
          pageNo: pageNo,
          categoryIds: "" + selectedCategoryId,
          referenceType: referenceType,
          referenceId: referenceId,
          onOffer: superSaver ? "superSaver" : "",
          esV2Flag: esV2SearchFlag,
        })
      )
      .then(({ data, headers }) => {
        const state = getState();

        if (
          state.catalog.categoryProductsRequestStartTime ===
          productsRequestStartTime
        ) {
          dispatch({
            type: actionTypes.CATALOG_GET_CATEGORY_PRODUCTS_FOR_BUYER_DONE,
            products: addPageToProducts(data.response.Products || [], pageNo),
            hasNextPage: data.response.HasNextPage,
            isFirstTime: isFirstTime,
            totalCartCount: data.response.TotalCartCount
          });
        }
      })
      .catch((err) => {
        const state = getState();

        if (
          state.catalog.categoryProductsRequestStartTime ===
          productsRequestStartTime
        ) {
          dispatch({
            type: actionTypes.CATALOG_GET_CATEGORY_PRODUCTS_FOR_BUYER_FAIL,
            error: _get(
              err,
              ["response", "data", "error", "message"],
              "Something went wrong!"
            ),
          });
        }
      });
  };
}

function getBannerProductsForBuyer(
  outletId,
  bannerProducts,
  pageNo,
  isFirstTime,
  referenceId = "",
  referenceType = ""
) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CATALOG_GET_BANNER_PRODUCTS_FOR_BUYER_BEGIN,
    });
    getAxios(true)
      .get(
        APIendpoints.getProducts({
          outletId: outletId,
          pageNo: pageNo,
          productNumbers: bannerProducts,
          referenceType: referenceType,
          referenceId: referenceId,
          esV2Flag: esV2SearchFlag
        })
      )
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.CATALOG_GET_BANNER_PRODUCTS_FOR_BUYER_DONE,
          products: addPageToProducts(data.response.Products || [], pageNo),
          hasNextPage: data.response.HasNextPage,
          isFirstTime: isFirstTime,
          totalCartCount: data.response.TotalCartCount
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.CATALOG_GET_BANNER_PRODUCTS_FOR_BUYER_FAIL,
          error:  _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  };
}

function v2_getCategoriesWithSubCategories(
  isAuth = false,
  accountId = 0,
  visibleOnly = false,
  activeOnly = false,
  outletCityId = 0
) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.V2_CATALOG_GET_CATEGORIES_AND_SUB_CATEGORIES_BEGIN,
    });
    getAxios(isAuth)
      .get(
        APIendpoints.v2_getCategoriesAndSubCategories(
          accountId,
          // visibleOnly,
          false,
          activeOnly,
          outletCityId,
          true
        )
      )
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.V2_CATALOG_GET_CATEGORIES_AND_SUB_CATEGORIES_DONE,
          // Catogory with initials "mi" is MS Ingredients (which we don't want to display to users)
          categories: (data.response.categories || []).filter(
            (el) => el.code.toLowerCase() !== "mi"
          ),
          superSaverRail: data.response.offerCategoryRail
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.V2_CATALOG_GET_CATEGORIES_AND_SUB_CATEGORIES_FAIL,
          error: _get(
            err,
            ["response", "data", "error", "message"],
            "Some error"
          ),
        });
      });
  };
}

function getCategories(auth , callBack) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_CATEGORIES_BEGIN
    })
    getAxios(auth)
      .get(APIendpoints.v3_getCategoriesAndSubCategories())
      .then(({data}) => {
        dispatch({
          type: actionTypes.GET_CATEGORIES_DONE,
          payload: data.response.Categories
        })
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_CATEGORIES_FAIL,
          error: _get(
            err,
            ["response", "data", "error", "message"],
            "Some error"
          ),
        });
      })
  }
}

function resetGetEntities() {
  return (dispatch) =>{
    dispatch({type: actionTypes.GET_ENTITIES_INIT})
  }
}

function getEntities(queryParams, withoutAuth ,entityType) {
  return (dispatch) => {
    if (queryParams?.length){
      dispatch({
        type: actionTypes.GET_ENTITIES_BEGIN
      })
      getAxios(withoutAuth ? false : true)
          .get(APIendpoints.getEntitySubCategories(queryParams))
          .then(({data}) => {
            dispatch({
              type: actionTypes.GET_ENTITIES_DONE,
              payload: data.response.Entities || [],
              hideSideMenu: data.response?.HideSideMenu || false,
              switchCategoriesCTA: data.response?.SwitchCategoriesCTA || false,
              entityType:entityType,
              emptyState: data.response.EmptyState,
            })
          })
          .catch((err) => {
            dispatch({
              type: actionTypes.GET_ENTITIES_FAIL,
              error: _get(
                  err,
                  ["response", "data", "error", "message"],
                  "Some error"
              ),
            });
          })
    }
    }
}

function getAlternateProducts(queryString) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ALTERNATE_PRODUCTS_BEGIN,
    });
    getAxios(true)
      .get(APIendpoints.getProductsWithQuery(`?${queryString}`, esV2SearchFlag,false,true))
      .then(({data, headers}) => {
        dispatch({
          type: actionTypes.ALTERNATE_PRODUCTS_DONE,
          data: data.response.Products || [],
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ALTERNATE_PRODUCTS_FAIL,
          error:  _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  };
}

function resetAlternateProducts() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_ALTERNATE_PRODUCTS,
    });
  };
}

function refreshSearchProducts() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REFRESH_CATALOG_SEARCH_PRODUCTS_FOR_BUYER,
    });
  };
}

function searchProductsForBuyer(
  outletId,
  searchKeyword,
  productId,
  pageNo,
  isFirstTime,
  referenceId = "",
  referenceType = "",
  searchDebugFlag = false,
  otherParams = "",
) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CATALOG_SEARCH_PRODUCTS_FOR_BUYER_BEGIN,
    });
    getAxios(true)
      .get(
        APIendpoints.getProducts({
          query: productId ? "" : encodeURIComponent(searchKeyword),
          outletId: outletId,
          pageNo: pageNo,
          productIds: productId ? productId : "",
          referenceType: referenceType,
          referenceId: referenceId,
          esV2Flag: esV2SearchFlag,
          searchDebugFlag: searchDebugFlag,
          otherParams: otherParams
        })
      )
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.CATALOG_SEARCH_PRODUCTS_FOR_BUYER_DONE,
          products: addPageToProducts(data.response.Products || [], pageNo),
          hasNextPage: data.response.HasNextPage,
          isFirstTime: isFirstTime,
          totalCartCount: data.response.TotalCartCount,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.CATALOG_SEARCH_PRODUCTS_FOR_BUYER_FAIL,
          error:  _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  };
}

function updateUrlParameter(url, param, value) {
  if (!url.includes(param)) {
    return url + "&" + param + "=" + value
  }
  let regex = new RegExp("(" + param + "=)[^&]+");
  return url.replace(regex, "$1" + value);
}

function setFilters (filterIdMap){
  return (dispatch) => {
    dispatch({
      type: actionTypes.FILTERS_SET,
      filterIdMap: filterIdMap,
    });
  }
}


function getFilters (query, auth ) {
  const getFiltersEndpoint = APIendpoints.v2_getFilters(query, esV2SearchFlag);
  const queryString = getBase64(getFiltersEndpoint);
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_FILTERS_BEGIN,
    });

    getAxios(auth)
      .get(APIendpoints.getFilters(query, esV2SearchFlag ))
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.GET_FILTERS_DONE,
          Filters: data?.response?.Filters,
          NoSearchResultFound: data?.response?.NoSearchResultFound,
          QueryString: queryString 
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_FILTERS_FAIL,
          error:  _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  };
}

function searchProductsForBuyerWithQuery(query, pageNo, isFirstTime, updatedCartCount = null, auth , debug, callback  = () => {}) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CATALOG_SEARCH_PRODUCTS_FOR_BUYER_BEGIN,
    });
    const queryString = updateUrlParameter(query, "pageNo", pageNo);
    getAxios(auth)
      .get(APIendpoints.getProductsWithQuery(queryString, esV2SearchFlag ,debug))
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.CATALOG_SEARCH_PRODUCTS_FOR_BUYER_DONE,
          products: addPageToProducts(data.response.Products || [], pageNo),
          hasNextPage: data.response.HasNextPage,
          isFirstTime: isFirstTime,
          updatedCartCount: updatedCartCount ? data.response.TotalCartCount : null
        });
        callback();
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.CATALOG_SEARCH_PRODUCTS_FOR_BUYER_FAIL,
          error:  _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  };
}

function getPromoProducts(
  outletId,
  pageNo,
  isFeatured,
  isFirstTime,
  referenceId = "",
  referenceType = "",
  trial = null
) {
  let status = trial === "trial" ? "OFFERS_GET_TRIAL" : (isFeatured ? "OFFERS_GET_FEATURED" : "OFFERS_GET_ALL");
  return (dispatch) => {
    dispatch({
      type: status + "_BEGIN"
    });
    getAxios(true)
      .get(
        APIendpoints.getProducts({
          outletId: outletId,
          pageNo: pageNo,
          onOffer: trial ? "trial" : (isFeatured ? "featured" : "all"),
          referenceType: referenceType,
          referenceId: referenceId,
          esV2Flag: esV2SearchFlag,
        })
      )
      .then(({ data, headers }) => {
        dispatch({
          type: status + "_DONE",
          products: addPageToProducts(data.response.Products || [], pageNo),
          hasNextPage: data.response.HasNextPage,
          isFirstTime: isFirstTime,
          trial
        });
      })
      .catch((err) => {
        dispatch({
          type: status + "_FAIL",
          error:  _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  };
}

function getSearchSuggestions({query, outletId, href}, isFirstChange, saveAutoSuggestReponse) {
  const searchSuggestionsRequestStartTime = new Date().getTime();

  return (dispatch, getState) => {
    if (window.location.href === href) {
      if (!saveAutoSuggestReponse){
      dispatch({
        type: actionTypes.CATALOG_GET_SEARCH_SUGGESTIONS_BEGIN,
        searchSuggestionsRequestStartTime: searchSuggestionsRequestStartTime,
      });
    }

      getAxios(getCookieForEntity("token","posToken") ? true : false)
        .get(APIendpoints.getSearchSuggestions(encodeURIComponent(query), outletId, true))
        .then(({ data, headers }) => {
          if (saveAutoSuggestReponse){
            dispatch({
              data: data.response.suggested_verticals,
              type: actionTypes.GET_SEARCH_BAR_VERTICALS,
            });
          }else{
          const state = getState();
          if (
            state.catalog.searchSuggestions
            .searchSuggestionsRequestStartTime ===
            searchSuggestionsRequestStartTime
          ) {
            const result = {
              products: [],
              categories: [],
              subCategories: [],
            };

            if (data.response.sections) {
              data.response.sections.forEach((el) => {
                el.suggestions.forEach((sugg, suggIndex) => {
                  if (suggIndex === 0) {
                    sugg.grouping = el.heading
                    sugg.groupingStart = true
                  }

                  if(suggIndex === el.suggestions.length - 1){
                    sugg.groupingEnd = true
                  }
                  sugg.displayType = el.displayType;
                  sugg.referenceType = el.referenceType;
                  result.products.push(sugg);
                })
              });
            }

            dispatch({
              ...result,
              data: data.response,
              isFirstChange,
              type: actionTypes.CATALOG_GET_SEARCH_SUGGESTIONS_DONE,
            });
          }
        }
        })
        .catch((err) => {
          if (!saveAutoSuggestReponse){
          const state = getState();

          if (
            state.catalog.searchSuggestions
            .searchSuggestionsRequestStartTime ===
            searchSuggestionsRequestStartTime
          ) {
            dispatch({
              type: actionTypes.CATALOG_GET_SEARCH_SUGGESTIONS_FAIL,
              error: _get(
                err,
                ["response", "data", "error", "message"],
                "Something went wrong!"
              ),
            });
          }
        }
        });
    }
  };
}

function getReqSearchSuggestions(query) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REQUEST_PRODUCT_SUGGESION_BEGIN
    })
    getAxios(getCookieForEntity("token","posToken") ? true : false)
      .get(APIendpoints.getReqSearchSuggestions(query, true))
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.REQUEST_PRODUCT_SUGGESION_DONE,
          payload: data.response.defaultSuggestion
        })
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.REQUEST_PRODUCT_SUGGESION_FAIL,
          error:  _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  }
}

function addAutoSuggestHistory(data) {
  return (dispatch) => {
    getAxios(true)
      .post(
        APIendpoints.autoSuggestHistory, data
      )
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.ADD_AUTO_SUGGEST_HISTORY
        });
      })
      .catch((err) => {});
  };
}

function resetOffers() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_OFFERS
    });
  };
}

function resetSearchSuggestions(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_SEARCH_SUGGESTION
    });
  };
}

function clearHistory() {
  return (dispatch) => {
    getAxios(true)
      .delete(
        APIendpoints.clearHistory
      )
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.CLEAR_AUTO_SUGGEST_HISTORY
        });
      })
      .catch((err) => {});
  };
}

function clearSuggestion() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REQUEST_PRODUCT_SUGGESTION_CLEAR
    })
  }
}

function notifyOutOfStock(data,source) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.OUT_OF_STOCK_NOTIFICATION_BEGIN
    })
    getAxios(true)
      .post(APIendpoints.notifyOutOfStock, data)
      .then(({ data, headers }) => {
        if(source!="NisCardForBuyer" && source!="PDP"){
          dispatch({
            type: actionTypes.OUT_OF_STOCK_NOTIFICATION_DONE,
            payload: data.response.defaultSuggestion
          })
        } 
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.OUT_OF_STOCK_NOTIFICATION_FAIL,
          error:  _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  }
}

function getVerticalProducts(railMeta = []) {

  let promises = railMeta.map(r => {
    return getAxios(true).get(r.Endpoint)
  })

  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_VERTICAL_PRODUCTS_BEGIN
    })
    Promise.all(promises)
      .then((data) => {
        let payload = {}
        for (let i = 0; i<data.length; i++) {
          payload = {
            ...payload,
            [railMeta[i].Heading]: data[i].data.response.Products
          } 
        }
        dispatch({
          type: actionTypes.GET_VERTICAL_PRODUCTS_DONE,
          payload
        })
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_VERTICAL_PRODUCTS_FAIL,
          error:  _get(
            err,
            ["response", "data", "error"],
            {}
          )
        });
      });
  }
}

function getProductDetailMeta(productId, source) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_PRODUCT_DETAIL_BEGIN
    })
    getAxios(true)
      .get(APIendpoints.getProductDescMeta(productId, source))
      .then(({ data }) => {
        dispatch({
          type: actionTypes.GET_PRODUCT_DETAIL_DONE,
          payload: data.response
        })
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_PRODUCT_DETAIL_FAIL,
          error:  _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  }
}

function toggleSimilarItemsModal (data, isOpen) {
  return dispatch => {
    dispatch({ 
      type: actionTypes.TOGGLE_SIMILAR_ITEMS_MODAL, 
      payload: {
        data: data,
        isOpen: isOpen
      } 
    });
  }
}

function resetFilter () {
  return dispatch => {
    dispatch({ 
        type: actionTypes.GET_FILTERS_DONE,
        Filters: [],
        NoSearchResultFound: {},
    });
  }
}

function updateIngredientList(param, successCallback, errCallBack) {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_INGREDIENT_LIST_BEGIN })
    getAxios(true)
      .put(APIendpoints.updateIngredientList, param)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.UPDATE_INGREDIENT_LIST_DONE,
          payload: data,
        })
        if (typeof successCallback === "function") {
          successCallback(data);
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.UPDATE_INGREDIENT_LIST_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        })
      })
  }
}

export {
  getAllProducts,
  getCategoryProducts,
  getAllProductsForBuyer,
  getCategoryProductsForBuyer,
  searchProductsForBuyer,
  getBannerProductsForBuyer,
  getPromoProducts,
  v2_getCategoriesWithSubCategories,
  getSearchSuggestions,
  getReqSearchSuggestions,
  clearSuggestion,
  searchProductsForBuyerWithQuery,
  refreshSearchProducts,
  getAlternateProducts,
  resetAlternateProducts,
  addAutoSuggestHistory,
  resetSearchSuggestions,
  clearHistory,
  resetOffers,
  notifyOutOfStock,
  getVerticalProducts,
  getCategories,
  getProductDetailMeta,
  getEntities,
  resetGetEntities,
  toggleSimilarItemsModal,
  getFilters,
  setFilters,
  resetFilter,
  updateIngredientList,
  esV2SearchFlag
};
