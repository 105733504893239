import React, { useCallback, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {getValidOutletsToSwitch, clearValidOutlets} from "../../../redux/dispatchers/creditDispatcher";
import {switchOutlet ,trackPopUp} from "../../../redux/dispatchers/userDispatcher";
import { useEffect } from "react";
import Modal from "sushiweb/Modal";
import styles from "./Header.module.scss";
import Input from "sushiweb/InputTypes/Input/Input";
import Image from "../Image/Image";
import { debounce } from "lodash-es";
import stateTypes from "../../../utils/constants/stateTypes";
import SpinnerCircular from "sushiweb/Spinner/SpinnerCircular";
import red from "sushiweb/tokens/color/red";
import { clearLocalStorage } from "./../../../utils/helpers";

const SwitchOutletModal = ({
    IsOpen,
    OnClose, 
    Source,
    actions,
    validSwitchOutlets,
    getValidOutletStatus,
    outletSwitchStatus,
    outletSwitch,
    history
}) => {
    // states
    const [showScrollStateModal, setShowScrollStateModal] = useState(false);
    const [keyWord, setKeyWord] = useState("");
    const [initialSearch, setInitalSearch] = useState(null);

    // component updates

    // get valid outlets to switch on modal open
    useEffect(()=>{
        if (IsOpen){
            actions.getValidOutletsToSwitch(Source);
        }
    },[IsOpen])

    // on outlet switch success reload the page
    useEffect(() => {
        if (outletSwitchStatus.state === stateTypes.STATE_DONE) {
            OnClose(history, true);
        }
    }, [outletSwitchStatus]);

    // search outlets by keyword
    useEffect(()=>{
        getOutletsSearch(Source, keyWord)
    },[keyWord]);

    // set initial search
    useEffect(()=>{
        if (getValidOutletStatus.state === stateTypes.STATE_DONE && initialSearch === null ) {
            setInitalSearch(validSwitchOutlets.ShowSearch);
        }
    },[getValidOutletStatus])


    // handlers
    const closeHandler = () => {
        OnClose(history, false);
        actions.clearValidOutlets()
        setKeyWord("")
    }

    const getOutlets = (Source, keyWord) => {
        actions.getValidOutletsToSwitch(Source, null, keyWord)
    }

    const getOutletsSearch = useCallback(debounce((Source, keyWord) => {
        getOutlets(Source, keyWord)
    }, 500), []);



    const handleScroll = (e) => {
        if (e.target.scrollTop > 5 && validSwitchOutlets.ShowSearch) {
            setShowScrollStateModal(true)
        } else {
            setShowScrollStateModal(false)
        }
    }

    return (
        <div>
            <Modal
              visible={IsOpen}
              onClose={() => closeHandler()}
              isCentered
              className={styles.modalRadius}
            >
                  <div className={styles.filterModal}>
            <div >
            <div className={`fs-30 w-800 d-flex justify-content-between align-items-center ${styles.pd24}  ${showScrollStateModal ? styles.topHeaderBgWhite : ""}`}>Switch outlet
            <img onClick={closeHandler} src = {process.env.PUBLIC_URL+ "/assets/images/cross.png"} className={`${styles.img32} pointer` }/>
            </div>
            </div>
            {initialSearch ? 
             <div className={`${styles.px24} ${styles.paddingBottom0} relative ${showScrollStateModal ? styles.boxShadowTop : ""}`}>
                   <img 
           className={`${styles.img16} ${styles.iconSearch}`}
            src= {process.env.PUBLIC_URL+"/assets/images/searchThick.png"}
           />
                <Input className={`${styles.searchableInput}`} label={"Search outlet name"} hideLabel value= {keyWord} onChange={(val)=> {setKeyWord(val)}}/> 
                </div>     
                :<></>}
            {
               getValidOutletStatus.state === stateTypes.STATE_BEGIN ?  <div className={`d-flex justify-content-center mt-1 mb-1 absolute ${styles.centralSpinner}`}><SpinnerCircular color={red.z500} size="large"/></div> : <></>
            }
            <div onScroll={handleScroll} className={`${initialSearch ? styles.dataBox: styles.dataBoxNonSearch} ${styles.px24}`}>
                {validSwitchOutlets.Accounts?.length ? 
                    validSwitchOutlets.Accounts.map((account) => 
                    <div className={styles.dataBox2}>
                        {validSwitchOutlets.Accounts?.length > 1 ? <div className={`d-flex justify-content-center fs-16 w-600 ${styles.padding16px} ${styles.borderBottomAccount} ${styles.padding24X} ${styles.colorGreyText}`}>{account.OrganizationName}</div> : <></>}
                        {
                            account.Outlets.map((outlet ,index) => 
                            <div  key={outlet.Id} 
                             onClick={()=>{actions.switchOutlet(outlet.Id); actions.trackPopUp({
                                            ActionType : "click",
                                            Element: "switch_outlet",
                                            ReferenceId : outlet.Id,
                                            ReferenceType: "Switched_outlet_id",
                                            Source: outletSwitch.data?.Source
                             }); clearLocalStorage();}}
                            className={`d-flex justify-content-between align-items-center pointer ${styles.padding24pxTopBottom}  ${styles.padding24X} ${index === account.Outlets?.length -1 ? "": styles.borderDotted}`}>
                                <div className="wd-90">
                                    {outlet.Status !== "Active" ? <div className={`${styles.inactiveTag} fs-12 w-600 mb-1`}>{outlet.Status}</div> : <></>}
                                    <div className={`fs-20 w-800 ${styles.truncate}`}>{outlet.OutletName}</div>
                                    <div className={`fs-16 w-400 wd-100 ${styles.colorGreyText} ${styles.truncate}`}>{outlet.Address}</div>
                                </div>
                                <div>
                                    <Image source={process.env.PUBLIC_URL + "/assets/images/ChevronRightRed.png"} classname={styles.img24}/>
                                </div>
                            </div>
                            )
                        }
                    </div>
                    )
                    : <></>
                }
                {
                    validSwitchOutlets.InvalidStateData ? 
                    <div className="d-flex flex-column justify-content-between align-items-center">
                    <Image classname={`${styles.img200px} mt-4`} source= { validSwitchOutlets.InvalidStateData.Image}/>
                    <div className={`fs-18 w-800 mt-3 ${styles.indigoColor}`}>{ validSwitchOutlets.InvalidStateData.Heading}</div>
                    <div className={`fs-14 w-400 mt-1 ${styles.indigoColor}`}>{ validSwitchOutlets.InvalidStateData.SubHeading}</div>
                </div>: <></>
                }
            </div>
            </div>

            </Modal>
        </div>
    )
}

export default withRouter(
    connect(
      (state) => ({
            validSwitchOutlets: state.credit.validSwitchOutlets || {},
            getValidOutletStatus: state.status.get_valid_outlets,
            outletSwitchStatus: state.status.user_outlet_switch,
            outletSwitch: state.user.outletSwitch || {},
      }),
      (dispatch) => ({
        actions: bindActionCreators({
            getValidOutletsToSwitch,
            clearValidOutlets,
            switchOutlet,
            trackPopUp
        },
          dispatch
        ),
      })
    )(SwitchOutletModal)
  );
  