import React from "react";
import Carousel from "react-multi-carousel";
import { connect } from "react-redux";

import RailCardForBuyer from "../../buyer/catalogForBuyer/RailCardForBuyer/RailCardForBuyer";
import { withRouter } from "react-router-dom";
import styles from "./Promotions.module.scss";
import "react-multi-carousel/lib/styles.css";
import { ButtonGroup } from "../../shared/CarouselArrow/CarouselArrow";
import {
  ALTERNATE_PRODUCT_RAIL,
  getRailConfig,
  PRODUCTS_RAIL,
  PRODUCT_REQUEST_RAIL,
  SIMILAR_PRODUCTS,
} from "../../../utils/constants/RailConfig";
import Image from "../Image/Image";
import CatalogCardForBuyer from "../../buyer/catalogForBuyer/catalogCardForBuyer/CatalogCardForBuyer";
import { handleActionType } from "../../../utils/helpers";
import MultiCarousel from "../carousel/MultiCarousel";
import CarouselWrapper from "../carousel/CarouselWrapper";
import { round } from "lodash-es";

class PromotionsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      modalImgSrc: "",
      modalTitle: "",
    };
  }

  shouldComponentUpdate = (prevProps, prevState) => {
    if (
      JSON.stringify(prevProps.promotionsData) ===
        JSON.stringify(this.props.promotionsData) &&
      prevProps.promotionProductsStatus?.state ===
        this.props.promotionProductsStatus?.state &&
      JSON.stringify(this.props.railMeta) === JSON.stringify(prevProps.railMeta)
    ) {
      return false;
    }
    return true;
  };

  checkHeader(items,length) {
    let n= length ;
    for(var i=0;i<n;i++){
      if(items[i]?.DeliveryByV2 || (items[i] && !items[i]?.IsInStock)){
        return true
      }
    }
    return false
  }

  checkHeaderMulti(items,length,index) {
    if(index==0){
      for(var i=0;i<length;i+=2){
        if(items[i]?.DeliveryByV2 || (items[i] && !items[i]?.IsInStock)){
          return true
        }
      }
      return false
    }
    else{
      for(var i=1;i<length;i+=2){
        if(items[i]?.DeliveryByV2 || (items[i] && !items[i]?.IsInStock)){
          return true
        }
      }
      return false
    }
  }

  findTitleSize(items,length) {
    let n= length ;
    let x=1;
    for(var i=0;i<n;i++){
      if(items[i].Name.length>60){
        return 3;
      }
      if(items[i].Name.length>30){
        x=2;
      }
    }
    return x;
  }

  findTitleSizeMulti(items,length,index) {
    let x=1;
    if(index==0){
      for(var i=0;i<length;i+=2){
        if(items[i].Name.length>60){
          return 3;
        }
        if(items[i].Name.length>30){
          x=2;
        }
      }
      return x;
    }
    else {
      for(var i=1;i<length;i+=2){
        if(items[i].Name.length>60){
          return 3;
        }
        if(items[i].Name.length>30){
          x=2;
        }
      }
      return x;
    }
  }

  openModal = (event, title, src) => {
    event.stopPropagation();
    this.setState((prevState) => ({
      ...prevState,
      modalTitle: title,
      modalVisible: true,
      modalImgSrc: src,
    }));
  };

  closeModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      modalVisible: false,
      modalTitle: "",
    }));
  };

  getData(data) {
    const products = this.props.promotionsData.map((ele, idx) => {
      if (this.props.type !== "alternateProducts" && !ele.IsInStock) {
        return null;
      }
      const filteredData = data.filter((product) => {
        if (product.Id === ele.Id) {
          return true;
        }
        return false;
      });

      if (filteredData.length > 0) {
        return { ...filteredData[0], idx };
      }
      return null;
    });
    return products.filter((p) => p !== null);
  }

  getCarouselColumns(data , rowIndex, config) {
    const {
      toastOpenHandler,
      cartUpdateSource,
      dontOpenIssueModal,
      addUpdateManyIssues,
      removeManyIssues,
      updateOneIssue,
      isIssuesModalOpen,
      getCartSummary,
      railMeta,
      type,
    } = this.props;

    const { ReferenceId, ReferenceType, MultilineSupport } = railMeta || {};

    const railColumn = [];
    const railData = this.getData(data);

    let spaceForMOQFlag = 0;
    let showHeader= this.checkHeader(railData,railData.length)
    let titleSize= this.findTitleSize(railData,railData.length)

    railData.map((item) => {
      if (item.Quantity.MinimumOrderQuantity > 1) {
        spaceForMOQFlag = 1;
        return;
      }
    });
     if (
      !MultilineSupport ||
      MultilineSupport?.MaxTilesInLine >= railData.length
    ) {

    return railData.map((props , index) => {
      return (
        //single layer rail
        window.innerWidth < 992 ? (
          <RailCardForBuyer
            itemId={props.Id}
            item={props}
            type={this.props.type}
            key={props.Id}
            openModal={this.openModal}
            toastOpenHandler={toastOpenHandler}
            cartUpdateSource={cartUpdateSource}
            dontOpenIssueModal={dontOpenIssueModal}
            addUpdateManyIssues={addUpdateManyIssues}
            removeManyIssues={removeManyIssues}
            updateOneIssue={updateOneIssue}
            isIssuesModalOpen={isIssuesModalOpen}
            clearIssues={this.props.clearIssues}
            additionalTransform={0}
            referenceId={ReferenceId}
            referenceType={ReferenceType}
            index={props.idx + 1}
            getCartSummary={getCartSummary}
            isMultiLine={false}
            spaceForMOQFlag={spaceForMOQFlag}
          />
        ) : (
          <CatalogCardForBuyer
            itemId={props.Id}
            item={props}
            type={this.props.type}
            key={props.Id}
            openModal={this.openModal}
            toastOpenHandler={toastOpenHandler}
            cartUpdateSource={cartUpdateSource}
            dontOpenIssueModal={dontOpenIssueModal}
            addUpdateManyIssues={addUpdateManyIssues}
            removeManyIssues={removeManyIssues}
            updateOneIssue={updateOneIssue}
            isIssuesModalOpen={isIssuesModalOpen}
            clearIssues={this.props.clearIssues}
            additionalTransform={0}
            referenceId={ReferenceId}
            referenceType={ReferenceType}
            index={props.idx + 1}
            getCartSummary={getCartSummary}
            isMultiLine={false}
            spaceForMOQFlag={spaceForMOQFlag}
            showHeader={showHeader}
            titleSize={titleSize}
            sourceType="promotion"
            className={styles.PromotionRailCard}
          />
        )
      );
    });
  } else {
    let newRailData = [];
    // curenty handling for 2
     railData.forEach((props,index) => { 
      if ((rowIndex === 2 && (index+1) % rowIndex === 0)|| (rowIndex === 1 && (index+1) % 2  !== 0)){
        newRailData.push(
          window.innerWidth < 992 ? (
            <RailCardForBuyer
              itemId={props.Id}
              item={props}
              type={this.props.type}
              key={props.Id}
              openModal={this.openModal}
              toastOpenHandler={toastOpenHandler}
              cartUpdateSource={cartUpdateSource}
              dontOpenIssueModal={dontOpenIssueModal}
              addUpdateManyIssues={addUpdateManyIssues}
              removeManyIssues={removeManyIssues}
              updateOneIssue={updateOneIssue}
              isIssuesModalOpen={isIssuesModalOpen}
              clearIssues={this.props.clearIssues}
              additionalTransform={0}
              referenceId={ReferenceId}
              referenceType={ReferenceType}
              index={props.idx + 1}
              getCartSummary={getCartSummary}
              isMultiLine={false}
              spaceForMOQFlag={spaceForMOQFlag}
              showHeader={showHeader}
              titleSize={titleSize}
              sourceType="promotion"
              className={styles.PromotionRailCard}
            />
          ) : (
            <CatalogCardForBuyer
              itemId={props.Id}
              item={props}
              type={this.props.type}
              key={props.Id}
              openModal={this.openModal}
              toastOpenHandler={toastOpenHandler}
              cartUpdateSource={cartUpdateSource}
              dontOpenIssueModal={dontOpenIssueModal}
              addUpdateManyIssues={addUpdateManyIssues}
              removeManyIssues={removeManyIssues}
              updateOneIssue={updateOneIssue}
              isIssuesModalOpen={isIssuesModalOpen}
              clearIssues={this.props.clearIssues}
              additionalTransform={0}
              referenceId={ReferenceId}
              referenceType={ReferenceType}
              index={props.idx + 1}
              getCartSummary={getCartSummary}
              isMultiLine={false}
              spaceForMOQFlag={spaceForMOQFlag}
              showHeader={showHeader}
              titleSize={titleSize}
              sourceType="promotion"
              className={styles.PromotionRailCard}
            />
          )
        );
  
  }
    })

    if (railData?.length % 2 !==0 && rowIndex === 2){
      newRailData.push(<div></div>)
    }
    return newRailData
  }
    

  }

  renderPromotionCards = (data) => {
    const { type, railMeta } = this.props;

    const {
      Description,
      Heading,
      IconPath,
      SeeAllCTA,
      SeeAllUrl = "",
      SeeAllCtaAction = {},
    } = railMeta || {};

    let config;
    if (type === "alternateProducts") {
      config = getRailConfig(ALTERNATE_PRODUCT_RAIL);
    } else if (type === "requestProduct") {
      config = getRailConfig(PRODUCT_REQUEST_RAIL);
    } else if (type === "similarProduct") {
      config = getRailConfig(SIMILAR_PRODUCTS);
    } else config = getRailConfig(PRODUCTS_RAIL);

    return (
        data && data.length ? (<section
          className={`relative ${
            type === "alternateProducts" || type === "requestProduct" || type==="similarProduct"
              ? "carousel-custom "
              : this.props.isHome ? "carousel-custom " : "carousel-custom"
          }`
        }
        
        >
          {!(type === "alternateProducts" || type === "requestProduct" || type==="similarProduct") ? (
            <div className={`d-flex align-items-center`}>
              <div className="d-flex flex-column wd-100">
                <div className="d-flex wd-100 justify-content-between">
                  {Heading ? <div className={this.props.isHome ? "w-800 fs-30 text-gray-1000 mb24" : "ml-4 w-800 fs-30 text-gray-1000 mb24"}>{Heading}</div> : <></>}
                  {SeeAllCTA && SeeAllCtaAction ? (
                    <div className="d-flex align-items-center pointer">
                      <div
                        onClick={() => handleActionType(SeeAllCtaAction?.ActionType, SeeAllCtaAction?.ActionData, this.props.history)}
                        className="w-600 fs-16"
                        style={{
                          color: SeeAllCTA.TextColor,
                        }}
                      >
                        {SeeAllCTA.Text}
                      </div>
                      {SeeAllCTA.IconImage ? (
                        <img
                          src={SeeAllCTA.IconImage}
                          alt=""
                          className="imageIcon"
                        />
                      ) : null}
                    </div>
                  ) : null}
                </div>
                {Description ? (
                  <div className="fs-20 text-gray-900 my-2">{Description}</div>
                ) : null}
              </div>
            </div>
          ) : null}
          {(!this.props.railMeta?.MultilineSupport ||  this.props.railMeta?.MultilineSupport?.MaxTilesInLine >= data.length) ? 
          
          <CarouselWrapper
            containerClass={type === "alternateProducts" ? styles.alternateCont:  styles.cont}
            deviceType="desktop"
            arrows={false}
            customButtonGroup={
              round(window.innerWidth< 1200 ? config.tablet.items : config.desktop.items,1) < data?.length ?
              <ButtonGroup
                dimension={48}
                type={type !== "alternateProducts" ? "productRail" : ""}
              />: <></>
            }
            responsive={config}
            renderButtonGroupOutside
            ssr
            shouldResetAutoplay={false}
            className={type === "" ?  styles.limitOverflow : ""}
          >
            {this.getCarouselColumns(data)}
          </CarouselWrapper>
        :
        <MultiCarousel
        row1={this.getCarouselColumns(data,1)}
        row2={this.getCarouselColumns(data,2)}
        config={config}
        type={type}
      />  
        }
        </section>
      ) : null
      // </ErrorBoundary>
    );
  };

  render() {
    const { promotionsData: data } = this.props;
    return <React.Fragment>{this.renderPromotionCards(data)}</React.Fragment>;
  }
}

export default withRouter(
  connect((state) => ({
    addToCartStatus: state.status.cart_add,
    updateInCartStatus: state.status.cart_update,
    deleteFromCartStatus: state.status.cart_delete,
  }))(PromotionsCard)
);
