import actionTypes from "../actionTypes";

function corporateAnnouncements(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.GET_CORPORATE_ANNOUNCEMENTS_DONE:
      return {
        ...state,
        corporateAnnouncements: payload.CorporateAnnouncements,
      };
    default:
      return state;
  }
}

export default corporateAnnouncements;
