import { useEffect } from "react";
import { HP_WEB_SERVICE_PORT } from "../../../utils/constants/others";

function RequestProductRedirection() {
    useEffect(() => {
        if (process.env.NODE_ENV === "development") {
            window.location.href = `http://localhost:${HP_WEB_SERVICE_PORT}/in/request-product`;
        } else {
            window.location.href = "/in/request-product";
        }
    }, []);

    return null;
}

export default RequestProductRedirection;
