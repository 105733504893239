import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styles from "./PayOnDelivery.module.scss";
import Modal from "sushiweb/Modal/Modal";
import Cross from "sushiweb/Icons/all/Cross";
import Checkbox from "sushiweb/InputTypes/Checkbox";
import { bindActionCreators } from "redux";
import {
  getPaymentOptions,
  makePendingPayment,
  clearPaymentDetail
} from "../../../redux/dispatchers/paymentDispatcher";
import {
  getOrderPaymentInfo
} from "../../../redux/dispatchers/orderDispatcher";
import { CTA_COLOUR, CHECKOUT_DUE_PAYMENT, HOME_DUE_PAYMENT, PAYMENT_SRC } from "../../../utils/constants/others";
import stateTypes from "../../../utils/constants/stateTypes";
import Button from "sushiweb/Button/Button";
import RightTriangle from "sushiweb/Icons/all/RightTriangle";
import {
  getThousandsFormatCurrency,
  isMobile,
  removeExtraPrecision,
} from "../../../utils/helpers";
import ExclaimationLine from "sushiweb/Icons/all/ExclamationLine";
import StyledDiv from "../StyledDiv";

function PayOnDeliveryModal({
  orders,
  dueOrders,
  onModalClose,
  paymentOptions,
  showModal,
  actions,
  userData,
  makePendingPaymentStatus,
  paymentPlan,
  paymentGUID,
  encryptedPaymentGuid,
  history,
  toastOpenHandler,
  source,
  refreshAerobars,
  title,
  paymentDetails,
  orderPaymentInfo,
  orderPaymentInfoStatus
}) {
  const [walletSelected, setWalletSelected] = useState(false);
  const [walletSummary, setWalletSummary] = useState({
    totalAmount: 0,
    walletAmount: 0,
    toPayAmount: 0,
  });
  const prevStatus = usePrevious(makePendingPaymentStatus.state);
  const prevOrderPaymentInfoStatus = usePrevious(orderPaymentInfoStatus?.state)

  useEffect(() => {
    if (userData && userData.outlet) {
      actions.getPaymentOptions(userData.outlet.id);
    }
  }, [userData]);


  useEffect(() => {
    const totalAmount = getTotalValue();
    if (!walletSelected) {
      setWalletSummary({
        ...walletSummary,
        totalAmount,
        walletAmount: 0,
        toPayAmount: totalAmount,
      });
    } else {
      const { wallet: { totalAmount: walletBalance = 0 } = {} } =
        paymentOptions;
      setWalletSummary({
        ...walletSummary,
        walletAmount:
          walletBalance >= walletSummary.totalAmount
            ? removeExtraPrecision(walletSummary.totalAmount)
            : walletBalance,
        toPayAmount:
          walletBalance >= walletSummary.totalAmount
            ? 0
            : removeExtraPrecision(walletSummary.totalAmount - walletBalance),
      });
    }
  }, [walletSelected, orders]);

  useEffect(() => {
    if (prevStatus === stateTypes.STATE_BEGIN) {
      if (makePendingPaymentStatus.state === stateTypes.STATE_DONE) {
        if (paymentPlan.online === 0) {
          history.push(`/buyer/payment/success?order_id=${paymentGUID}`);
        } else {
          if (source === "order_history" || source === "order_detail") {
            let showPaymentMethodsMessageHinting = orderPaymentInfo?.ShowPaymentMethodsMessageHinting
            history.push((`/buyer/payment?type=3&from=orders&showPaymentMethodsMessageHinting=${showPaymentMethodsMessageHinting}&source=${source}`) + (encryptedPaymentGuid ? `&paymentIdentifier=${encryptedPaymentGuid}` : ``));
          } else {
            history.push("/buyer/payment?type=3&from=orders");
          }
        }
        source === "home" && refreshAerobars();
      } else if (makePendingPaymentStatus.state === stateTypes.STATE_FAIL) {
        toastOpenHandler({ text: "Couldn't make payment", type: "error" });
        window.localStorage.removeItem(PAYMENT_SRC)
      }
      onModalClose();  
    }
  }, [makePendingPaymentStatus.state]);

  function getTotalValue() {
    if (dueOrders && dueOrders.TotalDueAmount){ 
      return dueOrders.TotalDueAmount
    }
    return orders!=null && orders.filter(o => !!o).reduce((acc, { PayableAmount , Amount , OutstandingAmount }) => { 
      return acc + (PayableAmount || Amount || OutstandingAmount);
    }, 0);
  }

  function onPaymentClick() {
    if (!paymentOptions || !orders) return;
    window.localStorage.setItem(PAYMENT_SRC, source)

    if (source === "order_history" || source === "order_detail") {
      const orderIds = orders.map(order => order.OrderId)
      let getOrderPaymentInfoParams = {}
      if (walletSelected) {
        getOrderPaymentInfoParams = {
          OrderIds: orderIds,
          'WALLET': true
        }
      } else {
        getOrderPaymentInfoParams = {
          OrderIds: orderIds
        }
      }
      actions.getOrderPaymentInfo(getOrderPaymentInfoParams, () => {
        actions.makePendingPayment({
          PendingPaymentOrders: orders.map((order) => {
            return {
              OrderId: order.OrderId || order.Id,
              Amount: order.PayableAmount || order.Amount || order.OutstandingAmount,
            };
          }),
          IsWalletUsed: walletSelected,
          PaymentOptions: {
            WalletAmount: paymentOptions.wallet.totalAmount,
            CreditAmount: paymentOptions.credit.totalAmount,
            CreditAccountId: paymentOptions.credit.accountId,
            OnlinePaymentEnabled: paymentOptions.credit.isOnlinePaymentEnabled,
          },
        });
      });
    } else {
      actions.makePendingPayment({
        PendingPaymentOrders: orders.map((order) => {
          return {
            OrderId: order.OrderId || order.Id,
            Amount: order.PayableAmount || order.Amount || order.OutstandingAmount,
          };
        }),
        IsWalletUsed: walletSelected,
        PaymentOptions: {
          WalletAmount: paymentOptions.wallet.totalAmount,
          CreditAmount: paymentOptions.credit.totalAmount,
          CreditAccountId: paymentOptions.credit.accountId,
          OnlinePaymentEnabled: paymentOptions.credit.isOnlinePaymentEnabled,
        },
      });
    }
  }

  function renderDueDetails() {
    if(!dueOrders || !dueOrders.ReasonString || !dueOrders.DueString) {
      return null
    }
    return (
      <div className={styles.dueDetails}>
        <div className="d-flex align-items-center justify-content-center">
          <ExclaimationLine size={24} color={"#EC4654"} />
          <div className="w-800 fs-18 text-gray-800 ml-3">
            {dueOrders.DueString}
          </div>
        </div>
        <div className="text-align-center text-gray-600 w-500 fs-14 mt-2">
          {`${orders.length >1 ? "Order IDs" : "Order ID"}: ${orders.map(o => o.OrderId).join(",")}`}
        </div>
        <div className="w-500 text-gray-700 text-align-center fs-14 mt-4">
          {dueOrders.ReasonString}
        </div>
      </div>
    );
  }

  function checkMatchingOrders() {
    if(paymentDetails && paymentDetails.orders && orders) {
      const paymentOrders = paymentDetails.orders.map(o => o.Id)
      const selectedOrderIds = orders.map(o => {
        return (o?.OrderId || o?.Id)
      })
      for(let id of paymentOrders) {
        if (!selectedOrderIds.includes(id)){
          return false
        }
      }
    }
    return true
  }
  

  function renderPaymentFailure() {
    if (!paymentDetails || paymentDetails.status !== "failed" ) {
      return null
    }
    if(!checkMatchingOrders()) {
      return null
    }
    return (
      <div className={styles.paymentFailure}>
        <div className="d-flex justify-content-center">
          <StyledDiv bgImage = {"/assets/images/fail_icon.png"} className={styles.failIcon} />
          <div className="fs-18 text-gray-800 w-800">{paymentDetails.text}</div>
        </div>
        <div className="text-align-center fs-16 w-600 text-gray-700 mt-4">
          {paymentDetails.subText}
        </div>
        <div className="text-align-center fs-14 w-600 text-orange mt-4">
          {paymentDetails.message}
        </div>
      </div>
    );
  }

  function renderPODDetails() {
    const paymentSrc = window.localStorage.getItem(PAYMENT_SRC)
    if(!paymentDetails || paymentDetails.status !== "failed") {
      return null
    }
    //Dont show POD details when due payment dialog
    if([CHECKOUT_DUE_PAYMENT, HOME_DUE_PAYMENT].includes(paymentSrc)) {
      return null
    }
    if(!checkMatchingOrders() || !paymentDetails.orders[0].IsCod) {
      return null
    }
    return (
      <div>
        <div className="d-flex mb-4">
          <StyledDiv bgImage={"/assets/images/cash_icon.png"} className={styles.cashIcon}/>
          <div className="w-600 fs-18 text-gray-800">{`Pay ${getThousandsFormatCurrency(walletSummary.totalAmount)} using cash`}</div>
        </div>
        <div className="d-flex mb-4 align-items-center">
          <div className="line-light"></div>
          <div className="mx-2 fs-12 text-red-600">OR</div>
          <div className="line-light"></div>
        </div>
        <div className="d-flex mb-4">
          <StyledDiv bgImage={"/assets/images/online_icon.png"} className={styles.onlineIcon}/>
          <div className="w-600 fs-18 text-gray-800">Pay online</div>
        </div>
      </div>
    )
  }

  function handleCheckboxChange() {
    setWalletSelected(!walletSelected);
  }

  function handleClose() {
    const paymentSrc = window.localStorage.getItem(PAYMENT_SRC)
    if([HOME_DUE_PAYMENT,CHECKOUT_DUE_PAYMENT].includes(paymentSrc)) {
      window.localStorage.removeItem(PAYMENT_SRC)
      actions.clearPaymentDetail()
    }
    onModalClose()
  }

  return (
    <Modal
      visible={showModal}
      type="default"
      titleComponent={
        <div className={styles.header}>
          {title ? (
            <span className="fs-20 w-600">{title}</span>
          ) : (
            <span className="fs-20 w-600">
              Payment for{" "}
              {orders.length === 1 ? "1 order" : `${orders.length} orders`}
            </span>
          )}

          <Cross onClick={handleClose} size={20} className="pointer" />
        </div>
      }
      isCentered
      slideOnMobile
      showCloseIcon={false}
      onClose={handleClose}
      mobileHeight={isMobile(991)}
      className={styles.PayOnDeliveryModal}
    >
      <div className={styles.content}>
        {renderPaymentFailure()}
        {renderDueDetails()}
        {renderPODDetails()}
        <div className={styles.paymentBreakup}>
          <div
            className={`d-flex justify-content-between px-4 pt-4 pb-3 ${styles.borderBtm}`}
          >
            <span className="fs-18 w-600">Total</span>
            <div className="d-flex flex-column text-align-right">
              <div className="fs-18 w-600">
                {getThousandsFormatCurrency(walletSummary.totalAmount)}
              </div>
              <div className="text-gray-600 w-500 fs-12">Inc. of Taxes</div>
            </div>
          </div>
          <div
            className={`d-flex flex-column w-500 fs-18 px-4 py-3 ${styles.borderBtm}`}
          >
            <div className="d-flex justify-content-between align-items-center">
              <Checkbox
                label={`Wallet`}
                checked={walletSelected}
                onChange={handleCheckboxChange}
                disabled={
                  paymentOptions.wallet.totalAmount === 0 ||
                  makePendingPaymentStatus.state === stateTypes.STATE_BEGIN
                }
                color={CTA_COLOUR}
                value="wallet"
                className="fs-16 mb-0 mr-3 text-black"
              />
              <span className="text-black fs-18">
                {" "}
                {walletSummary.walletAmount > 0
                  ? `- ₹ ${walletSummary.walletAmount}`
                  : `₹ 0`}{" "}
                {}
              </span>
            </div>
            <div
              className={`fs-14 w-500 text-gray-600 ${styles.walletBalance}`}
            >
              Available:{" "}
              {getThousandsFormatCurrency(paymentOptions.wallet.totalAmount)}
            </div>
          </div>
          <div
            className={`d-flex justify-content-between text-black fs-18 w-600 px-4 py-3 ${styles.finalAmount}`}
          >
            <div>To Pay</div>
            <div>{getThousandsFormatCurrency(walletSummary.toPayAmount)}</div>
          </div>
        </div>
        <div className="btnContainer">
          <Button
            btnColor={CTA_COLOUR}
            className={`${styles.submitBtn} solid`}
            icon={<RightTriangle />}
            iconAlign="right"
            onClick={onPaymentClick}
          >
            {walletSummary.toPayAmount === 0 ? "Pay now" : "Go To Payment"}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default withRouter(
  connect(
    (state) => ({
      //data
      userData: state.user.data,
      paymentOptions: state.payment.paymentOptions,
      paymentPlan: state.payment.paymentPlan,
      paymentGUID: state.payment.paymentGUID,
      encryptedPaymentGuid: state.payment.encryptedPaymentGuid,
      //status
      makePendingPaymentStatus: state.status.payment_makePending,
      orderPaymentInfo: state.order.orderPaymentInfo || {},
      orderPaymentInfoStatus: state.status.orderPaymentInfo,
    }),
    (dispatch) => ({
      actions: bindActionCreators(
        {
          getPaymentOptions,
          makePendingPayment,
          clearPaymentDetail,
          getOrderPaymentInfo
        },
        dispatch
      ),
    })
  )(PayOnDeliveryModal)
);
