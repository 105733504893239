import React from "react";
import Image from "../Image/Image";
import styles from "./Filters.module.scss";
export const FilterPill = ({
    Text,
    ImageUrl,
    Style,
    OnClick
})=>{
    return (
        <div className={`d-flex justify-content-center align-items-center pointer ${styles.filterPill} mr-3 ${styles.margin16} ${Style === "active" ? styles.activePill : ""}`}
            onClick ={OnClick}
        >
            <div className="w-400 fs-16">{Text}</div>
            {ImageUrl ? <Image source={ImageUrl} classname={`${styles.img20} ml-1`}/> : <></>}
        </div>
    )
}