import actionTypes from "../actionTypes";

const initialState = {
  salesLeadId: null,
  referralCode: null,
  stepOne: {
    outletName: null,
    phoneNumber: null,
    email: null,
    deliveryAddress: null,
    city: null,
    state: null,
    pinCode: null,
    deliveryTime: null,
    referralCode: null,
    //
    isSet: false,
  },
  stepTwo: {
    legalName: null,
    pan: null,
    gst: null,
    panPath: null,
    gstPath: null,
    //
    isSet: false,
  },
  stepThree: {
    name: null,
    email: null,
    phoneNumber: null,
    otp: null,
    password: null,
    confirmPassword: null,
    designation: null,
    //
    isSet: false,
  },
  documents: {
    pan: null,
    fssai: null,
    gst: null,
  },
};

function registerReducer(state = initialState, action) {
  const payload = action.payload;

  switch (action.type) {
    case actionTypes.REGISTER_STEP_SAVE:
      {
        const { step, stepData } = payload;

        switch (step) {
          case 2:
            return {
              ...state,
              stepTwo: {
                ...state.stepTwo,
                isSet: true,
                //
                legalName: stepData.legalName,
                pan: stepData.pan,
                fssai: stepData.fssai,
                gst: stepData.gstin,
                panPath: stepData.panPath,
                fssaiPath: stepData.fssaiPath,
                gstPath: stepData.gstPath,
              },
            };

          case 3:
            return {
              ...state,
              stepThree: {
                ...state.stepThree,
                isSet: true,
                //
                name: stepData.outletUser.name,
                email: stepData.outletUser.email,
                phoneNumber: stepData.outletUser.phoneNumber,
                otp: stepData.outletUser.otpNumber,
                password: stepData.outletUser.password,
                confirmPassword: stepData.outletUser.confirmPassword,
                designation: stepData.outletUser.designation,
              },
            };

          default:
            break;
        }
      }
      break;

    case actionTypes.REGISTER_STEP_ONE_2_DONE: {
      const stepOneData = payload.stepOneData;

      return {
        ...state,
        salesLeadId: payload.salesLeadId,
        stepOne: {
          ...state.stepOne,
          isSet: true,
          //
          outletName: stepOneData.outletName,
          phoneNumber: stepOneData.phoneNumber,
          email: stepOneData.email,
          deliveryAddress: stepOneData.address.addressLine,
          city: stepOneData.cityName,
          // TODO check
          state: stepOneData.address.state.name,
          pinCode: stepOneData.address.zipCode,
          deliveryTime: `${stepOneData.deliveryTime}`,
          referralCode: stepOneData.source,
        },
      };
    }

    case actionTypes.REGISTER_STEP_TWO_2_DONE: {
      const stepTwoData = payload.stepTwoData;

      return {
        ...state,
        stepTwo: {
          ...state.stepTwo,
          isSet: true,
          //
          legalName: stepTwoData.legalName,
          pan: stepTwoData.pan,
          fssai: stepTwoData.fssai,
          gst: stepTwoData.gstin,
          panPath: stepTwoData.panPath,
          fssaiPath: stepTwoData.fssaiPath,
          gstPath: stepTwoData.gstPath,
        },
      };
    }

    case actionTypes.REGISTER_STEP_THREE_FAIL: {
      const stepThreeData = payload.stepThreeData;

      return {
        ...state,
        stepThree: {
          ...state.stepThree,
          isSet: true,
          //
          name: stepThreeData.outletUser.name,
          email: stepThreeData.outletUser.email,
          phoneNumber: stepThreeData.outletUser.phoneNumber,
          otp: stepThreeData.outletUser.otpNumber,
          password: stepThreeData.outletUser.password,
          confirmPassword: stepThreeData.outletUser.confirmPassword,
          designation: stepThreeData.outletUser.designation,
        },
      };
    }

    case actionTypes.UPLOAD_PAN_IMAGE_2_DONE:
      return {
        ...state,
        documents: {
          ...state.documents,
          pan: payload.path,
        },
      };

    case actionTypes.UPLOAD_FSSAI_IMAGE_2_DONE:
      return {
        ...state,
        documents: {
          ...state.documents,
          fssai: payload.path,
        },
      };

    case actionTypes.UPLOAD_GST_IMAGE_2_DONE:
      return {
        ...state,
        documents: {
          ...state.documents,
          gst: payload.path,
        },
      };

    case actionTypes.VALIDATE_REFERRAL_CODE_DONE:
      if (action.referralCode.is_referral_code_valid) {
        return {
          ...state,
          referralCode: action.referralCode.code,
        };
      } else {
        return state;
      }

    case actionTypes.REGISTER_CLEAR_DATA:
      return initialState;

    default:
      return state;
  }
}

export default registerReducer;
