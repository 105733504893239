import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function NotFound(props) {
  useEffect(() => {

       const url=window.location.pathname;
   
        window.DD_RUM.addError(new Error('err: Page not found',url));
  }, []);

  return (
    <div className="site-content pd-top-global-large d-flex justify-content-center flex-column">
      <div className="container text-align-center"><h1>Page Not found</h1><br /> 
      <div onClick={()=>   window.location.href = "/"} className="link-red">Go to home</div>
      </div>
    </div>
  );
}

// export default NotFound;
export default NotFound;
