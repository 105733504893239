import { debounce } from "lodash";
import { useState, useEffect, useCallback } from "react";
import stateTypes from "../constants/stateTypes";
import { parseIntOr0, usePrevious, useOnUpdate } from "../helpers";

function useCart({
  addToCartStatus,
  updateInCartStatus,
  deleteFromCartStatus,
  actions: { addToCart, updateInCart, deleteFromCart },
  item = {},
  inProgressCart,
  toastOpenHandler,
  cartAlternateProductSuggestion,
  cartErrorAvailableQty,
  cartUpdateSource,
  dontOpenIssueModal,
  addUpdateManyIssues,
  isIssuesModalOpen,
  showToast,
  userData,
  referenceId,
  referenceType,
  index,
  type,
  getCartSummary = false,
}) {
  //VARS
  const [cartState, setCart] = useState({
    quantity: 0,
    prevQuantity: 0,
    maxAvailableQuantity: null,
    minOrderQty: 1,
    inputType: "",
  });
  const [issueNudge, setNudge] = useState("");
  const prevCart = usePrevious(inProgressCart);
  const prevItem = usePrevious(item);

  //HANDLERS
  function submitHandler(
    item = {},
    userData = {},
    quantity,
    prevQuantity,
    minOrderQty,
    inputType 
  ) {
    //Handles submit
    let params = {
      ProductId: item.Id,
      OutletId: userData.outlet.id,
      ReferenceId: referenceId,
      ReferenceType: referenceType,
      GetCartSummary: getCartSummary,
      Source: cartUpdateSource,
      InputType: inputType
    };
    if (userData) {
      if (
        parseIntOr0(quantity) === 0 
      ) {
        // delete
        params = {
          ...params,
          ProductIds: [params.ProductId],
          PageNo: item.pageNo || 1,
          Index: index,
        };
        deleteFromCart(params, true, type);
      } else if (parseIntOr0(quantity) === 1) {
        if (parseIntOr0(prevQuantity) === 0) {
          // add
          params = {
            ...params,
            Quantity: 1,
            PageNo: item.pageNo || 1,
            Index: index,
          };
          addToCart(params, "", type);
        } else {
          // update
          params = { ...params, Quantity: parseIntOr0(quantity) };
          updateInCart(params, null, type);
        }
      } else {
        // update
        params = { ...params, Quantity: parseIntOr0(quantity) };
        updateInCart(params, null, type);
      }
    }
  }

  const debouncedSubmit = useCallback(debounce(submitHandler, 1000), []);

  function quantityChangeHandler(value, inputType) {
    //Takes the new value and call submit handler
    const newValue = typeof value === "string" ? value.trim() : value;
    const isNumber = !isNaN(parseInt(newValue));
    if (newValue === "" || isNumber) {
      setCart((prevCart) => ({
        ...prevCart,
        quantity: value,
        inputType: inputType,
      }));
    }
  }

  // Effects
  useEffect(() => {
    //SET DEFAULT VALUES
    if (item.Quantity) {
      setCart({
        ...cartState,
        quantity: item.Quantity.CartQuantity,
        prevQuantity:
          prevItem && prevItem.Quantity
            ? prevItem.Quantity.CartQuantity
            : item.Quantity.CartQuantity,

        minOrderQty: item.Quantity.MinimumOrderQuantity,
      });
    }
  }, [item]);

  useOnUpdate(() => {
    //Handle Add to cart
    if (addToCartStatus.state === stateTypes.STATE_DONE) {
      if (prevCart.add.has(item.Id) && !inProgressCart.add.has(item.Id)) {
        setCart({
          ...cartState,
          prevQuantity: 1,
        });
        if (showToast) {
          toastOpenHandler({
            show: true,
            text: `${item.Name} added to cart`,
            type: "success",
            className: "toast-top-right",
          });
        }
      }
    } else if (addToCartStatus.state === stateTypes.STATE_FAIL) {
      if (prevCart.add.has(item.Id) && !inProgressCart.add.has(item.Id)) {
        const addUpdateIssue =
          cartAlternateProductSuggestion &&
          cartAlternateProductSuggestion.SearchQuery &&
          !dontOpenIssueModal &&
          typeof addUpdateManyIssues === "function";

        if (addUpdateIssue) {
          addUpdateManyIssues(
            [
              {
                id: item.Id,
                issueData: cartAlternateProductSuggestion,
                issueProduct: item,
                issueSelectedQuantity: parseIntOr0(cartState.quantity),
                issueAvailableQuantity: cartErrorAvailableQty,
                issueNudgeMessage:
                  cartAlternateProductSuggestion.ModalNudgeMessage,
              },
            ],
            false,
            isIssuesModalOpen
          );
        } else {
          shouldOpenToast(item.Id) &&
            toastOpenHandler({
              show: true,
              text: addToCartStatus.error,
              type: "error",
              className: "",
            });
          cartErrorAvailableQty && retryCartAddUpdate();
        }
        setCart({
          ...cartState,
          quantity: cartState.prevQuantity,
          maxAvailableQuantity: cartErrorAvailableQty,
        });
        setNudge(
          cartAlternateProductSuggestion &&
            cartAlternateProductSuggestion.ErrorNudge
        );
      }
    }
  }, [addToCartStatus.state]);

  useOnUpdate(() => {
    //Handle Update in cart
    if (updateInCartStatus.state === stateTypes.STATE_DONE) {
      if (prevCart.update.has(item.Id) && !inProgressCart.update.has(item.Id)) {
        setCart({
          ...cartState,
          prevQuantity: parseIntOr0(cartState.quantity),
        });
      }
    } else if (updateInCartStatus.state === stateTypes.STATE_FAIL) {
      if (prevCart.update.has(item.Id) && !inProgressCart.update.has(item.Id)) {
        const addUpdateIssue =
          cartAlternateProductSuggestion &&
          cartAlternateProductSuggestion.SearchQuery &&
          !dontOpenIssueModal &&
          typeof addUpdateManyIssues === "function";

        if (addUpdateIssue) {
          addUpdateManyIssues(
            [
              {
                id: item.Id,
                issueData: cartAlternateProductSuggestion,
                issueProduct: item,
                issueSelectedQuantity: parseIntOr0(cartState.quantity),
                issueAvailableQuantity: cartErrorAvailableQty,
                issueNudgeMessage:
                  cartAlternateProductSuggestion.ModalNudgeMessage,
              },
            ],
            false,
            isIssuesModalOpen
          );
        } else {
          shouldOpenToast(item.Id) &&
            toastOpenHandler({
              show: true,
              text: updateInCartStatus.error,
              type: "error",
              className: "",
            });
          cartErrorAvailableQty && retryCartAddUpdate();
        }
        setCart({
          ...cartState,
          quantity: cartState.prevQuantity,
          maxAvailableQuantity: item?.AvailableQty,
        });
        setNudge(
          cartAlternateProductSuggestion &&
            cartAlternateProductSuggestion.ErrorNudge
        );
      }
    }
  }, [updateInCartStatus]);

  useOnUpdate(() => {
    //Handle Delete from cart
    if (deleteFromCartStatus.state === stateTypes.STATE_DONE) {
      if (prevCart.delete.has(item.Id) && !inProgressCart.delete.has(item.Id)) {
        setCart({
          ...cartState,
          prevQuantity: parseIntOr0(cartState.quantity),
        });

        // shouldOpenToast() &&
        //   toastOpenHandler({
        //     show: true,
        //     text: `${item.Name} deleted from cart`,
        //     type: "success",
        //     className: "toast-top-right",
        //   });
      }
    } else if (deleteFromCartStatus.state === stateTypes.STATE_FAIL) {
      if (prevCart.delete.has(item.Id) && !inProgressCart.delete.has(item.Id)) {
        setCart({
          ...cartState,
          quantity: cartState.prevQuantity,
        });
        toastOpenHandler({
          show: true,
          text: deleteFromCartStatus.error,
          type: "error",
          className: "",
        });
      }
    }
  }, [deleteFromCartStatus.state]);

  useOnUpdate(() => {
    if (item.Quantity && item.Quantity.CartQuantity !== cartState.quantity) {
      if (item.Quantity.CartQuantity === 0) {
        //on add don't call debounced sumbit, slows UI down
        submitHandler(
          item,
          userData,
          cartState.quantity,
          cartState.prevQuantity,
          cartState.minOrderQty,
          cartState.inputType
        );
      } else
        debouncedSubmit(
          item,
          userData,
          cartState.quantity,
          cartState.prevQuantity,
          cartState.minOrderQty,
          cartState.inputType
        ); 
    }
  }, [cartState.quantity]);

  useOnUpdate(() => {
    if (item && item.Quantity && item.Quantity.CartQuantity) {
      setCart({
        ...cartState,
        quantity: item.Quantity.CartQuantity,
        prevQuantity: cartState.quantity,
        maxAvailableQuantity: item?.AvailableQty,
        minOrdeQty: item.Quantity.MinimumOrderQuantity,
      });
    }
  }, [item, item.Quantity, item?.Quantity?.CartQuantity]);

  //utils
  function shouldOpenToast() {
    return type === "alternateProducts" || !isIssuesModalOpen;
  }

  function retryCartAddUpdate() {
    if (cartState.prevQuantity !== cartErrorAvailableQty) {
      setCart({
        ...cartState,
        quantity: cartErrorAvailableQty,
        maxAvailableQuantity: cartErrorAvailableQty,
      });
    }
  }

  return [cartState, issueNudge, quantityChangeHandler];
}

export default useCart;
