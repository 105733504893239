import React from 'react';
import ButtonSushi from 'sushiweb/Button';
import styled from 'styled-components';

const Button = (props) => { 
   return <Custom btncolor={props.btnColor} style={{"font-family" : '"OurLexend-Regular", Helvetica, sans-serif' , "font-weight": props.bold ? "800" : "500"}} {...props}></Custom>
}

const Custom = styled(ButtonSushi)`
 span:first-child {
   background: ${props => props.btncolor ?  props.btncolor :  "" }
 }
 span:first-child:focus {
   background: ${props => props.btncolor ?  props.btncolor :  "" }
 }
`

export default Button;