import React, { useState } from "react";
import OtpInput from "react-otp-input";
import Input from "sushiweb/InputTypes/Input"
import { PROFILE_INPUT_BORDER } from "../../../utils/constants/others";
import styles from "./CustomInputs.module.scss";
import Image from "../Image/Image";
import SpinnerCircular from "sushiweb/Spinner/SpinnerCircular";
import { red, yellow, green, orange, purple, teal } from "sushiweb/tokens/color";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { verifyPanAndLegalEntityName } from "../../../redux/dispatchers/userDispatcher";
import { HTMLDiv } from "../HTMLDiv";
import { STATUS_VERIFIED,STATUS_VERIFY,ENTER_GST_MANUALLY } from "../../../utils/constants/others";
import { GET_PAN_DETAILS,GET_FSSAI_DETAILS,GET_GST_DETAILS } from "../../../utils/constants/others";

function GstInputs (props) {

  const handleClick = (selectedIndex,selectedText) => {
    props.setGstValueAndSelectedParam(selectedIndex,selectedText)
  }

  const handleIconButtonClick = (endpoint,queryKey,buttonText) => {
    if(buttonText === STATUS_VERIFIED){
        return
    }
    let value = props?.values.map((data)=>{
        if (data?.key===queryKey){
            return data?.value
        }
    })

    if (value?.length>=1){
        let type = ""
        if (props?.inputType===GET_PAN_DETAILS){
            type = "pan"
        }else if (props?.inputType===GET_FSSAI_DETAILS){
            type = "fssai"
        }else if (props?.inputType===GET_GST_DETAILS){
            type = "gst"
        }

        props.reset("",true,type,STATUS_VERIFY,"")
        props.actions.verifyPanAndLegalEntityName(endpoint+value[0],(sucessdata)=>{
          
            if(sucessdata?.VerificationStatus?.IsValid){
                props.reset(sucessdata?.DocumentDetails?.Data?.LegalEntityName,false,type,sucessdata?.VerificationStatus?.Text,"")
            }else{
                let errorTextWithColour = <HTMLDiv html={sucessdata?.VerificationStatus?.Text}/>
                props.reset(sucessdata?.DocumentDetails?.Data?.LegalEntityName,false,type,STATUS_VERIFY,errorTextWithColour)
            }
        },(error)=>{})
    }    
}

    const getIconOnInput = (data) => {
    return <div className="pointer" style={{color:data?.text === STATUS_VERIFIED ? green.z500 :`${data?.color}`}}>
        {data?.loaderVisible?<SpinnerCircular
                      color={red.z500}
                      size="small"/>:data?.visible?<span onClick={()=>{handleIconButtonClick(data?.action,data?.queryKey,data.text)}}>{data?.text}</span>:<></>}
    </div>
}

  return (<div>
    {props?.entityDetails?.BackgroundImage && props?.entityDetails?.AvatarIcon && props?.entityDetails?.Heading && props?.entityDetails?.SubHeading &&<div className={`position-relative ${styles.detailsBox}`}>
        <Image classname={` wd-100`} source={props?.entityDetails?.BackgroundImage}/>
        <Image classname={`position-absolute ${styles.documentAvatar}`} source={props?.entityDetails?.AvatarIcon}/>

        <div >
            <span className={`w-600 fs-18 position-absolute ${styles.documentDetails}`}>{props?.entityDetails?.Heading}</span>
            <span className={`w-400 fs-14 position-absolute ${styles.documentDetailsSub}`}>{props?.entityDetails?.SubHeading}</span>
        </div>
    </div>}
    <div className={props?.values?.length === 1 && props?.values[0]?.options[0]?.Text===ENTER_GST_MANUALLY?``:props?.values[0]?.options?.length<4?`${styles.gstInputsBox} d-flex flex-column align-items-start`:`${styles.gstInputsBox} ${styles.scrollableBox} d-flex flex-column align-items-start`}>
        {props?.values?.length === 1 && props?.values[0]?.options[0]?.Text===ENTER_GST_MANUALLY? <Input 
                                value={props?.values[0].value}
                                onChange={(val)=>props?.onChange(val,0)}
                                label = {"GST Number"}
                                className={`mb-20 ${styles.genericInput} `}//${styles.gstManualInput}
                                icon = { props?.values[0]?.Button ? getIconOnInput( props?.values[0]?.Button):""}
                                errorMsg = { props?.values[0].error}
                            /> :props.values.map((value,idx)=>{
           return value?.options?.map((option,index)=>{
                return <div className="wd-100">

                    <div key={idx+index} className={option.Text!==ENTER_GST_MANUALLY?`wd-100 ${styles.dashedBorderDown} ${styles.inputOptions}`:`wd-100 ${styles.inputOptions}`}>
                   
                   
                        <div className={`${styles.inputText} fs-16 mr-40`}>{option.Text}</div>
                        <div>
                            {
                                value.isSelectedIndex===index?<Image classname={"pointer"} onClick={()=>{handleClick(-1,"")}} source={`${process.env.PUBLIC_URL + "/assets/images/radio-active.png"}`}/>:<Image classname={"pointer"} onClick={()=>{handleClick(index,option.Text)}} source={`${process.env.PUBLIC_URL + "/assets/images/radio-circle.png"}`}/>
                            }
                         </div>
                    </div>        
                        {
                            value?.selectedValue?.length && option.Text===ENTER_GST_MANUALLY  && value?.selectedValue === ENTER_GST_MANUALLY ?<div  className={`` }><Input 
                                value={value.value}
                                onChange={(val)=>props?.onChange(val,idx)}
                                label = {value.Text}
                                className={`mb-20 ${styles.genericInput} `}//${styles.gstManualInput}
                                icon = {value?.Button ? getIconOnInput(value?.Button):""}
                                errorMsg = {value.error}
                            /></div>:null
                        }
                </div>
            })
        })}
    </div>
    </div>
  ) 
}

export default withRouter(
    connect(
      (state) => ({
      }),
      (dispatch) => ({
        actions: bindActionCreators({
            verifyPanAndLegalEntityName
        },
          dispatch
        ),
      })
    )(GstInputs)
);
