import actionTypes from "../actionTypes";

function campaignReducer(state = {}, action) {
    const { type } = action;
    switch (type) {
        case actionTypes.GET_BRAND_CAMPAIGN_DONE:
            return {
                ...state,
                campaignData: action.data
            }
        case actionTypes.CLEAR_BRAND_CAMPAIGN:
            return {
                ...state,
                campaignData: {}
            }

        default:
            return state;
    }
}

export default campaignReducer;
