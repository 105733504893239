const env = {
  // localhost
  development: {
    urls: {
      hyperpureHost: "https://devapi.hyperpure.com",
      mapKey: "AIzaSyAl0dKpOZGHK_TmQUl7IMKNlugeV_m7w1E",
      hpOrigin: "https://dev.hyperpure.com",
    },
  },
  production: {
    urls: {
      hyperpureHost: "https://api.hyperpure.com",
      mapKey: "AIzaSyAl0dKpOZGHK_TmQUl7IMKNlugeV_m7w1E",
      hpOrigin: "https://hyperpure.com",
    },
  },
  staging: {
    urls: {
      hyperpureHost: "https://api.hyperpure.com",
      mapKey: "AIzaSyAl0dKpOZGHK_TmQUl7IMKNlugeV_m7w1E",
      hpOrigin: "https://hyperpure.com",
    },
  },
  // development
  devServer: {
    urls: {
      hyperpureHost: "https://devapi.hyperpure.com",
      mapKey: "AIzaSyAl0dKpOZGHK_TmQUl7IMKNlugeV_m7w1E",
      hpOrigin: "https://dev.hyperpure.com",
    },
  },
};

let envEndpoints = "";
if (process.env.REACT_APP_API_ENDPOINT) {
  envEndpoints = env[process.env.REACT_APP_API_ENDPOINT];
} else {
  envEndpoints = env[process.env.NODE_ENV];
}

export default envEndpoints;