import React from 'react';
import { RadioActive , RadioCircle} from 'sushiweb/Icons';

const RadioButton = props => {
    return(
        props.checked ? <RadioActive className="pointer" {...props}/> : <RadioCircle className="pointer" {...props}/> 
    )

}

export default RadioButton;