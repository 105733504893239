export const isLottie = (url) => {
    let imageExtension = url?.split(".").pop();
    if (imageExtension === "json") {
      return true;
    }

    return false;
}

export const copyText = (text, callBack) => {
  let textArea = document.createElement("textarea");
  textArea.value = text;

  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
    if (typeof callBack === "function") {
      callBack()
    }
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }
  document.body.removeChild(textArea);
}

export const checkAllStatus  = (statusArr , state) => {
  return   statusArr.filter(i=> i?.state === state)?.length 
}