import actionTypes from "../actionTypes";

const initialState = {
  allProducts: {
    products: [],
    nextPage: 1,
    hasMore: true,
  },
  allProductsForBuyer: {
    products: [],
    nextPage: 1,
    hasMore: true,
  },
  categoryProducts: {
    products: [],
    nextPage: 1,
    hasMore: true,
  },
  categoryProductsForBuyer: {
    products: [],
    nextPage: 1,
    hasMore: true,
  },
  getBannerProductsForBuyer: {
    products: [],
    nextPage: 1,
    hasMore: true,
  },
  searchProductsForBuyer: {
    products: [],
    nextPage: 1,
    hasMore: true,
  },
  featuredOffers: {
    products: [],
    nextPage: 1,
    hasMore: true,
  },
  allOffers: {
    products: [],
    nextPage: 1,
    hasMore: true,
  },
  trialOffers: {
    products: [],
    nextPage: 1,
    hasMore: true,
  },
  categoryProductsRequestStartTime: null,
  v2Categories: [],
  superSaverRail: null,
  searchSuggestions: {
    products: [],
    categories: [],
    subCategories: [],
    suggestionList: null,
    suggestionListFirstChange: null,
    searchSuggestionsRequestStartTime: null,
  },
  requestProductSuggestion: {},
  alternateProducts: {
    products: [],
  },
  verticalProducts: {},
  productDetailMeta: {},
  updateIngredientList: {},
};

const buyerProductCollections = [
  "allProductsForBuyer",
  "categoryProductsForBuyer",
  "getBannerProductsForBuyer",
  "searchProductsForBuyer",
  "featuredOffers",
  "allOffers",
  "trialOffers",
  "alternateProducts"
];

function processProductUpdate(product, id, ids, change, updatedProduct) {
  if (id !== undefined ? id === product.Id : ids.includes(product.Id)) {
    if(updatedProduct) {
      return {
        ...product,
        ...updatedProduct,
        OfferExclusionText: product.OfferExclusionText,
        AvailableQty: product.AvailableQty ? product.AvailableQty : updatedProduct.AvailableQty
      };
    }
    return {
      ...product,
      ...change,
    };
  } else {
    return product;
  }
}

function updateBuyerProductCollections({ state, id, ids, change, updatedProduct }) {
  const result = {};
  if (id === undefined && ids === undefined) {
    return result;
  }
  //for old rails
  for (const collection of buyerProductCollections) {
    result[collection] = {
      ...state[collection],
      products: state[collection].products.map((product) => processProductUpdate(product, id, ids, change, updatedProduct)),
    };
  }

  //for new rails
  result.verticalProducts = { ...state.verticalProducts }
  Object.keys(state.verticalProducts).forEach(k => {
    if(!state.verticalProducts[k]) {
      return
    }
    result.verticalProducts = {
      ...result.verticalProducts,
      [k] : state.verticalProducts[k].map(product => processProductUpdate(product, id, ids, change, updatedProduct))
    }
  })

  //For product description product
  if(state.productDetailMeta && state.productDetailMeta.Product) {
    if(
      (id && id === state.productDetailMeta.Product.Id) ||
      (ids && ids.length && ids.includes(state.productDetailMeta.Product.Id))
    ) {
      result.productDetailMeta = {
        ...state.productDetailMeta,
        Product: processProductUpdate(state.productDetailMeta.Product, id, ids, change, updatedProduct)
      }
    }
  }
  return result;
}

function updateBuyerProductCollectionsForAll({ state, change }) {
  const result = {};

  for (const collection of buyerProductCollections) {
    result[collection] = {
      ...state[collection],
      products: state[collection].products.map((product) => ({
        ...product,
        ...change,
      })),
    };
  }

  return result;
}

function categoryReducer(state = initialState, action) {
  let products;
  const payload = action.payload;

  switch (action.type) {
    case actionTypes.CATALOG_SEARCH_PRODUCTS_FOR_BUYER_DONE:
      products = action.isFirstTime ?
        action.products : [...state.searchProductsForBuyer.products, ...action.products];

      return {
        ...state,
        searchProductsForBuyer: {
          ...state.searchProductsForBuyer,
          products: products,
          nextPage: action.isFirstTime ?
            2 : state.searchProductsForBuyer.nextPage + 1,
          hasMore: action.hasNextPage,
        },
      };

    case actionTypes.REFRESH_CATALOG_SEARCH_PRODUCTS_FOR_BUYER:
      return {
        ...state,
        searchProductsForBuyer: {
          products: [],
          nextPage: 1,
          hasMore: true,
        },
      };

    case actionTypes.CATALOG_GET_ALL_PRODUCTS_FOR_BUYER_DONE:
      products = action.isFirstTime ?
        action.products : [...state.allProductsForBuyer.products, ...action.products];

      return {
        ...state,
        allProductsForBuyer: {
          ...state.allProductsForBuyer,
          products: products,
          nextPage: action.isFirstTime ?
            2 : state.allProductsForBuyer.nextPage + 1,
          hasMore: action.hasNextPage,
        },
      };
    case actionTypes.OFFERS_GET_ALL_DONE:
      products = action.isFirstTime ? [...action.products] : [...state.allOffers.products, ...action.products];
      return {
        ...state,
        allOffers: {
          ...state.allOffers,
          products: products,
          nextPage: action.isFirstTime ? 2 : state.allOffers.nextPage + 1,
          hasMore: action.hasNextPage,
        },
      };

    case actionTypes.OFFERS_GET_FEATURED_DONE:
      products = action.isFirstTime ? [...action.products] : [...state.featuredOffers.products, ...action.products];
      return {
        ...state,
        featuredOffers: {
          ...state.featuredOffers,
          products: products,
          nextPage: action.isFirstTime ? 2 : state.featuredOffers.nextPage + 1,
          hasMore: action.hasNextPage,
        },
      };

    case actionTypes.OFFERS_GET_TRIAL_DONE:
      products = action.isFirstTime ? [...action.products] : [...state.featuredOffers.products, ...action.products];
      return {
        ...state,
        trialOffers: {
          ...state.trialOffers,
          products: products,
          nextPage: action.isFirstTime ? 2 : state.trialOffers.nextPage + 1,
          hasMore: action.hasNextPage,
        },
      };

    case actionTypes.CATALOG_GET_ALL_PRODUCTS_DONE:
      products = action.isFirstTime ?
        action.products : [...state.allProducts.products, ...action.products];

      return {
        ...state,
        allProducts: {
          ...state.allProducts,
          products: products,
          nextPage: action.isFirstTime ? 2 : state.allProducts.nextPage + 1,
          hasMore: action.hasNextPage,
        },
      };

    case actionTypes.CATALOG_GET_CATEGORY_PRODUCTS_BEGIN:
      return {
        ...state,
        categoryProducts: {
          ...state.categoryProducts,
          products: action.isFirstTime ? [] : state.categoryProducts.products,
        },
        categoryProductsRequestStartTime: action.categoryProductsRequestStartTime,
      };

    case actionTypes.CATALOG_GET_CATEGORY_PRODUCTS_DONE:
      products = action.isFirstTime ?
        action.products : [...state.categoryProducts.products, ...action.products];

      return {
        ...state,
        categoryProducts: {
          ...state.categoryProducts,
          products: products,
          nextPage: action.isFirstTime ?
            2 : state.categoryProducts.nextPage + 1,
          hasMore: action.hasNextPage,
        },
      };

    case actionTypes.ALTERNATE_PRODUCTS_BEGIN:
      return {
        ...state,
        alternateProducts: {
          products: [],
        },
      };

    case actionTypes.ALTERNATE_PRODUCTS_DONE:
      const alternateProductsData = [...action.data];
      return {
        ...state,
        alternateProducts: {
          products: alternateProductsData,
        },
      };

    case actionTypes.RESET_ALTERNATE_PRODUCTS:
      return {
        ...state,
        alternateProducts: {
          products: [],
        },
      };

    case actionTypes.RESET_OFFERS:
      return {
        ...state,
        allOffers: {
          products: [],
        },
      };

    case actionTypes.CATALOG_GET_CATEGORY_PRODUCTS_FOR_BUYER_BEGIN:
      return {
        ...state,
        categoryProductsForBuyer: {
          ...state.categoryProductsForBuyer,
          products: action.isFirstTime ? [] : state.categoryProductsForBuyer.products,
        },
        categoryProductsRequestStartTime: action.categoryProductsRequestStartTime,
      };

    case actionTypes.CATALOG_GET_CATEGORY_PRODUCTS_FOR_BUYER_DONE:
      products = action.isFirstTime ? [...action.products] : [...state.categoryProductsForBuyer.products, ...action.products];

      return {
        ...state,
        categoryProductsForBuyer: {
          ...state.categoryProductsForBuyer,
          products: products,
          nextPage: action.isFirstTime ?
            2 : state.categoryProductsForBuyer.nextPage + 1,
          hasMore: action.hasNextPage,
        },
      };

    case actionTypes.CATALOG_GET_BANNER_PRODUCTS_FOR_BUYER_DONE:
      products = action.isFirstTime ? [...action.products] : [...state.getBannerProductsForBuyer.products, ...action.products];

      return {
        ...state,
        getBannerProductsForBuyer: {
          ...state.getBannerProductsForBuyer,
          products: products,
          nextPage: action.isFirstTime ?
            2 : state.getBannerProductsForBuyer.nextPage + 1,
          hasMore: action.hasNextPage,
        },
      };

    case actionTypes.CART_ADD_DONE:
      return {
        ...state,
        ...updateBuyerProductCollections({
          state: state,
          id: action.productId,
          updatedProduct: action.product
        }),
      };

    case actionTypes.CART_ADD_FAIL:
      return {
        ...state,
        ...updateBuyerProductCollections({
          state: state,
          id: action.productId,
          change: {
            DiscountNudgeMessage: action.nudgeMessage,
          },
          updatedProduct: action.alternateProductSuggestion && action.alternateProductSuggestion.IssueProduct ? action.alternateProductSuggestion.IssueProduct : null,
        }),
      };

    case actionTypes.CART_DELETE_DONE:
      return {
        ...state,
        ...updateBuyerProductCollections({
          state: state,
          ids: payload.productIds,
          updatedProduct: action.payload.product
        }),
      };

    case actionTypes.CART_UPDATE_DONE:
      return {
        ...state,
        ...updateBuyerProductCollections({
          state: state,
          id: action.productId,
          updatedProduct: action.product
        }),
      };

    case actionTypes.CART_UPDATE_FAIL:
      return {
        ...state,
        ...updateBuyerProductCollections({
          state: state,
          id: action.productId,
          updatedProduct: action.alternateProductSuggestion && action.alternateProductSuggestion.IssueProduct ? action.alternateProductSuggestion.IssueProduct : null,
          change: {
            DiscountNudgeMessage: action.nudgeMessage,
          },
        }),
      };

    case actionTypes.CART_CLEAR_DONE:
      return {
        ...state,
        ...updateBuyerProductCollectionsForAll({
          state: state,
          change: {
            AlreadyAddedQuantityCount: 0,
            IsAlreadyAddedToCart: false,
          },
        }),
      };

    case actionTypes.V2_CATALOG_GET_CATEGORIES_AND_SUB_CATEGORIES_DONE:
      return {
        ...state,
        v2Categories: action.categories,
        superSaverRail: action.superSaverRail
      };
    
    case actionTypes.GET_CATEGORIES_DONE:
      return {
        ...state,
        v3Categories: action.payload
      }
    case actionTypes.GET_BRAND_SUBCATEGORIES_DONE:
      return {
        ...state,
        brandSubCategories: action.payload
      }
    case actionTypes.GET_BRAND_SUBCATEGORIES_FAIL:
      return {
        ...state,
        brandSubCategories: []
      }

    case actionTypes.GET_ENTITIES_DONE: 
      return {
        ...state,
        entities: action.payload,
        hideSideMenu: action.hideSideMenu,
        switchCategoriesCTA: action.switchCategoriesCTA,
        entityType: action.entityType,
        emptyState: action.emptyState
      }

    case actionTypes.CATALOG_GET_SEARCH_SUGGESTIONS_BEGIN:
      return {
        ...state,
        searchSuggestions: {
          ...initialState.searchSuggestions,
          searchSuggestionsRequestStartTime: action.searchSuggestionsRequestStartTime,
        },
      };

    case actionTypes.CATALOG_GET_SEARCH_SUGGESTIONS_DONE:
      return {
        ...state,
        searchSuggestions: {
          ...state.searchSuggestions,
          products: action.products,
          categories: action.categories,
          subCategories: action.subCategories,
          suggestionList: action.data,
          suggestionListFirstChange: action.isFirstChange ? action.data : state.searchSuggestions.suggestionListFirstChange
        },
      };
    
    case actionTypes.REQUEST_PRODUCT_SUGGESION_DONE:
      return {
        ...state,
        requestProductSuggestion: action.payload
      }
    case actionTypes.REQUEST_PRODUCT_SUGGESTION_CLEAR:
      return {
        ...state,
        requestProductSuggestion: initialState.requestProductSuggestion
      }
    
    case actionTypes.RESET_SEARCH_SUGGESTION:
      return {
        ...state,
        searchSuggestions: {
          ...state.searchSuggestions,
          products: [],
          categories: [],
          subCategories: [],
          suggestionList: null
        },
      };

    case actionTypes.GET_VERTICAL_PRODUCTS_DONE:
      return {
        ...state,
        verticalProducts: action.payload
      }
    
    case actionTypes.GET_PRODUCT_DETAIL_DONE:
      return {
        ...state,
        productDetailMeta: action.payload
      }

    case actionTypes.OUTLET_SWITCH_DONE:
      return initialState;

    case actionTypes.TOGGLE_SIMILAR_ITEMS_MODAL:
      return {
          ...state,
          toggleSimilarModal: action.payload,
      }

    case actionTypes.GET_FILTERS_DONE: 
      return {
        ...state,
        Filters: action.Filters,
        NoSearchResultFound: action.NoSearchResultFound,
        QueryString: action.QueryString
      }
    
    case actionTypes.FILTERS_SET: 
      return {
        ...state,
        FilterIdMap : action.filterIdMap || {},
      }
    case actionTypes.UPDATE_INREDIENT_LIST_DONE:
      return {
        ...state,
        updateIngredientList: action.payload,
      }

    default:
      return state;
  }
}

export default categoryReducer;
