import React from "react";
import moment from "moment";
import Cross from "sushiweb/Icons/all/Cross";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "sushiweb/Button";
import RightTriangle from "sushiweb/Icons/all/RightTriangle";
import { withRouter } from "react-router-dom";
import Spinner from "sushiweb/Spinner";
import InfiniteScroll from "../../../infiniteScroller";

import { v2_getCategoriesWithSubCategories } from "../../../../redux/dispatchers/catalogDispatcher";
import {
  getNotifications,
  markNotificationsRead,
} from "../../../../redux/dispatchers/notificationDispatcher";
import {
  trackPopUp,
  switchOutlet
} from "../../../../redux/dispatchers/userDispatcher";
import styles from "./NotificationPanel.module.scss";
import stateTypes from "../../../../utils/constants/stateTypes";
import { handleActionType } from "../../../../utils/helpers";
import { CTA_COLOUR } from "../../../../utils/constants/others";

class NotificationPanel extends React.Component {
  state = {
    isInDOM: false,
    isVisible: false,
    showSwitchOutletModal: false
  };

  componentDidUpdate(prevProps) {
    const {
      isVisibleProp,
      actions,
      userData,
      v2Categories,
      outletSwitchStatus
    } = this.props;
    const bodyEle = document.getElementsByTagName("BODY")[0];

    if (prevProps.isVisibleProp !== isVisibleProp) {
      if (isVisibleProp) {
        actions.getNotifications(1, true);
        if (v2Categories.length === 0) {
          actions.v2_getCategoriesWithSubCategories(
            true,
            userData.account.id,
            true,
            true,
            userData.outlet.outletAddress.cityId
          );
        }

        this.setState(
          (prevState) => ({
            ...prevState,
            isInDOM: true,
          }),
          () => {
            setTimeout(() => {
              this.setState((prevState) => ({
                ...prevState,
                isVisible: true,
              }));
            }, 0);
          }
        );
        bodyEle.style.overflowY = "hidden";
      } else {
        this.setState(
          (prevState) => ({
            ...prevState,
            isVisible: false,
          }),
          () => {
            setTimeout(() => {
              this.setState((prevState) => ({
                ...prevState,
                isInDOM: false,
              }));
            }, 300);
          }
        );
        bodyEle.style.overflowY = "auto";
      }
    }

    if (
      prevProps.outletSwitchStatus.state !== outletSwitchStatus.state &&
      outletSwitchStatus.state === stateTypes.STATE_DONE && this.state.notificationData
    ) {
      this.handleNotificationAction(this.state.notificationData, this.props.close, true)
    }
  }

  handleNotificationAction(data, close, showNotification = false) {
    const { actions, userData } = this.props;
    const outletId = userData.outlet.id;

    if (data.OutletData.ValidOutletIds && data.OutletData.DefaultOutletId && data.OutletData.ValidOutletIds.indexOf(outletId) < 0 && !showNotification) {
      this.setState({
        notificationData: data,
        showSwitchOutletModal: true,
        defaultOutletName: data.OutletData.DefaultOutletName
      }, () => {
        window.localStorage.setItem("switchOutletToast", data.OutletData.OutletSwitchToast)
        actions.switchOutlet(data.OutletData.DefaultOutletId);
      })
    } else {
      const notificationAction = ((data || {}).Payload || {}).data || {};
      const actionData = notificationAction.actionData ?
        JSON.parse(notificationAction.actionData) :
        "";
      const trackingParams = notificationAction.trackingParams ?
        JSON.parse(notificationAction.trackingParams) :
        "";
      trackingParams && this.props.actions.trackPopUp(trackingParams);
      handleActionType(
        notificationAction.actionType,
        actionData,
        this.props.history, {
          getCategoryAndSubCategoryNames: (params) => this.getCategoryAndSubCategoryNames(params),
          sendReference: true,
          reload: showNotification
        }
      );
      close();
      if (!data.IsRead) {
        actions.markNotificationsRead([data.NotificationId], false);
      }
    }
  }

  /**
   * Render methods
   */
  renderNotification(data, index) {
    const { close } = this.props;
    return (
      <div
        className={`d-flex ${styles.notification} ${
          !data.IsRead ? styles.unread : ""
        }`}
        key={data.NotificationId + "-notification" + index}
        onClick={() => this.handleNotificationAction(data, close)}
      >
        <div className="mr-5">
          <img
            src={data.Payload.notification.image}
            alt="icon"
            className={styles.image}
          />
        </div>
        <div className="flex-grow-1">
          <div className="fs-16 mb-2 w-600">
            {data.Payload.notification.title}
          </div>
          <div className="fs-14 mb-2">{data.Payload.notification.body}</div>
          <div className="fs-14 text-gray">
            {moment(data.CreatedAt).fromNow()}
          </div>
        </div>
      </div>
    );
  }

  renderPanelContent() {
    const {
      getNotificationsStatus,
      notifications,
      history,
      close,
      actions,
    } = this.props;
    
    if (getNotificationsStatus.state === stateTypes.STATE_FAIL) {
      return (
        <div className="flex-grow-1 d-flex justify-content-center align-items-center">
          Couldn't load notifications
        </div>
      );
    } else if (
      getNotificationsStatus.state === stateTypes.STATE_BEGIN &&
      notifications.list.length === 0
    ) {
      return (
        <div className="flex-grow-1 d-flex justify-content-center align-items-center">
          <Spinner size="large" color={CTA_COLOUR} />
        </div>
      );
    } else {
      return notifications.list.length !== 0 ? (
        <div className="wd-100">
          <InfiniteScroll
            dataLength={notifications.list.length}
            hasMore={notifications.hasMore}
            loader={
              <div className="d-flex justify-content-center pt-5 pb-3">
                <Spinner size="large" color={CTA_COLOUR} />
              </div>
            }
            useWindow={false}
            scrollableTarget={this.scrollParentRef}
            endMessage={
              <p className="text-gray fs-12 text-align-center pt-5 pb-3">
                All notifications viewed
              </p>
            }
            next={() => {
              actions.getNotifications(notifications.nextPage, false);
            }}
            className="py-0"
          >
            <div className={styles.infiniteScrollNegativeMargin}>
              {notifications.list.map((notification, index) =>
                this.renderNotification(notification, index)
              )}
            </div>
          </InfiniteScroll>
        </div>
      ) : (
        <div className="flex-grow-1 d-flex justify-content-center align-items-center">
          <div className="wd-75">
            <div className="mb-5 text-align-center">
              <img src="../../assets/images/no-notification.svg" alt="" />
            </div>
            <div className="text-align-center fs-22 mb-3">
              No notifications yet
            </div>
            <div className="text-align-center fs-16 mb-5">
              We promise to update you with our latest offerings soon. Meanwhile
              start shopping and place an order with us!
            </div>
            <div className="text-align-center">
              <Button
                onClick={() => {
                  history.push("/buyer/catalog");
                  close();
                }}
                btnColor="red"
                className="solid w-400"
                icon={<RightTriangle />}
                iconAlign="right"
              >
                Start Shopping
              </Button>
            </div>
          </div>
        </div>
      );
    }
  }

  /**
   * Utility methods
   */
  getCategoryAndSubCategoryNames({ categoryId, subCategoryId }) {
    const { v2Categories } = this.props;

    if (v2Categories.length === 0 || (!categoryId && !subCategoryId)) {
      return null;
    }

    if (subCategoryId) {
      for (let category of v2Categories) {
        for (let subCategory of category.children) {
          if (subCategory.id === subCategoryId) {
            return {
              categoryName: category.name,
              subCategoryName: subCategory.name,
            };
          }
        }
      }
    } else {
      const category = v2Categories.find((el) => el.id === categoryId);
      if (category) {
        return {
          categoryName: category.name,
          subCategoryName: "",
        };
      }
    }
    return null;
  }

  render() {
    const {
      isInDOM,
      isVisible,
    } = this.state;
    const { close } = this.props;

    return isInDOM ? (
      <React.Fragment>
        <div
          className={`${styles.background} ${isVisible ? styles.visible : ""}`}
          onClick={() => close()}
        ></div>
        <div
          className={`d-flex flex-column ${styles.panel} ${
            isVisible ? styles.visible : ""
          }`}
          ref={(ref) => (this.scrollParentRef = ref)}
        >
          <div className={`d-flex ${styles.heading}`}>
            <div className="d-flex flex-grow-1">Notifications</div>
            <div className="d-flex pointer" onClick={() => close()}>
              <Cross size={24} color="#777" />
            </div>
          </div>
          {this.renderPanelContent()}
        </div>
      </React.Fragment>
    ) : null;
  }
}

export default withRouter(
  connect(
    (state) => ({
      userData: state.user.data,
      notifications: state.user.notifications,
      v2Categories: state.catalog.v2Categories,
      getNotificationsStatus: state.status.notifications_get,
      outletSwitchStatus: state.status.user_outlet_switch
    }),
    (dispatch) => ({
      actions: bindActionCreators({
          v2_getCategoriesWithSubCategories,
          getNotifications,
          markNotificationsRead,
          trackPopUp,
          switchOutlet
        },
        dispatch
      ),
    })
  )(NotificationPanel)
);
