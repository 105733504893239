import React from "react";
import { useRef } from "react";
import { useEffect,useState} from "react";
import Spinner from "sushiweb/Spinner/Spinner";
import { MODAL_TYPE_VERIFY_FORGET_PASSWORD_OTP, MODAL_TYPE_VERIFY_FORGET_PASSWORD_PASSWORD_MATCH, MODAL_TYPE_VERIFY_OTP,MODAL_TYPE_SIGN_UP_OTP, GET_PERSONAL_DETAILS,GET_RES_LOCATION_DETAILS_2,GET_RES_LOCATION_DETAILS, GET_PAN_DETAILS, GET_GST_DETAILS, GET_FSSAI_DETAILS, GET_UPLOAD_DETAILS,COMPLETE_ONBOARDING_SETUP,BackPressModal,BackPressModalActionTypes, KEY_TNC, KEY_WHATS_APP_NOTIF_STATUS, STATUS_VERIFIED,STATUS_VERIFY, PAN_NUMBER, FSSAI_NUMBER, GST_NUMBER, PINCODE, ADDRESS, EMAIL, INVALID_PINCODE_ERROR, ADDRESS_VALIDATION_ERROR, PAN_ACTION, GST_ACTION, FSSAI_ACTION, EMAIL_ERROR } from "../../../utils/constants/others";
import Button from "../Button";
import Image from "../Image/Image";
import CustomInputs from "../Inputs/CustomInputs";
import styles from "./LoginScreens.module.scss";  
import Checkbox from "sushiweb/InputTypes/Checkbox";
import { HTMLDiv } from "../../shared/HTMLDiv"
import _isEmpty from "lodash-es/isEmpty";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { trackPopUp } from "../../../redux/dispatchers/userDispatcher";
import { RegexEmail } from "../../../utils/regex";


const LoginScreens = ({
    ImageUrl,
    Heading, 
    SubHeading,
    InputType,
    Fields, 
    SetFields,
    Buttons,
    renderModalInputSubHeading,
    onMountCall,
    loading,
    OnClose,
    CheckBoxes,
    sendTracking,
    setReceiveWhatsAppNotid,
    setTncStatus,
    AllFields,
    SetAllFields,
    handleFileSelections,
    Reset,
    documentVerificationData,
    setGstValueAndSelectedParam,
    setUploadDetailsFields,
    documentJobs,
    setDocumentJobs,
    PollingDuration,
    uploadCTAEnabled,
    setUploadCTAEnabled,
    handleBackPress,
    newModal,
    entityDetails,
    BottomMessage
}) => {

    const [checkBoxData,setCheckBoxData] = useState([])

    const handleCheckBoxCheckUncheck = (key,state) =>{
        if (key=== KEY_TNC){
            setTncStatus({key:key,value:state})
        }else if (key=== KEY_WHATS_APP_NOTIF_STATUS){
            setReceiveWhatsAppNotid({key:key,value:state})
        }

        let arr=checkBoxData.map((data)=>{
            
            if (data.Key!==key){
                return data
            }else{
                if (state){
                    sendTracking(data?.CheckTrackingParams)
                }else{
                    sendTracking(data?.UncheckTrackingParams)
                }
                return {...data,["Checked"]:!data?.Checked}
            }
        })
        setCheckBoxData(arr)
    }

    useEffect(()=>{
        if ((InputType === MODAL_TYPE_VERIFY_OTP || InputType === MODAL_TYPE_SIGN_UP_OTP || InputType === MODAL_TYPE_VERIFY_FORGET_PASSWORD_OTP) && typeof onMountCall === "function"){
                onMountCall()
        }
        if (InputType === MODAL_TYPE_SIGN_UP_OTP){
            setCheckBoxData(CheckBoxes)
            CheckBoxes.map((data)=>{
                if (data.Key===KEY_TNC){
                    setTncStatus({key:data.Key,value:data.Checked})
                }else if (data.Key===KEY_WHATS_APP_NOTIF_STATUS){
                    setReceiveWhatsAppNotid({key:data.Key,value:data.Checked})
                }
            })
        }

        
    },[InputType])

    const handleSetFields = (value,idx) => {
        let arr = AllFields.map((field,index)=>{
            if (idx!==index){
                
                if (idx === 0 && (field?.label === "FSSAI number" || field?.label === "License registration number")) {
                    let attribute = {}
                    AllFields.map((attributes)=>{
                        attributes && attributes.options && attributes.options?.length && attributes.options.map((option)=>{
                            if (option?.Text === value){
                                attribute = option
                            }
                        })
                    })

                
                    return {
                        ...field,
                        ["key"]:"FSSAI Document",
                        ["label"]:attribute?.TextHint,
                        ["value"]:attribute?.Number,
                        ["keyType"]:"input",
                        ["editable"]:attribute?.IsEditable,
                        ["inlineCTA"]:attribute?.VerifyCTA?.Text,
                        ["inlineCTAColor"]:attribute?.VerifyCTA?.TextColor,
                    }
                }

                return field
            }else{
                if (field?.key===PAN_NUMBER){
                    // if(documentVerificationData?.DocumentDetails?.Data?.Number!== value && value?.length==10){
                    //     return {...field,["value"]:value.toUpperCase(),["Button"]:{loaderVisible:false,text:STATUS_VERIFY,color:'red',action:PAN_ACTION,disabled:false,visible:true,queryKey:PAN_NUMBER}}
                    // }else{
                    //     return {...field,["value"]:value.toUpperCase(),["Button"]:{loaderVisible:false,text:STATUS_VERIFY,color:'red',action:PAN_ACTION,disabled:false,visible:false,queryKey:PAN_NUMBER}}
                    // }
                }else if (field?.key===FSSAI_NUMBER){
                    if(documentVerificationData?.DocumentDetails?.Data?.Number!== value && value?.length==14){
                        return {...field,["value"]:value.toUpperCase(),["Button"]:{loaderVisible:false,text:STATUS_VERIFY,color:'red',action:FSSAI_ACTION,disabled:false,visible:true,queryKey:FSSAI_NUMBER}}
                    }else{
                        return {...field,["value"]:value.toUpperCase(),["Button"]:{loaderVisible:false,text:STATUS_VERIFY,color:'red',action:FSSAI_ACTION,disabled:false,visible:false,queryKey:FSSAI_NUMBER}}
                    }
                }else if (field?.key===GST_NUMBER){
                    if(value?.length==15){
                        return {...field,["value"]:value.toUpperCase(),["Button"]:{loaderVisible:false,text:STATUS_VERIFY,color:'red',action:GST_ACTION,disabled:false,visible:true,queryKey:GST_NUMBER}}
                    }else{
                        return {...field,["value"]:value.toUpperCase(),["Button"]:{loaderVisible:false,text:STATUS_VERIFY,color:'red',action:GST_ACTION,disabled:false,visible:false,queryKey:GST_NUMBER}}
                    }
                }else if(field?.key===ADDRESS){
                    if(value.length<10){
                        let errorMessage = ADDRESS_VALIDATION_ERROR
                        return {...field,["value"]:value,["error"]:errorMessage}
                    }else{
                        return {...field,["value"]:value,["error"]:""}
                    }
                }else if(field?.key===PINCODE){
                    if(!Number(value)){
                        if(value?.length < 7){
                            let errorMessage = INVALID_PINCODE_ERROR
                            return {...field,["error"]:errorMessage}
                        }else{
                            return {...field}
                        }
                    }else{
                        if(value?.length < 6){
                            return {...field,["value"]:value,["error"]:"Please enter valid 6 digit pincode"}
                        }else if (value?.length == 6){
                            return {...field,["value"]:value,["error"]:""}
                        }else{
                            return {...field,["error"]:""}
                        }
                    }
                }else if(field?.key===EMAIL){
                    let errorMessage = EMAIL_ERROR

                    let result = RegexEmail(value)
                    if(!result){
                        return {...field,["value"]:value,["error"]:errorMessage}
                    }else{
                        return {...field,["value"]:value,["error"]:""}
                    }
                }else{
                    return {...field,["value"]:value}
                }
            }
        })
        SetAllFields(arr)
    }

    const handlesetUploadDetailsFields = (key,val,idx,finalStateResponse,finalStatesArr,bottomMessage) => {
       let arr = []
        if (!finalStateResponse){
            arr = AllFields.map((field,index)=>{
                if (idx!==index){
                    return field
                }else{
                    return {...field,[key]:val}
                }
            })
            setUploadDetailsFields(arr)
        }else{
           let fssaiDocument={}
           let panDocument={}
           let gstDocument={}
        
                   finalStatesArr.forEach((state,index2)=>{
                    if("PAN" === state?.DocumentType){
                        panDocument={
                            "DocumentType":AllFields[1].DocumentType,
                            "DocumentHeading":AllFields[1].DocumentHeading,
                            "DocumentSubHeading":AllFields[1].DocumentSubHeading,
                            "CTAText":state.CTAText,
                            "Status":state.Status,
                            "CTAImage":state.CTAImage,
                            "ErrorImage":state.ErrorImage,
                            "ErrorString":state.ErrorString,
                            "value":AllFields[1].value,
                            "jobId":state.jobId,
                            "uploadNudgeMessage":{imageIcon:bottomMessage?.ImageIcon,title:bottomMessage?.Title}
                        } 
                    }else if("FSSAI" === state?.DocumentType){
                        fssaiDocument={
                            "DocumentType":AllFields[0].DocumentType,
                            "DocumentHeading":AllFields[0].DocumentHeading,
                            "DocumentSubHeading":AllFields[0].DocumentSubHeading,
                            "CTAText":state.CTAText,
                            "Status":state.Status,
                            "CTAImage":state.CTAImage,
                            "ErrorImage":state.ErrorImage,
                            "ErrorString":state.ErrorString,
                            "value":AllFields[0].value,
                            "jobId":state.jobId,
                            "uploadNudgeMessage":{imageIcon:bottomMessage?.ImageIcon,title:bottomMessage?.Title}
                        } 
                    }else if("GST" === state?.DocumentType){
                        gstDocument={
                            "DocumentType":AllFields[0].DocumentType,
                            "DocumentHeading":AllFields[0].DocumentHeading,
                            "DocumentSubHeading":AllFields[0].DocumentSubHeading,
                            "CTAText":state.CTAText,
                            "Status":state.Status,
                            "CTAImage":state.CTAImage,
                            "ErrorImage":state.ErrorImage,
                            "ErrorString":state.ErrorString,
                            "value":AllFields[0].value,
                            "jobId":state.jobId,
                            "uploadNudgeMessage":{imageIcon:bottomMessage?.ImageIcon,title:bottomMessage?.Title}
                        }
                    }
                })
                if(_isEmpty(panDocument) && AllFields.length===2){
                    panDocument=AllFields[1]
                }else if (_isEmpty(fssaiDocument) && AllFields.length===2){
                    fssaiDocument=AllFields[0]
                }else if(_isEmpty(gstDocument) && AllFields.length===1){
                    gstDocument=AllFields[0]
                }

                if(AllFields.length===2){
                    setUploadDetailsFields([fssaiDocument,panDocument])
                }else if(AllFields.length===1){
                    setUploadDetailsFields([gstDocument])
                }
        }
    }

    const handleDocumentJobs = (documentType,jobId) => {
        let docexist=false
        let arr = documentJobs.map((document,index)=>{
            if (document.DocumentType !== documentType){
                return document
            }else{
                docexist=true
                return {...document,["JobId"]:jobId}
            }
        })
        if (!docexist){
            arr=[...arr,{"DocumentType":documentType,"JobId":jobId}]
        }
        setDocumentJobs(arr)
    }

    

    const handleTextClickablePart = (text,substring) => {
        
       
        const startIndex = text.indexOf(substring);
        const endIndex = startIndex + substring.length;
       
        return text.slice(0, startIndex)
    }


    const handleSubstringClick = (trackingParams) => {
        sendTracking(trackingParams)
    };

    const prepareCheckBox = (data,index) => {
        let preText=""
        let text= ""
        if (data?.Text.indexOf("<span style='color:#EF4F5F;'>terms & conditions</span>") !==-1){
             preText = handleTextClickablePart(data?.Text,"<span style='color:#EF4F5F;'>terms & conditions</span>")
             text=preText

             return <div className="d-flex pointer">
                <Checkbox
            checked={data?.Checked}
            label={<div className="d-flex"><HTMLDiv className={"mr-2"} html={text}/> </div>}
            key={data.Key}
            onChange={() => handleCheckBoxCheckUncheck(data?.Key,!data.Checked) }
            color={"#EF4F5F"}
            />
            <Link to={"/"+data?.CTA?.Action?.ActionData?.WebViewURL.split("/").slice(-1)} target="_blank" className="mt-1" onClick={()=>handleSubstringClick(data?.CTA?.Action?.TrackingParams)} style={{color:'#EF4F5F'}}> terms & conditions</Link>
             </div>
        }else{
            text=data?.Text
            return <Checkbox
            color={"#EF4F5F"}
            checked={data?.Checked}
            label={<HTMLDiv html={text}/>}
            key={data.Key}
            onChange={() => handleCheckBoxCheckUncheck(data?.Key,!data.Checked) }
        />
        }
    }

return (
    <div>
       
        {
            newModal ? <div className={`${styles.subTopBox} d-flex justify-content-between`}>
                <div className="d-flex justify-content-center align-items-center">
                {BackPressModal[InputType]?<svg className="pointer" onClick={()=>{handleBackPress(InputType,BackPressModalActionTypes[InputType])}} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8483 7.15128C21.317 7.61991 21.317 8.3797 20.8483 8.84833L13.6969 15.9998L20.8483 23.1513C21.317 23.6199 21.317 24.3797 20.8483 24.8483C20.3797 25.317 19.6199 25.317 19.1513 24.8483L11.1513 16.8483C10.6826 16.3797 10.6826 15.6199 11.1513 15.1513L19.1513 7.15128C19.6199 6.68265 20.3797 6.68265 20.8483 7.15128Z" fill="#1C1C1C"/>
                </svg>:<></>}
                    <div className={BackPressModal[InputType]?`${styles.headingFontSize} ml-2 w-800`:`${styles.headingFontSize} w-800`}>{Heading}</div>
                </div>
                
                <div className={`${styles.headingImageSize}`}><img onClick={OnClose} src={process.env.PUBLIC_URL + "/assets/images/cross.png"} className={`img32 pointer ${styles.widthInherit} wd-100 height-100 `}/></div>
            </div>:<></>
        }

        <div className={ BottomMessage?.Title ?`${styles.paddingModalWithBottomMessage}`:`${styles.paddingModal}`} >
            {!newModal?ImageUrl?.length?<div className={"mb16"}>
                <Image source = {ImageUrl} classname={"img160"}/>
            </div>:null:<></>}
            {!newModal ? <div className="fs-30 w-800">{Heading}</div> : <></>}
            <div className={!newModal ?`fs-16 ${styles.subHeadingColor}`:`fs-16`}>{SubHeading}</div>
            <div className={InputType===GET_RES_LOCATION_DETAILS_2?"":"mb40"}></div>
            {InputType!==GET_PERSONAL_DETAILS && InputType!==GET_RES_LOCATION_DETAILS_2 && InputType!==GET_PAN_DETAILS && InputType!==GET_GST_DETAILS && InputType!==GET_FSSAI_DETAILS && InputType!==GET_UPLOAD_DETAILS && InputType!==GET_RES_LOCATION_DETAILS?<div>
                <CustomInputs 
                    value={Fields.value}
                    onKeyDown = {(e)=>{
                        if (e.which === 13 && Buttons?.length){
                            let currButton = Buttons[Buttons.length -1];
                            if (!currButton.disabled) {
                                currButton.onClick();
                            }
                        }
                    }}
                    onChange = {
                        (val)=> {
                        SetFields({
                            ...Fields,
                            value: val, 
                        })
                    }}
                    label = {InputType === MODAL_TYPE_VERIFY_FORGET_PASSWORD_PASSWORD_MATCH ? "Enter new password" : ""}
                    error = {InputType === MODAL_TYPE_VERIFY_FORGET_PASSWORD_PASSWORD_MATCH ? "" : Fields.error}
                    inputType = {InputType}
                />

                {InputType === MODAL_TYPE_VERIFY_FORGET_PASSWORD_PASSWORD_MATCH ?  
                <>
                <div className="mb-2"></div>
                <CustomInputs 
                    value={Fields.checkValue}
                    onChange = {(val)=> {
                        SetFields({
                            ...Fields,
                            checkValue: val, 
                        })
                    }}
                    label = {InputType === MODAL_TYPE_VERIFY_FORGET_PASSWORD_PASSWORD_MATCH ? "Re-enter new password" : ""}
                    error = {Fields.error}
                    inputType = {InputType}
                /> </>:<></>}
            </div>:<div className={InputType === GET_UPLOAD_DETAILS ? `${styles.customInputs}`: `${styles.customInputsScrollable}`}>
                <CustomInputs 
                    values={AllFields}
                    onKeyDown = {(e)=>{
                        if (e.which === 13 && Buttons?.length){
                            let currButton = Buttons[Buttons.length -1];
                            if (!currButton.disabled) {
                                currButton.onClick();
                            }
                        }
                    }}
                    onChange = {
                        (val,idx)=> handleSetFields(val,idx)}
                    inputType = {InputType}
                    reset={Reset}
                    setGstValueAndSelectedParam={setGstValueAndSelectedParam}
                    setUploadDetailsFields={handlesetUploadDetailsFields}
                    documentJobs={documentJobs}
                    setDocumentJobs={handleDocumentJobs}
                    PollingDuration={PollingDuration}
                    uploadCTAEnabled={uploadCTAEnabled}
                    setUploadCTAEnabled={setUploadCTAEnabled}
                    entityDetails={entityDetails}
                    handleFileSelections={handleFileSelections}
                />
            </div>
            }
            <div className="mb40"></div>
            <div className={InputType === MODAL_TYPE_SIGN_UP_OTP?`mb32`:``}>
                {typeof renderModalInputSubHeading === "function" ? renderModalInputSubHeading() :<></>}
            </div>
            <div className={`${styles.loaderPoisiton} d-flex justify-content-center wd-100`}>{loading ? <Spinner color={"#EF4F5F"}/>: <></>}</div>
        </div>

        {
            AllFields?.length>=1 && (BottomMessage?.ImageIcon?.length && BottomMessage?.Title?.length) && <div className={`${styles.bottomNudge} position-absolute d-flex justify-content-center align-items-center`}>
                {BottomMessage?.ImageIcon?.length && <Image classname={`img40 mr-12px`} source={BottomMessage?.ImageIcon}/>}
                {
                   BottomMessage?.Title && <span className="fs-14 w-400">{BottomMessage?.Title}</span>
                }
            </div>
        }
        
        <div className={`${styles.btnBottomBar} d-flex flex-column`}>
            <div>
                {
                    
                    checkBoxData.map((data,index)=>{
                     return prepareCheckBox(data,index)
                })
                }

            </div>
            <div className={`${styles.modalButtons}`}>{Buttons.map(
            (btn,index) => 
            <Button 
            onClick = {btn.onClick}
            disabled = {btn.disabled}
            appearance = {btn.btnType}
            className={`${styles.btnMobile} wd-100 ${index !== Buttons.length -1 ? "mr-3" : ""} ${styles.btnBorder}`}
            >{btn.title}</Button>
            )}
            </div>
        </div>
    </div>
)
}

export default withRouter(
    connect(
      (state) => ({
      }),
      (dispatch) => ({
        actions: bindActionCreators({
            trackPopUp,
        },
          dispatch
        ),
      })
    )(LoginScreens)
  );
