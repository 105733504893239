import styles from "./CarouselArrow.module.scss"
import ChevronRight from "sushiweb/Icons/all/ChevronRight";
import ChevronLeft from "sushiweb/Icons/all/ChevronLeft";
import React from "react"

const CustomLeftArrow = ({ onClick, dimension, className,refCustom }) => {
    return (
      <div 
      ref={refCustom}
        onClick={onClick} 
        className={`${styles.paddle} ${className}`}
        style={{
          width: `${dimension}px`,
          height: `${dimension}px`,
          left: 0
        }}
      >
        <ChevronLeft color="#EF4F5F" size={32}/>
      </div>
    );
  };
  
const CustomRightArrow = ({ onClick, dimension, className, refCustom }) => {
    return (
      <div 
      ref ={refCustom}
        onClick={onClick} 
        className={`${styles.paddle} ${className}`}
        style={{
          width: `${dimension}px`,
          height: `${dimension}px`,
          right: 0
        }}
      >
        <ChevronRight color="#EF4F5F" size={32}/>
      </div>
    );
  };

  const ButtonGroup = ({ next, previous, goToSlide,currentIndex,setCurrentSlide,totalSlides, ...rest }) => {
    const { carouselState: { currentSlide, totalItems, slidesToShow }, dimension, isInfinite, type , notVisible, rowLeftRef , rowRightRef , handleClick} = rest;
    const previousClick = ()=>{
      if (typeof setCurrentSlide === "function") {
        setCurrentSlide(currentIndex-1 <=0 ? totalSlides - currentIndex+ 1 : currentIndex-1)
      }
      previous()
    }

    const nextClick = ()=>{
      if (typeof setCurrentSlide === "function") {
        setCurrentSlide(currentIndex+1 > totalSlides ? currentIndex+1 - totalSlides:currentIndex+1)
      }
      next()
    }
    const getLeftClassByType = () => {
      if (notVisible){
        return styles.displayNone
      }
      switch(type) {
        case "productRail":
          return styles.productRailLeft;
        case "subCategory":
          return styles.scLeft;
        case "banners": 
        case "brand": 
          return styles.bannerLeft;
        case "testimonials":
          return styles.testimonialLeft;
        case "aerobar":
          return styles.aerobarLeft;
        case "discount":
          return styles.discountLeft;
        default:
          return ""
      }
    }

    const getRightClassByType = () => {
      if (notVisible){
        return styles.displayNone
      }
      switch(type) {
        case "productRail":
          return styles.productRailRight;
        case "subCategory":
          return styles.scRight;
        case "banners": 
        case "brand": 
          return styles.bannerRight;
          case "testimonials":
          return styles.testimonialRight;
        case "aerobar":
          return styles.aerobarRight;
        case "discount":
          return styles.discountRight;
        default:
          return ""
      }
    }
 
    return (
      <div className={`carousel-button-group ${notVisible? "d-none": ""} ${styles.carouselBtns}`}>
        <CustomLeftArrow refCustom ={rowLeftRef} className={currentSlide === 0  && !isInfinite ? 'd-none' : getLeftClassByType()} onClick={() => {previousClick() ; if (typeof handleClick === "function"){handleClick("left")}}}  dimension={dimension} />
        <CustomRightArrow refCustom ={rowRightRef} className={(currentSlide === totalItems - slidesToShow || totalItems <= slidesToShow) && !isInfinite ? 'd-none': getRightClassByType()} onClick={() => {nextClick();; if (typeof handleClick === "function"){handleClick("right")}}} dimension={dimension} />
      </div>
    );
  };

export {
  CustomLeftArrow,
  CustomRightArrow,
  ButtonGroup
}