import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Modal from "sushiweb/Modal";
import styles from "./AccountStatement.module.scss";  
import Button from "../Button";
import "react-datepicker/dist/react-datepicker.css";
import { FilterPill } from "../filters/FilterPill";
import Input from "sushiweb/InputTypes/Input";
import {
  getSOAOptions,
  getSOAFilePath,
  toggleSOAModal,
} from "./../../../redux/dispatchers/userDispatcher";
import {
  downloadStatement,
} from "./../../../redux/dispatchers/statementDispatcher";
import moment from "moment";
import Image from "../../shared/Image/Image";
import Datepicker from "../datePicker/DatePicker";

const AccountStatement = (props) => {

  const [dateSelectionType , setDateSelectionType] = useState("Last Month")
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [downloadButton, setDownloadButton] = useState({
    enabled: false,
    loading: false,
  })
  const [emailButton, setEmailButton] = useState({
    enabled: false,
    loading: false,
  })

  const  handleDateSelectionTypeOnClickAction = (dateSelectionType,startDateStr,endDateStr) => {
    setDateSelectionType(dateSelectionType)
    if (startDateStr.length > 0 ) {
      setStartDate(new Date(moment(startDateStr).format("YYYY-MM-DD")))
    } else {
      setStartDate()
    }
    if (endDateStr.length > 0 ) {
      setEndDate(new Date(moment(endDateStr).format("YYYY-MM-DD")))
    } else {
      setEndDate()
    }
  }

  useEffect(() => {
    if (props?.soaOptions?.Options && startDate.length === 0 && endDate.length === 0) {
      props.soaOptions.Options.map((item) => {
        if (item.Name === dateSelectionType && dateSelectionType === "Last Month") {
          handleDateSelectionTypeOnClickAction(
            item.Name,
            item.StartDate,
            item.EndDate
          );
        }
      });
    }
  }, [props]);

  useEffect(() => {
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (!isNaN(diffDays) && diffDays >= 0 && diffDays < 93 && endDate >= startDate) {
      setDownloadButton({
        ...downloadButton,
        enabled: true,
      })
    } else {
      setDownloadButton({
        ...downloadButton,
        enabled: false,
      })
    } 

    if (!isNaN(diffDays) && diffDays >= 0 && endDate >= startDate){
      setEmailButton({
        ...emailButton,
        enabled: true,
      })
    } else {
      setEmailButton({
        ...emailButton,
        enabled: false,
      })
    }
  }, [endDate,startDate]);

  useEffect(() => {
    props.actions.getSOAOptions()
  },[]);

  const downloadSOAFilePath = () => {
    setDownloadButton({
      ...downloadButton,
      loading: true,
    })
    let params = {
      "StartDate": moment(startDate).format("YYYY-MM-DD"),
      "EndDate": moment(endDate).format("YYYY-MM-DD"),
      "Download": true,
      "SendEmail": false
    }
    props.actions.getSOAFilePath(params,
      (data) => {
        props.actions.downloadStatement( data.FilePath, 5, 5, "soa", "xlsx",{
          Text: data?.MessageToast?.Text,
          ImagePath: data?.MessageToast?.ImagePath,
        }, () => {
          setDownloadButton({
            ...downloadButton,
            loading: false,
          })
        }, () => {
          setDownloadButton({
            ...downloadButton,
            loading: false,
          })
        }
        )

      },
      () => {
        setDownloadButton({
          ...downloadButton,
          loading: false,
        })
      }
    )
  }

  const sendSOAEmail = () => {
    setEmailButton({
      ...emailButton,
      loading: true,
    })
    let params = {
      "StartDate": moment(startDate).format("YYYY-MM-DD"),
      "EndDate": moment(endDate).format("YYYY-MM-DD"),
      "Download": false,
      "SendEmail": true
    }
    props.actions.getSOAFilePath(params,
      () => {
      setEmailButton({
        ...emailButton,
        loading: false,
      })
      },
      () => {
        setEmailButton({
          ...emailButton,
          loading: false,
        })
      }, true
    )
  }

  return (
      <div>
          <Modal
          visible={props.showSOAModal}
          onClose={()=>{
            props.actions.toggleSOAModal()
          }}
          showCloseIcon={true}
          title={<div className="ml-2 fs-26 w-800">Choose accounting period</div>}
          isCentered
          className={`${styles.soaModalsLarge}`}
          >
              <div  className={`${styles.pillBorder}`}>
                <div className={`${styles.pills} d-flex flex-wrap py16`}>
                {props?.soaOptions?.Options?.map((item) => 
                  <FilterPill
                    Text={item.Name}
                    Style={dateSelectionType===item.Name?"active":""}
                    OnClick={()=>{handleDateSelectionTypeOnClickAction(item.Name,item.StartDate,item.EndDate)}}
                  />
                )}
                </div>
              </div>
                <div className={`${styles.mbt15} justify-content-between ${styles.pdLeftRight2}`}>
                <div className={`ml-2 fs-20 w-800 `}>Select Date</div>
                  <div className={`d-flex ${styles.datePickers} align-items-center justify-content-between`}>  
                    <Datepicker
                        dateFormat="MMMM d, yyyy"
                        showMonthDropdown
                        showYearDropdown
                        yearDropdownItemNumber={5}
                        selected={startDate}
                        onChange={(val)=>{setStartDate(val)}}
                        onChangeRaw={(e) => e.preventDefault()}
                        customInput={
                          <Input
                            label= "Start Date" 
                            icon={
                              <Image 
                                source = {props?.soaOptions?.Utility?.Icon? props.soaOptions.Utility.Icon : ""}
                              />
                            }
                          />
                        }
                        disabled = {(dateSelectionType != "Custom" && dateSelectionType != "" ) ? true:false}
                        className={ `${styles.datePicker} ${styles.pdr2}`}
                    />
                    <Datepicker
                        dateFormat="MMMM d, yyyy"
                        showMonthDropdown
                        showYearDropdown
                        yearDropdownItemNumber={5}
                        selected={endDate}
                        onChange={(val)=>{setEndDate(val)}}
                        onChangeRaw={(e) => e.preventDefault()}
                        customInput={
                          <Input
                            label= "End Date"
                            icon={
                              <Image 
                                source = {props?.soaOptions?.Utility?.Icon? props.soaOptions.Utility.Icon : ""}
                              />
                            }
                          />
                        }
                        disabled = {(dateSelectionType != "Custom" && dateSelectionType != "" ) ? true:false}
                        className={ `${styles.datePicker}`}
                    />
                  </div>
              </div>
              <div className={` d-flex flex-column  ${styles.pdLeftRight2}`}>
                <div className={`${styles.notes}`}></div>
                <div>
                  { 
                    props?.soaOptions?.Notes?.Header?
                    <div className={`d-flex ${styles.mrt15} text-cool-gray-400`}>{props?.soaOptions?.Notes?.Header}</div>
                      : 
                    <></>
                  }
                </div>
                <div>   
                  {
                    props?.soaOptions?.Notes?.NoteItems.map((item) => 
                      <div className={`d-flex ${styles.pd} text-cool-gray-400`}>{item.SerialNumber} {item.Text}</div>
                    )
                  }
                </div>
              </div>
             {
              !downloadButton.enabled && emailButton.enabled ?
              <div className={`${styles.downloadSOARemarkBorder}`}>
              <div className={`d-flex justify-content-center align-items-center ${styles.downloadSOARemark} `}>
                {
                  props?.soaOptions?.Footer?.Nudge?.ImagePath ?
                  <Image
                  source={props?.soaOptions?.Footer?.Nudge?.ImagePath}
                  classname={`${styles.pdLeftRight2}`}
                /> : <></>
                }
                {
                  props?.soaOptions?.Footer?.Nudge?.Text ? 
                  <div className={`d-flex justify-content-center `}>
                  {props?.soaOptions?.Footer?.Nudge?.Text}
                  </div>
                   : <></>
                }
              </div>
              </div>
               : <></>
              }
              <div className={`d-flex ${styles.downloadSOAutton}  justify-content-center align-items-center`}>
                  <Button 
                      appearance = "solid"
                      onClick={()=>sendSOAEmail()}
                      className={ `mr-3 wd-100 ${styles.btnBorder}`}
                      disabled={!emailButton.enabled}
                      loading={emailButton.loading}
                  >
                    Send Email
                  </Button>
                  <Button 
                      appearance = "outline"
                      disabled={!downloadButton.enabled}
                      loading={downloadButton.loading}
                      onClick={()=>downloadSOAFilePath()}
                      className={ `mr-3 wd-100 ${styles.btnBorder}`}
                  >
                    Download
                  </Button>
              </div>
          </Modal>
      </div>

  )
}

export default withRouter(
    connect(
      (state) => ({
        showSOAModal: state.user.showSOAModal,
        soaOptions : state.user.soaOptions,
        errorToast: state.user.errorToast,
      }),
      (dispatch) => ({
        actions: bindActionCreators(
          {
            getSOAOptions,
            getSOAFilePath,
            downloadStatement,
            toggleSOAModal,
          },
          dispatch
        ),
      })
    )(AccountStatement)
  );