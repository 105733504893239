import actionTypes from "../actionTypes";

export const setToastMessage = (message , type) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_TOAST_MESSAGE_DONE ,
    message: message,
    toasttype: type});
  }
}

export const resetToastMessage = () => {
    return (dispatch) => {
      dispatch({ type: actionTypes.SET_TOAST_MESSAGE_INIT});
    }
  }
