import React, { useEffect } from "react";
import Input from "sushiweb/InputTypes/Input"
import styles from "./CustomInputs.module.scss";
import Datepicker from "../datePicker/DatePicker";
import Image from "../../shared/Image/Image";
import { HTMLDiv } from "../HTMLDiv";
import Dropdown from "sushiweb/Dropdown";
import MenuItem from "sushiweb/MenuItem";
import SpinnerCircular from "sushiweb/Spinner/SpinnerCircular";
import { red, yellow, green, orange, purple, teal } from "sushiweb/tokens/color";
import { STATUS_VERIFIED } from "../../../utils/constants/others";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import stateTypes from "../../../utils/constants/stateTypes";

function FssaiInputs(props){

    const getMenuItems = (options) => {
        let arr = []
        options && options.map((option)=>{
            arr.append(option?.Text)
        })
        return arr
    }

    useEffect(()=>{
        
    },[props?.documentNumberVerificationDetailsState,props?.documentVerificationDetails])

    const [verifyloader,setVerifyLoader] = useState(false)
    const [inputChange,setInputChange] = useState(false)

    const handleIconButtonClick = () => {
        if (props && typeof(props.reset)==='function'){
            props.reset()
        }
        
        setVerifyLoader(true)
        setInputChange(false)
    }

    const checkVerifyToShowOrNot = () => {

        if ((props?.documentNumberVerificationDetails?.DocumentDetails?.PrimaryCTAEnabled || props?.documentNumberVerificationDetails?.VerificationStatus?.Text === STATUS_VERIFIED) && !inputChange){       
            return false
        }

        if (props?.values.length >= 2
             && props?.values[1].value?.length > 0
             && (props?.values[1].value !== props.documentVerificationDetails.DocumentDetails.Data.PreSelectedDocument.Number)) {
                return true 
        }
        return false
    }

    const getIconOnInput = (data,color) => {
        return <div className="pointer" style={{color:data === STATUS_VERIFIED ? green.z500 :color?.length > 0 ?`${color}` : "#EF4F5F"}}> 
            {
                (props?.documentNumberVerificationDetailsState?.state === stateTypes.STATE_BEGIN)?<SpinnerCircular
                    color={red.z500}
                    size="small"
                />:checkVerifyToShowOrNot()?<span onClick={()=>{handleIconButtonClick()}}>{data}</span>:""
            }
        </div>
    }   

    const handleChange = (val,idx) => {
        setInputChange(true)
        if (props && typeof(props.onChange) === 'function'){
            props.onChange(val,idx)
        }
    }

    const getInputByInputType = (inputField,idx) => {
        if (inputField?.keyType === "input" && inputField?.visible) {
            return <Input
                value={inputField?.value}
                onChange={(val)=>handleChange(val,idx)}
                label = {inputField?.label}
                className={!inputField?.editable ? `mb-15 ${styles.disabledInput} ${styles.genericInput} `:`mb-20 ${styles.genericInput} `}
                errorMsg = {inputField?.error?.length ? <HTMLDiv html={inputField?.error}/> : ""}
                disabled = {!inputField?.editable}
                icon = {inputField?.inlineCTA?.length ? getIconOnInput(inputField?.inlineCTA,inputField?.inlineCTAColor):""}
            />
        }else if (inputField?.keyType === "calendar" && inputField?.visible){
            return <div className={ `${styles.datePicker} ${styles.genericInput} ${styles.pdr2}`}><Datepicker
                        dateFormat="MMMM d, yyyy"
                        showMonthDropdown
                        showYearDropdown
                        yearDropdownItemNumber={5}
                        selected={inputField?.value}
                        onChange={(val)=>{props && props.onChange(val,idx)}}
                        onChangeRaw={(e) => e.preventDefault()}
                        customInput={
                          <Input
                            className="position-relative"
                            label= {inputField?.key}
                            icon={
                              <Image
                                classname = {`${styles.calendarIcon}`}
                                source = {"/assets/images/datePickerIcon.png"}
                              />
                            }
                          />
                        }
                        />
                    </div>
        }else if (inputField?.keyType === "dropdown" && inputField?.visible){
            return (inputField?.options?.length > 0 && inputField?.icon?.length> 0) ? <div className={ `${styles.dropdown} ${styles.datePicker} ${styles.genericInput} ${styles.pdr2}`}>
                <Dropdown
                          value={inputField?.value}
                          showArrow = {false}
                          onSelect={(val)=>{props && props.onChange(val,idx)}}
                          width="100%"
                        >
                        {inputField?.options?.map((option) => (
                        <MenuItem  value={option?.Text} label={option?.Text} />
                        ))}  
                </Dropdown>  </div> : <Input
                    value={inputField?.value}
                    onChange={(val)=>props?.onChange(val,idx)}
                    label = {inputField?.label}
                    className={!inputField?.editable ? `mb-15 ${styles.disabledInputDropdown} ${styles.genericInput} `:`mb-20 ${styles.genericInput} ${styles.disabledInputDropdown} `}
                    errorMsg = {inputField?.error?.length ? <HTMLDiv html={inputField?.error}/> : ""}
                    disabled = {!inputField?.editable}
                />
        }
    }

    return (
        <div>
            {
                props?.values?.map((inputField,index)=>{
                    return getInputByInputType(inputField,index)
                })
            }
        </div>
    )
}

export default withRouter(
    connect(
      (state) => ({
        documentVerificationDetails: state.user.document_verification_details,
        documentNumberVerificationDetails: state.user.document_number_verification_details,

        //status
        documentVerificationDetailsState: state.status.document_verification_details || {},
        documentNumberVerificationDetailsState: state.status.document_number_verification_details || {},
      }),
      (dispatch) => ({
        actions: bindActionCreators({
        },
          dispatch
        ),
      })
    )(FssaiInputs)
  );