import React, { useEffect, useRef } from 'react'
import Modal from "sushiweb/Modal"
import lottie from "lottie-web"
import styles from "./FeedbackModal.module.scss"

function FeedbackModal({
  visible,
  onClose
}) {

  let animationContainerRef = useRef()
  let animationRef = useRef()
  
  useEffect(() => {
    animationRef.current = lottie.loadAnimation({
      container: animationContainerRef.current,
      path: "/assets/animations/feedback_confirmation.json", 
      renderer: "canvas",
      loop: false,
      autoplay: true,
    })

    return () => {
      animationRef.current.destroy()
    }
  }, [animationContainerRef.current, visible])


  //auto dismiss after 3 seconds 
  useEffect(() => {
    let timer
    if(visible) {
      timer = setTimeout(() => {
        onClose()
      }, 3000)
    }
    return () => {
      if(timer) {
        clearTimeout()
      }
    }
  },[visible])

  return (
    <Modal
      visible={visible}
      type="default"
      isCentered
      slideOnMobile
      showCloseIcon={false}
      onClose={onClose}
    >
      <div className={styles.modalWrapper}>
        <div className={styles.animation} ref={animationContainerRef}></div>
        <div className="text-align-center w-600 fs-18 text-gray-900 mt-2">
          Feedback submitted successfully
        </div>
      </div> 
    </Modal>
  )
}

export default FeedbackModal
