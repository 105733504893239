import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "sushiweb/Button";
import ZImage from "sushiweb/ZImage";
import Modal from "sushiweb/Modal";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { isEqual, lastIndexOf } from "lodash-es";
import { getCommunications } from "../../../../redux/dispatchers/userDispatcher";
import {
  addToCart,
  updateInCart,
  deleteFromCart,
} from "../../../../redux/dispatchers/cartDispatcher";
import { 
  toggleSimilarItemsModal,
  searchProductsForBuyerWithQuery,
  getFilters
} from "../../../../redux/dispatchers/catalogDispatcher";
import {
  debounce,
  getFormattedINRValues,
  parseIntOr0,
  newLineText
} from "../../../../utils/helpers";
import CounterInput from "../../../shared/counterInput";
import styles from "./CatalogCard.module.scss";
import promoStyles from "../../../shared/home/Promotions.module.scss";
import stateTypes from "../../../../utils/constants/stateTypes";
import {
  CartUpdateSource_Cart,
  CartUpdateSource_ModalCart,
  CartUpdateSource_ModalListing,
} from "../../../../utils/constants/others";
import { getOverDue } from "../../../../redux/dispatchers/userDispatcher";
import Image from "../../../shared/Image/Image";
import MyList from "../../../shared/myList/MyList";
import { APP_MODE } from "../../../../utils/constants/others";

class CatalogCardForBuyer extends React.Component {
  // data members
  constructor(props) {
    super(props);

    this.debouncedSubmitHandler = debounce(this.submitHandler);

    this.state = {
      quantity: 0,
      prevQuantity: 0,
      maxAvailableQuantity: null,
      errorNudge: "",
      minOrderQty: 1,
      isOpen: true,
      setModal: false,
      hideStrikeOffPrice: null,
    };

    this.titleDivRef = React.createRef();
    this.strikeOffPriceRef = React.createRef();
  }

  /**
   * Lifecycle methods
   */

  componentDidMount() {
    const {
      item,
      itemId,
      type,
      featuredOffers,
      alternateProducts,
      trialOffers,
    } = this.props;

    let product;

    let allProductsOfferObj = {
      featuredOffers: featuredOffers,
      alternateProducts: { products: alternateProducts },
      trialOffers: trialOffers,
    };
    if (
      type === "featuredOffers" ||
      type === "trialOffers" ||
      type === "alternateProducts"
    ) {
      product = allProductsOfferObj[type].products.find(
        (product) => product.Id === itemId
      );
    } else {
      product = item;
    }
    if (product.Quantity) {
      this.setState((prevState) => ({
        ...prevState,
        quantity: parseIntOr0(product.Quantity.CartQuantity),
        prevQuantity: parseIntOr0(product.Quantity.CartQuantity),
        minOrderQty: parseIntOr0(product.Quantity.MinimumOrderQuantity),
      }));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      addToCartStatus,
      updateInCartStatus,
      deleteFromCartStatus,
      item,
      itemId,
      inProgressCart,
      toastOpenHandler,
      type,
      featuredOffers,
      alternateProducts,
      addUpdateManyIssues,
      cartAlternateProductSuggestion,
      cartErrorAvailableQty,
      dontOpenIssueModal,
      updateOneIssue,
      isIssuesModalOpen,
      showToast,
    } = this.props;

    let product;
    let prevProduct;
    if (type === "featuredOffers") {
      product = featuredOffers.products.find(
        (product) => product.Id === itemId
      );
      prevProduct = prevProps.featuredOffers.products.find(
        (product) => product.Id === itemId
      );
    } else if (type === "alternateProducts") {
      product = alternateProducts.find((product) => product.Id === itemId);
      prevProduct = prevProps.alternateProducts.find(
        (product) => product.Id === itemId
      );
    } else {
      product = item;
      prevProduct = prevProps.item;
    }

    if (
      prevProduct &&
      product &&
      prevProduct.Quantity &&
      product.Quantity &&
      prevProduct.Quantity.CartQuantity !== product.Quantity.CartQuantity
    ) {
      this.setState((prevState) => ({
        ...prevState,
        quantity: parseIntOr0(product.Quantity.CartQuantity),
        prevQuantity: parseIntOr0(prevProduct.Quantity.CartQuantity),
        minOrderQty: parseIntOr0(product.Quantity.MinimumOrderQuantity),
      }));
    }

    //
    if (prevProps.addToCartStatus.state !== addToCartStatus.state) {
      if (addToCartStatus.state === stateTypes.STATE_DONE) {
        const { cartAlternateProductSuggestion } = this.props;
        if (
          prevProps.cartAlternateProductSuggestion &&
          this.props.cartAlternateProductSuggestion &&
          !isEqual(
            prevProps.cartAlternateProductSuggestion.IssueProduct,
            this.props.cartAlternateProductSuggestion.IssueProduct
          )
        ) {
          updateOneIssue &&
            cartAlternateProductSuggestion.IssueProduct.Id &&
            updateOneIssue(cartAlternateProductSuggestion.IssueProduct.Id, {
              ...cartAlternateProductSuggestion.IssueProduct,
            });
        }

        if (
          prevProps.inProgressCart.add.has(product.Id) &&
          !inProgressCart.add.has(product.Id)
        ) {
          this.setState((prevState) => ({
            ...prevState,
            prevQuantity: 1,
          }));
          if (showToast) {
            toastOpenHandler({
              show: true,
              text: `${product.Name} added to cart`,
              type: "success",
              className: "toast-top-right",
            });
          }
          if (this.props.categoryID) {
            this.props.actions.getCommunications({
              source: "listing",
              categoryID: this.props.categoryID,
              subCategoryID: this.props.subCategoryID,
            });
          }
        }
      } else if (addToCartStatus.state === stateTypes.STATE_FAIL) {
        if (
          prevProps.inProgressCart.add.has(product.Id) &&
          !inProgressCart.add.has(product.Id)
        ) {
          const addUpdateIssue =
            cartAlternateProductSuggestion &&
            cartAlternateProductSuggestion.SearchQuery &&
            !dontOpenIssueModal &&
            typeof addUpdateManyIssues === "function";

          if (addUpdateIssue) {
            addUpdateManyIssues(
              [
                {
                  id: product.Id,
                  issueData: cartAlternateProductSuggestion,
                  issueProduct: product,
                  issueSelectedQuantity: parseIntOr0(this.state.quantity),
                  issueAvailableQuantity: cartErrorAvailableQty,
                  issueNudgeMessage:
                    cartAlternateProductSuggestion.ModalNudgeMessage,
                },
              ],
              false,
              isIssuesModalOpen
            );
          } else {
            this.shouldOpenToast(product.Id) &&
              toastOpenHandler({
                show: true,
                text: addToCartStatus.error,
                type: "error",
                className: "",
              });
          }

          this.setState(
            (prevState) => ({
              ...prevState,
              quantity: this.state.prevQuantity,
              maxAvailableQuantity: cartErrorAvailableQty,
              errorNudge:
                cartAlternateProductSuggestion &&
                cartAlternateProductSuggestion.ErrorNudge,
            }),
            () => {
              if (cartErrorAvailableQty) {
                this.retryCartAddUpdate(
                  cartErrorAvailableQty,
                  product,
                  addUpdateIssue
                    ? this.getCartUpdateSourceForModal()
                    : this.props.cartUpdateSource
                );
              }
            }
          );
        }
      }
    }
    //
    if (prevProps.updateInCartStatus.state !== updateInCartStatus.state) {
      if (updateInCartStatus.state === stateTypes.STATE_DONE) {
        const { cartAlternateProductSuggestion } = this.props;
        if (
          prevProps.cartAlternateProductSuggestion &&
          this.props.cartAlternateProductSuggestion &&
          !isEqual(
            prevProps.cartAlternateProductSuggestion.IssueProduct,
            this.props.cartAlternateProductSuggestion.IssueProduct
          )
        ) {
          updateOneIssue &&
            cartAlternateProductSuggestion.IssueProduct.Id &&
            updateOneIssue(cartAlternateProductSuggestion.IssueProduct.Id, {
              ...cartAlternateProductSuggestion.IssueProduct,
            });
        }

        if (
          prevProps.inProgressCart.update.has(product.Id) &&
          !inProgressCart.update.has(product.Id)
        ) {
          this.setState((prevState) => ({
            ...prevState,
            prevQuantity: parseIntOr0(this.state.quantity),
          }));
          if (this.props.categoryID) {
            this.props.actions.getCommunications({
              source: "listing",
              categoryID: this.props.categoryID,
              subCategoryID: this.props.subCategoryID,
            });
          }
        }
      } else if (updateInCartStatus.state === stateTypes.STATE_FAIL) {

        if (
          prevProps.inProgressCart.update.has(product.Id) &&
          !inProgressCart.update.has(product.Id)
        ) {
          
          const addUpdateIssue =
            cartAlternateProductSuggestion &&
            cartAlternateProductSuggestion.SearchQuery &&
            !dontOpenIssueModal &&
            typeof addUpdateManyIssues === "function";
          if (addUpdateIssue) {
            addUpdateManyIssues(
              [
                {
                  id: product.Id,
                  issueData: cartAlternateProductSuggestion,
                  issueProduct: product,
                  issueSelectedQuantity: parseIntOr0(this.state.quantity),
                  issueAvailableQuantity: cartErrorAvailableQty,
                  issueNudgeMessage:
                    cartAlternateProductSuggestion.ModalNudgeMessage,
                },
              ],
              false,
              isIssuesModalOpen
            );
          } else {
            this.shouldOpenToast(product.Id) &&
              toastOpenHandler({
                show: true,
                text: updateInCartStatus.error,
                type: "error",
                className: "",
              });
          }
          let errorAvailableQty = cartErrorAvailableQty;
          let maxAvlQty = cartErrorAvailableQty;
          if (alternateProducts?.length === 0) {
            errorAvailableQty = this.state.prevQuantity;
            maxAvlQty = this.state.maxAvailableQuantity;
          }
          this.setState(
            (prevState) => ({
              ...prevState,
              quantity: this.state.prevQuantity ===this.state.quantity && this.state.quantity ===1 ? 0 : this.state.prevQuantity,
              maxAvailableQuantity: maxAvlQty,
              errorNudge:
                cartAlternateProductSuggestion &&
                cartAlternateProductSuggestion.ErrorNudge,
            }),
            () => {
              if (cartErrorAvailableQty) {
                this.retryCartAddUpdate(
                  cartErrorAvailableQty,
                  product,
                  addUpdateIssue
                    ? this.getCartUpdateSourceForModal()
                    : this.props.cartUpdateSource
                );
              }
            }
          );
        }
      }
    }
    //
    if (prevProps.deleteFromCartStatus.state !== deleteFromCartStatus.state) {
      if (deleteFromCartStatus.state === stateTypes.STATE_DONE) {
        const { cartAlternateProductSuggestion } = this.props;
        if (
          prevProps.cartAlternateProductSuggestion &&
          this.props.cartAlternateProductSuggestion &&
          !isEqual(
            prevProps.cartAlternateProductSuggestion.IssueProduct,
            this.props.cartAlternateProductSuggestion.IssueProduct
          )
        ) {
          updateOneIssue &&
            cartAlternateProductSuggestion.IssueProduct.Id &&
            updateOneIssue(cartAlternateProductSuggestion.IssueProduct.Id, {
              ...cartAlternateProductSuggestion.IssueProduct,
            });
        }

        if (
          prevProps.inProgressCart.delete.has(product.Id) &&
          !inProgressCart.delete.has(product.Id)
        ) {
          this.setState((prevState) => ({
            ...prevState,
            prevQuantity: 0,
          }));

          if (this.props.categoryID) {
            this.props.actions.getCommunications({
              source: "listing",
              categoryID: this.props.categoryID,
              subCategoryID: this.props.subCategoryID,
            });
          }
        }
      } else if (deleteFromCartStatus.state === stateTypes.STATE_FAIL) {
        if (
          prevProps.inProgressCart.delete.has(product.Id) &&
          !inProgressCart.delete.has(product.Id)
        ) {
          this.setState((prevState) => ({
            ...prevState,
            quantity: this.state.prevQuantity,
          }));

          toastOpenHandler({
            show: true,
            text: deleteFromCartStatus.error,
            type: "error",
            className: "",
          });
        }
      }
    }
  }

  ///////////////////
  // Utility Functons
  ///////////////////

  retryCartAddUpdate(retryQuantity, product, cartUpdateSource) {
    if (this.state.prevQuantity !== retryQuantity) {
      this.setState(
        (prevState) => ({
          ...prevState,
          quantity: retryQuantity,
        }),
        () => {
          this.submitHandler({ product, cartUpdateSource });
        }
      );
    }
  }

  getCartUpdateSourceForModal() {
    const { cartUpdateSource } = this.props;

    if (cartUpdateSource === CartUpdateSource_Cart) {
      return CartUpdateSource_ModalCart;
    } else {
      return CartUpdateSource_ModalListing;
    }
  }

  // handler methods
  quantityChangeHandler(value, product,inputType) {
    const newValue = typeof value === "string" ? value.trim() : value;
    const isNumber = !isNaN(parseInt(newValue));
    const { activeRail, toggleRail } = this.props;
    const isActive = product.Id === activeRail;
    const prevValue = this.state.quantity;
    if (newValue === "" || isNumber) {
      this.setState(
        (prevState) => ({
          ...prevState,
          quantity: value,
        }),
        () => {
          if (
            newValue !== 0 &&
            newValue !== "" &&
            !isActive &&
            product?.Rails?.Endpoint &&
            parseInt(newValue) > parseInt(prevValue)
          ) {
            toggleRail(product.Id);
          }
          this.debouncedSubmitHandler({
            product,
            cartUpdateSource: this.props.cartUpdateSource,
            inputType
          });
        }
      );
    }
  }

  submitHandler({ product, cartUpdateSource,inputType }) {
    const {
      userData,
      actions,
      type,
      location,
      from,
      index,
      toggleRail,
      activeRail,
      source,
      getCartSummary,
      base64Query
    } = this.props;
    const query = queryString.parse(location.search);
    const ReferenceId = (query && query.referenceId) || "";
    const ReferenceType = (query && query.referenceType)  || "";
    let params = {
      ProductId: product.Id,
      OutletId: userData.outlet.id,
      ReferenceId,
      ReferenceType,
      InputType:inputType,
      GetCartSummary: getCartSummary ? getCartSummary :false,
      Source: cartUpdateSource,
      TrackingData: product.TrackingData
    };
    if (userData) {
      if (parseIntOr0(this.state.quantity) === 0) {
        // delete
        params = {
          ...params,
          ProductIds: [params.ProductId],
          PageNo: product.pageNo || 1,
          Index: index,
        };
        if (activeRail === product.Id) {
          toggleRail(0);
        }
        actions.deleteFromCart(params, true, from, base64Query);
      } else if (
        parseIntOr0(this.state.quantity) === parseIntOr0(this.state.minOrderQty)
      ) {
        if (parseIntOr0(this.state.prevQuantity) === 0) {
          // add
          params = {
            ...params,
            Quantity: parseIntOr0(this.state.minOrderQty),
            PageNo: product.pageNo || 1,
            Index: index,
          };
          actions.addToCart(params, type,"", base64Query);
        } else {
          // update
          params = { ...params, Quantity: parseIntOr0(this.state.quantity) };
          actions.updateInCart(params, from,"", base64Query);
        }
      } else {
        // update
        params = { ...params, Quantity: parseIntOr0(this.state.quantity) };
        actions.updateInCart(params, from, "",base64Query);
      }
    }
  }

  // enabling counter
  quantityAddHandler(product) {
    const { activeRail, toggleRail } = this.props;
    const isActive = product.Id === activeRail;
    this.setState(
      (prevState) => ({
        ...prevState,
        quantity: product.Quantity.MinimumOrderQuantity,
        prevQuantity : product.Quantity.MinimumOrderQuantity
      }),
      () => {
        if (!isActive && product?.Rails?.Endpoint) {
          toggleRail(product.Id);
        }
        this.submitHandler({
          product,
          cartUpdateSource: this.props.cartUpdateSource,
        });
      }
    );
  }

  shouldOpenToast(productId) {
    const { isIssuesModalOpen, alternateProducts, type } = this.props;

    return (
      type === "alternateProducts" ||
      !(
        isIssuesModalOpen &&
        alternateProducts.find((product) => product.Id === productId) !==
          undefined
      )
    );
  }
  
  toggleModal() {
    this.setState((prevState) => {
      return {isOpen: !prevState.isOpen}
    })
    
  }

  renderDeliveryHeader(product) {
    return (
      <React.Fragment>
        {product.DeliveryByV2 && this.props.appMode !== APP_MODE.EXPRESS ? (
          <div className={`d-flex flex-column align-items-center justify-content-between ${styles.headerCap}`} 
            style={{color: product?.DeliveryByV2?.TextColor, backgroundColor: product?.DeliveryByV2?.BgColor}}>
            <div className={`fw-600 w-600 ${styles.fsResp}`}> {product.DeliveryByV2.Text}</div>
          </div>
        ) : this.props.showHeader? 
          <div className={`d-flex flex-column align-items-center justify-content-between ${styles.blankHeader}`}>
            <div className={styles.height18}></div>
          </div>
        : <></>
        }
      </React.Fragment>
    )
  }

  renderQuantityAndPriceRow(product) {
    return (
      <React.Fragment>
        <div>
          {product.UnitPriceV2.Price && (
            <div
            className="d-flex align-items-baseline fs-20 fw-700 w-800 word-break text-gray-900 white-space-nowrap"
            >
            {product.UnitPriceV2.Price ? product.UnitPriceV2.Price : ""}
            </div>
          )}
          {product.UnitPriceV2.SubHeading && (
            <div
            className="d-flex align-items-baseline fs-14 w-800 word-break text-gray-600 white-space-nowrap"
            >
            {product.UnitPriceV2.SubHeading ? product.UnitPriceV2.SubHeading : ""}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }

  renderDiscountTagText(prices, product, voucher = false) {
    if (!this.hasHigherOldPrice(product)) {
      return null;
    }

    const discount = getFormattedINRValues(
      prices.perUnitDropCumulativeAmount *
        Math.max(parseIntOr0(this.state.quantity), 1)
    );

    return voucher ? (
      <div
        className={`${promoStyles.voucherCardLeft} flex-column justify-content-center`}
      >
        <div className={`${promoStyles.punch} ${promoStyles.punchTop}`}></div>
        <div
          className={`${promoStyles.punch} ${promoStyles.punchBottom}`}
        ></div>
        {discount}
        <div className="fs-18">OFF</div>
      </div>
    ) : (
      <span>{`${discount} OFF`}</span>
    );
  }

  renderMessage(product) {
    if (product.DiscountMessage) {
      return (
        <div className={`${styles.topMessage} ${styles.topMessagePriceDrop}`}>
          <span className="w-800 mb-1 ml-2">{product.DiscountMessage}</span>
        </div>
      );
    } else if (product && product.Offer && product.Offer.Text) {
      return (
        <div
          className={`${styles.topMessage} ${styles.topMessageTrial}`}
          style={{
            color: `${product.Offer.TextColor ? product.Offer.TextColor : ""}`,
            "background-color": `${
              product.Offer.BgColor ? product.Offer.BgColor : ""
            }`,
          }}
        >
          <img
            className="icon-image-height"
            src={product.Offer.ImagePath ? product.Offer.ImagePath : ""}
            alt="Discount icon"
          />
          <span className="w-800 mb-1 ml-2">{product.Offer.Text}</span>
        </div>
      );
    }
  }

  renderName(product){
    return(
      <React.Fragment>
        {product.Name && (
          <div
            ref={this.titleDivRef}
            className={`my-2 word-break text-align-left w-600 fs-16 ${styles.truncate}`}
          >
            {product.Name}
          </div>
        )}
      </React.Fragment>
    )
  }

  renderImage(product) {
    return(
      <React.Fragment>
        <div style={{height:120}}>
          <div className={styles.imageContainer}>
            <ZImage
              src={product.ImagePath}
              alt={product.Name}
              width="100%"
              height="100%"
              className="object-contain"
            />
            {product?.IngredientListMeta?<div className={`d-flex ${styles.myListContainer}`}>
              <MyList
                product={product}
              />
            </div> : <></>}
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderTag(product) {
    return(
        <React.Fragment>
          { product.Tag ? (
            <div className={`d-flex ${styles.tagV2}`} style={{backgroundColor:product.Tag.BgColor, color: product.Tag.TextColor, borderColor:product.Tag.BorderColor}}>
              <img 
              className={styles.imgTag}
              src={product.Tag.ImagePath}
              />
              <div className="fs-10 fw-600">
                {product.Tag.Text}
              </div>
            </div>
          ):<div className={styles.emptyTag}> 
            </div>
            }
        </React.Fragment>
    );
  }

  renderOfferTag(product) {
    return(
      <React.Fragment>
        {product?.OfferV2?.Tag ? (
          <span
            className={`w-800 ${styles.tag}`}
            style={{
              color: `${
                product.OfferV2.Tag.TextColor ? product.OfferV2.Tag.TextColor : ""
              }`,
              backgroundImage: `linear-gradient(to right, ${(
                product.OfferV2.Tag.Gradient || []
              ).join(",")})`,
            }}
          >
            {product.OfferV2.Tag.Text ? product.OfferV2.Tag.Text : ""}
          </span>
        ) : null}
      </React.Fragment>
    )
  }

  renderOffers(product) {
    if (!product?.OfferV2?.Offers) return null;
    const { OfferV2: { Offers = [],OffersBgColor = ""} = {} , SuperSaverSlab= {}} = product;
    return (
      <div className="text-align-left mt-2">
        {SuperSaverSlab?.Tag ? <div className={`mx-4 ${styles.offerTag} d-flex justify-content-start align-items-center`} style={{backgroundColor: SuperSaverSlab.Tag?.BgColor}}>
          {SuperSaverSlab.Tag?.ImagePath ? <Image source={SuperSaverSlab.Tag?.ImagePath} classname={`${styles.img16} mr-2`}/> : <></>}<div className="fs-12 w-800" style={{color: SuperSaverSlab.Tag?.TextColor}}>{SuperSaverSlab.Tag?.Text}</div></div> :<></>}
        {SuperSaverSlab ? <div className="mb-3 px-4">
                <div className={`d-flex justify-content-between ${styles.offerV2}`}  style={{backgroundColor:SuperSaverSlab.BgColor,borderRadius: SuperSaverSlab.Tag? "0px 8px 8px 8px": "8px"}}>
                    <div className="w-600 fs-14" style={{ color: SuperSaverSlab.TextColor}}>
                      {newLineText(SuperSaverSlab.Text)}
                    </div>
                    <div className="d-flex align-items-center pointer">
                      <div className="fs-14 w-800 text-red-500 text-align-right white-space-nowrap" style={{marginLeft:8}} 
                      onClick={(e)=> {e.stopPropagation(); this.quantityChangeHandler(SuperSaverSlab.CTA?.Action?.ActionData?.Quantity,product,SuperSaverSlab.CTA?.Action?.ActionData?.InputType)}}>
                        {SuperSaverSlab.CTA?.Text}
                      </div>
                    </div>
                    {SuperSaverSlab.ImagePath && (
                      <div className="d-flex flex-column align-items-center justify-content-center">
                        <div className={`${styles.tickImage}`}>
                        <ZImage 
                        src={SuperSaverSlab.ImagePath}
                        />
                        </div>
                      </div>
                    )
                    }
                </div> 
              </div>: <></>}


        {Offers && Offers?.length ? (
          <div className="px-4 mb-3 mt-2">
          <div className={`${styles.offerV2}`} style={{backgroundColor:OffersBgColor}}>
            {Offers.map((o,index) => (
              <div>
                <div className={`d-flex justify-content-between`}>
                    <div className="w-600 fs-14" style={{ color: o.TextColor}}>
                      {newLineText(o.Text)}
                    </div>
                    <div className="d-flex align-items-center pointer">
                      <div className="fs-14 w-800 text-red-500 text-align-right white-space-nowrap" style={{marginLeft:8}} 
                      onClick={(e)=> {e.stopPropagation(); this.quantityChangeHandler(o.CTA?.Action?.ActionData?.Quantity,product,o.CTA?.Action?.ActionData?.InputType)}}>
                        {o.CTA?.Text}
                      </div>
                    </div>
                    {o.ImagePath && (
                      <div className="d-flex flex-column align-items-center justify-content-center">
                        <div className={`${styles.tickImage}`}>
                        <ZImage 
                        src={o.ImagePath}
                        />
                        </div>
                      </div>
                    )
                    }
                </div>
                {Offers.length-index-1 ? (
                    <div className={styles.offerLine}></div>
                ):null}
              </div>
            ))}
          </div>
          <div className={styles.dropdownTriangleOffersV2} style ={{ borderTop: "10px solid "+ OffersBgColor}}></div>
          </div>
        ):null}
      </div>
    );
  }

  renderUnitPrice(product) {
    if (!product || !product.UnitPriceV2) return null;
    const {
      UnitPriceV2: { Color, CompareAtPrice, Price, Unit, SubHeading},
    } = product;
    
    if (this.state.hideStrikeOffPrice === null && this.strikeOffPriceRef?.current) {
    let hideStrikeOffPrice = false

    if (this.strikeOffPriceRef?.current?.scrollWidth !== this.strikeOffPriceRef?.current?.offsetWidth) {
      hideStrikeOffPrice = true
    }
    this.setState((prevState) => ({
      ...prevState,
      hideStrikeOffPrice: hideStrikeOffPrice,
    }));
    }
    return (
      <div className={SubHeading? "text-align-left": "text-align-left mt-2"}>
        <div className="d-flex">
          <div className={`d-flex`}>
          <span
            className={`w-800 text-gray-900 ${styles.price}`}
            style={{ color: Color }}
          >{`${Price}${Unit}`}</span>
          </div>
          {CompareAtPrice && !this.state.hideStrikeOffPrice && (
            <div className={`d-flex align-items-center ${styles.truncatedPrice} `} ref= {this.strikeOffPriceRef}>
            <span className={`w-500 text-gray-500 strike ml-3 ${styles.comparePrice}`}>
              {CompareAtPrice}
            </span>
            </div>
          )}
        </div>
        <div>
          {SubHeading && (
            <div
            className={`d-flex align-items-baseline fs-13 fw-400 w-400 text-gray-600 ${styles.subPrice}`}
            >
            {product.UnitPriceV2.SubHeading ? product.UnitPriceV2.SubHeading : ""}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderQuantity(product){
    return(
      <React.Fragment>
        {product.Quantity.DisplayValue && (
          <div className="text-align-left">
            <span className="fs-16 fw-400 w-400 text-gray-600">
              {product.Quantity.DisplayValue}
            </span>
          </div>
        )}
      </React.Fragment>
    );
  }

  renderOfferExclusionText(product) {
    return (
      <React.Fragment>
        {product.OfferExclusionText && (
          <div className="text-align-left pt-2">
            <span className="fs-14 fw-400 w-400 text-cool-gray-400">
              {product.OfferExclusionText}
            </span>
          </div>
        )}
      </React.Fragment>
    );
  }

  renderSimilarItemsCTA(product, type) {
    return (
      <React.Fragment>
        {product.BottomCompositeCTA ? (
          <div className={`d-flex flex-column align-items-center justify-content-between ${styles.bottomCap}`}
            style={{ color: product?.BottomCompositeCTA?.NonTappableTextColor }}>
            <div className={product.BottomCompositeCTA.FullText.length > 40 ? `${styles.bottomCTA}` : `${styles.bottomCTAResp}`}>
              <span className={`fw-600 w-600 fs-12`}> {product.BottomCompositeCTA.FullText.replace(product.BottomCompositeCTA.Text, "")}</span>
              <span className={`fw-600 w-600 fs-12`} style={{ color: product.BottomCompositeCTA.TextColor }}
                onClick={() => this.props.actions.toggleSimilarItemsModal({ product }, !this.props.toggleSimilarModal.isOpen)}
              >
                {product.BottomCompositeCTA.Text}
              </span>
            </div>
          </div>
        )
          : <></>
        }
      </React.Fragment>
    )
  }

  renderRepeatAddCta(product) {
    return (
      <React.Fragment>
        {product.BottomCTA && this.props.type=="catalogGrid" ? (
          <div className={`${styles.bottomCap}`}>
            <div className={`d-flex justify-content-center align-items-center`}>
              {product.BottomCTA.IconImage ? (
              <div className={`mr-3 ${styles.tickImage}`}>
                <ZImage
                src={product.BottomCTA.IconImage}
                />
              </div>
              )
              :<></>
              }
              <div 
                className="fs-16 w-800"
                style={{color:product.BottomCTA.TextColor}}
                onClick={()=> this.quantityChangeHandler(product.BottomCTA?.Action?.ActionData?.Quantity,product,product.BottomCTA?.Action?.ActionData?.InputType)}
              >
                {product.BottomCTA.Text}
              </div>
            </div>
          </div>
        )
        :<></>
      }
      </React.Fragment>
    )
  }

  renderBlankFooter(product){
    return (
      <React.Fragment>
        {(this.props.showBottomCTA && !product.BottomCTA && !product.BottomCompositeCTA)?
          <div className={`d-flex flex-column align-items-center justify-content-between ${styles.blankFooter}`}>
            <div className={styles.height18}></div>
          </div>
          :<></>
        }
      </React.Fragment>
    )
  }
  
  getContainerClass(type,catalogType) {
    if (catalogType === "catalog") {
        return "col-12 col-sm-6 col-lg-4"
    }else if (catalogType === "search") {
      return "col-12 col-sm-6 col-lg-3"
    }
    return "col-12 h-100";
  }

  getTitleFs(title) {
    //an approximate implementation to count number of lines
    if (!title || !this.titleDivRef.current) return;
    const WORD_LENGTH = 8.6; // approx width of 1 character when font size is 16px

    const divSize = this.titleDivRef.current.offsetWidth || 240;
    const lines = (title.length * WORD_LENGTH) / divSize;
    if (lines <= 2) return "fs-16";
    else if (lines <= 3) return "fs-14";
    else return "fs-12";
  }

  openProductDescription = (event) => {
    const { item, cartUpdateSource } = this.props;
    event.stopPropagation();
    item &&
      cartUpdateSource &&
      this.props.history.push(
        `/buyer/product-detail/${item.Id}?source=${cartUpdateSource}`
      );
  };

  render() {
    const {
      item,
      type,
      inProgressCart,
      featuredOffers,
      alternateProducts,
      itemId,
      consumerRail,
      trialOffers,
    } = this.props;
    const { errorNudge } = this.state;
    let product;

    if (type === "featuredOffers") {
      product = featuredOffers.products.find(
        (product) => product.Id === itemId
      );
    } else if (type === "trialOffers") {
      product = trialOffers.products.find((product) => product.Id === itemId);
    } else if (type === "alternateProducts") {
      product = alternateProducts.find((product) => product.Id === itemId);
    } else if (type === "forgetProduct") {
      product = consumerRail.Products.find((product) => product.Id === itemId);
    } else {
      product = item;
    }
    if (!product) return null;
    // For mobile catalog card
    if (type === "mweb") {
      return (
        <React.Fragment>
          <div className="wd-100 mb-4" key={this.props.cardKey}>                        
           <div
              className={`d-flex h-100  justify-content-between ${styles.catalogCardMweb}`}
            >
              <div className="card-body pb-0 px-4 wd-100">
                <div className="row">              
                  <div className="col-3">
                    {product.Tag ? (
                      <span
                        style={{
                          color: `${
                            product.Tag.TextColor ? product.Tag.TextColor : ""
                          }`,
                          backgroundImage: `linear-gradient(to right, ${(
                            product.Tag.Gradient || []
                          ).join(",")})`,
                        }}
                      >
                        {product.Tag.Text ? product.Tag.Text : ""}
                      </span>
                    ) : null}
                    <div
                      style={{
                        maxWidth: "150px",
                        margin: "auto",
                        height: "auto",
                        marginTop: "10px",
                      }}
                      onClick={() =>
                        this.props.openModal
                          ? this.props.openModal(
                              product.Name,
                              product.ImagePath
                            )
                          : {}
                      }
                    >
                      <ZImage
                        src={product.ImagePath}
                        alt={product.Name}
                        width="100%"
                        height="auto"
                        className="object-contain"
                      />
                    </div>
                  </div>
                  <div className="col-5">
                    <div>
                      {product.Offer && product.Offer.Text ? (
                        <div className={`${styles.topMessagePriceDrop}`}>
                          <span className="w-800 fs-11">
                            {product.Offer.Text}
                          </span>
                        </div>
                      ) : null}
                      {product.UnitPrice && (
                        <div
                          className={`text-align-left fw-600 text-gray-dark`}
                        >
                          <span>{product.UnitPrice}</span>
                          <span>&nbsp;</span>
                        </div>
                      )}
                      <div className="my-1 word-break fs-13 text-align-left fw-600 w-400">
                        {product.Name}
                      </div>
                    </div>
                    {product.DeliveryBy ? (
                      <div className="text-align-left mt-3">
                        <img
                          src={
                            product.DeliveryByIcon ? product.DeliveryByIcon : ""
                          }
                          alt="Delivery icon"
                          className={styles.deliveryTruckIcon}
                          style={{ height: "13px" }}
                        />
                        <span className="ml-2 fw-600 fs-12 text-gray-dark">
                          {product.DeliveryBy}
                        </span>
                      </div>
                    ) : null}
                    <div className="mt-2">
                      {this.renderQuantityAndPriceRow(product)}
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="d-flex input-btn-group justify-content-end">
                      {this.props.userData.outlet.ctaText ===
                      "Launching Soon" ? (
                        <div className={styles.launchingSoon}>
                          Launching Soon
                        </div>
                      ) : this.state.prevQuantity === 0 &&
                        this.state.maxAvailableQuantity !== 0 ? (
                        <Button
                          type="submit"
                          loading={inProgressCart.add.has(product.Id)}
                          size="small"
                          btnColor="red"
                          iconAlign="right"
                          appearance="outline"
                          onClick={() => this.quantityAddHandler(product)}
                          className="thin-sushi-btn-mweb"
                          disabled={this.state.maxAvailableQuantity === 0}
                        >
                          <span className="w-800">ADD</span>
                          <span className="fs-22 w-800">+</span>
                        </Button>
                      ) : (
                        <CounterInput
                          type="text"
                          placeholder={0}
                          size="smallest"
                          quantityChangeHandler={(val) =>
                            this.quantityChangeHandler(val, product)
                          }
                          minLimit={0}
                          maxLimit={
                            this.state.maxAvailableQuantity === null
                              ? undefined
                              : this.state.maxAvailableQuantity
                          }
                          defaultVal={this.state.quantity}
                          showLoader={
                            inProgressCart.update.has(product.Id) ||
                            inProgressCart.delete.has(product.Id)
                          }
                          minOrderQty={this.state.minOrderQty}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {product.BulkDiscount && product.DiscountNudgeMessage ? (
                  <div className={styles.mwebNudgeMessage}>
                    <img
                      src="../../../assets/images/discount-teal.svg"
                      alt="Discount icon"
                    />
                    <span className="w-800 ml-2">
                      {product.DiscountNudgeMessage}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div
          className={`${styles.marginBottom24px} ${this.props.withoutAuth ? "" : "pointer"} d-flex flex-column justify-content-end ${this.getContainerClass(type, this.props.catalogType)} ${this.props.sourceType === "promotion" ? "p-0" : ""} ${this.props.className}`}
          key={this.props.cardKey}

        >
          {this.renderDeliveryHeader(product)}
          <div
            className={`card d-flex flex-column ${styles.catalogCard} ${this.props.isMultiLine && styles.small}`} onClick={(e) => {
              if (typeof this.props.closeSimilarModal === "function") {
                this.props.closeSimilarModal();
              }
              if (!this.props.withoutAuth) {
                this.openProductDescription(e);
              }
            }
            }
          >
            {
              this.props.showScore && product.Scoring ?
                <div>
                  <div className="fs-10 w-800">
                    Score: {product.Scoring?.Score}
                  </div>
                  <div className="fs-10 w-800">
                    ScoreType: {product.Scoring?.ScoreType}
                  </div>
                  <div className="fs-10 w-800">
                    Category Names: {product.Scoring?.CategoryNames}
                  </div>
                  <div className="fs-10 w-800">
                    TextScore: {product.Scoring?.TextScore}
                  </div>
                  <div className="fs-10 w-800">Vertical: {product.Vertical}</div>
                </div>
                :
                <div></div>
            }

            {this.renderOfferTag(product)}
            {this.renderImage(product)}
            <div>
              {this.renderTag(product)}
              <div className={`card-body py-0 px-4`} style={{ height: (31 + 21 * this.props.titleSize +(product.OfferExclusionText.length > 0 ? 21 : 0)) }}>
                {this.renderName(product)}
                {this.renderQuantity(product)}
                {this.renderOfferExclusionText(product)}
              </div>
            </div>
            {this.props.withoutAuth ?

              <div className="mb-4"></div>
              : <div className="d-flex flex-column h-100">
                <div className="line-light"></div>

                <div className={type === "catalogGrid" ? `d-flex flex-column justify-content-end h-100` : `d-flex flex-column justify-content-end h-100 ${styles.negMargin}`} >

                  {this.renderOffers(product)}
                  <div className={this.props.spaceForMOQFlag ? "d-flex mt-2" : "d-flex mt-2 mb-3"}>
                    <div className="d-flex col-6 flex-column pl-4 pr-0" style={{ height: 40 }}>
                      {this.renderUnitPrice(product)}
                    </div>
                    <div className="d-flex col-6 flex-column align-items-center justify-content-center">
                      {this.props.userData?.outlet?.ctaText === "Launching Soon" ? (
                        <div className={styles.launchingSoon}>Launching Soon</div>
                      ) : (this.state.prevQuantity === 0) &&
                        this.state.maxAvailableQuantity !== 0 ? (
                        <Button
                          type="submit"
                          loading={inProgressCart.add.has(product.Id)}
                          size="small"
                          btnColor="red"
                          iconAlign="right"
                          appearance="outline"
                          onClick={() => this.quantityAddHandler(product)}
                          className={`thin-sushi-btn`}
                          disabled={this.state.maxAvailableQuantity === 0}
                        >
                          <span className="w-800">ADD</span>
                          <span className="fs-30 w-800">+</span>
                        </Button>
                      ) : (
                        <CounterInput
                          type="text"
                          placeholder={0}
                          quantityChangeHandler={(val) =>
                            this.quantityChangeHandler(val, product)
                          }
                          minLimit={0}
                          maxLimit={
                            this.state.maxAvailableQuantity === null
                              ? undefined
                              : this.state.maxAvailableQuantity
                          }
                          defaultVal={this.state.quantity}
                          size="smallest"
                          showLoader={
                            inProgressCart.update.has(product.Id) ||
                            inProgressCart.delete.has(product.Id)
                          }
                          minOrderQty={this.state.minOrderQty}
                        />
                      )}

                      <div
                        className={this.props.spaceForMOQFlag && `${styles.space}`}
                      >
                        {product?.Quantity?.MinimumOrderQuantity > 1 ? (
                          <div className="d-flex align-items-end text-align-left">
                            <span className="fs-13 ml-2  w-600 text-gray-600">
                              Min. Qty {product.Quantity.MinimumOrderQuantity}
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
          </div>
          {this.renderSimilarItemsCTA(product, type)}
          {this.renderRepeatAddCta(product)}
          {this.renderBlankFooter(product, type)}
        </div>
      </React.Fragment>

    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      // data
      userData: state.user.data,
      featuredOffers: state.catalog.featuredOffers,
      trialOffers: state.catalog.trialOffers,
      alternateProducts: state.catalog.alternateProducts.products,
      inProgressCart: state.cart.inProgress,
      cartAlternateProductSuggestion: state.cart.alternateProductSuggestion,
      cartErrorAvailableQty: state.cart.errorAvailableQty,
      consumerRail: ((state.user.consumerRail || {}).Rails || [])[0],
      toggleSimilarModal: state.catalog.toggleSimilarModal || {},
      base64Query: state.catalog.QueryString,
      appMode: state.user?.config?.AppMode,
      
      // status
      addToCartStatus: state.status.cart_add,
      updateInCartStatus: state.status.cart_update,
      deleteFromCartStatus: state.status.cart_delete,
    }),
    (dispatch) => ({
      actions: bindActionCreators(
        {
          addToCart,
          updateInCart,
          deleteFromCart,
          getOverDue,
          getCommunications,
          toggleSimilarItemsModal,
          searchProductsForBuyerWithQuery,
          getFilters
        },
        dispatch
      ),
    })
  )(CatalogCardForBuyer)
);