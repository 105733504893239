function validatePhoneNumber(value, label) {
  if (!/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value)) {
    return `Invalid ${label}`;
  }

  return null;
}

function validateEmail(value, label) {
  if (!/\S+@\S+\.\S+/.test(value)) {
    return `Invalid ${label}`;
  }
  return validateCharacterString(value, label)
}

function validateCharacterString(value, label) {
  if (/[^0-9A-Za-z#@()/\-+&_" '.,:]/.test(value)) {
    return `${label} has invalid characters`;
  }

  return null;
}

function validatePinCode(value, label) {
  if (!/^[0-9]{6}$/.test(value)) {
    return `Invalid ${label}`;
  }

  return null;
}

function validatePan(value, label) {
  if (!/(^([A-Z]{5})([0-9]{4})([A-Z]{1})$)/.test(value)) {
    return `Invalid ${label}`;
  }

  return null;
}

function validateFssai(value, label) {
  if (!/(^([0-9]{14})$)/.test(value)) {
    return `Invalid ${label}`;
  }

  return null;
}

function validateGst(value, label) {
  if (
    !/(^(\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1})$)/.test(value)
  ) {
    return `Invalid ${label}`;
  }

  return null;
}

function validateOtp(value, label) {
  if (value.length !== 6) {
    return `${label} length should be 6`;
  }

  return null;
}

function validatePassword(value, label) {
  if (value.length < 8) {
    return `${label} must be 8 or more characters`;
  }

  if (value.search(/\d/) === -1) {
    return `${label} must contain at least one number`;
  }

  return null;
}

function validateConfirmPassword(value, toCompareWith, label) {
  if (value.length < 8) {
    return `${label} must be 8 or more characters`;
  }

  if (value.search(/\d/) === -1) {
    return `${label} must contain at least one number`;
  }

  if (value !== toCompareWith) {
    return `${label} doesn't match with given password`;
  }

  return null;
}

export {
  validatePhoneNumber,
  validateEmail,
  validatePinCode,
  validatePan,
  validateFssai,
  validateGst,
  validateOtp,
  validatePassword,
  validateConfirmPassword,
  validateCharacterString
};
