import actionTypes from "../actionTypes";

function downloadDaApp(state = {}, action) {
  const { payload, type } = action;
  switch (type) {
    case actionTypes.GET_DOWNLOAD_DA_APP_DONE:
        return { ...state, link: payload};

    default:
      return state;
  }
}

export default downloadDaApp;
