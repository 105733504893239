import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Modal from "sushiweb/Modal/Modal";
import { dismissAppModePopup } from "../../../../redux/dispatchers/userDispatcher";

// styles
import styles from "./AppModeToggle.module.scss";

// main component
const AppModeSwitchPopup = (props) => {
  // unwrap modal data
  const {
    visible,
    heading,
    body,
    confirmButtonText,
  } = props.appModePopupData;

  // unwrap actions
  const { actions } = props;

  // reload page callback
  const reloadPage = () => {
    window.location.reload();
  };


  // get modal body component
  const getModalBodyItem = (index, item) => {
    return (
      <div key={index} className={`d-flex gap-1 align-items-center ${styles.appModeSwitchPopupBodyItem}`}>
        <img
          src={item.image.url}
          alt={item.text.text}
          height={item?.image?.height}
          width={item?.image?.width}
        />
        <span className={styles.appModeSwitchPopupBodyItemText}>{item.text.text}</span>
      </div>
    );
  };

  // get footer component
  const getFooter = () => {
    return (
      <div className={`d-flex flex-grow-1 ${styles.appModeSwitchPopupFooter}`} onClick={() => actions.dismissAppModePopup(reloadPage)}>
        <span> {confirmButtonText} </span>
      </div>
    );
  };

  // render popup
  return (
    <Modal
      onClose={() => actions.dismissAppModePopup(reloadPage)}
      title={heading}
      showCloseIcon={false}
      isCentered={true}
      visible={visible}
      className={styles.appModeSwitchPopup}
      showFooter={true}
      renderFooter={() => getFooter()}
    >

      <div className={`d-flex flex-column justify-content-center gap-2 ${styles.appModeSwitchPopupBody}`}>
        {
          body?.length ? body.map((item, index) => {
            return getModalBodyItem(index, item);
          }) : <></>
        }
      </div>
    </Modal>);
};

// actions
const actions = {
  dismissAppModePopup,
};

// redux binding
export default withRouter(
  connect(
    (state) => ({
      state: state,
      appModePopupData: state.user?.appModeModalData,
    }),
    (dispatch) => ({
      actions: bindActionCreators(actions, dispatch),
    })
  )(AppModeSwitchPopup)
);
