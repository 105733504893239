import React from "react";
import Input from "sushiweb/InputTypes/Input"
import styles from "./CustomInputs.module.scss";
import Datepicker from "../datePicker/DatePicker";
import Image from "../../shared/Image/Image";
import { HTMLDiv } from "../HTMLDiv";
import moment from "moment";

function PanInputs(props){
    

    const handleChange = (val,idx) => {
        props && props.onChange(val,idx)
    }
    
    const getInputByInputType = (inputField,idx) => {
        if (inputField?.keyType === "input" && inputField?.visible) {
            return <Input
                value={inputField?.value}
                onChange={(val)=>props?.onChange(val,idx)}
                label = {inputField?.key}
                className={!inputField?.editable ? `mb-20 ${styles.disabledInput} ${styles.genericInput} `:`mb-20 ${styles.genericInput} `}
                errorMsg = {inputField?.error?.length ? <HTMLDiv html={inputField?.error}/> : ""}
                disabled = {!inputField?.editable}
            />
        }else if (inputField?.keyType === "calendar" && inputField?.visible){
            
            return <div className={`position-relative mb-0 ${styles.datePicker} ${styles.genericInput} ${styles.pdr2}`}>
                <Datepicker
                        dateFormat="dd/MM/yyyy"
                        showMonthDropdown
                        showYearDropdown
                        yearDropdownItemNumber={5}
                        selected={inputField?.value}
                        onChange={(val)=>{handleChange(val,idx)}}
                        onChangeRaw={(e) => e.preventDefault()}
                        customInput={
                          <Input
                            className="position-relative"
                            label= {inputField?.key}
                            icon={
                              <Image
                                classname = {`${styles.calendarIcon}`}
                                source = {"/assets/images/datePickerIcon.png"}
                              />
                            }
                          />
                        }
                        />
                        {
                            inputField?.error?.length ? <div className={`${styles.removeMargin}`}><HTMLDiv html={inputField?.error}/></div> : <></>
                        }
                    </div>
        }
    }

    return (
        <div>
            {
                props?.values?.map((inputField,index)=>{
                    return getInputByInputType(inputField,index)
                })
            }
        </div>
    )
}

export default PanInputs