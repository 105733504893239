import ExclaimationCircle from "sushiweb/Icons/all/ExclamationCircle";
// import CounterInputAnimated from '../../../shared/counterInput/CounterInputAnimated';
import BellFill from "sushiweb/Icons/all/BellFill";

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ZImage from "sushiweb/ZImage";
import queryString from "query-string";
import { withRouter } from "react-router-dom";

import {
  addToCart,
  updateInCart,
  deleteFromCart,
} from "../../../../redux/dispatchers/cartDispatcher";
import styles from "./RailCardForBuyer.module.scss";
import { notifyOutOfStock } from "../../../../redux/dispatchers/catalogDispatcher";
import useCart from "../../../../utils/hooks/useCart";
import CounterInput from "../../../shared/counterInput";
import Button from "sushiweb/Button";

function RailCardForBuyer(props) {
  const query = queryString.parse(props.location.search);
  const referenceId = (query && query.referenceId) || props.referenceId || "";
  const referenceType =
    (query && query.referenceType) || props.referenceType || "";
  const {
    inProgressCart,
    item,
    userData: { outlet: { ctaText } = {} } = {},
  } = props;

  const [cartState, issueNudge, quantityChangeHandler] = useCart({
    ...props,
    referenceType,
    referenceId,
  });

  function handleNotify() {
    const { item, cartUpdateSource, clearIssues, actions } = props;
    clearIssues();
    actions.notifyOutOfStock({
      ProductId: item.Id,
      Source: cartUpdateSource,
    });
  }

  //RENDER METHODS

  function renderDeliveryHeader(product) {
    return (
      <React.Fragment>
        {product.DeliveryByV2 ? (
          <div className={`d-flex flex-column align-items-center justify-content-between ${styles.headerCap}`} 
            style={{color: product?.DeliveryByV2?.TextColor, backgroundColor: product?.DeliveryByV2?.BgColor}}>
            <div className={`fw-600 w-600 fs-12`}> {product.DeliveryByV2.Text}</div>
          </div>
        ) : <></>
        }
      </React.Fragment>
    )
  }

  function renderQuantity(product) {
    return (
      <React.Fragment>
        {product.Quantity.DisplayValue && (
          <div className="text-align-left mt-2">
            <span className="fs-14 w-600 text-gray-600">
              {product.Quantity.DisplayValue}
            </span>
          </div>
        )}
      </React.Fragment>
    )
  }

  function renderPrice() {
    const {
      item: { EffectiveUnitPrice: { Color, Price, CompareAtPrice } = {} } = {},
    } = props;
    return (
      <div className="d-flex flex-wrap align-items-center mb-2">
        <span
          className="fs-18 w-800 mr-2"
          style={{
            color: Color,
          }}
        >
          {Price}
        </span>
        <span className="fs-14 w-500 text-gray-600 strike">
          {CompareAtPrice}
        </span>
      </div>
    );
  }

  function renderTitle() {
    const { item: { Name } = {} } = props;
    return (
      <div
        className={`${styles.productName} ${
          props.isMultiLine && styles.small
        } w-600 fs-16`}
      >
        {Name}
      </div>
    );
  }

  function renderDeliverySlot() {
    const { item } = props;
    if (!item.DeliveryByV2 || !item.DeliveryByV2.Text) {
      return null;
    }
    const { item: { DeliveryByV2: { ImagePath, Text, TextColor } = {} } = {} } =
      props;

    return (
      <div className={styles.deliveryslot}>
        <img src={ImagePath} alt="" className={styles.deliveryImg} />
        <div className="fs-12 w-600" style={{ color: TextColor }}>
          {Text}
        </div>
      </div>
    );
  }

  function renderOffers() {
    const { item: { OffersV3 = [] } = {} } = props;
    return (
      <div>
        {OffersV3 &&
          OffersV3.map((offer, idx) => {
            return (
              <div className="d-flex align-items-center mt-1 mb-2" key={idx}>
                {offer.ImagePath ? (
                  <img
                    src={offer.ImagePath}
                    alt=""
                    className={styles.offerImg}
                  />
                ) : null}
                <div
                  style={{
                    color: offer.TextColor,
                  }}
                  className="fs-14 w-600"
                >
                  {offer.Text}
                </div>
              </div>
            );
          })}
      </div>
    );
  }

  function renderDisabledReason() {
    const { item, userData: { outlet: { ctaText } = {} } = {} } = props;
    const isLaunchingSoon = ctaText && ctaText === "Launching Soon";

    if (isLaunchingSoon) {
      return <div className="text-dark-green w-600 mb-2">Launching soon</div>;
    }
    if (!item.IsInStock) {
      return (
        <div className="fs-12 text-gray-600 w-600 mb-2 relative">
          <div className={styles.notifyMe} onClick={handleNotify}>
            <BellFill size={14} className="mr-3" color="#E03546" />
            <div className="fs-12 text-red-600 w-600">Notify Me</div>
          </div>
          OUT OF STOCK
        </div>
      );
    }
  }

  function renderErrorNudge() {
    if (issueNudge) {
      return (
        <div className={styles.errorNudge}>
          <ExclaimationCircle size={12} color="#E03546" />
          <div className="fs-12 ml-2 text-red-700 w-600">{issueNudge}</div>
        </div>
      );
    }
  }

  function renderTag() {
    if (item && item.OfferV2) {
      const { item: { OfferV2: { Tags } = {} } = {} } = props;

      return Tags && Tags.length && Tags[0].Text ? (
        <div
          className={`fs-12 w-600 ${item.DeliveryByV2? styles.offerTagHeader: styles.offerTag}`}
          style={{
            color: Tags[0].TextColor,
            backgroundImage: `linear-gradient(to right, ${(
              Tags[0].Gradient || []
            ).join(",")})`,
          }}
        >
          {Tags[0].Text}
        </div>
      ) : null;
    }
  }

  function openProductDescription(event) {
    event.stopPropagation();
    const isCart = props.location.pathname === "/buyer/cart";
    if (!isCart && item && props.cartUpdateSource) {
      props.history.push(
        `/buyer/product-detail/${item.Id}?source=${props.cartUpdateSource}`
      );
      props.clearIssues();
    }
  }

  function renderMOQ() {
    const { item } = props;
    if (item?.Quantity?.MinimumOrderQuantity > 1 && item.IsInStock)
      return (
        <div className="d-flex text-align-center s-13 fs-12 w-600 ml-2 text-gray-600">
          <div className="text-align-center wd-100">
            Min. Qty {item.Quantity.MinimumOrderQuantity}{" "}
          </div>
        </div>
      );
  }

  function renderButton() {
    if (ctaText === "Launching Soon" || !item.IsInStock) {
      return (
        <Button
          loading={inProgressCart.add.has(item.Id)}
          btnColor="black"
          iconAlign="right"
          appearance="outline"
          size="small"
          className={`${styles.addBtnDisabled}`}
          disabled
        >
          <span className="fs-15 w-600">ADD</span>
          <span className={styles.icon}>+</span>
        </Button>
      );
    }
    if (item?.Quantity?.CartQuantity === 0) {
      return (
        <Button
          type="submit"
          loading={inProgressCart.add.has(item.Id)}
          size="small"
          btnColor="red"
          iconAlign="right"
          appearance="outline"
          onClick={() =>
            quantityChangeHandler(item.Quantity.MinimumOrderQuantity)
          }
          className={styles.railBtn}
          disabled={cartState.maxAvailableQuantity === 0}
        >
          <span className="w-800">ADD</span>
          <span className="fs-22 w-800">+</span>
        </Button>
      );
    }
    return (
      <div className={styles.adderContainer}>
        <CounterInput
          type="text"
          placeholder={0}
          quantityChangeHandler={quantityChangeHandler}
          minLimit={0}
          maxLimit={
            cartState.maxAvailableQuantity === null
              ? undefined
              : cartState.maxAvailableQuantity
          }
          defaultVal={cartState.quantity}
          size="rail"
          showLoader={
            inProgressCart.update.has(item.Id) ||
            inProgressCart.delete.has(item.Id)
          }
          minOrderQty={item.Quantity.MinimumOrderQuantity}
        />
      </div>
    );
  }

  return (
    <div className="h-100 pointer d-flex flex-column justify-content-end" style={{margin:10}}>
      {renderDeliveryHeader(item)}
      <div
        className={`card d-flex flex-column h-100 ${styles.card} ${props.isMultiLine && styles.small}`}
        onClick={openProductDescription}
      >
          {renderTag()}
          <div
            className={`${styles.image} pointer`}
            style={
              !item.IsInStock
                ? {
                    filter: "grayscale(100%)",
                  }
                : null
            }
          >
            <ZImage
              src={item.ImagePath}
              alt={item.Name}
              width="100%"
              height="100%"
              className={`${styles.borderRadius}`}
            />
          </div>
          <div> 
            <div className="card-body py-0 px-4">
              {renderDisabledReason()}
              {renderTitle()}
              {renderQuantity(item)}
            </div>
            <div className="line-light"></div>
            <div>
              <div className="px-4 mb-4">
                {renderPrice()}
                <div style={{height:41}}>
                  {renderOffers()}
                </div> 
              </div>
              <div>
                {renderErrorNudge()}
                {renderButton()}
                <div className={props.spaceForMOQFlag && `${styles.space}`}>
                  {renderMOQ()}
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}

export default withRouter(
  connect(
    (state) => ({
      // data
      userData: state.user.data,
      featuredOffers: state.catalog.featuredOffers,
      trialOffers: state.catalog.trialOffers,
      alternateProducts: state.catalog.alternateProducts.products,
      inProgressCart: state.cart.inProgress,
      cartAlternateProductSuggestion: state.cart.alternateProductSuggestion,
      cartErrorAvailableQty: state.cart.errorAvailableQty,
      consumerRail: ((state.user.consumerRail || {}).Rails || [])[0],
      // status
      addToCartStatus: state.status.cart_add,
      updateInCartStatus: state.status.cart_update,
      deleteFromCartStatus: state.status.cart_delete,
    }),
    (dispatch) => ({
      actions: bindActionCreators(
        {
          addToCart,
          updateInCart,
          deleteFromCart,
          notifyOutOfStock,
        },
        dispatch
      ),
    })
  )(RailCardForBuyer)
);
