import React from "react";

import Button from "../Button";
import Modal from "sushiweb/Modal";
import { useDispatch, useSelector } from "react-redux";
import { addRemoveHyperPurePlusToCart } from "../../../redux/dispatchers/cartDispatcher";

const HpPlusModalPopup = ({ popUpData, onClose }) => {
  const isMobScreenSize =
    typeof window !== "undefined" ? window.outerWidth < 992 : false;
  const { HeroContainer, HeroCTA: heroCTA, Note: note } = popUpData;
  const outletId = useSelector((state) => state.user?.data?.outlet?.id);
  const dispatch = useDispatch();
  return (
    <Modal
      visible={true}
      title=""
      type={isMobScreenSize ? "small" : "mediumHpPlus"}
      showCloseIcon={false}
      isCentered={true}
      onClose={onClose}
      className={"modal24pxRadius"}
    >
      <div
        style={{
          display: "flex",
          padding: "40px",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          backgroundImage: `url(${popUpData?.BgImage?.ImageURL})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {HeroContainer?.PrimaryImage?.ImageURL && (
          <img
            src={HeroContainer?.PrimaryImage?.ImageURL}
            style={{
              height: HeroContainer?.PrimaryImage?.Height,
              width: HeroContainer?.PrimaryImage?.Width,
              marginBottom: "48px",
            }}
            alt="hp-plus"
          />
        )}
        {HeroContainer?.PrimaryText?.Text && (
          <span
            style={{
              color: HeroContainer?.PrimaryText?.Color,
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            {HeroContainer.PrimaryText.Text}
          </span>
        )}
        {HeroContainer?.TertiaryText?.Text && (
          <span
            style={{
              color: HeroContainer?.TertiaryText?.Color,
              fontSize: "18px",
              fontWeight: "400",
              marginBottom: "12px",
            }}
          >
            {HeroContainer.TertiaryText.Text}
          </span>
        )}

        {HeroContainer?.SecondaryImage?.ImageURL && (
          <img
            src={HeroContainer?.SecondaryImage?.ImageURL}
            style={{
              height: HeroContainer?.SecondaryImage?.Height,
              width: HeroContainer?.SecondaryImage?.Width,
              marginTop: "48px",
              marginBottom: "20px",
            }}
            alt="hp-plus"
          />
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            alignItems: "center",
          }}
        >
          <span
            style={{
              color: HeroContainer?.Title?.Color,
              fontSize: "26px",
              fontWeight: "700",
            }}
          >
            {HeroContainer?.Title?.Text}
          </span>
          <span
            style={{
              color: HeroContainer?.SecondaryText?.Color,
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            {HeroContainer?.SecondaryText?.Text}
          </span>
        </div>
        {HeroContainer?.TertiaryImage?.ImageURL && (
          <img
            src={HeroContainer?.TertiaryImage?.ImageURL}
            style={{
              height: HeroContainer?.TertiaryImage?.Height,
              width: HeroContainer?.TertiaryImage?.Width,
              marginTop: "48px",
            }}
            alt="hp-plus"
          />
        )}
        {HeroContainer?.KnowMore && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "16px",
              marginTop: "48px",
            }}
            onClick={() => (window.location.href = "/in/hp-plus")}
          >
            <span
              style={{
                color: HeroContainer?.KnowMore?.PrimaryText?.Color,
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              {HeroContainer?.KnowMore?.PrimaryText?.Text}
            </span>
            <img
              src={HeroContainer?.KnowMore?.PrimaryText?.SuffixIcon?.ImageURL}
              style={{
                height:
                  HeroContainer?.KnowMore?.PrimaryText?.SuffixIcon?.Height,
                width: HeroContainer?.KnowMore?.PrimaryText?.SuffixIcon?.Width,
              }}
              alt="Hp-plus"
            />
          </div>
        )}

        {heroCTA && (
          <Button
            style={{
              backgroundImage: `linear-gradient(to right, ${heroCTA?.BgGradient?.colors[0]}, ${heroCTA?.BgGradient?.colors[1]})`,
              width: "321px",
              borderRadius: "100px",
              height: "48px",
            }}
            onClick={(e) => {
              dispatch(
                addRemoveHyperPurePlusToCart({
                  endPoint: heroCTA?.Action?.ActionData?.Endpoint,
                  requestBody: heroCTA?.Action?.ActionData?.Body,
                  source: heroCTA?.Action?.ActionData?.Source,
                  outletId: outletId,
                })
              );
              onClose();
            }}
          >
            <span
              style={{
                color: heroCTA?.PrimaryText?.Color,
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              {heroCTA?.PrimaryText?.Text}{" "}
            </span>
            <span
              style={{
                color: heroCTA?.StrikeOffText?.Color,
                textDecoration: "line-through",
                opacity: 0.5,
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              {heroCTA?.StrikeOffText?.Text}
            </span>
            <span
              style={{
                color: heroCTA?.SecondaryText?.Color,
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              {heroCTA?.SecondaryText?.Text}
            </span>
          </Button>
        )}
        {note && (
          <span style={{ marginTop: "50px", color: note?.Color }}>
            {note?.Text}
          </span>
        )}
      </div>
    </Modal>
  );
};

export default HpPlusModalPopup;
