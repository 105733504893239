import React from "react";
import Spinner from "sushiweb/Spinner";

import styles from "./Message.module.scss";
import { CTA_COLOUR } from "../../../utils/constants/others";

function Message(props) {
  if (Array.isArray(props.content)) {
    return (
      <div className={`${styles.container} ${props.isPage ? styles.page : ""}`}>
        <div className={styles.content}>
          {props.content.map((el, index) => (
            <React.Fragment key={index}>{el}</React.Fragment>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className={`${styles.container} ${props.isPage ? styles.page : ""}`}>
        <div className={styles.content}>{props.content}</div>
      </div>
    );
  }
}

function SectionLoading() {
  return (
    <Message
      content={<Spinner size="large" color={CTA_COLOUR} />}
      isPage={false}
    />
  );
}

function PageLoading() {
  return (
    <Message
      content={<Spinner size="large" color={CTA_COLOUR} />}
      isPage={true}
    />
  );
}

function SectionError(props) {
  return (
    <Message
      content={<p className="text-gray-800">{props.error}</p>}
      isPage={false}
    />
  );
}

function PageError(props) {
  return (
    <Message
      content={<p className="text-gray-800">{props.error}</p>}
      isPage={true}
    />
  );
}

function PageMessage(props) {
  return <Message {...props} isPage={true}/>
}

export { SectionLoading, SectionError, PageLoading, PageError, PageMessage };
