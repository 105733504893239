import actionTypes from "../actionTypes";

function aerobars(state = {}, action) {
  const { payload, type } = action;

  switch (type) {
    case actionTypes.GET_AEROBAR_DONE:
      return {
        ...state,
        aerobars: payload
      }

    default:
      return state;
  }
}

export default aerobars;
