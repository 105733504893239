import React, { useEffect, useRef, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styles from "./SearchInput.module.scss";

const SearchCustomCarousel = (props) => {
  const carouselRef = useRef(null);
  const [slides, setSlides] = useState(props.data);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFocused, setIsFocused] = useState(true);
  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  window.addEventListener('blur', handleBlur);
  window.addEventListener('focus', handleFocus);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isFocused) {
        setCurrentIndex((prevIndex) => {
          const nextIndex = prevIndex + 1;
          if (nextIndex === slides.length) {
            // Reached the last slide, reset to the first slide
            return 0;
          } else {
            return nextIndex;
          }
        });
      }
    }, 3000);

    if (props.stopPlaying || !isFocused) {
      clearInterval(intervalId);
    }

    return () => {
      window.removeEventListener('blur', handleBlur);
      clearInterval(intervalId);
    };
  }, [slides.length, props.stopPlaying, isFocused]);

  useEffect(() => {
    if (currentIndex === slides.length - 1) {
      // Reached the last slide, add all slides back to the end
      setSlides((prevSlides) => [...prevSlides, ...props.data]);
    }
  }, [currentIndex, props.data]);

  const handleSlideChange = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className='d-flex align-items-center'>
      <div className={`mr-1 fs-16 w-400 ${styles.greyTextSearch}`}>Search</div>
      <Carousel
        selectedItem={currentIndex}
        onChange={handleSlideChange}
        infiniteLoop
        axis="vertical"
        swipeable={false}
        emulateTouch={false}
        useKeyboardArrows={false}
        showIndicators={false}
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        stopOnHover={false}
        transitionTime={900}
      >
        {slides.map((item, index) => (
          <div
            key={index}
            className={`wd-100 fs-16 height-100 d-flex align-items-center ${styles.greyTextSearch} w-600`}
          >
            ‘{item}’
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default SearchCustomCarousel;
