import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import rootReducer from "../reducers/index";

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

function configureStore(initialState = {}) {
    //enabling redux dev tools on dev
    if (process.env.NODE_ENV === "development") {
        return createStoreWithMiddleware(
            rootReducer,
            initialState,
            window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__()
        );
    } else {
        return createStoreWithMiddleware(rootReducer, initialState);
    }
}

export default configureStore;