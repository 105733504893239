import React, { Component } from "react";
import ExclamationLine from "sushiweb/Icons/all/ExclamationLine";
import { red } from "sushiweb/tokens/color";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // console.log("error: ", error);
    // console.log("errorInfo: ", errorInfo);
  }

  errorMessage() {
    if (this.props.message) {
      return this.props.message;
    } else if (this.props.type) {
      return `Something went wrong - ${this.props.type}.`;
    } else {
      return "Something went wrong.";
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      if (this.props.nullError) {
        return null;
      }
      return (
        <div
          className={`d-flex justify-content-center align-items-center ${this.props.className}`}
        >
          <ExclamationLine color={red.z500} />
          &nbsp;
          <h5>
            {this.errorMessage()}
            {/* Something went wrong{this.props.type ? ` - ${this.props.type}` : ""}. */}
          </h5>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
