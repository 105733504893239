import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect, Route } from "react-router-dom";
import {
  signOutAndRouted
} from "../../../redux/dispatchers/authDispatcher";

function ProtectedRoute({ render, ...props }) {

  // for signed in user
  if (props.isAuthenticated) {

    if (props.forAuth) {
      const redirectURL = window.localStorage.getItem("redirect");
      const redirectSearch = window.localStorage.getItem("redirect_search");
      if (redirectURL) {
        window.localStorage.removeItem("redirect");
        window.localStorage.removeItem("redirect_search");
        return <Redirect to={{ pathname: redirectURL, search : redirectSearch }} />;
      }
      return <Route exact {...props} render={render} />;
    } else {
      return <Redirect exact to={{ pathname: "/" }} />;
    }
    // for non signed in user
  } else {
    if (!props.forAuth || props.forCombineAuth) {
      return <Route exact {...props} render={render} />;
    } else {
      if (!props.noPathSave) {
        window.localStorage.setItem("redirect", window.location.pathname);
        window.localStorage.setItem("redirect_search", window.location.search);
      }
      return <Redirect to={{ pathname: "/" ,search: `?signIn=true`}} />;
    }
  }
}

export default connect(state => ({
    isAuthenticated: state.auth.isAuthenticated
  }),
  (dispatch) => ({
    actions: bindActionCreators({
        signOutAndRouted
      },
      dispatch
    ),
  })
)(ProtectedRoute);
