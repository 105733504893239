import actionTypes from "../actionTypes";

function toast(state = {}, action) {
  const { payload, type } = action;

  switch (type) {
    case actionTypes.SET_TOAST_MESSAGE_DONE:
      return {
        ...state,
        message: action.message,
        type: action.toasttype
      }
    case actionTypes.SET_TOAST_MESSAGE_INIT:
        return {}

    default:
      return state;
  }
}

export default toast;
