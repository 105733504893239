import React from "react";
import Modal from "sushiweb/Modal";
import Button from "sushiweb/Button";
import ExclamationCirle from "sushiweb/Icons/all/ExclamationCircle";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import CounterInput from "../counterInput/index";
import { debounce, isMobile, newLineText, parseIntOr0 } from "../../../utils/helpers";
import {
  addToCart,
  updateInCart,
  deleteFromCart,
} from "../../../redux/dispatchers/cartDispatcher";

import { getAlternateProducts, getFilters } from "../../../redux/dispatchers/catalogDispatcher";
import PromotionsCard from "../../shared/home/PromotionsCard";
import styles from "./CartIssueModal.module.scss";
import stateTypes from "../../../utils/constants/stateTypes";
import ZImage from "sushiweb/ZImage/ZImage";
import { CartUpdateSource_ModalCart } from "../../../utils/constants/others";
import Image from "../Image/Image";

class CartIssueModal extends React.Component {
  state = {
    quantity: 0,
    prevQuantity: 0,
    quantityWasChanged: false,
    isVisible: false,
    doneNextBtnClicked: false,
    alternateProductsSectionMaxHeight: "0px",
    enteredQty: "",
    minOrderQty: 1,
  };

  alternateProductsSectionRef = React.createRef();

  componentDidMount() {
    const { issueData, actions } = this.props;
    const { IssueProduct: issueProduct = {} } = issueData;
    if (issueData.SearchQuery) {
      actions.getAlternateProducts(issueData.SearchQuery);
    }

    this.setState((prevState) => ({
      ...prevState,
      quantity: parseIntOr0(
        issueProduct.Quantity && issueProduct.Quantity.CartQuantity
          ? issueProduct.Quantity.CartQuantity
          : 0
      ),
      prevQuantity: parseIntOr0(
        issueProduct.Quantity && issueProduct.Quantity.CartQuantity
          ? issueProduct.Quantity.CartQuantity
          : 0
      ),
      enteredQty: issueProduct.EnteredCartQty ? issueProduct.EnteredCartQty : 0,
      minOrderQty: parseIntOr0(
        issueProduct.Quantity && issueProduct.Quantity.MinimumOrderQuantity
          ? issueProduct.Quantity.MinimumOrderQuantity
          : 1
      ),
    }));

    setTimeout(() => {
      this.setState((prevState) => ({
        ...prevState,
        isVisible: true,
      }));
    }, 0);
  }

  componentDidUpdate(prevProps) {
    const {
      addToCartStatus,
      updateInCartStatus,
      deleteFromCartStatus,
      inProgressCart,
      issueProduct,
      closeModal,
      clearError,
      setError,
      issueId,
      issueData,
      alternateProducts,
    } = this.props;

    if (prevProps.addToCartStatus.state !== addToCartStatus.state) {
      if (addToCartStatus.state === stateTypes.STATE_DONE) {
        if (
          prevProps.inProgressCart.add.has(issueProduct.Id) &&
          !inProgressCart.add.has(issueProduct.Id)
        ) {
          this.setState((prevState) => ({
            ...prevState,
            prevQuantity: 1,
            doneNextBtnClicked: false,
          }));

          clearError(issueId);

          if (this.state.doneNextBtnClicked) {
            closeModal();
          }
        }
      } else if (addToCartStatus.state === stateTypes.STATE_FAIL) {
        if (
          prevProps.inProgressCart.add.has(issueProduct.Id) &&
          !inProgressCart.add.has(issueProduct.Id)
        ) {
          this.setState((prevState) => ({
            ...prevState,
            quantity: this.state.prevQuantity,
            doneNextBtnClicked: false,
          }));

          setError(issueId);
        }
      }
    }

    if (prevProps.updateInCartStatus.state !== updateInCartStatus.state) {
      if (updateInCartStatus.state === stateTypes.STATE_DONE) {
        if (
          prevProps.inProgressCart.update.has(issueProduct.Id) &&
          !inProgressCart.update.has(issueProduct.Id)
        ) {
          this.setState((prevState) => ({
            ...prevState,
            prevQuantity: parseIntOr0(this.state.quantity),
            doneNextBtnClicked: false,
          }));

          clearError(issueId);

          if (this.state.doneNextBtnClicked) {
            closeModal();
          }
        }
      } else if (updateInCartStatus.state === stateTypes.STATE_FAIL) {
        if (
          prevProps.inProgressCart.update.has(issueProduct.Id) &&
          !inProgressCart.update.has(issueProduct.Id)
        ) {
          this.setState((prevState) => ({
            ...prevState,
            quantity: this.state.prevQuantity,
            doneNextBtnClicked: false,
          }));

          setError(issueId);
        }
      }
    }

    if (prevProps.deleteFromCartStatus.state !== deleteFromCartStatus.state) {
      if (deleteFromCartStatus.state === stateTypes.STATE_DONE) {
        if (
          prevProps.inProgressCart.delete.has(issueProduct.Id) &&
          !inProgressCart.delete.has(issueProduct.Id)
        ) {
          this.setState((prevState) => ({
            ...prevState,
            prevQuantity: 0,
            doneNextBtnClicked: false,
          }));

          clearError(issueId);

          if (this.state.doneNextBtnClicked) {
            closeModal();
          }
        }
      } else if (deleteFromCartStatus.state === stateTypes.STATE_FAIL) {
        if (
          prevProps.inProgressCart.delete.has(issueProduct.Id) &&
          inProgressCart.delete.has(issueProduct.Id)
        ) {
          this.setState((prevState) => ({
            ...prevState,
            quantity: this.state.prevQuantity,
            doneNextBtnClicked: false,
          }));

          setError(issueId);
        }
      }
    }

    if (
      !isMobile(991) &&
      issueData.SearchQuery &&
      prevProps.alternateProducts.length === 0 &&
      alternateProducts.length > 0
    ) {
      this.setState((prevState) => ({
        ...prevState,
        alternateProductsSectionMaxHeight: `${this.alternateProductsSectionRef.current.scrollHeight}px`,
      }));
    }
  }

  quantityChangeHandler(value, inputType) {
    const newValue = typeof value === "string" ? value.trim() : value;
    const isNumber = !isNaN(parseInt(newValue));

    if (newValue === "" || isNumber) {
      this.setState(
        (prevState) => ({
          ...prevState,
          quantity: value,
          quantityWasChanged: true,
        }),
        () => {
          this.debouncedSubmitHandler(inputType);
        }
      );
    }
  }

  renderQuantityAndPriceRow(product) {
    return (
      <React.Fragment>
        {product.Quantity && product.Quantity.DisplayValue && (
          <div className="d-flex align-items-baseline">
            <div className="fs-12 mr-4 wd-25 text-align-left text-gray-800">
              Qty
            </div>
            <div className="fs-14 w-800">
              {product.Quantity.DisplayValue
                ? `${product.Quantity.DisplayValue}`
                : ""}
            </div>
          </div>
        )}
        {product.Price && product.Price.Price && (
          <div className="d-flex align-items-baseline">
            <div className="fs-12 wd-25 text-align-left text-gray-800 mr-4">
              {product.Price.Label ? product.Price.Label : ""}
            </div>
            <div
              className="fs-14 w-800 word-break text-gray-800"
              style={{ color: product.Price.Color }}
            >
              {product.Price.Price ? product.Price.Price : ""}
            </div>
            {product.Price.CompareAtPrice && (
              <div className="strike text-gray fs-11 ml-3">
                {product.Price.CompareAtPrice}
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }

  doneNextBtnClickHandler = () => {
    const { closeModal, hasError } = this.props;

    if (!hasError) {
      closeModal();
      return;
    }

    this.submitHandler();

    this.setState((prevState) => ({
      ...prevState,
      doneNextBtnClicked: true,
    }));
  };

  submitHandler(inputType) {
    const { cartUpdateSourceModal, actions, issueProduct, userData, base64Query} =
      this.props;

    let params = {
      ProductId: issueProduct.Id,
      OutletId: userData.outlet.id,
      GetCartSummary:
        cartUpdateSourceModal === CartUpdateSource_ModalCart ? true : false,
      Source: cartUpdateSourceModal,
      InputType: inputType
    };
    if (
      parseIntOr0(this.state.quantity) === 0 ||
      parseIntOr0(this.state.quantity) < parseIntOr0(this.state.minOrderQty)
    ) {
      params = { ...params, ProductIds: [params.ProductId] };
      actions.deleteFromCart(params, false,"", base64Query);
    } else if (parseIntOr0(this.state.quantity) === 1) {
      if (parseIntOr0(this.state.prevQuantity) === 0) {
        params = { ...params, Quantity: 1 };
        actions.addToCart(
          {
            ProductId: issueProduct.Id,
            OutletId: userData.outlet.id,
            Quantity: 1,
          },
          issueProduct.Id,"",base64Query
        );
      } else {
        params = { ...params, Quantity: parseIntOr0(this.state.quantity) };
        actions.updateInCart(params, "" ,"", base64Query);
      }
    } else {
      params = { ...params, Quantity: parseIntOr0(this.state.quantity) };
      actions.updateInCart(params, "", "",  base64Query);
    }
  }

  debouncedSubmitHandler = debounce(this.submitHandler);

  onClose = (e) => {
    const { inProgressCart, issueProduct, clearIssues, noOfUnresolvedIssues } =
      this.props;
    if (
      !(
        inProgressCart.add.has(issueProduct.Id) ||
        inProgressCart.update.has(issueProduct.Id) ||
        inProgressCart.delete.has(issueProduct.Id)
      )
    ) {
      if (!noOfUnresolvedIssues) {
        clearIssues();
      } else {
        return;
      }
    }
  };

  renderOfferTag(product) {
    return(
      <React.Fragment>
        {product?.OfferV2?.Tag ? (
          <span
            className={`w-800 ${styles.tag}`}
            style={{
              color: `${
                product.OfferV2.Tag.TextColor ? product.OfferV2.Tag.TextColor : ""
              }`,
              backgroundImage: `linear-gradient(to right, ${(
                product.OfferV2.Tag.Gradient || []
              ).join(",")})`,
            }}
          >
            {product.OfferV2.Tag.Text ? product.OfferV2.Tag.Text : ""}
          </span>
        ) : null}
      </React.Fragment>
    )
  }

  renderTag(product) {
    return(
        <React.Fragment>
          { product.Tag && (
            <div className={`d-flex mb-3 ${styles.tagV2}`} style={{backgroundColor:product.Tag.BgColor, color: product.Tag.TextColor, borderColor:product.Tag.BorderColor}}>
              <img 
              className={styles.imgTag}
              src={product.Tag.ImagePath}
              />
              <div className="fs-12 fw-600">
                {product.Tag.Text}
              </div>
            </div>
          )}
        </React.Fragment>
    );
  }

  renderUnitPrice(product) {
    if (!product || !product.UnitPriceV2) return null;
    const {
      UnitPriceV2: { Color, CompareAtPrice, Price, Unit },
    } = product;
    return (
      <div className={`text-align-left mt-3`}>
        <span
          className="w-800 text-gray-900 fs-16"
          style={{ color: Color }}
        >{`${Price}${Unit}`}</span>
        {CompareAtPrice && (
          <span className="w-500 fs-16 text-gray-500 strike ml-3">
            {CompareAtPrice}
          </span>
        )}
      </div>
    );
  }

  renderOffers(product) {
    if (!product?.OfferV2?.Offers) return null;
    const { OfferV2: { Offers = [],OffersBgColor = ""} = {} , SuperSaverSlab= {}} = product;
    return (
      <div className="text-align-left wd-100">
         {SuperSaverSlab?.Tag ? <div className={`mx-4 mt-2 ${styles.offerTag} d-flex justify-content-start align-items-center ${styles.offerTagV2}`} style={{backgroundColor: SuperSaverSlab.Tag?.BgColor}}>
          {SuperSaverSlab.Tag?.ImagePath ? <Image source={SuperSaverSlab.Tag?.ImagePath} classname={`${styles.img16} mr-2`}/> : <></>}<div className="fs-12 w-800" style={{color: SuperSaverSlab.Tag?.TextColor}}>{SuperSaverSlab.Tag?.Text}</div></div> :<></>}
        {SuperSaverSlab ? <div className="px-4">
                <div className={`d-flex justify-content-between ${styles.offerV2}`}  style={{backgroundColor:SuperSaverSlab.BgColor,borderRadius: SuperSaverSlab.Tag? "0px 8px 8px 8px": "8px"}}>
                    <div className="w-600 fs-13" style={{ color: SuperSaverSlab.TextColor}}>
                      {newLineText(SuperSaverSlab.Text)}
                    </div>
                    <div className="d-flex align-items-center pointer">
                      <div className="fs-13 w-800 text-red-500 text-align-right white-space-nowrap" style={{marginLeft:8}} 
                      onClick={(e)=> {e.stopPropagation(); this.quantityChangeHandler(SuperSaverSlab.CTA?.Action?.ActionData?.Quantity,SuperSaverSlab.CTA?.Action?.ActionData?.InputType)}}>
                        {SuperSaverSlab.CTA?.Text}
                      </div>
                    </div>
                    {SuperSaverSlab.ImagePath && (
                      <div className="d-flex flex-column align-items-center justify-content-center">
                        <div className={`${styles.tickImage}`}>
                        <ZImage 
                        src={SuperSaverSlab.ImagePath}
                        />
                        </div>
                      </div>
                    )
                    }
                </div> 
              </div>: <></>}

        {Offers && Offers?.length ? (
          <div className={`m-4 ${styles.offerV2}`} style={{backgroundColor:OffersBgColor}}>
            {Offers.map((o,index) => (
              <div>
                <div className={`d-flex justify-content-between`}>
                    <div className="w-600 fs-13" style={{ color: o.TextColor}}>
                      {o.Text}
                    </div>
                    <div className="d-flex align-items-center pointer">
                      <div className="fs-13 w-800 text-red-500 text-align-right white-space-nowrap" 
                      onClick={(e)=> {e.stopPropagation(); this.quantityChangeHandler(o.CTA?.Action?.ActionData?.Quantity,o.CTA?.Action?.ActionData?.InputType)}}>
                        {o.CTA?.Text}
                      </div>
                    </div>
                    {o.ImagePath ? (
                      <div className={styles.tickImage}>
                      <ZImage 
                      src={o.ImagePath}
                      />
                      </div>
                    ):<></>
                    }
                </div>
                {Offers.length-index-1 ? (
                    <div className={styles.offerLine}></div>
                ):null}
              </div>
            ))}
          </div>
        ):null}
      </div>
    );
  }

  render() {
    const {
      issueData,
      issueProduct,
      issueAvailableQuantity,
      inProgressCart,
      alternateProducts,
      alternateProductsStatus,
      cartUpdateSourceAlternateRail,
      toastOpenHandler,
      noOfUnresolvedIssues,
      isIssuesModalOpen,
      clearIssues,
    } = this.props;

    if (!issueData) return null;

    return (
      <Modal
        visible={this.state.isVisible}
        titleComponent={
          <div className={styles.header}>
            <div className={styles.headerTitle}>
              <img
                src={process.env.PUBLIC_URL + "/assets/images/exclamation.png"}
                alt=""
                className={styles.exclamation}
              />
              <span className="ml-3 fw-800 w-800 text-gray-1000">
                {issueData.IssueHeading}
              </span>
            </div>
            {!isMobile(991) && (
              <Button
                btnColor="red"
                className={`${
                  !this.state.doneNextBtnClicked &&
                  (inProgressCart.add.has(issueProduct.Id) ||
                    inProgressCart.update.has(issueProduct.Id) ||
                    inProgressCart.delete.has(issueProduct.Id))
                    ? ""
                    : "solid"
                }`}
                onClick={this.doneNextBtnClickHandler}
                loading={
                  this.state.doneNextBtnClicked &&
                  (inProgressCart.add.has(issueProduct.Id) ||
                    inProgressCart.update.has(issueProduct.Id) ||
                    inProgressCart.delete.has(issueProduct.Id))
                }
                disabled={
                  !this.state.doneNextBtnClicked &&
                  (inProgressCart.add.has(issueProduct.Id) ||
                    inProgressCart.update.has(issueProduct.Id) ||
                    inProgressCart.delete.has(issueProduct.Id))
                }
                size="compressed"
              >
                <span className="w-800">
                  {noOfUnresolvedIssues > 1 ? "Next" : "Done"}
                </span>
              </Button>
            )}
          </div>
        }
        showCloseIcon={false}
        slideOnMobile={true}
        isCentered={true}
        onClose={() => this.onClose()}
        padding="0px"
        className={styles.issueModalContainer}
      >
        <div className={styles.modalBody}>
          {issueData.IssueDescription && (
            <div
              className={`row text-red-700 w-600 fs-16 ${styles.issueDescription}`}
            >
              {issueData.IssueDescription}
            </div>
          )}
          <div className={`row ${styles.paddingFix}`}>
            <div className={`d-flex mb-5 ${styles.detailContainer}`}>
              <div className={`col-md-3 ${styles.isseModalImgContainer}`}>
                {this.renderOfferTag(issueProduct)}
                <img
                  src={issueProduct.ImagePath}
                  className={styles.imageContainer}
                  alt=""
                />
              </div>
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-12">
                    {this.renderTag(issueProduct)}
                    <div className="my-2 ml-4 fs-18 text-gray-900 w-800 word-break">
                      {issueProduct.Name}
                    </div>
                    <div className="ml-4 text-align-left">
                      <span className="fs-16 fw-400 w-400 text-gray-600">
                        {issueProduct.Quantity.DisplayValue}
                      </span>
                    </div>
                    {this.renderOffers(issueProduct)}
                    <div className="ml-4 line-light"></div>
                    <div className={`d-flex wd-100 mb-4 mt-4`}>
                      <div className="d-flex flex-column ml-3">
                        {issueProduct.UnitPriceV2 ? (
                          <div
                            className="fs-20 w-800 col-8"
                            style={{
                              color:
                                issueProduct.UnitPriceV2 &&
                                issueProduct.UnitPriceV2.Color,
                            }}
                          >
                            {issueProduct.UnitPriceV2?.Price}
                            {issueProduct.UnitPriceV2?.Unit}
                            {issueProduct.UnitPriceV2?.CompareAtPrice ? (
                              <span className="ml-3 fs-16 w-500 strike text-gray-600">
                                {issueProduct.UnitPriceV2.CompareAtPrice}
                              </span>
                            ) : null}
                          </div>
                        ) : (
                          <></>
                        )}
                        {issueProduct.UnitPriceV2?.SubHeading ? (
                          <div className="d-flex align-items-baseline fs-14 w-600 text-gray-600 pl-3">
                            {issueProduct.UnitPriceV2.SubHeading
                              ? issueProduct.UnitPriceV2.SubHeading
                              : ""}
                          </div>
                        ) : null}
                      </div>
                      <div
                        className={`d-flex flex-column justify-content-center ${styles.cta}`}
                      >
                        {this.props.userData.outlet.ctaText ===
                        "Launching Soon" ? (
                          <div className={styles.launchingSoon}>
                            Launching Soon
                          </div>
                        ) : this.state.prevQuantity === 0 &&
                          this.state.quantityWasChanged ? (
                          <Button
                            type="submit"
                            size="small"
                            btnColor="red"
                            iconAlign="right"
                            appearance="outline"
                            onClick={() =>
                              this.quantityChangeHandler(this.state.minOrderQty)
                            }
                            className="thin-sushi-btn"
                            disabled={this.state.doneNextBtnClicked}
                            loading={
                              !this.state.doneNextBtnClicked &&
                              inProgressCart.add.has(issueProduct.Id)
                            }
                          >
                            <span className="w-800">ADD</span>
                            <span className="fs-22 w-800 ml-2">+</span>
                          </Button>
                        ) : (
                          <CounterInput
                            type="text"
                            size="smallest"
                            placeholder={0}
                            quantityChangeHandler={(val) => {
                              this.quantityChangeHandler(val);
                            }}
                            minLimit={0}
                            maxLimit={
                              issueAvailableQuantity < this.state.minOrderQty
                                ? 0
                                : issueAvailableQuantity
                            }
                            defaultVal={
                              issueAvailableQuantity < this.state.minOrderQty
                                ? 0
                                : this.state.quantity
                            }
                            disabled={
                              issueAvailableQuantity === 0 ||
                              this.state.doneNextBtnClicked
                            }
                            showLoader={
                              !this.state.doneNextBtnClicked &&
                              (inProgressCart.update.has(issueProduct.Id) ||
                                inProgressCart.delete.has(issueProduct.Id))
                            }
                            minOrderQty={this.state.minOrderQty}
                          />
                        )}
                      </div>
                    </div>
                    <div className="text-red-400">
                      {issueProduct.PriceBreakup &&
                        issueProduct.PriceBreakup.length > 0 &&
                        issueProduct.PriceBreakup.map((priceBrekUp, index) => (
                          <span
                            key={index}
                            className="d-flex align-items-center"
                          >
                            {priceBrekUp}{" "}
                          </span>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {isMobile(991) && (
              <Button
                btnColor="red"
                className={`${
                  !this.state.doneNextBtnClicked &&
                  (inProgressCart.add.has(issueProduct.Id) ||
                    inProgressCart.update.has(issueProduct.Id) ||
                    inProgressCart.delete.has(issueProduct.Id))
                    ? ""
                    : "solid"
                } ${styles.buttonMobile}`}
                onClick={this.doneNextBtnClickHandler}
                loading={
                  this.state.doneNextBtnClicked &&
                  (inProgressCart.add.has(issueProduct.Id) ||
                    inProgressCart.update.has(issueProduct.Id) ||
                    inProgressCart.delete.has(issueProduct.Id))
                }
                disabled={
                  !this.state.doneNextBtnClicked &&
                  (inProgressCart.add.has(issueProduct.Id) ||
                    inProgressCart.update.has(issueProduct.Id) ||
                    inProgressCart.delete.has(issueProduct.Id))
                }
                size="compressed"
              >
                <span className="">
                  {noOfUnresolvedIssues > 1 ? "Next" : "Done"}
                </span>
              </Button>
            )}
          </div>
          {!isMobile(991) && issueData.SearchQuery && alternateProducts.length > 0 ? (
            <React.Fragment>
              <section
                className={styles.alternateProductsSection}
                ref={this.alternateProductsSectionRef}
                style={{
                  maxHeight: this.state.alternateProductsSectionMaxHeight,
                }}
              >
                <div>
                  <div
                    className={`mt-5 fs-24 w-800 text-gray-1000 ${styles.railHeading}`}
                  >
                    {issueData.RailHeading}
                  </div>
                  <div>
                    <PromotionsCard
                      promotionProductsStatus={alternateProductsStatus}
                      promotionsData={alternateProducts}
                      toastOpenHandler={toastOpenHandler}
                      type="alternateProducts"
                      cartUpdateSource={cartUpdateSourceAlternateRail}
                      dontOpenIssueModal={true}
                      isIssuesModalOpen={isIssuesModalOpen}
                      clearIssues={clearIssues}
                    />
                  </div>
                </div>
              </section>
            </React.Fragment>
          ) : null}
        </div>
      </Modal>
    );
  }
}

export default connect(
  (state) => ({
    // data
    userData: state.user.data,
    inProgressCart: state.cart.inProgress,
    alternateProducts: state.catalog.alternateProducts.products,
    // status
    alternateProductsStatus: state.status.products_alternate,
    addToCartStatus: state.status.cart_add,
    updateInCartStatus: state.status.cart_update,
    deleteFromCartStatus: state.status.cart_delete,
    base64Query: state.catalog.QueryString,

  }),
  (dispatch) => ({
    actions: bindActionCreators(
      { addToCart, updateInCart, deleteFromCart, getAlternateProducts, getFilters },
      dispatch
    ),
  })
)(CartIssueModal);
