export const PRODUCTS_RAIL = "PRODUCTS_RAIL";
export const CATEGORY_RAIL = "CATEGORY_RAIL";
export const SUBCATEGORY_RAIL = "SUBCATEGORY_RAIL";
export const BANNER_RAIL = "BANNER_RAIL";
export const AEROBAR_RAIL = "AEROBAR_RAIL";
export const TESTIMONIALS_RAIL = "TESTIMONIALS_RAIL";
export const COMMERCIAL_RAIL = "COMMERCIAL_RAIL";
export const ALTERNATE_PRODUCT_RAIL = "ALTERNATE_PRODUCT_RAIL";
export const PRODUCT_REQUEST_RAIL = "PRODUCT_REQUEST_RAIL";
export const SIMILAR_PRODUCTS = "SIMILAR_PRODUCTS";
export const AEROBAR_RAIL_INITIAL = "AEROBAR_RAIL_INITIAL";
export const BRAND_CAMPAIGN_RAIL = "BRAND_CAMPAIGN_RAIL";
export const SCORING_CHEAT_CODE = "XYZSPOON";
export const COLLABORATIVE_BANNERS = "COLLABORATIVE_BANNERS";
export const DISCOUNT_RAIL = "DISCOUNT_RAIL";

const config = {
    [AEROBAR_RAIL]: {
        slidesToShow: 2,
        slidesToSlide: 1
    },
    [AEROBAR_RAIL_INITIAL]: {
        slidesToShow: 2,
        slidesToSlide: 0
    },
    [TESTIMONIALS_RAIL]: {
        slidesToShow: 2,
        slidesToSlide: 1,
        slidesToShowTablet: 2,
        slidesToSlideTablet: 1,
    },
    [COMMERCIAL_RAIL]: {
        slidesToShow: 2,
        slidesToSlide: 1
    },
    [CATEGORY_RAIL]: {
        slidesToShow: 6,
        slidesToSlide: 2
    },
    [SUBCATEGORY_RAIL]: {
        slidesToShow: 4.7,
        slidesToSlide: 2,
        slidesToShowTablet: 3.7,
        slidesToSlideTablet: 1,
    },
    [ALTERNATE_PRODUCT_RAIL]: {
        slidesToShow: 2.5,
        slidesToSlide: 2,
        slidesToShowMobile: 1.5,
        slidesToSlideMobile: 1
    },
    [PRODUCT_REQUEST_RAIL]: {
        slidesToShow: 2.5,
        slidesToSlide: 2
    },
    [PRODUCTS_RAIL]: {
        slidesToShow: 4,
        slidesToSlide: 2,
        slidesToShowTablet: 3,
        slidesToSlideTablet: 1,
        slidesToShowMobile: 1.5,
        slidesToSlideMobile: 1
    },
    [SIMILAR_PRODUCTS]: {
        slidesToShow: 2.5,
        slidesToSlide: 2
    },
    [BRAND_CAMPAIGN_RAIL]: {
        slidesToShow: 6,
        slidesToSlide: 1,
        slidesToShowTablet: 5,
        slidesToSlideTablet: 1,
    },
    [COLLABORATIVE_BANNERS]: {
        slidesToShow: 1,
        slidesToSlide: 1
    },
    [DISCOUNT_RAIL]: {
        slidesToShow: 3,
        slidesToSlide: 1
    },
}

export function getRailConfig(type, count) {
    let responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1200 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1200, min: 800 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 800, min: 200 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
    };
    const selectedConfig = config[type]
    if(!selectedConfig){
        return null
    }
    responsive.desktop.items = selectedConfig.slidesToShow>=count?count:selectedConfig.slidesToShow
    responsive.desktop.slidesToSlide =  selectedConfig.slidesToSlide
    responsive.tablet.items = selectedConfig.slidesToShowTablet ?  selectedConfig.slidesToShowTablet>=count?count:selectedConfig.slidesToShowTablet : selectedConfig.slidesToShow>=count?count:selectedConfig.slidesToShow
    responsive.tablet.slidesToSlide = selectedConfig.slidesToSlideTablet ?  selectedConfig.slidesToSlideTablet :  selectedConfig.slidesToSlide
    responsive.mobile.items = selectedConfig.slidesToShowMobile ?  selectedConfig.slidesToShowMobile>=count?count:selectedConfig.slidesToShowMobile : selectedConfig.slidesToShow>=count?count:selectedConfig.slidesToShow
    responsive.mobile.slidesToSlide = selectedConfig.slidesToSlideMobile ?  selectedConfig.slidesToSlideMobile :  selectedConfig.slidesToSlide
    
    return responsive
}