import _get from "lodash-es/get";

import getAxios from "../../utils/axios";
import APIendpoints from "../../utils/constants/APIendpoints";
import { deleteCookieForEntity, getCookieForEntity, setCookieForEntity, RemoveLocalStorageForEntity, isPetPoojaClient } from "../../utils/helpers";
import actionTypes from "../actionTypes";
import { ADD_LOCATION_SIGN_UP, CLIENT_TYPE_PETPOOJA, COOKIE_KEYS, COOKIE_TTL_FOR_WEB, LOCAL_STORE_KEYS } from "../../utils/constants/others";

function signIn(requestData,callback) {
  return (dispatch) => {
    dispatch({ type: actionTypes.AUTH_SIGN_IN_BEGIN });
    getAxios()
      .post(APIendpoints.signIn, requestData)
      .then(({ data, headers }) => {
        // set in localStorage
        const token = headers.authorization;
        setCookieForEntity(COOKIE_KEYS.TOKEN, COOKIE_KEYS.POS_TOKEN, token, COOKIE_TTL_FOR_WEB)
        
        dispatch({
          type: actionTypes.AUTH_SIGN_IN_DONE,
          signInResponse: data,
          showLocationModal: data?.response?.Action?.ActionType === ADD_LOCATION_SIGN_UP? true : false,
          locationModalData: data?.response?.Action?.ActionData
        });

        if(typeof(callback) === 'function'){
          callback(data)
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.AUTH_SIGN_IN_FAIL,
          error:  _get(
            err,
            ["response", "data", "error"],
            {}
          ),
          errorData: _get(
            err,
            ["response", "data"],
            {}
          ),
        });
      });
  };
}

function signOut() {
  return (dispatch) => {
    dispatch({ type: actionTypes.AUTH_SIGN_OUT_BEGIN });
    getAxios(true)
      .post(APIendpoints.signOut)
      .then(() => {

        // delete cookies for outlet, token and app mode
        deleteCookieForEntity(COOKIE_KEYS.OUTLET_ID, COOKIE_KEYS.POS_OUTLET_ID);
        deleteCookieForEntity(COOKIE_KEYS.TOKEN, COOKIE_KEYS.POS_TOKEN);
        deleteCookieForEntity(COOKIE_KEYS.APP_MODE, COOKIE_KEYS.APP_MODE);


        // delete cookies for clientType, clientData, isIframe and posSessionId
        // and redirect to login only when clientType is not petpooja
        // else redirect to init page
        if (!isPetPoojaClient()) {
          deleteCookieForEntity("","clientData");
          deleteCookieForEntity("","clientType");
          deleteCookieForEntity(COOKIE_KEYS.IS_IFRAME, COOKIE_KEYS.IS_IFRAME);
          deleteCookieForEntity(COOKIE_KEYS.POS_SESSION_ID, COOKIE_KEYS.POS_SESSION_ID);
          RemoveLocalStorageForEntity(LOCAL_STORE_KEYS.POS_STORES_DATA, true);
        }
        window.location.href = "/";
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.AUTH_SIGN_OUT_FAIL,
          error:  _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
        deleteCookieForEntity("token","posToken");
        deleteCookieForEntity("outletId","posOutletId");
        deleteCookieForEntity("","clientData");
        deleteCookieForEntity("","clientType");
        window.location.href = "/";
      });
  };
}

function signOutAndRouted(routeTo) {
  return (dispatch) => {
    dispatch({ type: actionTypes.AUTH_SIGN_OUT_BEGIN });
    getAxios(true)
      .post(APIendpoints.signOut)
      .then(() => {

         // delete cookies for outlet, token and app mode
         deleteCookieForEntity(COOKIE_KEYS.OUTLET_ID, COOKIE_KEYS.POS_OUTLET_ID);
         deleteCookieForEntity(COOKIE_KEYS.TOKEN, COOKIE_KEYS.POS_TOKEN);
         deleteCookieForEntity(COOKIE_KEYS.APP_MODE, COOKIE_KEYS.APP_MODE);
 
 
         // delete cookies for clientType, clientData, isIframe and posSessionId
         // and redirect to login only when clientType is not petpooja
         // else redirect to init page
         if (getCookieForEntity("", "clientType") === CLIENT_TYPE_PETPOOJA) {
         }else{
           deleteCookieForEntity("","clientData");
           deleteCookieForEntity("","clientType");
           deleteCookieForEntity("", COOKIE_KEYS.IS_IFRAME);
           deleteCookieForEntity("", COOKIE_KEYS.POS_SESSION_ID);
           window.location.href = "/";
         }

         // redirect to routeTo if provided else reload
         if (routeTo) {
          window.location.href = routeTo;
        } else {
          window.location.reload();
        }        
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.AUTH_SIGN_OUT_FAIL,
          error:  _get(
            err,
            ["response", "data", "error"],
            {}
          )
        });
      });
  };
}

// Self-Onboardiong step 1

function registerStepOne(params) {
  return (dispatch) => {
    dispatch({ type: actionTypes.REGISTER_STEP_ONE_BEGIN });
    getAxios()
      .post(APIendpoints.registerStepOne, params)
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.REGISTER_STEP_ONE_DONE,
          salesLeadId: data.response.salesLeadId,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.REGISTER_STEP_ONE_FAIL,
          error:  _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  };
}

function updateRegStepOne(params, salesLeadId) {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_REG_STEP_ONE_BEGIN });
    getAxios()
      .put(APIendpoints.updateRegStepOne(salesLeadId), params)
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.UPDATE_REG_STEP_ONE_DONE,
          salesLeadId: data.response.salesLeadId,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.UPDATE_REG_STEP_ONE_FAIL,
          error:  _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  };
}

function registerStepTwo(params) {
  return (dispatch) => {
    dispatch({ type: actionTypes.REGISTER_STEP_TWO_BEGIN });
    getAxios()
      .post(APIendpoints.registerStepTwo, params)
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.REGISTER_STEP_TWO_DONE,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.REGISTER_STEP_TWO_FAIL,
          error:  _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  };
}

function setRegisterData(stepNo, data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_REGISTER_DATA_BEGIN,
      stepNo: stepNo,
      data: data,
    });
  };
}

function uploadImageOnboard(type, params, storedData) {
  return (dispatch) => {
    if (type === "Pan") {
      dispatch({ type: actionTypes.UPLOAD_PAN_IMAGE_BEGIN });
    } else if (type === "FSSAICertificate") {
      dispatch({ type: actionTypes.UPLOAD_FSSAI_IMAGE_BEGIN });
    } else if (type === "GstCertificate") {
      dispatch({ type: actionTypes.UPLOAD_GST_IMAGE_BEGIN });
    }
    getAxios()
      .post(APIendpoints.uploadOnboardDocument, params)
      .then(({ data, headers }) => {
        if (type === "Pan") {
          dispatch({
            type: actionTypes.UPLOAD_PAN_IMAGE_DONE,
            data: data.response.image[0],
          });
        } else if (type === "FSSAICertificate") {
          dispatch({
            type: actionTypes.UPLOAD_FSSAI_IMAGE_DONE,
            data: data.response.image[0],
          });
        } else if (type === "GstCertificate") {
          dispatch({
            type: actionTypes.UPLOAD_GST_IMAGE_DONE,
            data: data.response.image[0],
          });
        }
      })
      .catch((err) => {
        if (type === "Pan") {
          dispatch({
            type: actionTypes.UPLOAD_PAN_IMAGE_FAIL,
            error: _get(
              err,
              ["response", "data", "error", "message"],
              "Something went wrong!"
            ),
          });
        } else if (type === "FSSAICertificate") {
          dispatch({
            type: actionTypes.UPLOAD_FSSAI_IMAGE_FAIL,
            error: _get(
              err,
              ["response", "data", "error", "message"],
              "Something went wrong!"
            ),
          });
        } else if (type === "GstCertificate") {
          dispatch({
            type: actionTypes.UPLOAD_GST_IMAGE_FAIL,
            error: _get(
              err,
              ["response", "data", "error", "message"],
              "Something went wrong!"
            ),
          });
        }
      });
  };
}

function validateGstNumber(gst) {
  return (dispatch) => {
    dispatch({ type: actionTypes.VALIDATE_GST_BEGIN });
    getAxios()
      .get(APIendpoints.checkGst(gst))
      .then(({ data, headers }) => {
        dispatch({ type: actionTypes.VALIDATE_GST_DONE, data: data });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.VALIDATE_GST_FAIL,
          error:  _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  };
}

function checkValidPhoneNumber(requestData) {
  return (dispatch) => {
    dispatch({ type: actionTypes.VALIDATE_PHONE_NUMBER_BEGIN });
    getAxios()
      .post(APIendpoints.validatePhoneNumber, requestData)
      .then(({ data, headers }) => {
        dispatch({ type: actionTypes.VALIDATE_PHONE_NUMBER_DONE });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.VALIDATE_PHONE_NUMBER_FAIL,
          error:  _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  };
}

function registerVerifyOtp(otp, phoneNumber) {
  let requestData = {
    OtpNumber: otp,
    PhoneNumber: phoneNumber,
  };
  return (dispatch) => {
    dispatch({ type: actionTypes.REGISTER_VERIFY_OTP_BEGIN });
    getAxios()
      .post(APIendpoints.registerVerifyOtp, requestData)
      .then(({ data, headers }) => {
        dispatch({ type: actionTypes.REGISTER_VERIFY_OTP_DONE });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.REGISTER_VERIFY_OTP_FAIL,
          error:  _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  };
}

function getOtp(phoneNumber) {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_OTP_BEGIN });
    getAxios()
      .post(APIendpoints.getOtp(phoneNumber))
      .then(({ data, headers }) => {
        dispatch({ type: actionTypes.GET_OTP_DONE });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_OTP_FAIL,
          error:  _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  };
}

function registerAllSteps(params) {
  return (dispatch) => {
    dispatch({ type: actionTypes.REGISTER_ALL_STEPS_BEGIN });
    getAxios()
      .post(APIendpoints.registerAllSteps, params)
      .then(({ data, headers }) => {
        const token = headers.authorization;
        setCookieForEntity("token","posToken", token, COOKIE_TTL_FOR_WEB)
        dispatch({
          type: actionTypes.AUTH_SIGN_IN_DONE,
        });
        dispatch({
          type: actionTypes.REGISTER_ALL_STEPS_DONE,
          promotionData: data.response.promotion,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.REGISTER_ALL_STEPS_FAIL,
          error:  _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  };
}

function forgotPasswordEnterPhone(phoneNumber,source) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FORGOT_PASSWORD_ENTER_PHONE_BEGIN });
    getAxios()
      .post(APIendpoints.forgotPasswordEnterPhone(phoneNumber,source), {
        isForgotPassword: true,
        userPhoneNumber: phoneNumber,
      })
      .then(({ data, headers }) => {
        dispatch({ type: actionTypes.FORGOT_PASSWORD_ENTER_PHONE_DONE });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.FORGOT_PASSWORD_ENTER_PHONE_FAIL,
          error:  _get(
            err,
            ["response", "data", "error"],
            {}
          ),
          errorData:  _get(
            err,
            ["response", "data"],
            {}
          )
        });
      });
  };
}

function validateReferralCode(code) {
  return (dispatch) => {
    dispatch({ type: actionTypes.VALIDATE_REFERRAL_CODE_BEGIN });
    getAxios()
      .get(APIendpoints.referralCode(code))
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.VALIDATE_REFERRAL_CODE_DONE,
          referralCode: data.response.referralDetails,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.VALIDATE_REFERRAL_CODE_FAIL,
          error:  _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  };
}

function promotionVisibility(cityId = 0) {
  return (dispatch) => {
    dispatch({ type: actionTypes.PROMOTION_VISIBILITY_BEGIN });
    getAxios()
      .get(APIendpoints.getBonus("signup", cityId))
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.PROMOTION_VISIBILITY_DONE,
          bonusAmount: data.response.signup_bonus_amount,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.PROMOTION_VISIBILITY_FAIL,
          error:  _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  };
}

function setReferralCode(code) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_REFERRAL_CODE_DONE, code });
  };
}

function validatePinCode(cityId, pinCode) {
  return (dispatch) => {
    dispatch({ type: actionTypes.VALIDATE_PINCODE_BEGIN });
    getAxios()
      .get(APIendpoints.validatePinCode(cityId, pinCode))
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.VALIDATE_PINCODE_DONE,
          isPinCodeValid: data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.VALIDATE_PINCODE_FAIL,
          error:  _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  };
}

function serviceablePinCode(cityId, pinCode) {
  return (dispatch) => {
    dispatch({ type: actionTypes.VALIDATE_PINCODE_BEGIN });
    getAxios()
      .get(APIendpoints.serviceablePinCode(cityId, pinCode))
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.VALIDATE_PINCODE_DONE,
          serviceablePinCode: data.response,
          isPinCodeValid: data.response.IsServiceable !== "NOT_SERVICEABLE",
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.VALIDATE_PINCODE_FAIL,
          error:  _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  };
}

function resetOnboardThirdFailStatus() {
  return (dispatch) => {
    dispatch({ type: actionTypes.REGISTER_ALL_STEPS_INIT });
  };
}

function deleteContact(requestBody, errorCallBack) {
  return (dispatch) => {
    dispatch({ type: actionTypes.DELETE_CONTACT_BEGIN });
    getAxios(true)
      .delete(APIendpoints.deleteContact, { data: requestBody })
      .then(() => {
        dispatch({
          type: actionTypes.DELETE_CONTACT_DONE,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.DELETE_CONTACT_FAIL,
          error: _get(
            err,
            ["response", "data", "error", "message"],
            "Something went wrong!"
          ),
        });
        if (typeof errorCallBack === "function") {
          errorCallBack(err);
        }
      });
  };
}

export {
  signIn,
  signOut,
  registerStepOne,
  setRegisterData,
  uploadImageOnboard,
  validateGstNumber,
  checkValidPhoneNumber,
  registerVerifyOtp,
  getOtp,
  registerAllSteps,
  forgotPasswordEnterPhone,
  validateReferralCode,
  setReferralCode,
  promotionVisibility,
  registerStepTwo,
  updateRegStepOne,
  validatePinCode,
  resetOnboardThirdFailStatus,
  signOutAndRouted,
  serviceablePinCode,
  deleteContact,
};
