import actionTypes from "../actionTypes";

function dashboardReducer(
  state = {
    graphDetails: null,
    savings: [],
    categoryWiseSpendData: [],
    outletWiseSpendData: [],
    daysOrdered: 0,
    numberOfOrders: 0,
    durations: [],
    fcsFilePath: "",
  },
  action
) {
  const { type, payload } = action;

  switch (type) {
   
    case actionTypes.GET_OWNERS_DASHBOARD_DONE:
      return {
        ...state,
        graphDetails: action.graphDetails,
        savings: action.savings,
        categoryWiseSpendData: action.categoryWiseSpendData,
        outletWiseSpendData: action.outletWiseSpendData,
        daysOrdered: action.daysOrdered,
        numberOfOrders: action.numberOfOrders,
        durations: action.durations,
      };

    case actionTypes.GET_OWNERS_DASHBOARD_FILE_PATH_DONE:
      return {
        ...state,
        fcsFilePath: action.payload,
      };

    default:
      return state;
  }
}

export default dashboardReducer;
