import React from "react";
import Header from "../shared/header/Header";
import { useEffect,useState } from "react";
import styles from "./CompleteRestaurantSetup.module.scss";
import Image from "../shared/Image/Image";
import { getOnboardingStatus } from "../../redux/dispatchers/userDispatcher";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { HTMLDiv } from "../shared/HTMLDiv";
import Button from "../shared/Button";
import { white, black, red, green } from "sushiweb/tokens/color";
import SignInFlow from "../shared/header/SignInFlow";
import { trackPopUp } from "../../redux/dispatchers/userDispatcher";
import queryString from "query-string";
import { REDIRECTION_HOME,REDIRECTION_CART,REDIRECTION_CHECKOUT } from "../../utils/constants/others";
import { CompleteGreenTick } from "../../StaticVectorGraphics/completeGreenTicket";
import { Lock } from "../../StaticVectorGraphics/Lock";
import { isMobile } from "../../utils/helpers";
import { ChevronLeft, ChevronDown } from "sushiweb/Icons";

const RestaurantSetup = props => {

    const [onboardingData,setOnboardingData] = useState({})
    const [actionType,setActionType] = useState("")
    const [modalOpen,setModalOpen] = useState(false)
    
    useEffect(()=>{
        props.actions.getOnboardingStatus((data)=>{
            setOnboardingData(data)
            //here condition if all the steps are done then redirect to home page
            if(data?.Steps[data?.Steps?.length-1].CompletedImage.length>0){
                let param=queryString.parse(window.location.search)
                param = param?.completeRedirection
                
                if(param === REDIRECTION_HOME){
                    props.history.push('/')
                }else if(param?.length>20){
                    props.history.push(param)
                }else if(param===REDIRECTION_CHECKOUT){
                    props.history.push("/buyer/checkout")
                }else if(param===REDIRECTION_CART){
                    props.history.push("/buyer/cart")
                }else{
                    props.history.push('/')
                }
            }
        })
        
    },[])

    const handleOnboardingPageCTA = (actionType,trackingParams) => {
        setActionType(actionType)
        setModalOpen(true)
        props.actions.trackPopUp(trackingParams)
    }

    


    const getCompletedButton = (completedImage) => {
        return isMobile(899) ? <CompleteGreenTick/>  :<div className={`w-400 fs-16 ${styles.completedButton} d-flex justify-content-center`}>
           <CompleteGreenTick/>
         <span className="ml-2 d-flex justify-content-center align-items-center">Completed</span>
        </div>
    }   

    const getLockedButton = () => {
        return isMobile(899) ? <></> :<div className={`${styles.buttonWidth} d-flex justify-content-center`}> <Button 
        disabled={true}
        icon={<Lock/>}
        iconAlign="center"
        btnColor="#F2F4F7"
        className={`${styles.cardButton} ${styles.lockedButton}`} 
        ></Button></div>
    }

    const addDetailsButton = (cta) => {
        return <div style={{width:'80%'}} className="d-flex justify-content-center"> <Button 
        icon={cta?.IconImage?<Image source={cta?.IconImage}/>:<></>}
        onClick={()=>handleOnboardingPageCTA(cta?.Action?.ActionType,cta?.Action?.TrackingParams)}
        iconAlign="center"
        btnColor={"#EF4F5F"}
        className={`${styles.cardButton}`}
        >{cta?.Text}</Button></div>
    }

    const getButton = (cta,completedImage) => {

        if ( completedImage.length > 0 ){
           return getCompletedButton(completedImage)
        }else if(cta===null){
            return getLockedButton()
        }else {
            return addDetailsButton(cta)
        }

    }

    const handleOnClose = () => {
        setModalOpen(false)
    }

    return (
        <React.Fragment>
            <div className={`${styles.headerVisible}`}>
                <Header/>
            </div>
            
            <div className={`${styles.siteBackgroundColor} `}>
                <div className={`container ${styles.marginTop}`}>
                    <div className={`${styles.backgroundMain} position-relative`}>
                        <div className={`${styles.setupBackgroundImage}`}>

                            <div className={`${styles.heading} position-absolute d-flex flex-column`}>
                                <span className={` w-800`}>{onboardingData?.Header?.Heading.split(" ")[0]}</span>
                                <span className={` w-800`}>{onboardingData?.Header?.Heading.split(" ").slice(1).join(" ")}</span>
                            </div>
                            <div className={`${styles.cafeImage} position-absolute`}></div>
                            <div className={`${styles.cafeInlineImage} position-absolute`}>
                                <div className={`${styles.cafeInlineImageDiv}`}></div>
                            </div>

                            <div className={`${styles.onboardinModalIcon} position-absolute`}>
                                <ChevronLeft
                                    size={24}
                                    onClick={() => {
                                        window.location.href = "/";
                                    }}
                                    color={"white"}
                                />
                            </div>

                            <span className={`${styles.subHeading} position-absolute w-400`}>{onboardingData?.Header?.SubHeading}</span>    

                            <div  className={onboardingData?.Header?.BottomTag?.ImagePath?.length?`${styles.discountBox} position-absolute fs-15  d-flex justify-content-start align-items-center`:`${styles.discountBox} position-absolute fs-15  d-flex justify-content-center align-items-center`}>
                                {onboardingData?.Header?.BottomTag?.ImagePath?.length  ?
                                <div  className={`${styles.bottomTagImage} mr-6`}>
                                    <Image classname={` ${styles.widthInherit}`} source={onboardingData?.Header?.BottomTag?.ImagePath}/>
                                </div>:<></>}
                                <div  >{onboardingData?.Header?.BottomTag?.Text} </div>
                            </div>
                        </div>

                        <div className={`${styles.stepsBox}  width-1040`}>
                        {
                            onboardingData?.Steps?.map((step,index)=>{
                                return <div className={`${styles.stepsCard}`}>
                                            <div className={`${styles.stepsImages} width-100 d-flex justify-content-center`}>
                                                <Image source={step.Icon} classname={`${styles.widthInherit}`}/>
                                            </div>
                                            <span className={`${styles.cartButtonText}`}>{<HTMLDiv html= { step?.Heading}/>}</span>
                                            <div className={`${styles.cardButtons}`} >{getButton(step.CTA,step.CompletedImage)} </div>
                                        </div>
                            })
                        }
                        </div>
                    </div>
                    

                   
                    <div  className={`${styles.notesLine}`}>
                        <hr className={`${styles.horizontalDash} `}/>
                    </div>

                    <div className={`${styles.listColorMainBox}`}>
                        <div  className={`d-flex justify-content-center ${styles.listColor} width-100`}>
                            <div className="width-1040">
                                <span>{onboardingData?.Note?.Header}:</span>
                                    <ol className={`mt-0`} style={{paddingLeft:'20px'}}>
                                        {
                                    onboardingData?.Note?.NoteItems?.map((item,idx)=>{
                                    return <li>
                                            {item.Text}
                                        </li>
                                    }) 
                                        }
                                    </ol>
                                </div>
                        </div>
                    </div>
                    
                    {
                        actionType?.length?<SignInFlow onClose={handleOnClose} isOpen={modalOpen} onboardingData={onboardingData} actionType={actionType} setActionType={setActionType}/>:null
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default withRouter(
    connect(
        (state)=>({

        }),(dispatch)=>({
            actions:bindActionCreators({
                getOnboardingStatus,trackPopUp
            },dispatch)
        })
    )
(RestaurantSetup))