import React, { useState } from "react";
import { isLottie } from "../../../utils/constants/utils";
import AnimationRenderer from "../AnimationRenderer/AnimationRenderer";

function Image({ source, classname, onClick, loop, destroyOnLoopComplete }) {
  return source ? (
    isLottie(source) ? (
      <AnimationRenderer
        onClick={onClick}
        destroyOnLoopComplete={destroyOnLoopComplete}
        animationClass={`${classname} `}
        animationUrl={source}
        loop={loop}
      />
    ) : (
      <img onClick={onClick} className={classname} src={source} />
    )
  ) : (
    <div onClick={onClick} className={classname}></div>
  );
}

export default Image;
