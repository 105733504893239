import { ORDER_SUPPORT_MODAL_PANELS } from "../../utils/constants/others";
import actionTypes from "../actionTypes";

/**
 * @typedef {Object} issueDetails
 * @property {number} quantity - The primary panel identifier.
 * @property {number} reason - The secondary panel details.
 * @property {string} comment - The current secondary panel.
 * @property {Array<string>} photos - The total number of secondary panels.
 * @property {number} productId - product id of the item with issue
 */

/**
 * @typedef {Object} Panel
 * @property {number} primary - The primary panel identifier.
 * @property {Object} secondary - The secondary panel details.
 * @property {number} secondary.current - The current secondary panel.
 * @property {number} secondary.total - The total number of secondary panels.
 */

/**
 * @typedef {Object} InitialState
 * @property {Array<*>} selectedItems - The array of selected items.
 * @property {Object} selectedItemsMap - A map of selected items.
 * @property {boolean} nextPanel - Indicates whether the next panel is active.
 * @property {issueDetails} issueDetails - Details of the issue.
 * @property {Panel} panel - Panel details.
 * @property {boolean} loading - Indicates that final request is in process.
 */

/** @typedef {InitialState} */
const initialState = {
  selectedItems: [],
  selectedItemsMap: {},
  nextPanel: false,
  issueDetails: {
    productId: 0,
    quantity: 0,
    reason: 0,
    comment: "",
    photos: [],
  },
  panel: {
    primary: 1,
    secondary: {
      current: 1,
      total: 1,
    },
  },
  imageLoading: false,
  loading: false,
  success: false,
};

function orderIssueTicket(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.ADD_ORDER_ISSUE_BEGIN:
      return { ...state, loading: true };
    case actionTypes.ADD_ORDER_ISSUE_DONE:
      return { ...state, loading: false, success: true };
    case actionTypes.ADD_ORDER_ISSUE_FAIL:
      return { ...state, loading: false };
    case actionTypes.UPLOAD_SINGLE_IMAGE_BEGIN:
      return { ...state, imageLoading: true };
    case actionTypes.UPLOAD_SINGLE_IMAGE_DONE:
      return {
        ...state,
        imageLoading: false,
        issueDetails: {
          ...state.issueDetails,
          photos: [...state.issueDetails.photos, payload],
        },
      };
    case actionTypes.UPLOAD_SINGLE_IMAGE_FAIL:
      return { ...state, imageLoading: false };
    case actionTypes.ORDER_ISSUE_SET_SELECTED_ITEMS:
      return { ...state, selectedItems: payload };
    case actionTypes.ORDER_ISSUE_SET_SELECTED_ITEMS_MAP:
      return { ...state, selectedItemsMap: payload };
    case actionTypes.ORDER_ISSUE_UPDATE_SELECTED_ITEMS_MAP:
      return {
        ...state,
        selectedItemsMap: payload,
      };
    case actionTypes.ORDER_ISSUE_SET_ORDER_ISSUE_DETAILS:
      return { ...state, issueDetails: payload };
    case actionTypes.ORDER_ISSUE_MODAL_PANEL:
      if (payload.primary === ORDER_SUPPORT_MODAL_PANELS.ITEM_ISSUE_SELECTION) {
        const newIssueDetails =
          state.selectedItemsMap[
            state.selectedItems[payload.secondary.current - 1].ProductId
          ].Issue || initialState.issueDetails;
        return { ...state, panel: payload, issueDetails: newIssueDetails };
      }
      return { ...state, panel: payload };

    case actionTypes.ORDER_ISSUE_MODAL_NEXT_PANEL:
      return { ...state, nextPanel: payload };
    case actionTypes.ORDER_ISSUE_MODAL_STATE_FORWARD:
      let selectedItemsList = [];
      Object.keys(state.selectedItemsMap).map((i) => {
        selectedItemsList.push(state.selectedItemsMap[i]);
        return null;
      });
      switch (state.panel.primary) {
        case ORDER_SUPPORT_MODAL_PANELS.ITEM_SELECTION:
          return {
            ...state,
            panel: {
              primary: ORDER_SUPPORT_MODAL_PANELS.ITEM_ISSUE_SELECTION,
              secondary: {
                current: 1,
                total: Object.keys(state.selectedItemsMap).length,
              },
            },
            selectedItems: selectedItemsList,
            nextPanel: false,
            issueDetails: {
              ...initialState.issueDetails,
            },
          };
        case ORDER_SUPPORT_MODAL_PANELS.ITEM_ISSUE_SELECTION:
          if (state.panel.secondary.current < state.panel.secondary.total) {
            const newIssueDetails =
              state.selectedItemsMap[
                state.selectedItems[state.panel.secondary.current].ProductId
              ].Issue || initialState.issueDetails;
            return {
              ...state,
              panel: {
                primary: ORDER_SUPPORT_MODAL_PANELS.ITEM_ISSUE_SELECTION,
                secondary: {
                  ...state.panel.secondary,
                  current: state.panel.secondary.current + 1,
                },
              },
              issueDetails: newIssueDetails,
              nextPanel: false,
            };
          }
          return {
            ...state,
            panel: {
              ...state.panel,
              primary: ORDER_SUPPORT_MODAL_PANELS.ITEM_ISSUE_SUMMARY,
            },
          };
        case ORDER_SUPPORT_MODAL_PANELS.ITEM_ISSUE_SUMMARY:
          return state;
        default:
          return state;
      }
    case actionTypes.ORDER_ISSUE_MODAL_STATE_BACKWARD:
      switch (state.panel.primary) {
        case ORDER_SUPPORT_MODAL_PANELS.ITEM_SELECTION:
          return initialState;
        case ORDER_SUPPORT_MODAL_PANELS.ITEM_ISSUE_SELECTION:
          if (state.panel.secondary.current > 1) {
            const newPanel = {
              ...state.panel,
              secondary: {
                ...state.panel.secondary,
                current: state.panel.secondary.current - 1,
              },
            };

            return {
              ...state,
              panel: newPanel,
              issueDetails: {
                ...state.selectedItemsMap[
                  state.selectedItems[newPanel.secondary.current - 1].ProductId
                ].Issue,
              },
            };
          }
          return {
            ...state,
            panel: {
              ...state.panel,
              primary: ORDER_SUPPORT_MODAL_PANELS.ITEM_SELECTION,
            },
            nextPanel: Object.keys(state.selectedItemsMap).length > 0,
          };
        case ORDER_SUPPORT_MODAL_PANELS.ITEM_ISSUE_SUMMARY:
          return {
            ...state,
            panel: {
              ...state.panel,
              primary: ORDER_SUPPORT_MODAL_PANELS.ITEM_ISSUE_SELECTION,
            },
            issueDetails: {
              ...state.selectedItemsMap[
                state.selectedItems[state.panel.secondary.current - 1].ProductId
              ].Issue,
            },
          };
        default:
          return state;
      }
    case actionTypes.ORDER_ISSUE_MODAL_CLEANUP:
      return { ...initialState, success: state.success };
    case actionTypes.ORDER_ISSUE_SUCCESS:
      return { ...state, success: payload };
    default:
      return state;
  }
}

export default orderIssueTicket;
