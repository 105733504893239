import { clone } from "lodash-es";
import actionTypes from "../actionTypes";

function errorHandler(state = {}, action) {
  const { payload, type } = action;
  let currentState = clone(state)
  if (type.includes("_FAIL")){
    currentState = {
      ...currentState,
      errToast: action?.error?.errorToast
    }
  }
  switch (type) {
    case actionTypes.ERROR_HANDLE:
        return {
            ...currentState,
            ...payload,
        }
    case actionTypes.ERROR_TOAST_SET:
      return {
        ...currentState,
        errToast: action?.errorToast
      }
    case actionTypes.ERROR_CLEAR:
      return {
        ...currentState,
        errToast: {}
      }
    default:
      return currentState;
  }
}

export default errorHandler;
