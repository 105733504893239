import _get from "lodash-es/get";

import getAxios from "../../utils/axios";
import APIendpoints from "../../utils/constants/APIendpoints";
import actionTypes from "../actionTypes";
import { CartUpdateSource_Cart } from "../../utils/constants/others";
import { getCheckoutParams, getESV2FlagFromQueryParam, getHpPlusData, handleCallBack } from "../../utils/helpers";
import EsV2QueryParams from "../../utils/constants/EsV2QueryParams";
import { INVENTORY_NOT_AVAILABLE } from "../../utils/constants/others"
import { handleErrorType } from "../../utils/errorHandler";

import { STATIC_ENDPOINT, DYNAMIC_ENDPOINT } from "../../utils/constants/APIendpoints";


// const esV2SearchFlag = getESV2FlagFromQueryParam(EsV2QueryParams.ES_V2_SEARCH_FLAG)
const esV2SearchFlag = true

function addToCart(requestData, type, from, queryString) {
  const postbackParams = window.localStorage.getItem("postbackParams");

  return (dispatch) => {
    dispatch({ type: actionTypes.CART_ADD_BEGIN, itemId: requestData.ProductId });
    getAxios(true)
      .put(APIendpoints.addUpdateDeleteCart(esV2SearchFlag, from === "productDescription", postbackParams, queryString), { ...requestData })
      .then(({ data }) => {
        dispatch({
          type: actionTypes.CART_ADD_DONE,
          productId: requestData.ProductId,
          productQuantity: requestData.Quantity,
          nudgeMessage: data.response.DiscountNudgeMessage,
          source: requestData.Source,
          productType: type,
          product: data.response.Product,
          categories: data.response.CartSummary && data.response.CartSummary.Data ? data.response.CartSummary.Data : [],
          cartSummary: data.response.CartSummary && data.response.CartSummary.CartSummary ? data.response.CartSummary.CartSummary : {},
          cartIssues: data.response.CartSummary && data.response.CartSummary.CartIssues ? data.response.CartSummary.CartIssues : [],
          nudgeInfo: data.response?.CartSummary?.NudgeInfo ? data.response.CartSummary.NudgeInfo : null,
          bannerInfo: data.response?.CartSummary?.BannerInfo ? data.response.CartSummary.BannerInfo : null,
          bannerV2: data.response?.CartSummary?.BannerV2 ? data.response.CartSummary.BannerV2 : null,
          cartCount: data.response?.CartCount,
          CartDiscount: data.response?.CartDiscount
        });
        window.localStorage.setItem("postbackParams", data.response?.CartDiscount?.PostbackParams ? data.response.CartDiscount.PostbackParams : "");
      })
      .catch((err) => {
        const data = _get(
          err.response.data,
          ["error", "data", "AlternateCartProductSuggestion"],
          null
        );

        let alternateProductSuggestion;
        if (!data) {
          alternateProductSuggestion = data;
        } else {
          alternateProductSuggestion = {
            ErrorMessage: data.ErrorMessage,
            IssueDescription: data.IssueDescription,
            IssueHeading: data.IssueHeading,
            ModalNudgeMessage: data.ModalNudgeMessage,
            RailHeading: data.RailHeading,
            SearchQuery: data.SearchQuery,
            IssueProduct: data.Product,
            ErrorNudge: data.ErrorNudge
          };
        }

        dispatch({
          type: actionTypes.CART_ADD_FAIL,
          error: _get(
            err,
            ["response", "data", "error"],
            {}
          ),
          productId: requestData.ProductId,
          nudgeMessage: _get(err.response.data, [
            "error",
            "data",
            "NudgeMessage",
          ]),
          alternateProductSuggestion: alternateProductSuggestion,
          errorAvailableQty: _get(
            err.response.data,
            ["Product", "AvailableQty"],
            0
          ),
        });
      });
  };
}

function updateInCart(requestData, successCallback, from, queryString) {
  const postbackParams = window.localStorage.getItem("postbackParams");
  return (dispatch) => {
    dispatch({ type: actionTypes.CART_UPDATE_BEGIN, itemId: requestData.ProductId });
    getAxios(true)
      .put(APIendpoints.addUpdateDeleteCart(esV2SearchFlag, from === "productDescription", postbackParams, queryString), { ...requestData })
      .then(({ data }) => {
        dispatch({
          type: actionTypes.CART_UPDATE_DONE,
          productId: requestData.ProductId,
          productQuantity: requestData.Quantity,
          nudgeMessage: data.response.DiscountNudgeMessage,
          product: data.response.Product,
          categories: data.response.CartSummary && data.response.CartSummary.Data ? data.response.CartSummary.Data : [],
          cartSummary: data.response.CartSummary && data.response.CartSummary.CartSummary ? data.response.CartSummary.CartSummary : {},
          nudgeInfo: data.response?.CartSummary?.NudgeInfo ? data.response.CartSummary.NudgeInfo : null,
          bannerInfo: data.response?.CartSummary?.BannerInfo ? data.response.CartSummary.BannerInfo : null,
          bannerV2: data.response?.CartSummary?.BannerV2 ? data.response.CartSummary.BannerV2 : null,
          cartCount: data.response?.CartCount,
          CartDiscount: data.response?.CartDiscount,
          CombinedCartCount: data.response?.CombinedCartCount
        });
        window.localStorage.setItem("postbackParams", data.response?.CartDiscount?.PostbackParams ? data.response.CartDiscount.PostbackParams : "");
        if (typeof successCallback === "function") {
          successCallback(data.response.Product)
        }
      })
      .catch((err) => {
        const data = _get(
          err.response.data,
          ["error", "data", "AlternateCartProductSuggestion"],
          null
        );

        let alternateProductSuggestion;
        if (!data) {
          alternateProductSuggestion = data;
        } else {
          alternateProductSuggestion = {
            ErrorMessage: data.ErrorMessage,
            IssueDescription: data.IssueDescription,
            IssueHeading: data.IssueHeading,
            ModalNudgeMessage: data.ModalNudgeMessage,
            RailHeading: data.RailHeading,
            SearchQuery: data.SearchQuery,
            IssueProduct: data.Product,
            ErrorNudge: data.ErrorNudge
          };
        }

        dispatch({
          type: actionTypes.CART_UPDATE_FAIL,
          error: _get(
            err,
            ["response", "data", "error"],
            {}
          ),
          productId: requestData.ProductId,
          nudgeMessage: _get(err.response.data, [
            "error",
            "data",
            "NudgeMessage",
          ]),
          alternateProductSuggestion: alternateProductSuggestion,
          errorAvailableQty: _get(
            data,
            ["Product", "AvailableQty"],
            0
          ),
        });
      });
  };
}
function addRemoveHyperPurePlusToCart(params, callBack, errCallBack) {
  return (dispatch) => {
    dispatch({ type: actionTypes.HP_PLUS_ADD_REMOVE_BEGIN });

    getAxios(true)
      .put(params.endPoint, params.requestBody, params.source)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.HP_PLUS_ADD_REMOVE_DONE,
          payload: data,
          hpPlusData: getHpPlusData(data.response?.Standard?.CartData),
          SuccessPopupData: data.response?.Standard?.SuccessPopupData,
        });
        if (data.response?.Standard?.SuccessPopupData) {
          setTimeout(() => {
            dispatch(getCart(params.outletId, 'STANDARD'));
          }, 3000);
        } else {
          dispatch(getCart(params.outletId, 'STANDARD'));
        }


        if (typeof callBack === "function") {
          callBack();
        }
      })
      .catch((err) => {
        const error = _get(err, ["response", "data", "error"], {});
        dispatch({
          type: actionTypes.HP_PLUS_ADD_REMOVE_FAIL,
          error,
        });

        if (typeof errCallBack === "function") {
          errCallBack();
        }
      });
  };
}
function getCart(outletId, checkoutMode) {
  const postbackParams = window.localStorage.getItem("postbackParams");

  return (dispatch) => {
    dispatch({ type: actionTypes.CART_GET_BEGIN });
    getAxios(true)
      .get(APIendpoints.getCart(outletId, esV2SearchFlag, postbackParams))
      .then(({ data }) => {
        dispatch({
          type: actionTypes.CART_GET_DONE,
          categories: data.response.Cart ? data.response.Cart.Data : [],
          cartIssues: data.response.Cart && data.response.Cart.CartIssues ? data.response.Cart.CartIssues : [],
          cartSummary: data.response.Cart && data.response.Cart.CartSummary ? data.response.Cart.CartSummary : {},
          emptyCartState: data.response.EmptyCartState ? data.response.EmptyCartState : {},
          deleteCartPopup: data.response.Cart && data.response.Cart.DeleteCartPopup ? data.response.Cart.DeleteCartPopup : {},
          cartCount: data.response.Cart ? data.response.Cart.CartCount : 0,
          deliveryCharge: data.response.DeliveryCharges,
          minimumValueFreeDelivery: data.response.MinimumValueFreeDelivery,
          deliveryChargesNudge: data.response.DeliveryChargesNudge,
          minimumOrderValue: data.response.MinimumOrderValue,
          totalTCS: data.response.TotalTCS,
          checkoutAddress: {
            addressLine: data.response.AddressLine,
            promptForLocation: data.response.PromptForLocation
          },
          deliveryChargeArray: data.response.DeliveryChargeArray,
          deliveryChargeInfo: data.response.DeliveryChargeInfo,
          nudgeInfo: data.response?.Cart?.NudgeInfo,
          bannerInfo: data.response?.Cart?.BannerInfo,
          bannerV2: data.response?.Cart?.BannerV2,
          CartDiscount: data.response?.CartDiscount
        });
        window.localStorage.setItem("postbackParams", data.response?.CartDiscount?.PostbackParams ? data.response.CartDiscount.PostbackParams : "");
      })
      .catch((err) => {
        let errCode = _get(
          err,
          ["response", "data", "error", "code"],
          null)

        let errData = _get(
          err,
          ["response", "data", "error", "data", "Action"],
          null)
        handleErrorType(errCode, errData, null, dispatch)
        dispatch({
          type: actionTypes.CART_GET_FAIL,
          error: _get(
            err,
            ["response", "data", "error"],
            {}
          ),
          code: errCode,
        })
      }
      );

    // cart v4
    dispatch({ type: actionTypes.CART_V2_GET_BEGIN });
    getAxios(true)
      .get(STATIC_ENDPOINT.GET_CART)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.CART_V2_GET_DONE,
          payload: data.response,
          checkoutMetaData: {
            checkoutMode: checkoutMode,
            checkoutParams: getCheckoutParams(checkoutMode, {
              EXPRESS: data.response?.Express?.CheckoutParams,
              STANDARD: data.response?.Standard?.CheckoutParams,
              COMBINED: data.response?.Combined?.CheckoutParams
            }),

          },
        });
        dispatch({
          type: actionTypes.HP_PLUS_ADD_REMOVE_DONE,
          hpPlusData: getHpPlusData(data.response?.Standard?.CartData),
        });
      }).catch((err) => {
        let errCode = _get(
          err,
          ["response", "data", "error", "code"],
          null)

        let errData = _get(
          err,
          ["response", "data", "error", "data", "Action"],
          null)
        handleErrorType(errCode, errData, null, dispatch)
        dispatch({
          type: actionTypes.CART_V2_GET_FAIL,
          error: _get(
            err,
            ["response", "data", "error"],
            {}
          ),
          code: errCode,
        })
      })
  };
}

function deleteFromCart(params, dontRemoveFromList, from, queryString) {
  const postbackParams = window.localStorage.getItem("postbackParams");

  return (dispatch) => {
    dispatch({
      type: actionTypes.CART_DELETE_BEGIN,
      payload: {
        productIds: [params.ProductId],
      },
    });
    getAxios(true)
      .delete(APIendpoints.addUpdateDeleteCart(esV2SearchFlag, from === "productDescription", postbackParams, queryString), {
        data: params,
      })
      .then(({ data }) => {
        dispatch({
          type: actionTypes.CART_DELETE_DONE,
          payload: {
            productIds: [params.ProductId],
            dontRemoveFromList: dontRemoveFromList,
            nudgeMessage: data.response.DiscountNudgeMessage,
            product: data.response.Product,
            categories: data.response.CartSummary && data.response.CartSummary.Data ? data.response.CartSummary.Data : [],
            cartSummary: data.response.CartSummary && data.response.CartSummary.CartSummary ? data.response.CartSummary.CartSummary : {},
            cartIssues: data.response.CartSummary && data.response.CartSummary.CartIssues ? data.response.CartSummary.CartIssues : [],
            cartCount: data.response.CartCount ? data.response.CartCount : 0,
            nudgeInfo: data.response?.CartSummary?.NudgeInfo ? data.response.CartSummary.NudgeInfo : null,
            bannerInfo: data.response?.CartSummary?.BannerInfo ? data.response.CartSummary.BannerInfo : null,
            bannerV2: data.response?.CartSummary?.BannerV2 ? data.response.CartSummary.BannerV2 : null,
            CartDiscount: data.response?.CartDiscount
          },
        });
        window.localStorage.setItem("postbackParams", data.response?.CartDiscount?.PostbackParams ? data.response.CartDiscount.PostbackParams : "");
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.CART_DELETE_FAIL,
          error: _get(
            err,
            ["response", "data", "error"],
            {}
          ),
          payload: {
            productIds: [params.ProductId],
          },
        });
      });
  };
}

function clearCart(source = null) {
  return (dispatch) => {
    dispatch({ type: actionTypes.CART_CLEAR_BEGIN });
    getAxios(true)
      .delete(APIendpoints.addUpdateDeleteCart(esV2SearchFlag), {
        data: {
          ClearCart: true,
          Source: source ? source : CartUpdateSource_Cart,
        },
      })
      .then(() => {
        dispatch({ type: actionTypes.CART_CLEAR_DONE });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.CART_CLEAR_FAIL,
          error: _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  };
}

const checkoutCart = (checkoutParams, errCallBack, callBack) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CART_CHECKOUT_BEGIN });
    getAxios(true)
      .get(DYNAMIC_ENDPOINT.getCheckout(checkoutParams))
      .then(({ data }) => {
        dispatch({
          type: actionTypes.CART_CHECKOUT_DONE,
          deliveries: !data.response.Cart.DeliverySlotNewFlowEnabled ? data.response.Cart.Data : data.response.Cart.DataV2,
          userData: data.response.User,
          deliveryCharge: data.response.DeliveryCharges,
          minimumValueFreeDelivery: data.response.MinimumValueFreeDelivery,
          deliveryChargesNudge: data.response.DeliveryChargesNudge,
          cartSummary: data.response.Cart.CartSummary,
          isNewFlowEnabled: data.response.Cart.DeliverySlotNewFlowEnabled,
          cartIssues: data.response.Cart.CartIssues,
          settingConfig: data.response.UserConfig,
          checkoutHeader: data?.response?.Header,
        });
        handleCallBack(callBack)
      })
      .catch((err) => {
        let errCode = _get(
          err,
          ["response", "data", "error", "code"],
          "UNKNOWN_ERROR"
        )
        let errData = _get(
          err,
          ["response", "data", "error", "data", "Action"],
          null)

        handleErrorType(errCode, errData, null, dispatch)
        dispatch({
          type: actionTypes.CART_CHECKOUT_FAIL,
          error: _get(
            err,
            ["response", "data", "error"],
            {}
          ),
          code: errCode,
        });

        if (errCode === INVENTORY_NOT_AVAILABLE) {
          handleCallBack(errCallBack);
        }
      });
  };
}

function getDeliverySolts(cityId, outletId, source, callBack) {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_DELIVERY_SLOTS_BEGIN });
    getAxios(true)
      .get(APIendpoints.getDeliveryTimeSlots(cityId, outletId, source))
      .then(({ data }) => {
        if (data?.response?.deliveryTimeSlotsV2 && data?.response?.deliveryMessage) {
          data.response.deliveryTimeSlotsV2.push({
            Id: -1,
            "TimeSlot": data.response.deliveryMessage,
            "isDisabled": true,
          });
        }
        dispatch({
          type: actionTypes.GET_DELIVERY_SLOTS_DONE,
          payload: data?.response,
        });
        if (typeof callBack === "function") {
          callBack(data.response);
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_DELIVERY_SLOTS_FAIL,
          error: _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  };
}

function getDeliverySlotsForPackage(cityId, outletId, source, packageId) {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_DELIVERY_SLOTS_FOR_PACKAGE_BEGIN });
    getAxios(true)
      .get(APIendpoints.getDeliveryTimeSlotsForPackage(cityId, outletId, source, packageId))
      .then(({ data }) => {
        dispatch({
          type: actionTypes.GET_DELIVERY_SLOTS_FOR_PACKAGE_DONE,
          payload: data?.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_DELIVERY_SLOTS_FOR_PACKAGE_FAIL,
          error: _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  }
}

function resetDeliverySlotsForPackage() {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_DELIVERY_SLOTS_FOR_PACKAGE_INIT });
  }
}

function updateCheckout(params, callBack, errCallBack) {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_CHECKOUT_BEGIN });
    getAxios(true)
      .put(APIendpoints.updateCheckout, params)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.UPDATE_CHECKOUT_DONE,
          deliveries: !data.response.Cart.DeliverySlotNewFlowEnabled ? data.response.Cart.Data : data.response.Cart.DataV2,
          userData: data.response.User,
          deliveryCharge: data.response.DeliveryCharges,
          minimumValueFreeDelivery: data.response.MinimumValueFreeDelivery,
          deliveryChargesNudge: data.response.DeliveryChargesNudge,
          cartSummary: data.response.Cart.CartSummary,
          isNewFlowEnabled: data.response.Cart.DeliverySlotNewFlowEnabled,
        });
        if (typeof callBack === "function") {
          callBack();
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.UPDATE_CHECKOUT_FAIL,
          error: _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
        if (typeof errCallBack === "function") {
          errCallBack();
        }
      });
  };
}

function validateLocation(params) {
  return (dispatch) => {
    dispatch({ type: actionTypes.LOCATION_VALIDATE_BEGIN });
    getAxios(true)
      .post(APIendpoints.validateLocation, params)
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.LOCATION_VALIDATE_DONE,
          locationData: data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.LOCATION_VALIDATE_FAIL,
          error: _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  };
}

function updateLocation(params) {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_LOCATION_BEGIN });
    getAxios(true)
      .post(APIendpoints.updateLocation, params)
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.UPDATE_LOCATION_DONE,
          locationData: data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.UPDATE_LOCATION_FAIL,
          error: _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  };
}

const getMoveCartProducts = (appMode) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_MOVE_CART_PRODUCTS_BEGIN });
    getAxios(true)
      .get(DYNAMIC_ENDPOINT.getMoveCartProducts(appMode))
      .then(({ data }) => {
        dispatch({
          type: actionTypes.GET_MOVE_CART_PRODUCTS_DONE,
          payload: data.response,
          cartAppMode: appMode,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_MOVE_CART_PRODUCTS_FAIL,
          error: _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  };
}

const setMoveCartProducts = (params, appMode, callBack) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_MOVE_CART_PRODUCTS_BEGIN });
    getAxios(true)
      .post(STATIC_ENDPOINT.MOVE_CART_PRODUCTS, params)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.SET_MOVE_CART_PRODUCTS_DONE,
          payload: data.response,
          cartAppMode: appMode,
        });
        handleCallBack(callBack);
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.SET_MOVE_CART_PRODUCTS_FAIL,
          error: _get(
            err,
            ["response", "data", "error"],
            {}
          ),
        });
      });
  };
}

const updateCheckoutHeader = (checkoutHeader, callBack) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CART_CHECKOUT_HEADER_UPDATE,
      payload: checkoutHeader,
    });
    handleCallBack(callBack);
  };
};

export {
  addToCart,
  getCart,
  deleteFromCart,
  clearCart,
  updateInCart,
  checkoutCart,
  validateLocation,
  updateLocation,
  getDeliverySolts,
  updateCheckout,
  getDeliverySlotsForPackage,
  resetDeliverySlotsForPackage,
  getMoveCartProducts,
  setMoveCartProducts,
  addRemoveHyperPurePlusToCart,
  updateCheckoutHeader,
};
