import React from "react";
import Autosuggest from "react-autosuggest";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import ChevronRight from "sushiweb/Icons/all/ChevronRight";
import ChevronLeft from "sushiweb/Icons/all/ChevronLeft.js";
import { debounce, htmlSanitizedString } from "../../../../utils/helpers";
import { grey } from "sushiweb/tokens/color";
import queryString from "query-string";
import {
  getSearchSuggestions,
  addAutoSuggestHistory,
  resetSearchSuggestions,
  clearHistory,
  searchProductsForBuyerWithQuery
} from "../../../../redux/dispatchers/catalogDispatcher";
import { handleActionType } from "../../../../utils/helpers.js";
import styles from "./SearchInput.module.scss";
import SpinnerCircular from "sushiweb/Spinner/SpinnerCircular";
import stateTypes from "../../../../utils/constants/stateTypes";
import { CTA_COLOUR } from "../../../../utils/constants/others";
import RequestBanner from "../../../buyer/requestProduct/requestBanner/RequestBanner";
import cartIssueManager from "../../cartIssueManager/CartIssueManager";
import {
  CartUpdateSource_ModalListing,
  CartUpdateSource_AlternateRailListing,
} from "../../../../utils/constants/others";
import { trackPopUp } from "../../../../redux/dispatchers/userDispatcher";
import { handleActionTypesViaRedux,handleSearchToggleAction } from "../../../../redux/dispatchers/commonDispatcher";
import SearchCustomCarousel from "./SearchCustomCarousel";
import { HTMLDiv } from "../../HTMLDiv.jsx";
import { toggleScroll } from "../../../../utils/helpers";
import { isMobile } from "../../../../utils/helpers";

class SearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.callAPI = debounce(props.actions.getSearchSuggestions, 500);
  }

  state = {
    value: "",
    suggestions: [],
    showDefaultSuggestion: false,
    selectedOosSuggestion: 0
  };

  storeInputReference = (autosuggest) => {
    if (autosuggest !== null) {
      this.input = autosuggest.input;
    }
  };

  processSuggestions = (suggestion = []) => {
    if(!suggestion){
      return []
    }
    return suggestion.map((s, idx) => {
      return ({
        ...s,
        isLast: idx === suggestion.length - 1
      })
    })
  }

  // lifecycle methods
  componentDidMount() {
    const { searchValue } = this.props;

    if (searchValue) {
      this.setState((prevState) => ({
        ...prevState,
        value: searchValue,
      }));
    }

    if (this.props.autoFocus) {
      this.input.focus();
    }
  }

  componentDidUpdate(prevProps) {
    const { 
      searchSuggestions, 
      outOfStockProductStatus, 
      allProducts,
      addUpdateManyIssues,
      searchSuggestionsStatus
    } = this.props;
    const { value } = this.state;
    if (
      prevProps.searchSuggestions.products.length !==
      searchSuggestions.products.length
    )
      this.setState((prevState) => ({
        ...prevState,
        suggestions: this.processSuggestions(searchSuggestions.products),
      }));

    if (prevProps.searchSuggestionsStatus.state !== this.props.searchSuggestionsStatus.state &&
      this.props.searchSuggestionsStatus.state === stateTypes.STATE_DONE) {
      if ((value && value.trim() === "") || value === "")
        this.setState({ showDefaultSuggestion: true })
    }

    if(prevProps.outOfStockProductStatus.state !== outOfStockProductStatus.state && 
      outOfStockProductStatus.state === stateTypes.STATE_DONE) {
        const { id, similarItemsCTA } = this.state.selectedOosSuggestion
        const product = allProducts.products && allProducts.products.find(p => p.Id === id)
        if(product) {
          const data = [{
            id: product.Id,
            issueProduct: product,
            issueData: {
              ErrorMessage: "Out of stock",
              ErrorNudge: "Out of stock",
              IssueHeading: "Out of stock",
              IssueProduct: product,
              RailHeading: "Buy Similar Products",
              SearchQuery: similarItemsCTA.Action.ActionData.SearchQuery
            },
            issueAvailableQuantity: 0,
            issueSelectedQuantity: 0,
          }]
          addUpdateManyIssues(data, false, false)
        }
      }

      if (this.props.isAuthenticated && (prevProps.userData?.outlet?.id !== this.props.userData?.outlet?.id)){
        this.props.actions.getSearchSuggestions({
          query: "",
          href: window.location.href,
        }, true, true);
      }

      if (
        prevProps.searchSuggestionsStatus.state !==
          searchSuggestionsStatus.state &&
        isMobile(992)
      ) {
        this.props.actions.handleSearchToggleAction(true);
      }

  }

  onChange = (event, { newValue }) => {
    const {
      isAuthenticated,
      userData,
    } = this.props;
    this.setState((prevState) => ({
      ...prevState,
      value: newValue
    }));

    if (event.which === 13) {
      event.preventDefault()
      return
    }
    if (event.which !== 40 && event.which !== 38) {
      this.props.actions.resetSearchSuggestions();
    }
    if (newValue.trim().length === 0 && isAuthenticated && userData) {
      this.props.actions.getSearchSuggestions({
        query: htmlSanitizedString(newValue),
        outletId: userData.outlet.id,
        href: window.location.href,
      }, true);
    }
  };

  onKeyUp = (e) => {
    if (e.key === "Enter" && e.target.value) {
      if (this.props.config?.DefaultSearchAction ) {
        if (this.props.isAuthenticated){
        this.props.actions.addAutoSuggestHistory({
          "Suggestion": {
            "name": htmlSanitizedString(e.target.value), 
            "plainText": htmlSanitizedString(e.target.value),
            "type": "default",
            "action": {
              "ActionType": "SEARCH_QUERY",
              "ActionData": {
                "TrackingMetadata": this.props.config?.DefaultSearchAction?.ActionData?.TrackingMetadata,
                "Title":  htmlSanitizedString(e.target.value),
                "SearchQuery": "query="+htmlSanitizedString(e.target.value)+"&sourcePage=SEARCH_PAGE&minScore=30"
              }
            }
          },
          "Query" : htmlSanitizedString(e.target.value),

        })
      }

             handleActionType(this.props.config?.DefaultSearchAction?.ActionType, this.props.config?.DefaultSearchAction?.ActionData, this.props.history, {
          // getCategoryAndSubCategoryNames: (params) => this.getCategoryAndSubCategoryNames(params),
          sendReference: true,
          query: `${encodeURIComponent(htmlSanitizedString(e.target.value))}&referenceType=${this.props.config?.DefaultSearchAction?.ActionData?.TrackingMetadata?.parent_reference_type}&${queryString.stringify(this.props.config?.DefaultSearchAction?.ActionData?.TrackingMetadata)}&title=${encodeURIComponent("<span style='color:#1c1c1c;'>Search Results For <b>"+htmlSanitizedString( e.target.value)+"</b></span>")}`,
        })
        //this.props.history.push(`/buyer/search?q=${encodeURIComponent(e.target.value)}&referenceType=${this.props.config?.DefaultSearchAction?.ActionData?.TrackingMetadata?.parent_reference_type}&${queryString.stringify(this.props.config?.DefaultSearchAction?.ActionData?.TrackingMetadata)}`);
      }
    }
  };

  searchResultClick(e) {
    e.preventDefault();
    const { searchSuggestions, isAuthenticated } = this.props;
    if (searchSuggestions.suggestionList && searchSuggestions.suggestionList.defaultSuggestion && this.state.suggestions) {
      if (isAuthenticated){
      this.props.actions.addAutoSuggestHistory({
        "Suggestion": searchSuggestions.suggestionList.defaultSuggestion,
        "GroupId": searchSuggestions.suggestionList.groupId,
        "Query": this.state.value
      })
    }
      handleActionType(
        searchSuggestions.suggestionList.defaultSuggestion.action.ActionType,
        searchSuggestions.suggestionList.defaultSuggestion.action.ActionData,
        this.props.history, {
          // getCategoryAndSubCategoryNames: (params) => this.getCategoryAndSubCategoryNames(params),
          sendReference: true
        }
      );
    } 
  };

  getSuggestionValue = (suggestion) => suggestion.plainText;

  handleOosClick = (e, suggestion) => {
    const {
      action: {
        ActionData: {
          SearchQuery
        } = {}
      } = {},
      similarItemsCTA: {
        Action: {
          TrackingParams
        } = {}
      }
    } = suggestion
    e.stopPropagation()
    const { actions } = this.props
    TrackingParams && actions.trackPopUp(TrackingParams)
    this.setState({ 
      selectedOosSuggestion: suggestion 
    }, () => actions.searchProductsForBuyerWithQuery(`?${SearchQuery}`, 1, true)) 
  }

  renderSuggestion = (suggestion) => {

    if (this.props.toggleSearch !== true && isMobile(992)){
      this.props.actions.handleSearchToggleAction(true);
    }

    if (typeof window !== "undefined"){
      toggleScroll(true);

      return (
        <>
          {isMobile(992) && suggestion.groupingStart && (
            <div
              className={`${styles.groupMargin} ${styles.groupMargin}`}
            ></div>
          )}
          <div
            className={`${isMobile(992) ? `bg-white ${styles.suggestionContainer}` : ``}`}
            style={
              isMobile(992)
                ? {
                    borderRadius: `${
                      suggestion.groupingStart ? "20px 20px" : "0px 0px"
                    } ${suggestion.groupingEnd ? "20px 20px" : "0px 0px"}`
                  }
                : {}
            }
          >
            {suggestion.grouping && (
              <div
                className={`${styles.suggHead} ${
                  isMobile(992) && styles.suggHeadMobile
                } fs-14 w-600 disable`}
              >
                {suggestion.grouping}
              </div>
            )}
            <div
              className={`d-flex align-items-center ${
                suggestion.grouping ? styles.suggTopEle : ""
              }`}
            >
              {suggestion.image && (
                <div
                  className={` d-flex justify-content-center align-items-center mr-12px ${
                    suggestion.type === "see_all"
                      ? `${styles.suggestionTopImage} ${styles.suggestionTopImageSearch}`
                      : suggestion.type === "product"
                      ? styles.suggestionProduct
                      : styles.suggestionTopImage
                  }`}
                >
                  <img
                    className={suggestion.isInStock ? "" : "greyScale-100"}
                    src={
                      suggestion.type === "see_all"
                        ? process.env.PUBLIC_URL +
                          "/assets/images/searchThick.png"
                        : suggestion.image
                    }
                    alt="suggestion"
                  />{" "}
                </div>
              )}
              <div className="d-flex flex-column">
                <HTMLDiv
                  html={suggestion.name}
                  className={`${
                    !suggestion.isInStock ? "text-gray-600" : ""
                  } fs-14`}
                />
                {!suggestion.isInStock ? (
                  <div className="d-flex align-items-center">
                    <div className={`fs-12 w-600 text-red-700 mr-3`}>
                      OUT OF STOCK
                    </div>
                    {suggestion.similarItemsCTA ? (
                      <div
                        className="d-flex align-items-center"
                        onClick={(e) => this.handleOosClick(e, suggestion)}
                      >
                        <div
                          style={{
                            color: suggestion.similarItemsCTA.TextColor,
                          }}
                          className="fs-14 w-600"
                        >
                          {suggestion.similarItemsCTA.Text}
                        </div>
                        <img
                          src={suggestion.similarItemsCTA.IconImage}
                          alt=""
                          className={styles.oosIcon}
                        />
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
            {!isMobile(992) && suggestion.isLast && (
              <div className="mt-5 mb-3">
                <RequestBanner
                  source="search"
                  searchText={this.state.value}
                  title={`Can't find ‘${this.state.value}’?`}
                />
              </div>
            )}
          </div>
        </>
      );
    }

  }

  firstFocusSugg = () => {
    if (this.state.showDefaultSuggestion &&
      this.props.searchSuggestions.suggestionListFirstChange) {

      if (typeof window !== "undefined") toggleScroll(true);

      if (this.props.toggleSearch !== true && isMobile(992))
      this.props.actions.handleSearchToggleAction(true);

      return (
        <div className={styles.firstFocus}>
          <div className={styles.innerFocusBox}>
          {
            this.props.searchSuggestions.suggestionListFirstChange.sections.map((ele, index) => {
              return (
                 ele.displayType !== "tabs" ||
                !this.props.isAutoSuggestHistoryClear ? (
                <div className="mt-4" key={index}>
                  <div className="d-flex fs-20 w-800 justify-content-between align-items-center text-gray-1000">
                    {ele.heading}
                    {ele.showClearHistoryCta && (
                      <span
                        className={`${styles.clear} w-800`}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          this.props.actions.clearHistory();
                        }}
                      >
                        clear
                      </span>
                    )}
                  </div>
                  <div className={styles.searchBarSuggestions}>
                    <div
                      className={` ${
                        ele.displayType === "tabs"
                          ? ""
                          : `d-flex ${styles.listHeader} align-items-baseline`
                      }`}
                    >
                      {ele.suggestions.map((sugg, index) => {
                        return (
                          <div
                            className={`
                            ${
                              ele.displayType === "tabs"
                                ? styles.tabs
                                : styles.list
                            } }
                          `}
                            onMouseDown={() => {
                              this.props.isAuthenticated &&
                                this.props.actions.addAutoSuggestHistory({
                                  Suggestion: sugg,
                                  GroupId:
                                    this.props.searchSuggestions
                                      .suggestionListFirstChange.groupId,
                                  Query: this.state.value,
                                  SectionDisplayType: sugg.displayType,
                                  SectionReferenceType: sugg.referenceType,
                                });
                              this.setState({ value: sugg.plainText });
                              handleActionType(
                                sugg.action.ActionType,
                                sugg.action.ActionData,
                                this.props.history,
                                {
                                  // getCategoryAndSubCategoryNames: (params) => this.getCategoryAndSubCategoryNames(params),
                                  sendReference: true,
                                }
                              );
                            }}
                          >
                            {sugg.image && (
                              <img
                                src={
                                  sugg.image?.length
                                    ? sugg.image
                                    : process.env.PUBLIC_URL +
                                      "/assets/images/defaultSideBarImg.png"
                                }
                                className={` mr-2 vertically-align-top  ${
                                  ele.displayType === "tabs"
                                    ? ""
                                    : styles.imageList
                                }`}
                                alt="suggestion"
                              />
                            )}
                            <HTMLDiv
                              className={`mx-2 vertically-align-top fs-13 w-600 ${
                                styles.greyColor
                              } ${
                                ele.displayType !== "tabs"
                                  ? `${styles.colorGrey700} fs-14 w-400 mt-2 ${styles.margin0}`
                                  : ""
                              }`}
                              html={sugg.name}
                            />
                            {ele.displayType === "ctas" && (
                              <ChevronRight
                                style={{ marginLeft: "auto" }}
                                color={grey.z600}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ): null)
            })
          }
          </div>
        </div>
      )
    }

    if (typeof window !== "undefined") {
      toggleScroll(false);
    } 

    if (this.props.toggleSearch !== false && this.state.suggestions.length === 0){
      this.props.actions.handleSearchToggleAction(false);
    }
  }

  renderInputComponent = (inputProps) => {
    const modifiedInputProps = {...inputProps, onBlur: isMobile(991) ?  ()=>{}: inputProps.onBlur}

    return (
      <div className={`relative ${styles.searchInputBox} ${(this.state.showDefaultSuggestion &&  this.props.searchSuggestions.suggestionListFirstChange) || this.state.suggestions?.length ? styles.searchInputSelected : "" }`}>
        <div className={`absolute ${styles.autosuggestScroll}`}>
        {this.state.value?.length  ? <></> : this.props.searchBar?.data?.length > 1 && this.props.isAuthenticated? 
        
        <SearchCustomCarousel 
        
          data ={this.props.searchBar?.data}
          stopPlaying ={this.state.showDefaultSuggestion &&
            this.props.searchSuggestions.suggestionListFirstChange}
        >
         
        </SearchCustomCarousel>: <div className={`fs-16 w-400 ${styles.greyTextSearch} d-flex align-items-center ${styles.autosuggestScroll}` }>Search items or categories</div>}
        </div>
        <input
          {...modifiedInputProps}
          spellCheck="false"
          className={`custom-input ${styles.searchInput}`}
          
          onClick={(e) => {
            const { isAuthenticated, userData } = this.props;
            e.target.select();
            const value = e.target.value;
            if (typeof this.callAPI === "function") {
              if (isAuthenticated && userData && !value) {
                this.setState((prevState) => ({
                  ...prevState,
                  value
                }));
                this.props.actions.resetSearchSuggestions();
                this.props.actions.getSearchSuggestions({
                  query: htmlSanitizedString(value),
                  outletId: userData.outlet.id,
                  href: window.location.href,
                }, true);
              }
            }}
          }
        />
        {this.firstFocusSugg()}
      </div>
    )
  };

  shouldRenderSuggestions = (value) => {
    return value.trim().length >= 3;
  }

  onSuggestionsFetchRequested = ({ value }) => {
    const { isAuthenticated, userData } = this.props;
    const inputValue = value.trim().toLowerCase();
    if (inputValue.length < 3) {
      this.setState((prevState) => ({
        ...prevState,
        suggestions: [],
        showDefaultSuggestion: false
      }));
    } else {
      if (typeof this.callAPI === "function") {
        if (isAuthenticated && userData) {
          this.callAPI({
            query: htmlSanitizedString(value),
            outletId: userData.outlet.id,
            href: window.location.href,
          });
        } else {
          this.callAPI({
            query: htmlSanitizedString(value),
            outletId: "",
            href: window.location.href,
          });
        }
      }
    }
  };

  onSuggestionsClearRequested = () => {
    this.setState((prevState) => ({
      ...prevState,
      suggestions: [],
    }));
  };

  getCategoryAndSubCategoryNames({ categoryId, subCategoryId }) {
    const { v2Categories } = this.props;

    if (!v2Categories || v2Categories.length === 0 || (!categoryId && !subCategoryId)) {
      return null;
    }

    if (subCategoryId) {
      for (let category of v2Categories) {
        for (let subCategory of category.children) {
          if (subCategory.id === subCategoryId) {
            return {
              categoryName: category.name,
              subCategoryName: subCategory.name,
            };
          }
        }
      }
    } else {
      const category = v2Categories.find((el) => el.id === categoryId);
      if (category) {
        return {
          categoryName: category.name,
          subCategoryName: "",
        };
      }
    }
    return null;
  }

  onBlur = () => {
    this.props.actions.resetSearchSuggestions();
    this.setState({ showDefaultSuggestion: false })
  }

  onSuggestionSelected = (event, { suggestion }) => {
    if (event.which !== 13){
    const { searchSuggestions } = this.props;
    if (this.props.isAuthenticated) {
      this.props.actions.addAutoSuggestHistory({
        "Suggestion": suggestion,
        "GroupId": searchSuggestions.suggestionList.groupId,
        "Query": this.state.value,
        "SectionDisplayType": suggestion.displayType,
        "SectionReferenceType": suggestion.referenceType
      })
    }
   this.props.actions.handleActionTypesViaRedux(suggestion.action.ActionType, suggestion.action.ActionData, this.props.history, {
      // getCategoryAndSubCategoryNames: (params) => this.getCategoryAndSubCategoryNames(params),
      sendReference: true,
    });
  }
  };

  render() {
    const { value, suggestions } = this.state;
    const { searchSuggestionsStatus } = this.props;
    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: "",
      value,
      onChange: this.onChange,
      onBlur: this.onBlur,
      onKeyUp: this.onKeyUp,
    };
    const isMobScreenSize = window.outerWidth < 500 ? true : false;
    return (
      <div
        className={`relative search ${styles.searchInputBox} ${
          this.props.className ? this.props.className : ""
        }`}
      >
        <form autocomplete="none" onSubmit={(e) => this.searchResultClick(e)}>
          <button
            type="submit"
            className={`${styles.searchIcon} ${styles.searchIconLeft} pointer `}
          >
            {isMobile(992) &&
            ((this.state.showDefaultSuggestion &&
              this.props.searchSuggestions.suggestionListFirstChange) ||
              suggestions.length) ? (
              <ChevronLeft
                size={24}
                onClick={() => {
                  this.props.actions.resetSearchSuggestions();
                  this.setState({ showDefaultSuggestion: false });
                }}
              />
            ) : (
              <img
                className={styles.img16}
                src={process.env.PUBLIC_URL + "/assets/images/searchThick.png"}
              />
            )}
          </button>
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            onSuggestionSelected={this.onSuggestionSelected}
            renderInputComponent={this.renderInputComponent}
            inputProps={inputProps}
            theme={styles}
            ref={this.storeInputReference}
            focusInputOnSuggestionClick={!isMobile(992)}
            shouldRenderSuggestions={this.shouldRenderSuggestions}
          />

          {searchSuggestionsStatus.state === stateTypes.STATE_BEGIN ? (
            isMobile(992) ? (
              <div className={styles.loadingSearchResults}>
                <SpinnerCircular color={CTA_COLOUR} />
              </div>
            ) : (
              <span className={styles.inputLoader}>
                <SpinnerCircular color={CTA_COLOUR} />
              </span>
            )
          ) : null}
        </form>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      // data
      isAuthenticated: state.auth.isAuthenticated,
      searchSuggestions: state.catalog.searchSuggestions,
      userData: state.user.data,
      v2Categories: state.catalog.v2Categories,
      allProducts: state.catalog.allProducts || {},
      config: state.user.config || {},
      searchBar: state.user.searchBar || [],
      toggleSearch: state.user.toggleSearch,
      isAutoSuggestHistoryClear: state.user.isAutoSuggestHistoryClear,
      // status
      searchSuggestionsStatus: state.status.catalog_getSearchSuggestions,
      outOfStockProductStatus: state.status.catalog_searchProductsForBuyer,
    }),
    (dispatch) => ({
      actions: bindActionCreators({
        getSearchSuggestions,
        addAutoSuggestHistory,
        resetSearchSuggestions,
        clearHistory,
        searchProductsForBuyerWithQuery,
        trackPopUp,
        handleActionTypesViaRedux,
        handleSearchToggleAction
      }, dispatch),
    })
  )(cartIssueManager(
    CartUpdateSource_ModalListing,
    CartUpdateSource_AlternateRailListing
  )(SearchInput))
);
