import _get from "lodash-es/get";

import getAxios from "../../utils/axios";
import APIendpoints from "../../utils/constants/APIendpoints";
import actionTypes from "../actionTypes";

import { getCookieForEntity, getESV2FlagFromQueryParam, handleCallBack, setCookie, setCookieForEntity } from "../../utils/helpers";
import EsV2QueryParams from "../../utils/constants/EsV2QueryParams";
import { setToastMessage } from "./toastDispatcher";
import { ADD_LOCATION_SIGN_UP, COOKIE_TTL_FOR_WEB, COOKIE_KEYS } from "../../utils/constants/others";
import {getAppModeModalDataFromResponse} from "../../utils/constants/modalBody";
import { STATIC_ENDPOINT } from "../../utils/constants/APIendpoints";

const esV2SearchFlag = getESV2FlagFromQueryParam(
  EsV2QueryParams.ES_V2_SEARCH_FLAG
);

const getUserData = (successCallback) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.USER_GET_DATA_BEGIN });
    getAxios(true)
      .get(STATIC_ENDPOINT.SIGN_USER_DATA)
      .then(({data, headers}) => {
        if (getCookieForEntity(COOKIE_KEYS.OUTLET_ID, COOKIE_KEYS.POS_OUTLET_ID) === null) {
          setCookieForEntity(COOKIE_KEYS.OUTLET_ID, COOKIE_KEYS.POS_OUTLET_ID, data.response.outlet.id, COOKIE_TTL_FOR_WEB);
        }
        const token = headers.authorization;
        if (token) {
          setCookieForEntity("token", "posToken", token, COOKIE_TTL_FOR_WEB)
        }
        dispatch({
          type: actionTypes.USER_GET_DATA_DONE, 
          data: data.response, 
          showLocationModal: data?.response?.action?.ActionType === ADD_LOCATION_SIGN_UP ? true : false,
          locationModalData: data?.response?.action?.ActionData
        });

        handleCallBack(successCallback, data);
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ERROR_HANDLE,
          payload: {
            errToast: {
              Text: "Something Went Wrong!",
            }
          },
        });
        dispatch({
          type: actionTypes.USER_GET_DATA_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function getOverDue(source = "", categoryId = null, subCategoryId = null) {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_OVERDUE_BEGIN });
    getAxios(true)
      .get(APIendpoints.v2_overDue(source, categoryId, subCategoryId))
      .then(({ data, headers }) => {
        dispatch({ type: actionTypes.GET_OVERDUE_DONE, data: data.response });
      })
      .catch((err) =>
        dispatch({
          type: actionTypes.GET_OVERDUE_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        })
      );
  };
}

function removeSignedInFromZomato() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REMOVE_ZOMATO_SIGNEDIN,
    });
  };
}

function getCommercialBanners(endpoint) {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_COMMERCIAL_BANNER_BEGIN });
    getAxios(true)
      .get(endpoint)
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.GET_COMMERCIAL_BANNER_DONE,
          data: (data.response.Banners || [])
            .sort((a, b) => a.Order - b.Order)
            .map((el, index) => ({
              src: el.Image,
              keyVal: `${index}-banner`,
              id: el.Id,
              commercialName: el.CommercialName,
              productCount: el.ProductCount,
              actionType: el.ActionType,
              actionData: el.ActionData,
              trackingData: el.TrackingParams,
              bannerType: el.BannerType,
              heading: el.Heading,
              subHeading: el.SubHeading,
              imageRatio: el.ImageRatio,
            })),
        });
      })
      .catch((err) =>
        dispatch({
          type: actionTypes.GET_COMMERCIAL_BANNER_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        })
      );
  };
}

function getCartDiscount(endpoint) {
  const postbackParams = window.localStorage.getItem("postbackParams");
  const newEndpoint = endpoint.concat("&PostbackParams=", postbackParams);
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_CART_DISCOUNT_BEGIN });
    getAxios(true)
      .get(newEndpoint)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.GET_CART_DISCOUNT_DONE,
          CartDiscount: data?.response?.CartDiscount || data?.response?.Aerobars,
        });
        window.localStorage.setItem("postbackParams", data?.response?.CartDiscount?.PostbackParams ? data.response.CartDiscount.PostbackParams : "");
      })
      .catch((err) =>
        dispatch({
          type: actionTypes.GET_CART_DISCOUNT_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        })
      );
  };
}

function getFeatureTickers(endpoint) {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_FEATURE_TICKER_BEGIN });
    getAxios(true)
      .get(endpoint)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.GET_FEATURE_TICKER_DONE,
          data: data.response.FeatureTickers,
        });
      })
      .catch((err) =>
        dispatch({
          type: actionTypes.GET_FEATURE_TICKER_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        })
      );
  };
}

function getCollaborativeBanners(endpoint) {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_COLLABORATIVE_BANNERS_BEGIN });
    getAxios(true)
      .get(endpoint)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.GET_COLLABORATIVE_BANNERS_DONE,
          data: data.response.Banners,
        });
      })
      .catch((err) =>
        dispatch({
          type: actionTypes.GET_COLLABORATIVE_BANNERS_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        })
      );
  };
}

function getExigencyBanners(endpoint) {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_EXIGENCY_BANNERS_BEGIN });
    getAxios(true)
      .get(endpoint)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.GET_EXIGENCY_BANNERS_DONE,
          data: data.response.Banners,
        });
      })
      .catch((err) =>
        dispatch({
          type: actionTypes.GET_EXIGENCY_BANNERS_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        })
      );
  };
}

function switchOutlet(outletId) {
  return (dispatch) => {
    dispatch({ type: actionTypes.OUTLET_SWITCH_BEGIN });
    getAxios(true)
      .post(APIendpoints.switchOutlet, { OutletId: outletId })
      .then(() => {
        setCookie("outletId", outletId,COOKIE_TTL_FOR_WEB);
        dispatch({
          type: actionTypes.OUTLET_SWITCH_DONE,
          outletSwitchId: outletId,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.OUTLET_SWITCH_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function resendEmail() {
  return (dispatch) => {
    dispatch({ type: actionTypes.USER_RESEND_EMAIL_BEGIN });
    getAxios(true)
      .post(APIendpoints.resendEmail)
      .then(({ data, headers }) => {
        dispatch({ type: actionTypes.USER_RESEND_EMAIL_DONE });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.USER_RESEND_EMAIL_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function changeName(requestData) {
  return (dispatch) => {
    dispatch({ type: actionTypes.USER_CHANGE_NAME_BEGIN });
    getAxios(true)
      .post(STATIC_ENDPOINT.CHANGE_NAME, requestData)
      .then(() => {
        dispatch({ type: actionTypes.USER_CHANGE_NAME_DONE });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.USER_CHANGE_NAME_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function changeEmail(requestData) {
  return (dispatch) => {
    dispatch({ type: actionTypes.USER_CHANGE_EMAIL_BEGIN });
    getAxios(true)
      .post(APIendpoints.changeEmail, requestData)
      .then(() => {
        dispatch({ type: actionTypes.USER_CHANGE_EMAIL_DONE });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.USER_CHANGE_EMAIL_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function changePhone(requestData) {
  return (dispatch) => {
    dispatch({ type: actionTypes.USER_CHANGE_PHONE_BEGIN });
    getAxios(true)
      .post(APIendpoints.changePhone, requestData)
      .then(({ data, headers }) => {
        dispatch({ type: actionTypes.USER_CHANGE_PHONE_DONE });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.USER_CHANGE_PHONE_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function sendReferralEmailInvite(requestData) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SEND_REFERRAL_EMAIL_INVITE_BEGIN });
    getAxios(true)
      .post(APIendpoints.sendReferalEmailInvite, requestData)
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.SEND_REFERRAL_EMAIL_INVITE_DONE,
          data: data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.SEND_REFERRAL_EMAIL_INVITE_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function acceptTnC(outletId) {
  return (dispatch) => {
    dispatch({ type: actionTypes.ACCEPT_TNC_BEGIN });
    getAxios(true)
      .post(APIendpoints.acceptTnC, { id: outletId })
      .then(({ data, headers }) => {
        dispatch({ type: actionTypes.ACCEPT_TNC_DONE, data: data });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ACCEPT_TNC_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function changePassword(requestData) {
  return (dispatch) => {
    dispatch({ type: actionTypes.USER_CHANGE_PASSWORD_BEGIN });
    getAxios(true)
      .post(APIendpoints.changePassword, requestData)
      .then(({ data, headers }) => {
        dispatch({ type: actionTypes.USER_CHANGE_PASSWORD_DONE });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.USER_CHANGE_PASSWORD_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function getTestimonials() {
  return (dispatch) => {
    dispatch({ type: actionTypes.TESTIMONIALS_BEGIN });
    getAxios()
      .get(APIendpoints.testimonials)
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.TESTIMONIALS_DONE,
          testimonials: data.response.testimonials,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.TESTIMONIALS_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function getCities() {
  return (dispatch) => {
    dispatch({ type: actionTypes.USER_GET_CITIES_BEGIN });
    getAxios()
      .get(APIendpoints.getCities)
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.USER_GET_CITIES_DONE,
          cities: data.response || [],
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.USER_GET_CITIES_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function getDeliveryTimeSlots(cityId = "", storeCityBasedSLots = false) {
  return (dispatch) => {
    dispatch({ type: actionTypes.USER_GET_DELIVERY_TIME_SLOTS_BEGIN });
    getAxios()
      .get(APIendpoints.getDeliveryTimeSlots(cityId))
      .then(({ data }) => {
        if (storeCityBasedSLots) {
          dispatch({
            type: actionTypes.USER_GET_DELIVERY_TIME_SLOTS_DONE,
            // TODO when submitting data, convert it back to int
            cityDeliveryTimeSlots: data.response.deliveryTimeSlots
              ? {
                  [cityId]: data.response.deliveryTimeSlots.map(
                    (deliveryTimeSlot) => ({
                      ...deliveryTimeSlot,
                      id: `${deliveryTimeSlot.id}`,
                    })
                  ),
                }
              : {},
            deliveryTimeSlots: [],
            storeCityBasedSLots,
          });
        } else {
          dispatch({
            type: actionTypes.USER_GET_DELIVERY_TIME_SLOTS_DONE,
            // TODO when submitting data, convert it back to int
            deliveryTimeSlots: data.response.deliveryTimeSlots
              ? data.response.deliveryTimeSlots.map((deliveryTimeSlot) => ({
                  ...deliveryTimeSlot,
                  id: `${deliveryTimeSlot.id}`,
                }))
              : [],
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.USER_GET_DELIVERY_TIME_SLOTS_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function getDesignations() {
  return (dispatch) => {
    dispatch({ type: actionTypes.USER_GET_DESIGNATIONS_BEGIN });
    getAxios()
      .get(APIendpoints.getDesignations)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.USER_GET_DESIGNATIONS_DONE,
          designations: data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.USER_GET_DESIGNATIONS_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function getOutlets() {
  return (dispatch) => {
    dispatch({ type: actionTypes.USER_GET_OUTLETS_BEGIN });
    getAxios(true)
      .get(APIendpoints.accounts)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.USER_GET_OUTLETS_DONE,
          accounts: data.response.Accounts,
          showSearch: data.response.ShowSearch
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.USER_GET_OUTLETS_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function getOutletsOnly(callBack) {
  return (dispatch) => {
    dispatch({ type: actionTypes.USER_GET_OUTLETS_ONLY_BEGIN });
    getAxios(true)
      .get(APIendpoints.getOutlets)
      .then(({ data }) => {
        const response = data.response;
        dispatch({
          type: actionTypes.USER_GET_OUTLETS_ONLY_DONE,
          outlets: data.response.outlets,
        });
        if (typeof callBack === "function") {
          callBack(response);
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.USER_GET_OUTLETS_ONLY_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function getReferral(outletName, outletId) {
  return (dispatch) => {
    dispatch({ type: actionTypes.USER_GET_REFERRAL_BEGIN });
    getAxios(true)
      .get(APIendpoints.getReferral(outletName, outletId))
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.USER_GET_REFERRAL_DONE,
          data: data.response.referralDetails,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.USER_GET_REFERRAL_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function getConfig() {
  return (dispatch) => {
    dispatch({ type: actionTypes.CONFIG_GET_BEGIN });
    getAxios(true)
      .get(APIendpoints.getConfig)
      .then(({ data }) => {
        const response = data.response;
        dispatch({
          type: actionTypes.CONFIG_GET_DONE,
          payload: {
            SiteWideMessage: response.SiteWideMessage,
            Rating: response.Rating,
            ContactUs: response.ContactUs,
            NpsMeta: response.NPSMeta,
            ProfileMeta: response.ProfileMeta,
            PrintHardInvoice: response.PrintHardInvoice,
            InstitutionalAccount: response.InstitutionalAccountMeta,
            DefaultSearchAction: response.DefaultSearchAction,
            PaymentStatusMaxPollCount: response.PaymentStatusMaxPollCount,
            PaymentStatusPollTimeInSeconds:
              response.PaymentStatusPollTimeInSeconds,
            LocationMeta: response.LocationMeta,
            ContactUsV2: response.ContactUsV2,
            ReportIssueMeta:response.ReportIssueMeta,
            AppMode: response.AppMode,
          },
        });
        setCookieForEntity(COOKIE_KEYS.APP_MODE, COOKIE_KEYS.APP_MODE, response.AppMode, COOKIE_TTL_FOR_WEB);
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.CONFIG_GET_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function verifyEmail(userId, token) {
  return (dispatch) => {
    const params = {
      UserId: userId ? parseInt(userId) : 0,
      Code: token,
    };
    dispatch({ type: actionTypes.VERIFY_EMAIL_BEGIN });
    getAxios(false)
      .post(APIendpoints.verifyEmail, params)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.VERIFY_EMAIL_DONE,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.VERIFY_EMAIL_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function fetchMechantFromZomato(token, callback, errCallBack) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_MERCHANT_ZOMATO_BEGIN });
    const header = {
      "X-ResToken": token,
    };
    getAxios(false, undefined, header)
      .get(APIendpoints.getMerchantDataFromZomato)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.FETCH_MERCHANT_ZOMATO_DONE,
          payload: { data: data.response },
        });
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.FETCH_MERCHANT_ZOMATO_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
        if (typeof errCallBack === "function") {
          errCallBack();
        }
      });
  };
}

function autoOnboardingZomato(params, callBack, errCallBack) {
  return (dispatch) => {
    dispatch({ type: actionTypes.AUTO_ONBOARDING_ZOMATO_BEGIN });
    sessionStorage.setItem("onboarding_source", "partner_web");
    getAxios(true)
      .post(APIendpoints.autoOnboardingZomato, params)
      .then(({ data, headers }) => {
        const token = headers.authorization;
        if (token) {
          setCookieForEntity("token","posToken", token,COOKIE_TTL_FOR_WEB)
          setCookieForEntity("outletId","posOutletId", data?.response?.OutletId,COOKIE_TTL_FOR_WEB)
        }
        dispatch({
          type: actionTypes.AUTO_ONBOARDING_ZOMATO_DONE,
          payload: { data: data.response },
        });
        window.location.href = "/";
        if (typeof callBack === "function") {
          callBack();
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.AUTO_ONBOARDING_ZOMATO_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
        if (typeof errCallBack === "function") {
          errCallBack();
        }
      });
  };
}

function updateWhatsappNotificationStatus(params) {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_WHATSAPP_NOTIFICATION_STATUS_BEGIN });
    getAxios(true)
      .post(APIendpoints.updateWhatsappNotificationStatus, params)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.UPDATE_WHATSAPP_NOTIFICATION_STATUS_DONE,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.UPDATE_WHATSAPP_NOTIFICATION_STATUS_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function refundToWallet(params) {
  return (dispatch) => {
    dispatch({ type: actionTypes.REFUND_TO_WALLET_BEGIN });
    getAxios(true)
      .put(APIendpoints.refundToWallet, params)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.REFUND_TO_WALLET_DONE,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.REFUND_TO_WALLET_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function closeAppInstallPrompt() {
  return (dispatch) => {
    dispatch({ type: actionTypes.CLOSE_APP_INSTALL_PROMPT });
  };
}

function getPopupType(source, state) {
  if (source === "CHECKOUT" || source === "HOME") {
    return actionTypes[`GET_POPUP_${source}_${state}`];
  } else return actionTypes[`GET_POPUP_${state}`];
}

function getPopup(source , callBack) {
  return (dispatch) => {
    dispatch({
      type: getPopupType(source, "BEGIN"),
    });

    getAxios(true)
      .get(APIendpoints.getPopup(source))
      .then(({ data }) => {

      dispatch({
          type: getPopupType(source, "DONE"),
          data: data.response,
        });
        if (typeof callBack === "function") {
          callBack(data.response, false)
        }
      })
      .catch((err) => {
        dispatch({
          type: getPopupType(source, "FAIL"),
          error: _get(err, ["response", "data", "error"], {}),
        });
        if (typeof callBack === "function") {
          callBack({}, true)
        }
      });
  };
}

function referralPopup() {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_POPUP_INIT });
  };
}

function pastOrders() {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_PAST_ORDERS_BEGIN });

    getAxios(true)
      .get(APIendpoints.pastOrders(esV2SearchFlag))
      .then(({ data }) => {
        dispatch({
          type: actionTypes.GET_PAST_ORDERS_DONE,
          data: data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_PAST_ORDERS_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function shareAppLink(params) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SHARE_APP_LINK_BEGIN });

    getAxios(true)
      .post(APIendpoints.shareAppLink, params)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.SHARE_APP_LINK_DONE,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.SHARE_APP_LINK_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function getAppLink() {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_APP_LINK_BEGIN });

    getAxios(true)
      .get(APIendpoints.shareAppLink)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.GET_APP_LINK_DONE,
          applinks: data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_APP_LINK_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function tourNextStep(step) {
  return (dispatch) => {
    if (step === "done") {
      window.localStorage.setItem("isHomeTourDone", true);
    }
    dispatch({
      type: actionTypes.HOME_TOUR_BEGIN,
      step,
    });
  };
}

function getRewards(isAuthenticated) {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_REWARDS_BEGIN });

    getAxios(isAuthenticated)
      .get(APIendpoints.getRewards)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.GET_REWARDS_DONE,
          rewards: data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_REWARDS_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function trackPopUp(params, callBack) {
  return (dispatch) => {
    dispatch({ type: actionTypes.TRACK_CLICK_BEGIN });

    getAxios(true)
      .post(APIendpoints.track, params)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.TRACK_CLICK_DONE,
        });
        if (typeof callBack === "function") {
          callBack();
        }
      });
  };
}

function trackingWithoutAuth(params) {
  return (dispatch) => {
    dispatch({ type: actionTypes.TRACK_CLICK_BEGIN });

    getAxios(false)
      .post(APIendpoints.openTrack, params)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.TRACK_CLICK_DONE,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.TRACK_CLICK_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function updatePayLater(optInStatus) {
  return (dispatch) => {
    dispatch({ type: actionTypes.PAY_LATER_UPDATE_BEGIN });

    getAxios(true)
      .post(APIendpoints.updatePayLater, {
        OptInStatus: optInStatus,
      })
      .then(({ data }) => {
        dispatch({
          type: actionTypes.PAY_LATER_UPDATE_DONE,
          payload: {
            imageURL: data.response.ActionData.PopupData.ImageUrl,
            backgroundImageURL:
              data.response.ActionData.PopupData.BackgroundImageUrl,
            backgroundAnimationURL:
              data.response.ActionData.PopupData.BackgroundAnimationUrl,
            heading: data.response.ActionData.PopupData.Heading,
            text: data.response.ActionData.PopupData.Text,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.PAY_LATER_UPDATE_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function getConsumerRail(source) {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_CONSUMER_RAIL_BEGIN });

    getAxios(true)
      .get(APIendpoints.getConsumerRail(source, esV2SearchFlag))
      .then(({ data }) => {
        dispatch({
          type: actionTypes.GET_CONSUMER_RAIL_DONE,
          consumerRail: data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_CONSUMER_RAIL_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function setDeliverySlot(params) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_DELIVERY_SLOTS_BEGIN });

    getAxios(true)
      .post(APIendpoints.setDeliverySlot, params)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.SET_DELIVERY_SLOTS_DONE,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.SET_DELIVERY_SLOTS_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function setPinCode(params) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_PINCODE_BEGIN });

    getAxios(true)
      .post(APIendpoints.setPinCode, params)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.SET_PINCODE_DONE,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.SET_PINCODE_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function semiServiceable(pageNo, isFirstTime = false) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SEMI_SERVICEABLE_BEGIN });

    getAxios(true)
      .get(`${APIendpoints.semiServiceable}?pageNo=${pageNo}`)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.SEMI_SERVICEABLE_DONE,
          data: data.response,
          hasNextPage: data.response.HasNextPage,
          isFirstTime: isFirstTime,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.SEMI_SERVICEABLE_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function getFAQs() {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_FAQ_BEGIN });
    getAxios(true)
      .get(APIendpoints.getFAQs)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.GET_FAQ_DONE,
          data: data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_FAQ_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function setQuestion(data, successCallback = () => {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_FAQ, data });
    successCallback();
  };
}

function resetFAQAnswer() {
  return (dispatch) => {
    dispatch({ type: actionTypes.RESET_FAQ });
  };
}

function getFAQAnswer(id, successCallback = () => {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_FAQ_ANSWER_BEGIN });
    getAxios(true)
      .get(APIendpoints.getFAQAnswer(id))
      .then(({ data }) => {
        dispatch({
          type: actionTypes.GET_FAQ_ANSWER_DONE,
          data: data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_FAQ_ANSWER_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function sideMenu(id, successCallback = () => {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_AUTH_OPTIONS_BEGIN });
    getAxios(true)
      .get(APIendpoints.sideMenu)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.GET_AUTH_OPTIONS_DONE,
          data: data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_AUTH_OPTIONS_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function addNpsRating(rating, token) {
  return (dispatch) => {
    dispatch({ type: actionTypes.ADD_NPS_RATING_BEGIN });
    const params = {
      Rating: parseInt(rating),
      Token: token,
    };

    getAxios(false)
      .post(APIendpoints.npsRating, params)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.ADD_NPS_RATING_DONE,
          data: data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ADD_NPS_RATING_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function updateNpsRating(reasons, comment, token) {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_NPS_RATING_BEGIN });
    const params = {
      Reasons: reasons,
      Comment: comment,
      Token: token,
    };

    getAxios(true)
      .put(APIendpoints.npsRating, params)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.UPDATE_NPS_RATING_DONE,
          data: data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.UPDATE_NPS_RATING_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function addNpsFeedback(data) {
  return (dispatch) => {
    dispatch({ type: actionTypes.ADD_NPS_FEEDBACK_BEGIN });

    getAxios(true)
      .post(APIendpoints.npsFeedbackRating, data)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.ADD_NPS_FEEDBACK_DONE,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ADD_NPS_FEEDBACK_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function getHomePage() {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_HOME_BEGIN });
    getAxios(true)
      .get(STATIC_ENDPOINT.GET_HOME_PAGE)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.GET_HOME_DONE,
          payload: data && data.response,
        });
        // dispatch app mode info
        dispatch({ type: actionTypes.SET_APP_MODE_INFO, payload: data.response?.Header?.AppModeInfo });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_HOME_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function getHomePageV2() {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_HOME_META_V2_BEGIN });
    getAxios(true)
      .get(STATIC_ENDPOINT.GET_HOME_PAGE)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.GET_HOME_META_V2_DONE,
          payload: data && data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_HOME_META_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function getMilestoneDetails(state,type) {
  return (dispatch) => {
    dispatch({ type: actionTypes.MILESTONE_DETAILS_BEGIN });
    getAxios(true)
      .get(APIendpoints.getMilestoneDetails(state,type))
      .then(({ data }) => {
        dispatch({
          type: actionTypes.MILESTONE_DETAILS_DONE,
          payload: data && data.response,
        });
      })
      .catch((err) => {console.log("Tjos os tje err",err)
        dispatch({
          type: actionTypes.MILESTONE_DETAILS_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function getHistoricalMilestoneDetails(val) {
  return (dispatch) => {
    dispatch({ type: actionTypes.MILESTONE_HISTORICAL_DETAILS_BEGIN });
    getAxios(true)
      .get(APIendpoints.getMilestoneDetails(val,""))
      .then(({ data }) => {
        dispatch({
          type: actionTypes.MILESTONE_HISTORICAL_DETAILS_DONE,
          payload: data && data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.MILESTONE_HISTORICAL_DETAILS_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function updateAccount(data) {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_ACCOUNT_BEGIN });
    getAxios(true)
      .put(APIendpoints.updateAccount, data)
      .then(() => {
        dispatch({
          type: actionTypes.UPDATE_ACCOUNT_DONE,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.UPDATE_ACCOUNT_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function updateOutlet(data) {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_OUTLET_BEGIN });
    getAxios(true)
      .put(APIendpoints.updateOutlet, data)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.UPDATE_OUTLET_DONE,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.UPDATE_OUTLET_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function getZomatoStatus(data) {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_ZOMATO_STATUS_BEGIN });
    getAxios(true)
      .post(APIendpoints.zomatoGetStatus, data)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.GET_ZOMATO_STATUS_DONE,
          payload: data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_ZOMATO_STATUS_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function resetUserPreferenceStatus() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_USER_PREFERENCE_INIT,
    });
  };
}

function getCommunications(params) {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_COMMUNICATIONS_BEGIN });
    getAxios(true)
      .get(
        APIendpoints.communications(
          params.source,
          params.categoryID,
          params.subCategoryID
        )
      )
      .then(({ data }) => {
        dispatch({
          type: actionTypes.GET_COMMUNICATIONS_DONE,
          payload: data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_COMMUNICATIONS_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function resetCommunications() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_COMMUNICATIONS_INIT,
      payload: [],
    });
  };
}

function toggleDAModal(viewModal, deliveryData) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TOGGLE_DA_MODAL,
      payload: viewModal,
      deliveryData: deliveryData,
    });
  };
}

function getMaskedNumber(params, callBack) {
  return (dispatch) => {
    getAxios(true)
      .get(
        APIendpoints.getMaskedNumber(
          params.OrderNumber,
          params.PhoneNumber?.length ? params.PhoneNumber : ""
        )
      )
      .then(({ data }) => {
        if (typeof callBack === "function") {
          callBack(data);
        }
      })
      .catch((err) => {
        let errNew = _get(
          err,
          ["response", "data", "error", "message"],
          "Something went wrong!"
        );
        dispatch(setToastMessage(errNew, "error"));
      });
  };
}

function getOnboardingStatus(callBack){
  return (dispatch) => {
   
    getAxios(true).get(APIendpoints.getOnboardingStatus).then(({data})=>{
      if (typeof(callBack)==='function'){
        callBack(data.response)
      }
    }).catch((err)=>{
      dispatch({
        type: actionTypes.AUTH_SIGN_IN_FAIL,
        error:  _get(
          err,
          ["response", "data", "error"],
          {}
        ),
        errorData: _get(
          err,
          ["response", "data","error","errorToast"],
          {}
        ),
      });
    })
  }
}

function updateUserPreferences(params, preference, setInRedux, callBack,queryParams = {}) {
  const queryString = JSON.stringify(queryParams);
  const url = queryString
  ? `${APIendpoints.consumerPreferences}?${queryString}`
  : APIendpoints.consumerPreferences;

  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_USER_PREFERENCE_BEGIN });
    getAxios(true)
      .post(url, params)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.UPDATE_USER_PREFERENCE_DONE,
          payload: data.response,
          preferenceType: preference,
          setInRedux: setInRedux,
        });
        if (typeof callBack === "function") {
          callBack();
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.UPDATE_USER_PREFERENCE_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}
function setErrorToast(text) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ERROR_TOAST_SET,
      errorToast: {
        Text: text,
      },
    });
  };
}

function clearErrorToast() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ERROR_CLEAR,
    });
  };
}

function resetCartDiscount() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_CART_DISCOUNT_DONE,
      CartDiscount: {},
    });
  };
}

function showNotification(show) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SHOW_NOTIFICATION,
      showNotification: show,
    });
  };
}

function showHeaderDeliveryNudge(show) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SHOW_HEADER_DELIVERY_NUDGE,
      headerDeliveryNudge: show,
    });
  };
}

function showOutletSwitchModal(show, data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SHOW_OUTLET_SWITCH_MODAL,
      outletSwitch: {
        show: show,
        data: data,
      },
    });
  };
}

function getAddressDetailsUsingLatLong(actionSource, lat, long, dontSaveBegin) {
  return (dispatch) => {
    if (!dontSaveBegin) {
      dispatch({ type: actionTypes.GET_ADDRESS_DETAILS_BEGIN });
    }
    getAxios(true)
      .get(APIendpoints.getAddressDetailsUsingLatLong(lat, long, actionSource))
      .then(({ data }) => {
        dispatch({
          type: actionTypes.GET_ADDRESS_DETAILS_DONE,
          payload: data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_ADDRESS_DETAILS_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function resetAddressDetailsUsingLatLong() {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_ADDRESS_DETAILS_INIT });
  };
}

function getSearchLocations(searchQuery) {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_SEARCH_LOCATIONS_BEGIN });
    getAxios(true)
      .get(APIendpoints.getSearchLocations(searchQuery))
      .then(({ data }) => {
        dispatch({
          type: actionTypes.GET_SEARCH_LOCATIONS_DONE,
          payload: data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_SEARCH_LOCATIONS_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function resetSearchLocations() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_SEARCH_LOCATIONS_INIT,
    });
  };
}

function updateOutletLocation(params) {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_OUTLET_LOCATION_BEGIN });
    getAxios(true)
      .post(APIendpoints.updateOutletLocation, params)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.UPDATE_OUTLET_LOCATION_DONE,
          payload: data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.UPDATE_OUTLET_LOCATION_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function checkOutletLocation() {
  return (dispatch) => {
    dispatch({ type: actionTypes.CHECK_OUTLET_LOCATION_BEGIN });
    getAxios(true)
      .get(APIendpoints.checkOutletLocation)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.CHECK_OUTLET_LOCATION_DONE,
          payload: data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.CHECK_OUTLET_LOCATION_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function getHomeCategoryRails (pageNo) {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_HOME_PAGE_CATEGORY_RAILS_BEGIN })
  getAxios(true)
    .get(APIendpoints.getHomeCategoryRails(pageNo))
    .then(({ data }) => {
      dispatch({
        type: actionTypes.GET_HOME_PAGE_CATEGORY_RAILS_DONE,
        payload: data.response,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_HOME_PAGE_CATEGORY_RAILS_FAIL,
        error: _get(
          err,
          ["response", "data", "error"],
          {}
        ),
      });

    });
}
}

function resetHomeCategoryRails () {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_HOME_PAGE_CATEGORY_RAILS_INIT })
  }
}

function verifyUser(phoneNumber) {
  return (dispatch) => {
    dispatch({ type: actionTypes.VERIFY_USER_BEGIN })
    getAxios(true)
      .get(APIendpoints.verifyUser(phoneNumber))
      .then(({ data }) => {
        dispatch({
          type: actionTypes.VERIFY_USER_DONE,
          payload: data.response,
        })
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.VERIFY_USER_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
          errorData: _get(err, ["response", "data"], {}),
        })
      })
  }
}

function completePersonalDetails (params,callBack,errCallBack){
  return (dispatch)=>{
    getAxios(true).post(APIendpoints.personalDetails,params)
    .then(({data})=>{
      
      if (typeof(callBack)==='function'){
        callBack(data.response)
      }
    })
    .catch((err)=>{

      dispatch({
        type: actionTypes.AUTH_SIGN_IN_FAIL,
        error:  _get(
          err,
          ["response", "data", "error"],
          {}
        ),
        errorData: _get(
          err,
          ["response", "data","error","errorToast"],
          {}
        ),
      });

    })
  }
}

function completeUploadDetails (param,callBack){
  return (dispatch)=>{
    getAxios(true).post(APIendpoints.completeOnboarding,param).then(({data})=>{
      if(typeof(callBack)==='function'){
        callBack(data.response)
      }
    }).catch((err)=>{
      dispatch({
        type: actionTypes.AUTH_SIGN_IN_FAIL,
        error:  _get(
          err,
          ["response", "data", "error"],
          {}
        ),
        errorData: _get(
          err,
          ["response", "data","error","errorToast"],
          {}
        ),
      });

    })
  }
}

function completeRestaurantDetails (params,callBack,errCallBack){
  return (dispatch)=>{
    getAxios(true).post(APIendpoints.restaurantDetails,params).then(({data})=>{
      
      if (typeof(callBack)==='function'){
        callBack(data.response)
      }
    }).catch((err)=>{
      dispatch({
        type: actionTypes.AUTH_SIGN_IN_FAIL,
        error:  _get(
          err,
          ["response", "data", "error"],
          {}
        ),
        errorData: _get(
          err,
          ["response", "data","error","errorToast"],
          {}
        ),
      });

    })
  }
}

function documentVerificationViewDetails (viewType,callBack,errcallback){
  return (dispatch) => {
    dispatch({type:actionTypes.DOCUMENT_VERIFICATION_DETAILS_BEGIN})
    getAxios(true).get(APIendpoints.documentVerificationViewDetails(viewType)).then(({data})=>{
      dispatch({type:actionTypes.DOCUMENT_VERIFICATION_DETAILS_DONE,data:data?.response})
      if (typeof(callBack)=='function'){
        callBack(data.response)
      }
    }).catch((err)=>{
      dispatch({type:actionTypes.DOCUMENT_VERIFICATION_DETAILS_FAIL,data:_get(err, ["response", "data", "error"], {})})
      dispatch({
        type: actionTypes.AUTH_SIGN_IN_FAIL,
        error:  _get(
          err,
          ["response", "data", "error"],
          {}
        ),
        errorData: _get(
          err,
          ["response", "data","error","errorToast"],
          {}
        ),
      });
    })
  }
}

function verifyPanAndLegalEntityName (endpoint,callBack,errorCallBack){
  
  return (dispatch) => {
    dispatch({type:actionTypes.DOCUMENT_NUMBER_VERIFICATION_DETAILS_BEGIN})
    getAxios(true).put(endpoint).then(({data})=>{
      dispatch({type:actionTypes.DOCUMENT_NUMBER_VERIFICATION_DETAILS_DONE,data:data?.response})
      if(typeof(callBack)==='function'){
        callBack(data.response)
      }
    }).catch((err)=>{
      dispatch({type:actionTypes.DOCUMENT_NUMBER_VERIFICATION_DETAILS_FAIL,data:_get(err, ["response", "data", "error"], {})})
      dispatch({
        type: actionTypes.AUTH_SIGN_IN_FAIL,
        error:  _get(
          err,
          ["response", "data", "error"],
          {}
        ),
        errorData: _get(
          err,
          ["response", "data","error","errorToast"],
          {}
        ),
      });
    })
  }
}

function uploadDocuments(formData,callBack){
  return (dispatch)=>{
    dispatch({type:actionTypes.UPLOAD_DOCUMENT_DETAILS_BEGIN})
    getAxios(true).post(APIendpoints.uploadDocuments,formData).then(({data})=>{
      dispatch({type:actionTypes.UPLOAD_DOCUMENT_DETAILS_DONE,data:data?.response})
      callBack(data.response)
    }).catch((err)=>{
      dispatch({type:actionTypes.UPLOAD_DOCUMENT_DETAILS_FAIL,data:_get(err, ["response", "data", "error"], {})})
      dispatch({
        type: actionTypes.AUTH_SIGN_IN_FAIL,
        error:  _get(
          err,
          ["response", "data", "error"],
          {}
        ),
        errorData: _get(
          err,
          ["response", "data","error","errorToast"],
          {}
        ),
      });
    })
  }
}

function pollDocuments(params,callBack){
  return (dispatch)=>{
    dispatch({type:actionTypes.POLL_DOCUMENT_DETAILS_BEGIN})
    getAxios(true).put(APIendpoints.pollDocuments,params).then(({data})=>{
      dispatch({type:actionTypes.POLL_DOCUMENT_DETAILS_DONE,data:data?.response})
      if(typeof(callBack)==='function'){
        callBack(data)
      }
    }).catch((err)=>{
      dispatch({type:actionTypes.POLL_DOCUMENT_DETAILS_FAIL,data:_get(err, ["response", "data", "error"], {})})
      dispatch({
        type: actionTypes.AUTH_SIGN_IN_FAIL,
        error:  _get(
          err,
          ["response", "data", "error"],
          {}
        ),
        errorData: _get(
          err,
          ["response", "data","error","errorToast"],
          {}
        ),
      });
    })
  }
}

function resetVerifyUserData () {
  return (dispatch)=>{
    dispatch({type: actionTypes.VERIFY_USER_INIT})
  }
}

function verifyUserOtp(phoneNumber, otp, otpType) {
  return (dispatch) => {
    dispatch({ type: actionTypes.VERIFY_USER_OTP_BEGIN })
    getAxios(true)
      .post(APIendpoints.verifyOtp(phoneNumber, otp, otpType))
      .then(({ data }) => {
        dispatch({
          type: actionTypes.VERIFY_USER_OTP_DONE,
          payload: data.response,
        })
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.VERIFY_USER_OTP_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
          errorData: _get(err, ["response", "data"], {}),
        })
      })
  }
}

function resetVerifyUserOTPData() {
  return (dispatch) => {
    dispatch({ type: actionTypes.VERIFY_USER_OTP_INIT })
  }
}

function changeDeliverySlot(params , successCallback){
  return (dispatch)=>{
    dispatch({type :actionTypes.CHANGE_DELIVERY_SLOT_BEGIN})
    getAxios(true)
    .put(APIendpoints.changeDeliverySlot , params)
    .then(({data})=>{
      
      dispatch({
        type: actionTypes.CHANGE_DELIVERY_SLOT_DONE,
        payload : data.response,
      })
      if (typeof successCallback === "function") {
        successCallback(data.response);
      }
    })
    .catch((err)=>{
      dispatch({
        type: actionTypes.CHANGE_DELIVERY_SLOT_FAIL,
        error: _get(err, ["response", "data", "error"], {}),
      })
    })
  }
}

function generatePayloadToken(param , successCallback , errCallBack){
  return (dispatch)=>{
    dispatch({type :actionTypes.GENERATE_PAYLOAD_TOKEN_BEGIN})
    getAxios(true)
      .post(APIendpoints.generatePayloadToken, param)
      .then(({ data }) => {

        dispatch({
          type: actionTypes.GENERATE_PAYLOAD_TOKEN_DONE,
          payload: data.token,
        })
        if (typeof successCallback === "function") {
          successCallback(data);
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GENERATE_PAYLOAD_TOKEN_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        })
      })
  }
}

function generateAccessToken(param, successCallback, errCallBack) {
  return (dispatch) => {
    dispatch({ type: actionTypes.GENERATE_ACCESS_TOKEN_BEGIN })
    getAxios(true)
      .post(APIendpoints.generateAccessToken, param)
      .then(({ data }) => {

        dispatch({
          type: actionTypes.GENERATE_ACCESS_TOKEN_DONE,
          payload: data.token,
        })
        if (typeof successCallback === "function") {
          successCallback(data);
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GENERATE_ACCESS_TOKEN_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        })
      })
  }
}

function showChatBot(isSummaryPage, renderChatBotEverywhere) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SHOW_CHATBOT,
      payload: {
        showChatBot: true,
        isSummaryPage: isSummaryPage,
        renderChatBotEverywhere: renderChatBotEverywhere,
      }
    })

  }
}

function getOfferDetails(endpoint) {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_OFFER_DETAILS_BEGIN });
    getAxios(true)
      .get(endpoint)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.GET_OFFER_DETAILS_DONE,
          payload: data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_OFFER_DETAILS_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
        });
      });
  };
}

function toggleSOAModal(showSOAModal) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TOGGLE_SOA_MODAL,
      payload: {
        showSOAModal: showSOAModal,
      }
    })
  }
}

function getSOAOptions () {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_SOA_OPTIONS_BEGIN });
  getAxios(true)
    .get(APIendpoints.getSOAOptions)
    .then(({ data }) => {
      dispatch({
        type: actionTypes.GET_SOA_OPTIONS_DONE,
        payload: data.response,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.GET_SOA_OPTIONS_FAIL,
        error: _get(
          err,
          ["response", "data", "error"],
          {}
        ),
      });

    });
  }
  }

  function getSOAFilePath(param, successCallback, errCallBack, dispatchSuccessMessage = false) {
    return (dispatch) => {
      dispatch({ type: actionTypes.GET_SOA_FILE_PATH_BEGIN })
      getAxios(true)
        .post(APIendpoints.getSOAFilePath, param)
        .then(({ data }) => {
          dispatch({
            type: actionTypes.GET_SOA_FILE_PATH_DONE,
            payload: data.response,
          })
          if (typeof successCallback === "function") {
            successCallback(data.response);
          }
          if (dispatchSuccessMessage) {
            dispatch({
              type: actionTypes.ERROR_HANDLE,
              payload: {
                errToast: {
                  Text: data?.response?.MessageToast?.Text,
                  ImagePath: data?.response?.MessageToast?.ImagePath
                }
              },
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: actionTypes.GET_SOA_FILE_PATH_FAIL,
            error: _get(err, ["response", "data", "error"], {}),
          })
          if (typeof errCallBack === "function") {
            errCallBack();
          }
        })
    }
  }  

  function getBottomTabs() {
    return (dispatch) => {
      dispatch({ type: actionTypes.BOTTOM_TABS_BEGIN })
      getAxios(true)
        .get(APIendpoints.bottomTabs)
        .then(({ data }) => {
          dispatch({
            type: actionTypes.BOTTOM_TABS_DONE,
            payload: data.response,
          })
        })
        .catch((err) => {
          dispatch({
            type: actionTypes.BOTTOM_TABS_FAIL,
            error: _get(err, ["response", "data", "error"], {}),
          })
        })
    }
  }

  function getUserOutletMappingData(outletId, successCallback) {
    return (dispatch) => {
      dispatch({ type: actionTypes.GET_USER_OUTLET_MAPPING_BEGIN });
      getAxios(true)
        .get(APIendpoints.getUserOutletMapping(outletId))
        .then(({ data }) => {
          dispatch({ 
            type: actionTypes.GET_USER_OUTLET_MAPPING_DONE, 
            payload: data.response
          });
  
          if (typeof successCallback == "function") {
            successCallback(data)
          }
        })
        .catch((err) => {
          dispatch({
            type: actionTypes.GET_USER_OUTLET_MAPPING_FAIL,
            error: _get(err, ["response", "data", "error"], {}),
          });
        });
    };
  }

  function modifyUserOutletMapping(payload, successCallback) {
    return (dispatch) => {
      dispatch({ type: actionTypes.MODIFY_USER_OUTLET_MAPPING_BEGIN });
      getAxios(true)
        .put(APIendpoints.modifyUserOutletMapping, payload)
        .then(({ data }) => {
          dispatch({
            type: actionTypes.MODIFY_USER_OUTLET_MAPPING_DONE,
          })

          if (typeof successCallback == "function") {
            successCallback(data)
          }
        })
        .catch((err) => {
          dispatch({
            type: actionTypes.MODIFY_USER_OUTLET_MAPPING_FAIL,
            error: _get(err, ["response", "data", "error"], {}),
          })
        });
    }
  }

  function getUsersForOutletMapping(outletId, searchParams, successCallback) {
    return (dispatch) => {
      dispatch({ type: actionTypes.GET_USERS_FOR_OUTLET_MAPPING_BEGIN});
      getAxios(true)
        .get(APIendpoints.getUsersForOutletMapping(outletId, searchParams))
        .then(({ data }) => {
          dispatch({
            type: actionTypes.GET_USERS_FOR_OUTLET_MAPPING_DONE,
            payload: data?.response
          })

          if (typeof successCallback == "function") {
            successCallback(data)
          }
        })
        .catch((err) => {
          dispatch({
            type: actionTypes.GET_USERS_FOR_OUTLET_MAPPING_FAIL,
            error: _get(err, ["response", "data", "error"], {}),
          })
        })
    }
  }

  function resetUserForOutletMapping() {
    return (dispatch) => {
      dispatch({ type: actionTypes.GET_USERS_FOR_OUTLET_MAPPING_INIT });
    }
  }

  function getUserDeviceVerificationData(successCallback) {
    return (dispatch) => {
      dispatch({ type: actionTypes.GET_USER_DEVICE_VERIFICATION_BEGIN });
      getAxios(true)
        .get(APIendpoints.getUserDeviceVerification)
        .then(({ data, headers }) => {
          dispatch({ type: actionTypes.GET_USER_DEVICE_VERIFICATION_DONE, data: data.response });
          if (typeof successCallback === "function") {
            successCallback({ data: data.response });
          }
        })
        .catch((err) =>
          dispatch({
            type: actionTypes.GET_USER_DEVICE_VERIFICATION_FAIL,
            error: _get(err, ["response", "data", "error"], {}),
          })
        );
    };
  }

  function getEntityOwnersData(searchParams, successCallback) {
    return (dispatch) => {
      dispatch({ type: actionTypes.GET_ENTITY_OWNERS_BEGIN });
      getAxios(true)
        .get(APIendpoints.getEntityOwners(searchParams))
        .then(({ data, headers }) => {
          dispatch({ type: actionTypes.GET_ENTITY_OWNERS_DONE, data: data.response });
          if (typeof successCallback === "function") {
            successCallback({ data: data.response });
          }
        })
        .catch((err) =>
          dispatch({
            type: actionTypes.GET_ENTITY_OWNERS_FAIL,
            error: _get(err, ["response", "data", "error"], {}),
          })
        );
    };
  }
  function sendVerifyDeviceOtp(userId, successCallback, errCallBack) {
    return (dispatch) => {
      getAxios(true)
        .get(APIendpoints.sendDeviceVerificationOtp(userId))
        .then(({ data }) => {
          if (typeof successCallback === "function") {
            successCallback({ data: data.response });
          }
        }).catch((err) => {
          let errNew = _get(
            err,
            ["response", "data", "error", "message"],
            "Something went wrong!"
          );
          dispatch(setErrorToast(errNew, "error"));
          if (typeof errCallBack === "function") {
            errCallBack(_get(err, ["response", "data", "error"], {}));
          }
        })
    };
  }
  function verifyDeviceOtp(params, successCallback, errorCallBack) {
    return (dispatch) => {
      dispatch({ type: actionTypes.VERIFY_DEVICE_OTP_BEGIN })
      getAxios(true)
        .post(APIendpoints.verifyDeviceVerificationOtp, params)
        .then(({ data }) => {
          dispatch({
            type: actionTypes.VERIFY_DEVICE_OTP_DONE,
            payload: data,
          })
          if (typeof successCallback === "function") {
            successCallback(data);
          }
        })
        .catch((err) => {
          dispatch({
            type: actionTypes.VERIFY_DEVICE_OTP_FAIL,
            error: _get(err, ["response", "data", "error"], {}),
          })
          if (typeof errorCallBack === "function") {
            errorCallBack(_get(err, ["response", "data", "error"], {}));
          }
        })
    }
  }

  function getOwnerDetails() {
    return (dispatch) => {
      dispatch({ type: actionTypes.GET_OWNER_DETAILS_BEGIN })
      getAxios(true).get(APIendpoints.getOwnerDetails).then(({data})=>{
        dispatch({type:actionTypes.GET_OWNER_DETAILS_DONE,data:data?.response})
      }).catch((err)=>{
        dispatch({type:actionTypes.GET_OWNER_DETAILS_FAIL,data:_get(err, ["response", "data", "error"], {})})
      })
    }
  }

  function callCustomEndpoint(props,params) {
    return (dispatch) => {
      dispatch({type:actionTypes.DOCUMENT_VERIFICATION_DETAILS_BEGIN})
      //PUT METHOD
      if (props?.Method === "PUT"){
        getAxios(true).put(props?.Endpoint,params).then(({data})=>{
          dispatch({type:actionTypes.DOCUMENT_VERIFICATION_DETAILS_DONE,data:data?.response})
        }).catch((err)=>{
        dispatch({type:actionTypes.DOCUMENT_VERIFICATION_DETAILS_FAIL,data:_get(err, ["response", "data", "error"], {})})
        })
      }
    }
  }

function updateOrderPlaceErrorCode() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ORDER_PLACE_FAIL,
      error: {},
      errorCode: ""
    });
  }
}

function addUserPhoneNumberAndReonboard(phoneNumber) {
  let params = {
    "PhoneNumber": phoneNumber,
    "Source": "ADD_USER_PHONE_NUMBER"
  };

  return (dispatch) => {
    dispatch({ type: actionTypes.ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER_BEGIN })
    getAxios(true)
      .put(APIendpoints.addUserPhoneNumberAndReonboard, params)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER_DONE,
          payload: data.response,
        })
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
          errorData: _get(err, ["response", "data"], {}),
        })
      })
  }
}

function addUserPhoneNumberAndReonboardVerifyOTP(phoneNumber, otp) {
  let params = {
    "PhoneNumber": phoneNumber,
    "OTP": otp,
    "Source": "ADD_USER_PHONE_NUMBER"
  };

  return (dispatch) => {
    dispatch({ type: actionTypes.ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER_VERIFY_OTP_BEGIN })
    getAxios(true)
      .put(APIendpoints.addUserPhoneNumberAndReonboard, params)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER_VERIFY_OTP_DONE,
          payload: data.response,
        })
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER_VERIFY_OTP_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
          errorData: _get(err, ["response", "data"], {}),
        })
      })
  }
}

function setAppMode(appMode, path = "") {
  const params = {
    AppMode : appMode,
    Source : path === "/buyer/cart" ? "CART" : "",
  };

  return (dispatch) => {
    getAxios(true)
      .put(STATIC_ENDPOINT.APP_MODE, params)
      .then(({ data }) => {
        // map data to modal UI
        const popupModalData = getAppModeModalDataFromResponse(data?.response?.ExpressBanner?.PopupData);
        popupModalData.visible = data?.response?.ExpressBanner !== null && data?.response?.ExpressBanner?.PopupData !== undefined && path !== "/buyer/cart";

        // dispatch state update
        dispatch({ type: actionTypes.SET_APP_MODE, payload: {
          appMode: appMode,
          popupData: popupModalData
        }});

        // set cookie
        setCookieForEntity(COOKIE_KEYS.APP_MODE, COOKIE_KEYS.APP_MODE, appMode, COOKIE_TTL_FOR_WEB);

        // reload page is popup is not visible
        if (!popupModalData.visible) {
          window.location.reload();
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.SET_APP_MODE,
          error: _get(err, ["response", "data", "error"], {}),
          errorData: _get(err, ["response", "data"], {}),
        })
      })
  }
}

function dismissAppModePopup(callBack) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DISMISS_APP_MODE_POPUP,
      payload: true,
    });

    handleCallBack(callBack);
  };
}

const setCombinedCartCheckout = (state) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.COMBINED_CART_CHECKOUT, payload: {
      isEnabled : state,
      modal:{
        isVisible: false,
      }
    }});
  };
}

const setCombinedCartCheckoutPopup = (state) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.DISMISS_COMBINED_CART_CHECKOUT_POPUP, payload: {
      isVisible : state
    }});
  };
}

const getPosLinkStatus = (requestBody, callBack) => {
  return dispatch => {
    dispatch({ type: actionTypes.GET_POS_LINK_BEGIN });
    getAxios(true)
      .put(STATIC_ENDPOINT.POS_LINK, requestBody)
      .then(({data}) => {
        dispatch({ type: actionTypes.GET_POS_LINK_DONE , payload: data.response});
        if (typeof callBack === "function") {
          callBack(data.response);
        }
      })
      .catch(err => {
        dispatch({
          type: actionTypes.GET_POS_LINK_FAIL,
          error: _get(
            err,
            ["response", "data", "error", "message"],
            "Something went wrong!"
          ),
          errorData: _get(err, ["response", "data"], {}),
        });
      });
  };
}

const setPosLinkStatus = (requestBody, callBack) => {
  return dispatch => {
    dispatch({ type: actionTypes.SET_POS_LINK_BEGIN });
    getAxios(true)
      .put(STATIC_ENDPOINT.POS_LINK, requestBody)
      .then(({data}) => {
        dispatch({ type: actionTypes.SET_POS_LINK_DONE , payload: data.response});
        if (typeof callBack === "function") {
          callBack(data.response);
        }
      })
      .catch(err => {
        dispatch({
          type: actionTypes.SET_POS_LINK_FAIL,
          error: _get(
            err,
            ["response", "data", "error", "message"],
            "Something went wrong!"
          ),
          errorData: _get(err, ["response", "data"], {}),
        });
      });
  };
}

const getReceiverDetails = (callBack) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_RECEIVER_DETAILS_BEGIN });
    getAxios(true)
      .get(STATIC_ENDPOINT.RECEIVER_DETAILS)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.GET_RECEIVER_DETAILS_DONE,
          payload: data.response,
        });
        if (typeof callBack === "function") {
          callBack(data.response);
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_RECEIVER_DETAILS_FAIL,
          error: _get(
            err,
            ["response", "data", "error", "message"],
            "Something went wrong!"
          ),
          errorData: _get(err, ["response", "data"], {}),
        });
      });
  };
};

const setReceiverDetails = (requestBody, callBack) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_RECEIVER_DETAILS_BEGIN });
    getAxios(true)
      .post(STATIC_ENDPOINT.RECEIVER_DETAILS, requestBody)
      .then(({ data }) => {
        dispatch({
          type: actionTypes.SET_RECEIVER_DETAILS_DONE,
          payload: data.response,
        });
        if (typeof callBack === "function") {
          callBack(data.response);
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.SET_RECEIVER_DETAILS_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
          errorData: _get(err, ["response", "data", "error", "message"], {}),
        });
      });
  };
};

export {
  getUserData,
  resendEmail,
  changeName,
  toggleDAModal,
  changeEmail,
  changePhone,
  changePassword,
  sendReferralEmailInvite,
  acceptTnC,
  getOverDue,
  getCommercialBanners,
  getTestimonials,
  getCities,
  getDeliveryTimeSlots,
  getOutlets,
  getReferral,
  getDesignations,
  switchOutlet,
  getConfig,
  verifyEmail,
  fetchMechantFromZomato,
  removeSignedInFromZomato,
  closeAppInstallPrompt,
  refundToWallet,
  updateWhatsappNotificationStatus,
  getPopup,
  pastOrders,
  referralPopup,
  shareAppLink,
  getAppLink,
  tourNextStep,
  getRewards,
  updatePayLater,
  trackPopUp,
  trackingWithoutAuth,
  getConsumerRail,
  setDeliverySlot,
  setPinCode,
  semiServiceable,
  getFAQs,
  setQuestion,
  getFAQAnswer,
  resetFAQAnswer,
  sideMenu,
  getOutletsOnly,
  addNpsRating,
  updateNpsRating,
  addNpsFeedback,
  getHomePage,
  updateAccount,
  updateOutlet,
  getMilestoneDetails,
  getHistoricalMilestoneDetails,
  autoOnboardingZomato,
  getZomatoStatus,
  updateUserPreferences,
  resetUserPreferenceStatus,
  getFeatureTickers,
  getCollaborativeBanners,
  getExigencyBanners,
  getCommunications,
  resetCommunications,
  getMaskedNumber,
  getCartDiscount,
  clearErrorToast,
  resetCartDiscount,
  setErrorToast,
  getHomePageV2,
  showNotification,
  showHeaderDeliveryNudge,
  showOutletSwitchModal,
  getAddressDetailsUsingLatLong,
  resetAddressDetailsUsingLatLong,
  getSearchLocations,
  updateOutletLocation,
  checkOutletLocation,
  resetSearchLocations,
  getHomeCategoryRails,
  resetHomeCategoryRails,
  changeDeliverySlot,
  verifyUser,
  verifyUserOtp,
  resetVerifyUserData,
  resetVerifyUserOTPData,
  generatePayloadToken,
  generateAccessToken,
  showChatBot,
  getOnboardingStatus,
  completePersonalDetails,
  completeRestaurantDetails,
  documentVerificationViewDetails,
  verifyPanAndLegalEntityName,
  uploadDocuments,
  pollDocuments,
  completeUploadDetails,
  getOfferDetails,
  toggleSOAModal,
  getSOAOptions,
  getSOAFilePath,
  getBottomTabs,
  getUserOutletMappingData,
  modifyUserOutletMapping,
  getUsersForOutletMapping,
  resetUserForOutletMapping,
  getUserDeviceVerificationData,
  getEntityOwnersData,
  sendVerifyDeviceOtp,
  verifyDeviceOtp,
  updateOrderPlaceErrorCode,
  getOwnerDetails,
  callCustomEndpoint,
  addUserPhoneNumberAndReonboardVerifyOTP,
  addUserPhoneNumberAndReonboard,
  setAppMode,
  dismissAppModePopup,
  setCombinedCartCheckout,
  setCombinedCartCheckoutPopup,
  setPosLinkStatus,
  getPosLinkStatus,
  getReceiverDetails,
  setReceiverDetails,
};