import _get from "lodash-es/get";

import getAxios from "../../utils/axios";
import actionTypes from "../actionTypes";
import APIendpoints from "../../utils/constants/APIendpoints";
import { setToastMessage } from "./toastDispatcher";

function getCredit(requestData) {
  return (dispatch) => {
    dispatch({ type: actionTypes.CREDIT_GET_BEGIN });
    getAxios(true)
      .post(APIendpoints.getCredit, requestData)
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.CREDIT_GET_DONE,
          creditData: data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.CREDIT_GET_FAIL,
          error: _get(
            err,
            ["response", "data", "error", "message"],
            "Something went wrong!"
          ),
        });
      });
  };
}

function walletTransactions(
  requestData,
  creditsPerPage,
  isFirstTime,
  isReset = false
) {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_WALLET_TRANSACTIONS_BEGIN });
    getAxios(true)
      .get(
        APIendpoints.walletTransactions(
          requestData.PageNo,
          creditsPerPage,
          requestData.filter
        )
      )
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.GET_WALLET_TRANSACTIONS_DONE,
          walletTransactions: data.response,
          hasNextPage:
            requestData.PageNo * creditsPerPage < data.response.TotalCount,
          isFirstTime: isFirstTime,
          isReset,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_WALLET_TRANSACTIONS_FAIL,
          error: _get(
            err,
            ["response", "data", "error", "message"],
            "Something went wrong!"
          ),
        });
      });
  };
}

function walletRecharge(amount) {
  return (dispatch) => {
    dispatch({ type: actionTypes.WALLET_RECHARGE_BEGIN });
    getAxios(true)
      .post(APIendpoints.walletRecharge, {
        amount,
      })
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.WALLET_RECHARGE_DONE,
          payment: data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.WALLET_RECHARGE_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
          errorData: _get(err, ["response", "data", "error", "errorToast"], {}),
        });
      });
  };
}

const getCreditTransactions = (startDate, endDate, callback, PageNo) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_CREDIT_TRANSACTIONS_BEGIN });
    getAxios(true)
      .get(APIendpoints.getCreditTransactions(startDate, endDate, PageNo))
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.GET_CREDIT_TRANSACTIONS_DONE,
          payload: data.response,
          params: {
            startDate,
            endDate,
            PageNo,
          },
        });
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_CREDIT_TRANSACTIONS_FAIL,
          error: _get(
            err,
            ["response", "data", "error", "message"],
            "Something went wrong!"
          ),
        });
      });
  };
};

const getCreditTransactionDetails = (txnId, callBack, errCallBack) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_CREDIT_TRANSACTION_DETAIL_BEGIN });
    getAxios(true)
      .get(APIendpoints.getCreditTransactionDetails(txnId))
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.GET_CREDIT_TRANSACTION_DETAIL_DONE,
          payload: data.response,
        });
        if (typeof callBack === "function") {
          callBack(data.response);
        }
      })
      .catch((err) => {
        let errNew = _get(
          err,
          ["response", "data", "error", "message"],
          "Something went wrong!"
        );
        dispatch({
          type: actionTypes.GET_CREDIT_TRANSACTION_DETAIL_FAIL,
          error: errNew,
        });
        dispatch(setToastMessage(errNew, "error"));
        if (typeof errCallBack === "function") {
          errCallBack();
        }
      });
  };
};

const makeCreditPayment = (params, callBack, errCallBack) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_MAKE_CREDIT_PAYMENT_BEGIN });
    getAxios(true)
      .post(APIendpoints.makeCreditPayment, params)
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.GET_MAKE_CREDIT_PAYMENT_DONE,
          payload: data.response,
        });
        if (typeof callBack === "function") {
          callBack(data.response);
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_MAKE_CREDIT_PAYMENT_FAIL,
          error: _get(
            err,
            ["response", "data", "error", "message"],
            "Something went wrong!"
          ),
        });
        if (typeof errCallBack === "function") {
          errCallBack();
        }
      });
  };
};

const getCreditPayments = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_CREDIT_PAYMENTS_BEGIN });
    getAxios(true)
      .get(APIendpoints.getCreditPayments)
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.GET_CREDIT_PAYMENTS_DONE,
          payload: data.response,
        });
      })
      .catch((err) => {
        let errNew = _get(
          err,
          ["response", "data", "error", "message"],
          "Something went wrong!"
        );
        dispatch({
          type: actionTypes.GET_CREDIT_PAYMENTS_FAIL,
          error: errNew,
        });
        dispatch(setToastMessage(errNew, "error"));
      });
  };
};

const getCreditPaymentsV2 = (params, successCallback) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_CREDIT_PAYMENTS_V2_BEGIN });
    getAxios(true)
      .get(
        APIendpoints.getCreditPaymentsV2(
          params.Amount,
          params.Index,
          params.IsEdited,
          params.PaymentMethodId,
          params.PaymentMethodType
        )
      )
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.GET_CREDIT_PAYMENTS_V2_DONE,
          payload: data.response,
        });
        if (typeof successCallback === "function") {
          successCallback(data.response);
        }
      })
      .catch((err) => {
        let errNew = _get(
          err,
          ["response", "data", "error", "message"],
          "Something went wrong!"
        );
        dispatch({
          type: actionTypes.GET_CREDIT_PAYMENTS_V2_FAIL,
          error: errNew,
        });
        dispatch(setToastMessage(errNew, "error"));
      });
  };
};

const resetCreditPayments = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_CREDIT_PAYMENTS_INIT,
    });
  };
};

const toggleCreditModal = (show) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.TOGGLE_CREDIT_MODAL_DONE, payload: show });
  };
};

function getCreditOboarding(requestData) {
  return (dispatch) => {
    dispatch({ type: actionTypes.CREDIT_ONBOARDING_BEGIN });
    getAxios(true)
      .get(APIendpoints.getCreditOnboarding, requestData)
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.CREDIT_ONBOARDING_DONE,
          creditData: data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.CREDIT_ONBOARDING_FAIL,
          error: _get(
            err,
            ["response", "data", "error", "message"],
            "Something went wrong!"
          ),
        });
      });
  };
}

function getCreditPlans(callBack) {
  return (dispatch) => {
    dispatch({ type: actionTypes.CREDIT_PLANS_BEGIN });
    getAxios(true)
      .get(APIendpoints.getCreditPlans)
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.CREDIT_PLANS_DONE,
          creditPlans: data.response,
        });
        if (typeof callBack === "function") {
          callBack(false);
        }
      })
      .catch((err) => {
        let errNew = _get(
          err,
          ["response", "data", "error", "message"],
          "Something went wrong!"
        );
        dispatch(setToastMessage(errNew, "error"));
        dispatch({
          type: actionTypes.CREDIT_PLANS_FAIL,
          error: errNew,
        });
        if (typeof callBack === "function") {
          callBack(true);
        }
      });
  };
}

function getConsumerOTP(source, showSuccessToast, callBack, userPhoneNumber) {
  let phoneNumber = "";
  if (userPhoneNumber?.length) {
    phoneNumber = userPhoneNumber;
  }
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_CONSUMER_OTP_BEGIN });
    getAxios(true)
      .get(APIendpoints.getConsumerOTP(source, phoneNumber))
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.GET_CONSUMER_OTP_DONE,
          consumerOTP: data.response,
        });
        if (showSuccessToast) {
          dispatch(setToastMessage("OTP send successfully", "success"));
        }
        if (typeof callBack === "function") {
          callBack(false);
        }
      })
      .catch((err) => {
        let errNew = _get(
          err,
          ["response", "data", "error", "message"],
          "Something went wrong!"
        );
        let errData = _get(
          err,
          ["response", "data", "error", "data"],
          "Something went wrong!"
        );
        if (errNew !== "OTP_LIMIT_EXCEED") {
          dispatch(setToastMessage(errNew, "error"));
        }
        dispatch({
          type: actionTypes.GET_CONSUMER_OTP_FAIL,
          error: errNew,
        });
        if (typeof callBack === "function") {
          callBack(true, errNew, errData);
        }
      });
  };
}

function verifyConsumerOTP(params, callBack) {
  return (dispatch) => {
    dispatch({ type: actionTypes.VERIFY_CONSUMER_OTP_BEGIN });
    getAxios(true)
      .post(APIendpoints.verifyConsumerOTP, params)
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.VERIFY_CONSUMER_OTP_DONE,
          verifyOTP: data.response,
        });
        if (typeof callBack === "function") {
          callBack(false);
        }
      })
      .catch((err) => {
        let errNew = _get(
          err,
          ["response", "data", "error", "message"],
          "Something went wrong!"
        );
        let errData = _get(
          err,
          ["response", "data", "error", "data"],
          "Something went wrong!"
        );
        if (
          errNew !== "Incorrect OTP. Please try again." &&
          errNew !== "OTP_LIMIT_EXCEED"
        ) {
          dispatch(setToastMessage(errNew, "error"));
        }
        dispatch({
          type: actionTypes.VERIFY_CONSUMER_OTP_FAIL,
          error: errNew,
        });
        if (typeof callBack === "function") {
          callBack(true, errNew, errData);
        }
      });
  };
}

const resetCreditPlans = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CREDIT_PLANS_INIT });
  };
};

const resetVerifyConsumerOTP = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.VERIFY_CONSUMER_OTP_INIT });
  };
};

const resetGetConsumerOTP = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_CONSUMER_OTP_INIT });
  };
};

const getValidOutletsToSwitch = (source, callBack, outletKeyword) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_VALID_OUTLETS_BEGIN });
    getAxios(true)
      .get(APIendpoints.getValidOutlets(source, outletKeyword))
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.GET_VALID_OUTLETS_DONE,
          validOutletData: data.response,
        });
        if (typeof callBack === "function") {
          callBack(false, data.response);
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_VALID_OUTLETS_FAIL,
          error: _get(
            err,
            ["response", "data", "error", "message"],
            "Something went wrong!"
          ),
        });
        if (typeof callBack === "function") {
          callBack(true, null);
        }
      });
  };
};

const clearValidOutlets = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_VALID_OUTLETS_INIT,
    });
  };
};

const createServiceOrder = (reqData, successCallback) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CREATE_SERVICE_ORDER_BEGIN });
    getAxios(true)
      .post(APIendpoints.createServiceOrder, reqData)
      .then(({ data, headers }) => {
        dispatch({
          type: actionTypes.CREATE_SERVICE_ORDER_DONE,
          payload: data.response,
        });
        if (typeof successCallback === "function") {
          successCallback(data.response);
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.CREATE_SERVICE_ORDER_FAIL,
          error: _get(
            err,
            ["response", "data", "error", "message"],
            "Something went wrong!"
          ),
        });
      });
  };
};

function getWalletRechargeData(requestData, successCallback) {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_WALLET_RECHARGE_DATA_BEGIN });
    getAxios(true)
      .get(
        APIendpoints.walletRechargeData(
          requestData.PaymentMethodId,
          requestData.PaymentMethodType,
          requestData.Amount
        )
      )
      .then(({ data }) => {
        dispatch({
          type: actionTypes.GET_WALLET_RECHARGE_DATA_DONE,
          payload: data.response,
        });
        if (typeof successCallback === "function") {
          successCallback(data.response);
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_WALLET_RECHARGE_DATA_FAIL,
          error: _get(err, ["response", "data", "error"], {}),
          code: _get(err, ["response", "data", "error", "code"], null),
        });
      });
  };
}

export {
  getCredit,
  walletTransactions,
  walletRecharge,
  getCreditTransactions,
  getCreditTransactionDetails,
  getCreditPayments,
  toggleCreditModal,
  resetCreditPayments,
  makeCreditPayment,
  getCreditOboarding,
  getCreditPlans,
  getConsumerOTP,
  verifyConsumerOTP,
  resetGetConsumerOTP,
  resetVerifyConsumerOTP,
  resetCreditPlans,
  getValidOutletsToSwitch,
  clearValidOutlets,
  getCreditPaymentsV2,
  createServiceOrder,
  getWalletRechargeData,
};
