import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import styles from "./PopupModal.module.scss";
import RatingModal from "../../buyer/order/ratingModal/RatingModal";
import Cross from "sushiweb/Icons/all/Cross";
import {
  trackPopUp
} from "../../../redux/dispatchers/userDispatcher";
import { clearPaymentDetail } from "../../../redux/dispatchers/paymentDispatcher"
import { handleActionType } from "../../../utils/helpers";
import { HOME_DUE_PAYMENT } from "../../../utils/constants/others"
import BasePopUpModal from "./basePopUp/BasePopUp";
import Modal from "sushiweb/Modal/Modal";
import PayOnDeliveryModal from "../payOnDeliveryModal/PayOnDeliveryModal";
import NpsRatingModal from "../../buyer/npsuserrating/npsRatingModal/NpsRatingModal";
import GenericPopup from "./GenericPopup";
import { HTMLDiv } from "../HTMLDiv";
import HpPlusModalPopup from "./HpPlusModalPopup";

class PopupModal extends React.Component {
  state = {
    orderRating: null ,
    consentPopup: false,
  }

  closePopup = (e) => {
    if (e) {
      e.stopPropagation();
    }
    this.props.closePopup()
    this.props.actions.clearPaymentDetail()
  }

  getDuePopupTitle = (data) => {
    if(data && data.Orders && data.Orders.length) {
      const str = data.Orders.length > 1 ? `${data.Orders.length} orders`: "1 order"
      return `Payment due for last ${str}`
    }
    return ""
  }


  getCategoryAndSubCategoryNames({ categoryId, subCategoryId }) {
    const { v2Categories } = this.props;

    if (v2Categories.length === 0 || (!categoryId && !subCategoryId)) {
      return null;
    }

    if (subCategoryId) {
      for (let category of v2Categories) {
        for (let subCategory of category.children) {
          if (subCategory.id === subCategoryId) {
            return {
              categoryName: category.name,
              subCategoryName: subCategory.name,
            };
          }
        }
      }
    } else {
      const category = v2Categories.find((el) => el.id === categoryId);
      if (category) {
        return {
          categoryName: category.name,
          subCategoryName: "",
        };
      }
    }
    return null;
  }

  redirectPopup = () => {
    this.props.closePopup();
    const { popupData } = this.props;
    const trackParams = popupData.PopupData.CTA ? popupData.PopupData.CTA.Action.TrackingParams : popupData.PopupData.TrackingParams;
    this.props.actions.trackPopUp(trackParams);
    const actionData = this.props.popup.PopupData.ActionData;
    handleActionType(this.props.popup.PopupData.ActionType, actionData, this.props.history, {
      // getCategoryAndSubCategoryNames: (params) => this.getCategoryAndSubCategoryNames(params),
      sendReference: true
    });
  }

  trackPopUp = (url) => {
    const { popupData } = this.props;
    const trackParams = popupData.PopupData.CTA ? popupData.PopupData.CTA.Action.TrackingParams : popupData.PopupData.TrackingParams;
    this.props.actions.trackPopUp(trackParams);
    this.props.history.push(url)
  }

  render() {
    const { popup } = this.props;
    if (this.props.source === "/buyer/cart" && popup.PopupData) {
      if (popup.TemplateId === 1) {
        return (
          <div className={`${styles.modalSide} ${styles.modalSideTemplate1}`} style={{background: popup.PopupData.OuterBackgroundColor}}>
            <div className={styles.modalSideCross}>
              <Cross color="white" className="pointer" onClick={this.closePopup}/>
            </div>
            <HTMLDiv
              style={{color: popup.PopupData.MessageTextColor}}
              html={popup.PopupData.Message}
            />
            <img className="mt-2" style={{marginBottom: "-8px"}} src={popup.PopupData.ImageUrl} width="60px" alt="" />
            <div className={styles.inner} style={{background: popup.PopupData.InnerBackgroundColor}}>
              <div className="w-800 fs-22" style={{color: popup.PopupData.InnerTextColor}}>
                {popup.PopupData.InnerText}
              </div>
              <div className="fs-12 ls-3" style={{color: popup.PopupData.InnerTextColor}}>
                {popup.PopupData.InnerSubtext}
              </div>
            </div>
            <div className="btn btn-transparent-white mt-4 fs-14" onClick={() => this.trackPopUp("/buyer/catalog")}>
              {popup.PopupData.CTA.Text}
            </div>
          </div>
        )
      } else if (popup.TemplateId === 2) {
        return (
          <div 
            className={`pointer ${styles.modalSide} ${styles.modalSideTemplate2} ${popup.PopupData.Theme === "light" ? styles.modalSideTemplate2Light : styles.modalSideTemplate2Dark}`} 
            style={{backgroundImage: `url('${popup.PopupData.BackgroundImageUrl}')`}}
            onClick={() => this.trackPopUp("/paylater")}
          >
            <div className={styles.modalSideCross}>
              <Cross color="white" className="pointer" onClick={this.closePopup}/>
            </div>
            <img src={popup.PopupData.HeadingImageUrl} alt="Zomato logo"/>
            <HTMLDiv
              className={styles.text1}
              html={ popup.PopupData.HeadingText1 }
            />
            <HTMLDiv
              className={styles.text2}
              html={ popup.PopupData.HeadingText2 }
            />
             <HTMLDiv
              className={styles.text3}
              html={ popup.PopupData.Text }
            />
            <button>
              {popup.PopupData.CTA.Text}
            </button>
          </div>
        )
      } else if(this.props.popup?.PopupType === 'HP_PLUS_BOTTOMSHEET'){
        return (
          <HpPlusModalPopup popUpData={popup?.PopupData} onClose={(e) => this.closePopup(e)}/>
        )
      }else {
        return null;
      }
    } else if (this?.props?.popup?.PopupData?.ActionType === "ORDER_RATE" && (this?.props?.location?.pathname === "/") ) {
      return (
        <RatingModal
          visible={true}
          closeModal={this.closePopup}
          orderRating={this.state.orderRating}
          isFromPopup={true}
          order={{
            OrderId: this.props.popup.PopupData.OrderId,
            OrderNo: this.props.popup.PopupData.ActionData.OrderNumber}
          }
          toggleSuccessModal={this.props.toggleFeedbackSuccess}
        />
      )
    }

    else if (this.props.popup.PopupData?.ActionType === "ORDER_RATE" && (this.props?.location?.pathname !== "/") ) {
      return (
        null
      )
    } else if (this.props.popup.PopupType === "CONSENT") {
      return (
        <GenericPopup
          visible={true}
          showCloseIcon={false}
          isCentered={true}
          type={1}
          Blocking = {true}
          data = {this.props.popup}
          handleCTA = {()=> {handleActionType(this.props.popup.PopupData?.CTA?.Action?.ActionType ,null , null , null , this.props.popup.PopupData?.CTA?.Action?.TrackingParams , this.props.actions.trackPopUp   ) ; this.closePopup()}}
          onClose={(e) => this.closePopup(e)}
        />
      )
    } else if (this.props.popup.PopupType === "PAYMENT_DUE_POPUP" && this.props.location.pathname === "/" ) {
      return (
        <PayOnDeliveryModal
          dueOrders={this.props.popup.PopupData}
          orders={this.props.popup.PopupData.Orders}
          showModal={true}
          onModalClose={this.closePopup}
          paymentDetails={this.props.paymentV2}
          title={this.getDuePopupTitle(this.props.popup.PopupData)}
          source={HOME_DUE_PAYMENT}
          toastOpenHandler={this.props.toastOpenHandler}
        />
      )
    }
    else if (this.props.popup.PopupType === "NPS" && this.props.location.pathname === "/") {
      return (
        <NpsRatingModal 
          popupData={this.props.popup.PopupData}
          visible={true}
          closeModal={this.closePopup}
          toggleFeedbackSuccess={this.props.toggleFeedbackSuccess}
        />
      )
    }
    else if (this.props.popup.PopupType === 'BASE_POPUP') {
      const isMobScreenSize = window.outerWidth < 992;
      return (
        <Modal
          visible={true}
          title=""
          type={isMobScreenSize ? "small" : "medium"}
          showCloseIcon={false}
          isCentered={true}
          onClose={(e) => this.closePopup(e)}
          >
          <BasePopUpModal
            popUp={this.props.popup} 
            onClose={(e) => this.closePopup(e)} 
            isMobileScreenSize={isMobScreenSize}
            trackPopUp={() => this.trackPopUp('/buyer/rewards')}
          />
        </Modal>
      )
    }
    else if (this.props.popup.PopupType === 'HP_PLUS_BOTTOMSHEET') {
      return (
        <HpPlusModalPopup popUpData={popup?.PopupData} onClose={(e) => this.closePopup(e)}/>
      )
    }
    
   
  
    return (
      <div className={styles.modal}>
        <div className={styles.overlay} onClick={this.closePopup}></div>
        <div className={styles.popup} style={{"cursor": "pointer"}}>
          <Cross className={styles.cross} onClick={this.closePopup}/>
          <img 
            src={this.props.popup?.PopupData?.Image}
            onClick={this.redirectPopup}
            alt=""
          />
        </div>
      </div>
    )
  }
}

export default withRouter(connect(
  (state) => ({
    // data
    popupData: state.user.popupData,
    trackClickStatus: state.status.user_trackClick,
    v2Categories: state.catalog.v2Categories,
    paymentV2: state.payment.transaction || {},
  }),
  (dispatch) => ({
    actions: bindActionCreators({ trackPopUp, clearPaymentDetail },
      dispatch
    ),
  })
)(PopupModal));
