function generateStatusActionTypes(baseTypes) {
    const actionTypes = {};
    for (const baseType of baseTypes) {
        actionTypes[`${baseType}_BEGIN`] = `${baseType}_BEGIN`;
        actionTypes[`${baseType}_DONE`] = `${baseType}_DONE`;
        actionTypes[`${baseType}_FAIL`] = `${baseType}_FAIL`;
    }
    return actionTypes;
}

function generateNoStatusActionTypes(baseTypes) {
    const actionTypes = {};
    for (const baseType of baseTypes) {
        actionTypes[baseType] = baseType;
    }
    return actionTypes;
}

export default {
  ...generateNoStatusActionTypes([
    "REGISTER_ALL_STEPS_INIT",
    "REGISTER_CLEAR_DATA",
    "REGISTER_STEP_SAVE",
    "CLOSE_APP_INSTALL_PROMPT",
    "FORGOT_PASSWORD_VERIFY_OTP_INIT",
    "REGISTER_HAPPY_STEP_2_SAVE",
    "REMOVE_ZOMATO_SIGNEDIN",
    "REFRESH_CATALOG_SEARCH_PRODUCTS_FOR_BUYER",
    "RESET_NOTIFICATION_COUNT",
    "RESET_ALTERNATE_PRODUCTS",
    "GET_POPUP",
    "GET_POPUP_INIT",
    "ORDER_REORDER_INIT",
    "HOME_TOUR_BEGIN",
    "ADD_AUTO_SUGGEST_HISTORY",
    "RESET_SEARCH_SUGGESTION",
    "SET_FAQ",
    "RESET_FAQ",
    "RESET_OFFERS",
    "UPDATE_CATALOGUE_PRODUCT",
    "GET_PAYMENT_NUDGES",
    "CLEAR_PAYMENT_NUDGE",
    "CLEAR_TRANSACTION_DETAILS",
    "REQUEST_PRODUCT_SUGGESTION_CLEAR",
    "UPDATE_USER_PREFERENCE_INIT",
    "GET_COMMUNICATIONS_INIT",
    "GET_CREDIT_TRANSACTION_DETAIL_INIT",
    "GET_CREDIT_PAYMENTS_INIT",
    "SET_TOAST_MESSAGE_INIT",
    "GET_CONSUMER_OTP_INIT",
    "VERIFY_CONSUMER_OTP_INIT",
    "CREDIT_PLANS_INIT",
    "TOGGLE_DA_MODAL",
    "TOGGLE_SIMILAR_ITEMS_MODAL",
    "CATALOG_ACTION_SET",
    "GET_ENTITIES_INIT",
    "ERROR_HANDLE",
    "ERROR_CLEAR",
    "ERROR_TOAST_SET",
    "PAYMENT_INFO_POPUP",
    "FILTERS_SET",
    "FILTERS_CLEAR",
    "SHOW_NOTIFICATION",
    "SHOW_HEADER_DELIVERY_NUDGE",
    "GET_CONSUMER_REFUND_JOURNEY_INIT",
    "GET_VALID_OUTLETS_INIT",
    "SHOW_OUTLET_SWITCH_MODAL",
    "GET_SEARCH_BAR_VERTICALS",
    "GET_ADDRESS_DETAILS_INIT",
    "CHECK_OUTLET_LOCATION_INIT",
    "GET_OFFER_DETAILS_INIT",
    "GET_SEARCH_LOCATIONS_INIT",
    "GET_HOME_PAGE_CATEGORY_RAILS_INIT",
    "VERIFY_USER_INIT",
    "VERIFY_USER_OTP_INIT",
    "SHOW_CHATBOT",
    "GET_DELIVERY_SLOTS_FOR_PACKAGE_INIT",
    "GET_PAYMENT_INFO_NO_RELOAD",
    "GET_SOA_OPTIONS",
    "TOGGLE_SOA_MODAL",
    "GET_SOA_FILE_PATH",
    "UPDATE_INGREDIENT_LIST",
    "BOTTOM_TABS",
    "GET_USERS_FOR_OUTLET_MAPPING_INIT",
    "TOGGLE_SEARCH",
    "CLEAR_AUTO_SUGGEST_HISTORY",
    "ORDER_ISSUE_MODAL_PANEL",
    "ORDER_ISSUE_MODAL_NEXT_PANEL",
    "ORDER_ISSUE_MODAL_STATE_FORWARD",
    "ORDER_ISSUE_MODAL_STATE_BACKWARD",
    "ORDER_ISSUE_SET_SELECTED_ITEMS",
    "ORDER_ISSUE_SET_SELECTED_ITEMS_MAP",
    "ORDER_ISSUE_SET_ORDER_ISSUE_DETAILS",
    "ORDER_ISSUE_UPDATE_SELECTED_ITEMS_MAP",
    "ORDER_ISSUE_MODAL_CLEANUP",
    "ORDER_ISSUE_SUCCESS",
    "SET_APP_MODE",
    "SET_APP_MODE_INFO",
    "DISMISS_APP_MODE_POPUP",
    "COMBINED_CART_CHECKOUT",
    "DISMISS_COMBINED_CART_CHECKOUT_POPUP",
    "CART_CHECKOUT_HEADER_UPDATE",
  ]),
  ...generateStatusActionTypes([
    "AUTH_SIGN_IN",
    "AUTH_SIGN_OUT",
    "USER_GET_DATA",
    "USER_RESEND_EMAIL",
    "USER_CHANGE_NAME",
    "USER_CHANGE_EMAIL",
    "USER_CHANGE_PHONE",
    "USER_CHANGE_PASSWORD",
    "CATALOG_GET_ALL_PRODUCTS",
    "CATALOG_GET_ALL_PRODUCTS_FOR_BUYER",
    "CATALOG_GET_CATEGORY_PRODUCTS",
    "CATALOG_GET_CATEGORY_PRODUCTS_FOR_BUYER",
    "CATALOG_GET_BANNER_PRODUCTS_FOR_BUYER",
    "CATALOG_SEARCH_PRODUCTS_FOR_BUYER",
    "CATALOG_GET_SEARCH_SUGGESTIONS",
    "CART_ADD",
    "CART_UPDATE",
    "CART_GET",
    "CART_V2_GET",
    "CART_DELETE",
    "CART_CHECKOUT",
    "CART_CLEAR",
    "OUTLET_SWITCH",
    "CREDIT_GET",
    "STATEMENT_DOWNLOAD",
    "STATEMENT_EMAIL",
    "ORDER_GET_ALL",
    "ORDER_GET_ONE",
    "ORDER_PLACE",
    "ORDER_REORDER",
    "ORDER_ADD_RATING",
    "CREDIT_NOTE_GET",
    "PAYMENT_GET_ACCESS_CODE",
    "PAYMENT_GET_HASH",
    "PAYMENT_UPDATE_STATUS",
    "PAYMENT_GET_OPTIONS",
    "PAYMENT_GET_OPTIONS_FOR_DROPDOWN",
    "PAYMENT_MAKE_PENDING",
    "GET_INVOICE_DETAILS",
    "OTP_VERIFY",
    "OTP_RESEND",
    "GET_ENUM",
    "REGISTER_STEP_ONE",
    "REGISTER_STEP_ONE_2",
    "UPDATE_REG_STEP_ONE",
    "REGISTER_STEP_TWO",
    "REGISTER_STEP_TWO_2",
    "REGISTER_STEP_THREE",
    "SET_REGISTER_DATA",
    "UPLOAD_PAN_IMAGE",
    "UPLOAD_FSSAI_IMAGE",
    "UPLOAD_GST_IMAGE",
    "UPLOAD_PAN_IMAGE_2",
    "UPLOAD_FSSAI_IMAGE_2",
    "UPLOAD_GST_IMAGE_2",
    "VALIDATE_GST",
    "VALIDATE_PHONE_NUMBER",
    "REGISTER_VERIFY_OTP",
    "GET_OTP",
    "FORGOT_PASSWORD_ENTER_PHONE",
    "FORGOT_PASSWORD_VERIFY_OTP",
    "FORGOT_PASSWORD_RESEND_OTP",
    "REGISTER_ALL_STEPS",
    "SEND_REFERRAL_EMAIL_INVITE",
    "ACCEPT_TNC",
    "VALIDATE_REFERRAL_CODE",
    "SET_REFERRAL_CODE",
    "PROMOTION_VISIBILITY",
    "OFFERS_GET_ALL",
    "OFFERS_GET_FEATURED",
    "OFFERS_GET_TRIAL",
    "VALIDATE_PINCODE",
    "GET_OVERDUE",
    "GET_COMMERCIAL_BANNER",
    "GET_FEATURE_TICKER",
    "GET_COLLABORATIVE_BANNERS",
    "GET_EXIGENCY_BANNERS",
    "V2_CATALOG_GET_CATEGORIES_AND_SUB_CATEGORIES",
    "TESTIMONIALS",
    "USER_GET_CITIES",
    "USER_GET_DELIVERY_TIME_SLOTS",
    "USER_GET_DESIGNATIONS",
    "USER_GET_OUTLETS",
    "USER_GET_REFERRAL",
    "CONFIG_GET",
    "VERIFY_EMAIL",
    "HAPPY_REGISTER_STEP_ONE",
    "HAPPY_REGISTER_STEP_TWO",
    "HAPPY_REGISTER_STEP_THREE",
    "FETCH_MERCHANT_ZOMATO",
    "UPLOAD_ZONBOARD_PAN_IMAGE",
    "VERIFY_ZOMATO_PHONE",
    "NOTIFICATIONS_GET",
    "NOTIFICATIONS_GET_STATUS",
    "NOTIFICATIONS_MARK_READ",
    "LOCATION_VALIDATE",
    "UPDATE_LOCATION",
    "ALTERNATE_PRODUCTS",
    "VALIDATE_PAN",
    "REFUND_TO_WALLET",
    "UPDATE_WHATSAPP_NOTIFICATION_STATUS",
    "GET_POPUP",
    "GET_POPUP_CHECKOUT",
    "GET_POPUP_HOME",
    "GET_PAST_ORDERS",
    "SHARE_APP_LINK",
    "GET_APP_LINK",
    "GET_WALLET_TRANSACTIONS",
    "WALLET_RECHARGE",
    "GET_REWARDS",
    "PAY_LATER_UPDATE",
    "TRACK_CLICK",
    "RATING_ORDER_GET",
    "GET_CONSUMER_RAIL",
    "SET_DELIVERY_SLOTS",
    "SET_PINCODE",
    "SEMI_SERVICEABLE",
    "GET_FAQ",
    "GET_FAQ_ANSWER",
    "GET_AUTH_OPTIONS",
    "USER_GET_OUTLETS_ONLY",
    "GET_AEROBAR",
    "GET_ACQUISITION_RAIL",
    "GET_DOWNLOAD_DA_APP",
    "ADD_NPS_RATING",
    "UPDATE_NPS_RATING",
    "REQUEST_PRODUCT",
    "REQUEST_PRODUCT_SUGGESION",
    "OUT_OF_STOCK_NOTIFICATION",
    "ADD_NPS_FEEDBACK",
    "GET_HOME",
    "GET_VERTICAL_PRODUCTS",
    "GET_CATEGORIES",
    "GET_PRODUCT_DETAIL",
    "UPDATE_ACCOUNT",
    "UPDATE_OUTLET",
    "AUTO_ONBOARDING_ZOMATO",
    "MILESTONE_DETAILS",
    "MILESTONE_HISTORICAL_DETAILS",
    "GET_ZOMATO_STATUS",
    "UPDATE_CHECKOUT",
    "GET_DELIVERY_SLOTS",
    "UPDATE_USER_PREFERENCE",
    "GET_COMMUNICATIONS",
    "GET_CREDIT_TRANSACTIONS",
    "GET_CREDIT_TRANSACTION_DETAIL",
    "GET_CREDIT_PAYMENTS",
    "TOGGLE_CREDIT_MODAL",
    "GET_MAKE_CREDIT_PAYMENT",
    "SET_TOAST_MESSAGE",
    "CREDIT_ONBOARDING",
    "CREDIT_PLANS",
    "GET_CONSUMER_OTP",
    "VERIFY_CONSUMER_OTP",
    "GET_BRAND_CAMPAIGN",
    "GET_BRAND_SUBCATEGORIES",
    "GET_VALID_OUTLETS",
    "VALIDATE_COUPON",
    "CLAIM_COUPON",
    "GET_PAYMENT_INFO",
    "UPDATE_PAYMENT_INFO",
    "GET_ENTITIES",
    "ADD_UPDATE_PHONE",
    "DELETE_CONTACT",
    "GET_FILTERS",
    "GET_CART_DISCOUNT",
    "GET_HOME_META_V2",
    "GET_CONSUMER_REFUND_JOURNEY",
    "GET_ADDRESS_DETAILS",
    "GET_SEARCH_LOCATIONS",
    "UPDATE_OUTLET_LOCATION",
    "CHECK_OUTLET_LOCATION",
    "GET_OFFER_DETAILS",
    "GET_HOME_PAGE_CATEGORY_RAILS",
    "CANCEL_ORDER",
    "GET_REASONS",
    "CHANGE_DELIVERY_SLOT",
    "GENERATE_PAYLOAD_TOKEN",
    "GENERATE_ACCESS_TOKEN",
    "VERIFY_USER",
    "VERIFY_USER_OTP",
    "GET_DELIVERY_SLOTS_FOR_PACKAGE",
    "SHOW_CHATBOT",
    "TOGGLE_SOA_MODAL",
    "GET_SOA_OPTIONS",
    "GET_SOA_FILE_PATH",
    "UPDATE_INGREDIENT_LIST",
    "BOTTOM_TABS",
    "GET_OWNERS_DASHBOARD",
    "SUBMIT_FEEDBACK",
    "GET_OWNERS_DASHBOARD_FILE_PATH",
    "GET_USER_OUTLET_MAPPING",
    "MODIFY_USER_OUTLET_MAPPING",
    "GET_USERS_FOR_OUTLET_MAPPING",
    "GET_USER_DEVICE_VERIFICATION",
    "GET_ENTITY_OWNERS",
    "VERIFY_DEVICE_OTP",
    "GET_CREDIT_PAYMENTS_V2",
    "CREATE_SERVICE_ORDER",
    "GET_CORPORATE_ANNOUNCEMENTS",
    "GET_ORDER_PAYMENT_INFO",
    "UPDATE_ORDER_PAYMENT_INFO",
    "GET_OWNER_DETAILS",
    "DOCUMENT_VERIFICATION_DETAILS",
    "DOCUMENT_NUMBER_VERIFICATION_DETAILS",
    "UPLOAD_DOCUMENT_DETAILS",
    "POLL_DOCUMENT_DETAILS",
    "ADD_ORDER_ISSUE",
    "UPLOAD_SINGLE_IMAGE",
    "ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER",
    "ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER_VERIFY_OTP",
    "GET_WALLET_RECHARGE_DATA",
    "GET_MOVE_CART_PRODUCTS",
    "SET_MOVE_CART_PRODUCTS",
    "GET_SETTINGS_CONFIG",
    "UPDATE_TAX_TOGGLE_SELECTION",
    "GET_POS_LINK",
    "SET_POS_LINK",
    "ADJUST_PG_CHARGE",
    "HP_PLUS_ADD_REMOVE",
    "HP_PLUS_PAYMENT_INFO",
    "ACQUIRE_LOCK",
    "GET_RECEIVER_DETAILS",
    "SET_RECEIVER_DETAILS",
    "GET_ORDER_DETAILS",
    "GET_LINK_RESOLUTION",
  ]),
};
