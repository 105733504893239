import React from "react";
import styles from "./ChatBot.module.scss";
import Image from "../Image/Image";
import APIendpoints from "../../../utils/constants/APIendpoints";
import { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  generateAccessToken,
  generatePayloadToken,
  showChatBot,
} from "./../../../redux/dispatchers/userDispatcher";
import { useEffect } from "react";
import { clearLocalStorage } from "./../../../utils/helpers";
function ChatBot(props) {
  const [openChatBox, setOpenChatBox] = useState(false);
  const [isVisible, setIsVisible] = useState(props.isSummaryPage);
  const [storedAccessToken, setStoredAccessToken] = useState("");
  const [storedPayloadToken, setStoredPayloadToken] = useState("");

  let payloadParam = {
    orderId: props.order?.ChatDetails?.CTA?.Action?.ActionData?.OrderId,
  };
  useEffect(() => {
    if (props.isSourceSideMenu || props.openChatBotDeeplink) {
      handleClosedChatBot({});
    }
  }, [props.isSourceSideMenu, props.openChatBotDeeplink]);

  useEffect(() => {
    if (window.localStorage.getItem("renderChatBoxEverywhere")) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, []);
  
  function ClosedChatBox() {
    return (
      <div
        className={`d-flex ${styles.boxShadow}`}
      
      >
        <div
          className={`d-flex align-items-center ${styles.ClosedChatBox} ${styles.ClosedChatBoxLeft}` }
          onClick={() => handleClosedChatBot(payloadParam)}
        >
          <Image source="../../../../assets/images/ChatCircleText.svg"> </Image>{" "}
          <span className="mr-4 ml-2 fs-16 fw-600"> Order help</span>
        </div>
        <div
          className={`d-flex align-items-center ${styles.ClosedChatBox} ${styles.ClosedChatBoxRight}`}
        >
          {!props.isSummaryPage ? (
            <Image
              source="../../../../assets/images/Cross.svg"
              onClick={() => handleCloseChatBotEveryWhere()}
              classname={`pointer`}
            >
              {" "}
            </Image>
          ) : (
            <Image
              source="../../../../assets/images/Chevron-up-with-circle-bg.svg"
              classname={`pointer`}
              onClick={() => handleClosedChatBot()}
            />
          )}
        </div>
      </div>
    );
  }


  const handleClosedChatBot = (payloadParam) => {
    if (props.isAuthenticated) {
      const timeNow = new Date().getTime();
      const expirationTime = localStorage.getItem("accessTokenExpiration");
      if (!expirationTime || timeNow >= expirationTime) {
          clearLocalStorage()
      }
      const payloadToken = localStorage.getItem("Payload_Token");
      const accessToken = localStorage.getItem("Access_Token");

      if (payloadToken && accessToken) {
        setStoredPayloadToken(payloadToken);
        setStoredAccessToken(accessToken);
      } else {
        props.actions.generateAccessToken(
          {},
          (data) => {
            localStorage.setItem("Access_Token", data.token);
            const expirationTime = new Date().getTime() + 60 * 60 * 1 * 1000;
            localStorage.setItem(
              "accessTokenExpiration",
              expirationTime.toString()
            );
            setStoredAccessToken(data.token);

            props.actions.generatePayloadToken(
              payloadParam,
              (payload) => {
                localStorage.setItem("Payload_Token", payload.token);
                setStoredPayloadToken(payload.token);
              },
              () => {}
            );
          },
          () => {}
        );
      }
    }

    const existingValue = window.localStorage.getItem(
      "renderChatBoxEverywhere"
    );
    if (!existingValue || existingValue !== "true") {
      window.localStorage.setItem("renderChatBoxEverywhere", "true");
      setIsVisible(true);
    }

    setOpenChatBox(true);
  };

  function handleCloseChatBotEveryWhere() {
   props.setSourceSideMenuFalse()
    setTimeout(() => {
      localStorage.removeItem("renderChatBoxEverywhere");
    }, 500);
    setIsVisible(false);
    clearLocalStorage()
  }

  function OpenChatBox() {
    return (
      <>
        <div className={styles.chatBoxWrapper}>
          <div
            className={`d-flex align-items-center justify-content-between ${styles.chatBoxHeader}`}
          >
            <div
              className={`d-flex align-items-center fs-16 fw-600 ${styles.chatBoxHeaderRight}`}
            >
              {" "}
              <Image source="../../../../assets/images/ChatCircleText.svg">
                {" "}
              </Image>{" "}
              <span className="ml-2"> Order help</span>
            </div>
            <div className={styles.chatBoxHeaderLeft}>
              {" "}
              <Image
                source="../../../../assets/images/Chevron-down.svg"
                classname={`pointer ml-2`}
                onClick={() => setOpenChatBox(false)}
              >
                {" "}
              </Image>{" "}
              {!props.isSummaryPage ? (
                <Image
                  source="../../../../assets/images/Cross.svg"
                  onClick={() => handleCloseChatBotEveryWhere()}
                  classname={`pointer ml-2`}
                >
                  {" "}
                </Image>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className={styles.chatBoxMain}>
            <iframe
              src={APIendpoints.chatBot(storedPayloadToken, storedAccessToken)}
              frameBorder="0"
              className={`${styles.chatFrame}`}
            ></iframe>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      {isVisible || props.isSummaryPage ? (
        <React.Fragment>
          {openChatBox ? (
            <div className={styles.chatPosition}> {OpenChatBox()}</div>
          ) : (
            <div className={` pointer ${styles.chatPosition}`}>
              {ClosedChatBox()}
            </div>
          )}
        </React.Fragment>
      ) : (
        <></>
      )}
    </>
  );
}

export default connect(
  (state) => ({
    accessToken: state.user.accessToken,
    accessToken: state.user.payloadToken,
    isAuthenticated: state.auth.isAuthenticated,
    order: state.order.order,
    showChatBot : state.user.showChatBotConfig?.showChatBot,
    isSummaryPage: state.user.showChatBotConfig?.isSummaryPage,
    renderChatBotEverywhere : state.user.showChatBotConfig?.renderChatBotEverywhere ,
  }),
  (dispatch) => ({
    actions: bindActionCreators(
      {
        generateAccessToken,
        generatePayloadToken,
        showChatBot
      },
      dispatch
    ),
  })
)(ChatBot);
