import React, { useEffect, useRef, useState } from "react";
import Input from "sushiweb/InputTypes/Input"
import { DOCUMENT_FSSAI, DOCUMENT_GST, DOCUMENT_PAN, DOCUMENT_TYPE_FSSAI, DOCUMENT_TYPE_GST, DOCUMENT_TYPE_PAN, PROFILE_INPUT_BORDER } from "../../../utils/constants/others";
import styles from "./CustomInputs.module.scss";
import Button from "sushiweb/Button";
import { uploadDocuments,pollDocuments } from "../../../redux/dispatchers/userDispatcher";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {useQuery} from "react-query"
import DocumentPolling from "./DocumentPolling";
import {QueryClient,QueryClientProvider,useQueryClient} from "react-query"
import SpinnerCircular from "sushiweb/Spinner/SpinnerCircular";
import { red, yellow, green, orange, purple, teal, blue } from "sushiweb/tokens/color";
import Image from "../Image/Image";
import stateTypes from "../../../utils/constants/stateTypes";

const queryClient = new QueryClient({
    defaultOptions : {
        queries: {
            refetchOnMount:false,
            refetchOnWindowFocus:false,
            refetchOnReconnect:false,
            staleTime:0,
            retry:0,
        }
    }
})

function UploadInputs (props) {

    const fileInputsRefs = useRef([]);

     
    

  let documentOne = useRef();
  let documentTwo = useRef();

  const handleUploadButton = (index) => {
        if(index % 2 == 0){
            documentOne.current.click()
        }else if (index % 2 > 0){
            documentTwo.current.click()
        }
  }

  

  const handleUpload = (index) => {
    if (fileInputsRefs.current?.length > 0){
      const fileInput = fileInputsRefs.current[index];
      fileInput.click();
    }
  }



  return (
    <div className={`d-flex flex-column`}>
        {
        props?.values?.map((option,index)=>{
                return <div className={`${styles.UploadInputsBoxAbove} d-flex flex-column justify-content-center`}>
                  {option?.ErrorImage?.length && option?.ErrorString?.length ? <div className={`d-flex justify-content-center align-items-center ${styles.UploadInputsErrorBox}`}><div  className={`d-flex justify-content-center align-items-center ${styles.UploadInputsErrorBoxTextAndImage}`}><Image   source={option?.ErrorImage}/><span className="d-flex align-items-center"  >{option?.ErrorString}</span></div></div>:<></>}
                  <div className={` ${styles.scrollableBoxWithImages} d-flex flex-column align-items-center justify-content-center`}>
                  <div className={`${styles.UploadInputsBox} d-flex justify-content-between align-items-center`}  >
                    <div className={`${styles.UploadInputsText} d-flex flex-column`}>
                        <span className={`fs-16 w-600 ${styles.documentHeading}`}><b>{option?.DocumentHeading}</b></span>
                        <span className={`fs-14 w-400 ${styles.documentSubHeading}`}>{option?.DocumentSubHeading}</span>
                    </div>

                   { option?.Status === ("SUCCESS") || option?.Status === ("SKIPPED_API_CHECK") ? 
                      <div className={`${styles.verifiedText} mt-2 d-flex justify-content-center align-items-center`}>
                        <Image source={option?.CTAImage} classname="mr-2 img16" alt="CROSS"/>{option?.CTAText}
                      </div> : option?.Status === ("PROCESSING")? <div className={`d-flex padding-8-16`}>
                          <span style={{color:'#256FEF'}} className="mr-2">{option?.CTAText}</span><SpinnerCircular
                            color={blue.z500}
                            size="small"/>
                        </div> : <Button onClick={() => handleUpload(index)} key={index}  size="small">
                      {option?.CTAText}
                    </Button>}
                    <input
                      hidden
                      type="file"
                      accept="application/pdf, image/png, image/jpeg"
                      ref={(input)=>fileInputsRefs.current[index]=input}
                      onChange={(e)=>props?.handleFileSelections(e,index,option)}
                    />
                    

                    
                    
                         
                   
                   
                </div>
                {option?.UploadCTA?.Action?.ActionData?.Image?.length ? <div className={`${styles.imageContainer}`}>
                    <hr className={`${styles.dashedLine} mb-20`}></hr>
                      <span className="mb-12px">
                        {option?.UploadCTA?.Action?.ActionData?.SubHeading}
                      </span>

                      <Image
                        
                        source={option?.UploadCTA?.Action?.ActionData?.Image}
                        classname={`pointer mt-14 ${styles.inheritImage}`}
                        //onClick={onClose}
                      />
                    </div>:<></>}
                </div>
              </div>

            })
            
        }

        {//instead of true we will be checking the api status if it is in begin state then this loader will appear.
          props?.uploadDocumentDetailsState?.state === stateTypes.STATE_BEGIN ? <div className={`${styles.UploadInputsLoader}`}><SpinnerCircular color={red.z500} size="large"/></div> : ""
        } 

       
       
    </div>
  ) 
}

export default withRouter(
    connect(
      (state) => ({
        uploadDocumentDetails: state.user.upload_document_details,

        //status
        uploadDocumentDetailsState: state.status.upload_document_details,
      }),
      (dispatch) => ({
        actions: bindActionCreators({
            uploadDocuments,
        },
          dispatch
        ),
      })
    )(UploadInputs)
);
